<a *ngFor="let impacter of impacters" target="_blank" [href]="impacter.url" class="card-item impacter-card im-{{impacter.id}} im-background d-flex text-decoration-none">
  <div class="impacter-header d-flex">
    <span class="impacter-name im-color flex-grow-1">{{impacter.firstname}} {{impacter.lastname}}</span>
    <div *ngIf="impacter.articleCount > 1" class="article-count positive-count">
        <span>
          {{impacter.articleCount}}
          {{'article.plural' | translate}}
        </span>
    </div>
    <span *ngIf="impacter.articleCount <= 1" class="article-count empty-count">{{'article.no-other-articles' | translate}}</span>
    <span *ngIf="impacter.articleCount === undefined" class="css-spinner"></span>
  </div>
  <div class="font-italic color-basic-600 im-color">
    <img class="positions-icon filter-basic-600" src="../../../../assets/images/icon-award.svg" alt="positions-icon">
    <span>
      {{impacter.positions.length}}
      {{(impacter.positions.length > 1 ? 'article.positions-since' : 'article.position-since') | translate}}
      {{impacter.oldestPositionYear}}
    </span>
  </div>
  <div class="impacter-positions color-basic-800">
    <div *ngFor="let position of impacter.relevantPositions" class="impacter-position">
      <span *ngIf="position.isOld" class="font-italic color-basic-600">{{'content.old' | translate}} </span>
      <span class="mr-1" [ngClass]="{'color-basic-600 font-italic': position.isOld}">{{position.role}}</span>
      <span class="position-territory color-basic-600 font-italic">({{position.territory.name}})</span>
    </div>
  </div>
</a>
