<div class="example-section">
  <div class="font-weight-bold title">{{'template.example' | translate}}</div>
  <div class="list">
    <div class="d-flex" *ngFor="let example of examples">
      <div *ngIf="!example.correct" class="icon icon--cross-circle align-self-center"></div>
      <div *ngIf="example.correct" class="icon icon--check-circle align-self-center"></div>
      <div class="align-self-center" [innerHTML]="example.text | translate"></div>
    </div>
  </div>
</div>
