<div class="error-toast-container align-middle no-select">
  <img src="assets/images/icon-alert-triangle-white.svg" class="align-self-center toast-image"
       alt="icon-alert">
  <div class="align-self-center toast-text">
    <p class="title font-weight-bold m-0">{{title | translate | uppercase}}</p>
    <p class="font-italic m-0">
      {{text | translate}}
      <a class="font-italic content-error" href="mailto:{{'share.mailto' | translate}}">{{'share.mailto' | translate}}</a>
    </p>
  </div>
</div>
