<ol *ngIf="!guestDisplay" class="breadcrumb">
  <li *ngFor="let breadcrumb of breadcrumbs, let i = index" class="item">
    <span *ngIf="i == breadcrumbs.length - 1">
      <span>{{breadcrumb.label | translate}}</span>
      <span *ngIf="label"> | {{label | truncate: 50:'...'}}</span>
    </span>
    <span *ngIf="i < breadcrumbs.length - 1">
      <a [routerLink]="['/' + getUrl(breadcrumb.url)]">
        <span>{{ breadcrumb.label | translate}}</span>
        <span *ngIf="i === breadcrumbs.length - 2 && preLabel"> | {{preLabel | truncate: 50:'...'}}</span>
      </a>
      <img src="assets/images/arrow-ios-right.svg" alt="arrow-ios-right">
    </span>
  </li>
</ol>
