import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
/** Usefull service to store previous URL and current URL during navigation  */
export class UrlService {

  // private previousPreviousUrl: string;
  private previousUrl: string;
  private currentUrl: string;

  constructor() {
    this.previousUrl = '';
    // this.previousPreviousUrl = '';
    this.currentUrl = '';
  }

  get PreviousUrl(): string {
    return this.previousUrl;
  }

  set PreviousUrl(previousUrl: string) {
    // this.previousPreviousUrl = this.previousUrl;
    this.previousUrl = previousUrl;
  }

  get CurrentUrl(): string {
    return this.currentUrl;
  }

  set CurrentUrl(currentUrl: string) {
    this.currentUrl = currentUrl;
  }

  /** Method to evaluate if the previous url was an Article | Document | ImpacterPage */
  evaluateIfComingFromOpenedDocument(): boolean {
    let rest = '';
    if (this.previousUrl.includes('article')) {
      rest = this.previousUrl.slice(this.previousUrl.indexOf('article') + 'article'.length, this.previousUrl.length);
      this.previousUrl = ''; // set to '' the previousUrl.
    }
    if (this.previousUrl.includes('admin-doc')) {
      rest = this.previousUrl.slice(this.previousUrl.lastIndexOf('admin-doc') + 'admin-doc'.length, this.previousUrl.length);
      this.previousUrl = ''; // set to '' the previousUrl.
    }
    if (this.previousUrl.includes('impacter')) {
      rest = this.previousUrl.slice(this.previousUrl.lastIndexOf('impacter') + 'impacter'.length, this.previousUrl.length);
      this.previousUrl = ''; // set to '' the previousUrl.
    }
    // return rest.length > 1 && this.previousPreviousUrl.length > 0; // assertion
    const boolToReturn = rest.length > 1;
    // console.log('REST : ' + rest);
    return boolToReturn; // assertion
  }

}
