<div class="container-fluid folder-contain pt-0 ">
  <div *ngIf="items.length > 0"
       class="row custom-shadow-sm w-100 filtered-ar">
    <div class="col-10 text-uppercase d-flex align-items-center">
      <div class="label-filtered mr-3">{{'topic.search' | translate}}</div>
      <div>
        <span class="filtered-input-container">
          <input [formControl]="itemFilteredNameFormControl" type="text" placeholder="{{'folder.filter-placeholder' | translate}}" class="filtered-input">
            <img (click)="clearText()" *ngIf="showClearIcon" src="assets/images/close-circle.svg" alt="close" class="cursor-pointer">
        </span>
      </div>
    </div>
  </div>
  <div class="row folders-container"
        [style.margin-top]="items.length > 0 ? '55px' : '15px'">
    <div class="col-12">
      <app-item-list
        [items]="filteredItems"
        [itemListLength]="items.length"
        [hasFilter]="hasFilter"
        [updateOption]="false"
        [columns]="{'col1': 'name', 'col2': 'documentsCount', 'col3': 'lastModified'}"
        [translationFileSection]="'folder'"
        [iconName]="iconFolderName"
        (selectItem)="onSelectItem($event)"
        (refreshListItem)="refreshListItem()"
        (deleteItem)="deleteItem($event)"
        (updateItem)="updateItem($event)">
      </app-item-list>
    </div>
  </div>
</div>
