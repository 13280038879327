<div class="template-container">
  <div class="card-block">
    <div class="block-body d-flex">
      <div class="block-container">
        <div class="font-weight-bold title">{{'template.configuration' | translate}}</div>
        <div class="d-flex justify-content-between">
          <div class="term-block">
            <div class="font-weight-600">{{'template.solid' | translate}}</div>
            <div class="font-weight-600">{{'template.park' | translate}}</div>
          </div>
          <div class="icon-block icon-block--shuffle align-self-center"></div>
          <div class="term-block">
            <div class="font-weight-600">{{'template.photovoltaic' | translate}}</div>
            <div class="font-weight-600">{{'template.solar' | translate}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="block-footer">
      <div class="font-weight-bold title">{{'template.results' | translate}}</div>
      <div class="request-chain">
        <div class="d-inline-block font-weight-bold">{{'template.solid' | translate}}&nbsp;{{'template.photovoltaic' | translate}}</div>
        <div class="d-inline-block color-basic-600 font-weight-600">{{'content.or' | translate}}</div>
        <div class="d-inline-block font-weight-bold">{{'template.solid' | translate}}&nbsp;{{'template.solar' | translate}}</div>
        <div class="d-inline-block color-basic-600 font-weight-600">{{'content.or' | translate}}</div>
        <div class="d-inline-block font-weight-bold">{{'template.park' | translate}}&nbsp;{{'template.photovoltaic' | translate}}</div>
        <div class="d-inline-block color-basic-600 font-weight-600">{{'content.or' | translate}}</div>
        <div class="d-inline-block font-weight-bold">{{'template.park' | translate}}&nbsp;{{'template.solar' | translate}}</div>
      </div>
    </div>
  </div>
  <app-example-guide-template [examples]="examples"></app-example-guide-template>
</div>
