import { Component, Input, OnInit } from '@angular/core';
import { SearchService } from '../../shared/services/search.service';
import { ArticleImSearchService } from '../../shared/services/article-im-search.service';

@Component({
  selector: 'app-result-message',
  templateUrl: './result-message.component.html',
  styleUrls: ['./result-message.component.scss']
})
export class ResultMessageComponent implements OnInit {
  @Input() inImpacterView: boolean;
  @Input() inImpacterPage: boolean;
  public territoriesIsEmpty: boolean;
  public titlesIsEmpty: boolean;

  constructor(private searchService: SearchService, private articleImSearchService: ArticleImSearchService) {

  }

  getSearchService(searchService: SearchService) {
    searchService.getSearch().subscribe(search => {
      this.territoriesIsEmpty = search['filters']['territories'].length === 0;
      this.titlesIsEmpty = this.inImpacterPage && search['filters']['titles'].length === 0;
    });
  }

  ngOnInit(): void {
    if (this.inImpacterView) {
      this.getSearchService(this.articleImSearchService);
    } else {
      this.getSearchService(this.searchService);
    }
  }
}
