import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from './shared/services/authentication.service';
import { Location } from '@angular/common';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private location: Location
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    const goodWillToken = this.authenticationService.goodwillToken;
    if (currentUser || goodWillToken) {
      // authorized so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    console.log(`Case cannot activate route. INFO - currentUser email : ${currentUser?.email} goodwillToken : ${goodWillToken}`);
    this.router.navigate(['/login'], {queryParams: {fromUrl: this.location.path()}}).then();
    return false;
  }
}
