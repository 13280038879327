import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { SubKind, TerritoryKind } from '../../core/territory-kind/territory-kind.enum';
import { AlertSettingService } from '../../shared/services/alert-setting.service';
import { StepModel } from '../../core/step/step.model';
import { FunnelContextEnum, StepsService } from '../../shared/services/steps.service';
import { DigestPublicationTerritoryKind } from '../../models/digest-settings';
import { TreeviewConfig, TreeviewItem } from '../../lib/ngx-treeview';
import { Territory } from '../../models/territory/territory';
import { makeSyndicatesTreeview } from '../../shared/helpers/territory.helper';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TerritoryManager } from '../../models/territory/territory-manager';

@Component({
  selector: 'app-source-step-template',
  templateUrl: './source-step-template.component.html',
  styleUrls: ['./source-step-template.component.scss']
})
export class SourceStepTemplateComponent implements OnInit, OnChanges, OnDestroy {
  @Input() regionalDailyPress: boolean;
  @Input() territoriesKindSetting: Array<DigestPublicationTerritoryKind>;
  @Input() syndicatesSetting: Array<string>;
  @Input() step: StepModel;
  config = TreeviewConfig.create();
  syndicates: TreeviewItem[];
  syndicatesReg: {[scope: string]: Territory[]};
  citySyndicates: {[uid: string]: string[]};
  validSyndicates: string[];
  syndicatesStore: string[];
  selectedSyndicates: string[];
  public territoriesKind: Array<DigestPublicationTerritoryKind> = [
    { value: TerritoryKind.COMMUNE, checked: true},
    { value: TerritoryKind.EPCI, checked: true},
    { value: SubKind.SYNDICAT, checked: true}
  ];
  touchedStep = false;
  subscription = new Subscription();

  constructor(
    private stepsService: StepsService,
    private alertSettingService: AlertSettingService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.setTerritoriesKindStats();
    this.onTerritoriesKindChange();
    this.onRegionalDailyPressChange();

    this.subscription.add(this.stepsService.currentStep$
      .subscribe((currentStep) => {
        this.touchedStep = this.touchedStep || (currentStep.stepIndex === 2);
        if (this.touchedStep) {
          this.checkCompleted();
        }
      })
    );

    this.alertSettingService.territoryManagers
      .subscribe((registry) => {
      let syndicates = {};
      const citySyndicates = {};
      Object.values(registry).forEach((manager) => {
        Object.entries(manager.syndicates).forEach(([scope, list]) => {
          syndicates[scope] = [
            ...syndicates[scope] ?? [],
            ...list.filter((syndicate) => !syndicates[scope]?.some((item) => item.uid === syndicate.uid))];
        });
        Object.entries(manager.citySyndicates).forEach(([uid, list]) => {
          citySyndicates[uid] = [...citySyndicates[uid] ?? [], ...list];
        });
      });
      syndicates = TerritoryManager.sortSyndicates(syndicates);
      this.syndicatesReg = syndicates;
      this.citySyndicates = citySyndicates;
      const currentSelection = this.syndicates?.map((treeview) => treeview.getSelection());
      this.syndicates = makeSyndicatesTreeview(this.syndicatesReg, this.translate.instant('filter.all-syndicates'));
      this.syndicatesStore = this.syndicates.reduce(
        (res, treeview) => [...res, ...treeview.getSelection().checkedItems.map((item) => item.value)],
        []
      );
      this.syndicates.forEach((item, i) => item.setCheckedRecursive(true, null, currentSelection?.[i]));
      this.onSelectedSyndicateChange(this.syndicates.reduce(
        (res, treeview) => [...res, ...treeview.getSelection().checkedItems.map((item) => item.value)],
        []
      ));
    });

    this.alertSettingService.onPerimeterChange.subscribe((perimeter: {territory: any, values: any}[]) => {
      this.validSyndicates = perimeter.reduce(
        (res, node) => [
          ...res,
          ...this.alertSettingService.territoryManagers.value[node.territory]
            .getCorrespondingSyndicates(node.values)
        ], []
      );
    });

    this.stepsService.arePerimetersRetrieved$.subscribe((state) => {
      if (state) {
        this.syndicates.forEach((item) => item.setCheckedRecursive(
          true,
          (syndicate) => this.syndicatesSetting.includes(syndicate.value)
        ));
        if (this.syndicatesSetting) {
          this.onSelectedSyndicateChange(this.syndicatesSetting);
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.territoriesKindSetting) {
      this.territoriesKindSetting = changes.territoriesKindSetting.currentValue;
      this.setTerritoriesKindStats();
      this.onTerritoriesKindChange();
    }

    if (changes.regionalDailyPress) {
      this.regionalDailyPress = changes.regionalDailyPress.currentValue;
      this.onRegionalDailyPressChange();
    }

    if (changes.territoriesKindSetting || changes.regionalDailyPress) {
      this.checkCompleted();
    }
  }

  setTerritoriesKindStats(): void {
    if (this.territoriesKindSetting) {
      this.territoriesKind = [...this.territoriesKindSetting];
    }
  }

  onTerritoriesKindChange(): void {
    const territoriesActive = this.getTerritoriesKindSelected();
    this.checkCompleted();
    this.alertSettingService.setPublicationTerritoriesKind(territoriesActive);
  }

  onSelectedSyndicateChange(value: Array<any>): void {
    this.selectedSyndicates = value;
    this.alertSettingService.setSyndicates(value);
    this.checkCompleted();
  }

  badgeNumber(currentValue: Array<string>, allValue: Array<any>): string {
    if (!currentValue || !allValue) {
      return;
    }
    const selectedCount = currentValue.length;
    const allCount = allValue.length;
    if (selectedCount < allCount) {
      return `${selectedCount}/${allCount}`;
    }
  }

  countChecked(item: TreeviewItem) {
    return item.children.reduce((acc, child) => acc + (child.checked ? 1 : 0), 0);
  }

  resetSyndicates() {
    this.syndicates.forEach((item) => item.setCheckedRecursive(true));
    this.onSelectedSyndicateChange(this.syndicatesStore);
  }

  getTerritoriesKindSelected(): Array<string> {
    return this.territoriesKind?.filter(x => x['checked']).map(x => x['value']);
  }

  onRegionalDailyPressChange(): void {
    this.checkCompleted();
    this.alertSettingService.setRegionalDailyPress(this.regionalDailyPress);
  }

  checkCompleted(): void {
    const isComplete = this.regionalDailyPress || (this.getTerritoriesKindSelected()?.length !== 0 && !!this.selectedSyndicates.length);
    if (this.stepsService.funnelContext === FunnelContextEnum.CREATION) {
      this.stepsService.updateStepCompletionState(2, isComplete && this.touchedStep);
    } else {
      this.stepsService.updateStepCompletionState(2, isComplete);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
