import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { finalize, first } from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;
  loading = false;
  isError = false;
  submitted = false;
  isEmailSend: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.isError = false;
    this.isEmailSend = false;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;

    // STOP HERE if form is invalid
    if (this.form.invalid) {
      this.isError = true;
      return;
    }

    this.isError = false;
    this.loading = true;

    this.authenticationService.forgotPassword(this.f.email.value.trim())
      .pipe(first())
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: () => {
          this.isEmailSend = true;
        },
        error: () => {
          this.isError = true;
          this.isEmailSend = false;
        }
      });

  }

}
