import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import blockType from '../../../assets/data/blockType.json';

@Component({
  selector: 'app-topic-block-select',
  templateUrl: './topic-block-select.component.html',
  styleUrls: ['./topic-block-select.component.scss']
})
export class TopicBlockSelectComponent implements OnInit {
  public blockType = blockType.data;
  public typeBlockToAdd: string;

  @Input() isPrevue = false ;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onBlockSelect = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onSelectChange(value): void {
    this.typeBlockToAdd = value;
    this.onBlockSelect.emit(value);
  }

}
