<div class="filter">
  <div class="filter-content">
    <ngb-accordion
      *ngIf="!inDocumentView"
      [closeOthers]="true"
      activeIds="static-3">
      <ngb-panel id="static-0" *ngIf="currentPage === 'impacter'">
        <ng-template ngbPanelHeader let-opened="opened">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="align-items-center">{{'filter.roles' | translate}}</div>
                <div class="d-flex align-items-center badge-active" *ngIf="badgeNumber(filter['titles'], titlesStore)"
                     [ngClass]="{'empty-badge': !filter['titles'].length}">
                  <div class="align-self-center">{{badgeNumber(filter['titles'], titlesStore)}}</div>
                  <img class="align-self-center" src="assets/images/icon-close.svg" (click)="resetTitles($event)" alt="icon-close"/>
                </div>
              </div>
              <img *ngIf="!opened" src="assets/images/icon-chevron-down.svg" alt="icon-chevron-down"/>
              <img *ngIf="opened" src="assets/images/icon-chevron-up.svg" alt="icon-chevron-up"/>
            </div>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-template #itemTemplate let-roleItem="item" let-onCollapseExpand="onCollapseExpand"
                       let-onCheckedChange="onCheckedChange">
            <div class="row-item" [ngClass]="{'root-item': roleItem.isRoot}">
              <div class="left-group">
                <i *ngIf="roleItem.children && !roleItem.isRoot" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="roleItem.collapsed">
                  <img *ngSwitchCase="true" src="assets/images/arrow-ios-right.svg" alt="arrow-ios-right"/>
                  <img *ngSwitchCase="false" src="assets/images/arrow-ios-down.svg" alt="arrow-ios-down"/>
                </i>
                <i *ngIf="roleItem.isRoot" class="pl-3">
                </i>
                <div *ngIf="!roleItem.isRoot" class="treeview-path"></div>
              </div>
              <div class="form-check" [ngClass]="{'node-root': roleItem.isRoot}">
                <label class="checkbox align-self-center" [ngClass]="{'last-node': !roleItem.children}">
                  <input type="checkbox" class="form-check-input" [checked]="roleItem.checked" [(ngModel)]="roleItem.checked" (change)="onCheckedChange()"
                         [disabled]="roleItem.disabled" [indeterminate]="roleItem.indeterminate" />
                  <span class="checkbox-text">{{'filter.'+roleItem.text | translate}}</span>
                  <span class="checkmark" ngbTooltip="{{'alert.tooltip-all-select' | translate}}"
                        placement="auto" container="body" [disableTooltip]="!roleItem.isRoot"></span>
                </label>
              </div>
            </div>
          </ng-template>
          <ngx-treeview [itemTemplate]="itemTemplate" [config]="config" [items]="functionItems"
                        (selectedChange)="onFunctionChange($event)">
                        <app-simple-loader [isSynchronizedWithLoaderService]="false" [vertical]="true"></app-simple-loader>
          </ngx-treeview>
          <div class="footer px-3">
            <div class="content">
              <div class="text-uppercase role-label">{{'filter.history-roles' | translate}}</div>
              <div class="d-flex">
                <div class="align-self-center">
                  <label class="switch">
                    <input type="checkbox" [(ngModel)]="oldPosition" (ngModelChange)="onOldPositionChange()">
                    <span class="slider round"></span>
                  </label>
                </div>
                <div class="label align-self-center">{{'filter.old-position-tip' | translate}}</div>
              </div>
              <div class="sub-label">{{'filter.old-position-sub-tip' | translate}}</div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="static-1" *ngIf="currentPage === 'admin-doc'">
        <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="align-items-center">{{'filter.issuing-authorities' | translate}}</div>
              </div>
            </div>
            <ul class="territory-kinds">
              <li *ngFor="let kind of listTerritoriesKind">
                <label class="switch checkbox">
                  <input type="checkbox" [checked]=kind.checked [(ngModel)]="kind.checked" (change)="onKindChange()">
                  <span class="slider round"></span>
                </label>
                <div class="t-kind-name ml-2">{{('filter.'+kind.value) | translate}}</div>
                <div class="d-flex align-items-center badge-active"
                     *ngIf="kind.value === 'SYND' && badgeNumber(selectedSyndicates, syndicatesStore) && kind.checked"
                     [ngClass]="{'empty-badge': !selectedSyndicates.length}">
                  <div class="align-self-center">{{badgeNumber(selectedSyndicates, syndicatesStore)}}</div>
                  <img class="align-self-center" src="assets/images/icon-close.svg" (click)="resetSyndicates($event)" alt="icon-close"/>
                </div>
                <button ngbPanelToggle *ngIf="kind.value === 'SYND'" class="flex-fill btn btn-link d-flex">
                  <img *ngIf="!opened" src="assets/images/icon-chevron-down.svg" alt="icon-chevron-down" class="ml-auto"/>
                  <img *ngIf="opened" src="assets/images/icon-chevron-up.svg" alt="icon-chevron-up" class="ml-auto"/>
                </button>
              </li>
            </ul>
        </ng-template>
        <ng-template ngbPanelContent>
            <ng-template #itemTemplate let-syndicateItem="item" let-onCollapseExpand="onCollapseExpand"
                         let-onCheckedChange="onCheckedChange">
              <div class="row-item" [ngClass]="{'root-item': syndicateItem.isRoot}">
                <div class="left-group">
                  <i *ngIf="syndicateItem.children && !syndicateItem.isRoot" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="syndicateItem.collapsed">
                    <img *ngSwitchCase="true" src="assets/images/arrow-ios-right.svg" alt="arrow-ios-right"/>
                    <img *ngSwitchCase="false" src="assets/images/arrow-ios-down.svg" alt="arrow-ios-down"/>
                  </i>
                  <i *ngIf="syndicateItem.isRoot" class="pl-3">
                  </i>
                  <div *ngIf="!syndicateItem.isRoot" class="treeview-path"></div>
                </div>
                <div class="form-check" [ngClass]="{'node-root': syndicateItem.isRoot}">
                  <label  class="align-self-center checkbox"
                          [ngClass]="{'last-node': !syndicateItem.children}">
                    <input type="checkbox" class="form-check-input" [checked]="syndicateItem.checked" [(ngModel)]="syndicateItem.checked" (change)="onCheckedChange()"
                           [disabled]="syndicateItem.disabled || !listTerritoriesKind[2].checked" [indeterminate]="syndicateItem.indeterminate" />
                    <span class="checkbox-text"
                          [ngClass]="{'text-line-through': syndicateItem.value && !validSyndicates.includes(syndicateItem.value) || !listTerritoriesKind[2].checked}"
                          ngbTooltip="{{'filter.no-city-in-syndicate' | translate}}"
                          placement="right" container="body" [disableTooltip]="!(syndicateItem.value && !validSyndicates.includes(syndicateItem.value))"
                    >{{syndicateItem.text}} {{!syndicateItem.isRoot && !syndicateItem.value ? '('+countChecked(syndicateItem)+'/'+syndicateItem.children.length+')' : ''}}</span>
                    <span class="checkmark" ngbTooltip="{{'alert.tooltip-all-select' | translate}}"
                          placement="auto" container="body" [disableTooltip]="!syndicateItem.isRoot"></span>
                    <img *ngIf="syndicateItem.infos?.fullname" src="assets/images/icon-info-filled.svg" class="filter-basic-500" alt="full-name-icon"
                         ngbTooltip="{{syndicateItem.infos['fullname']}}" placement="bottom" container="body"/>
                  </label>
                </div>
              </div>
            </ng-template>
            <ngx-treeview [itemTemplate]="itemTemplate" [items]="syndicates" [config]="config"
                          (selectedChange)="onSelectedSyndicateChange($event)">
            </ngx-treeview>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="static-2" *ngIf="currentPage != 'impacter' && currentPage != 'admin-doc'">
        <ng-template ngbPanelHeader let-opened="opened">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="align-items-center">{{'filter.period' | translate}}</div>
                <div class="d-flex align-items-center badge-active" *ngIf="beginDate && endDate">
                  <div class="align-self-center text-capitalize">
                    <span>{{beginDate.singleDate.jsDate.toDateString() | date:'mediumDate': '':'fr'}}</span>
                    <span> > </span>
                    <span>{{endDate.singleDate.jsDate.toDateString() | date:'mediumDate': '':'fr'}}</span>
                  </div>
                  <img class="align-self-center" src="assets/images/icon-close.svg" (click)="clearDate($event)" alt="icon-close"/>
                </div>
              </div>
              <img *ngIf="!opened" src="assets/images/icon-chevron-down.svg" alt="icon-chevron-down"/>
              <img *ngIf="opened" src="assets/images/icon-chevron-up.svg" alt="icon-chevron-up"/>
            </div>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="datepicker d-flex justify-content-between px-3">
            <div>
              <div class="dp-label">{{'filter.since' | translate}}</div>
              <div class="position-relative">
                <input class="dp-input input-box form-control location-search" [placeholder]="'filter.begin' | translate"
                       angular-mydatepicker name="mydate" (click)="dpBegin.toggleCalendar()" (inputFieldChanged)="onInputDateChange($event, true)"
                       [(ngModel)]="beginDate" [options]="myBeginDpOptions" locale="fr"
                       (viewActivated)="onViewActivated($event, dpBegin)"
                       #dpBegin="angular-mydatepicker" (dateChanged)="onDateChanged(true)" (keyup.enter)="dpBegin.openCalendar()"/>
                <div class="icon-filter icon-filter--calendar" (click)="dpBegin.toggleCalendar()"></div>
              </div>
            </div>
            <div>
              <div class="dp-label">{{'filter.until' | translate}}</div>
              <div class="position-relative">
                <div class="disabled-input location-search" [ngClass]="{'show': !beginDate}"></div>
                <input class="dp-input input-box form-control location-search" [placeholder]="'filter.end' | translate"
                       angular-mydatepicker name="mydate2" (click)="dpEnd.toggleCalendar()" (inputFieldChanged)="onInputDateChange($event, false)"
                       [(ngModel)]="endDate" [options]="myEndDpOptions" locale="fr"
                       (viewActivated)="onViewActivated($event, dpEnd)"
                       #dpEnd="angular-mydatepicker" (dateChanged)="onDateChanged(false)" (keyup.enter)="dpEnd.openCalendar()"/>
                <div class="icon-filter icon-filter--calendar" (click)="dpEnd.toggleCalendar()"></div>
              </div>
            </div>
          </div>
          <div class="datepicker-reset d-inline-flex float-right" [ngClass]="{'on-active': inputEndDate || inputBeginDate}" (click)="clearDate($event)">
            <div class="icon-filter icon-filter--reset align-self-center"></div>
            <div class="reset-label font-weight-bold text-uppercase align-self-center">{{'filter.reset' | translate}}</div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="static-3">
        <ng-template ngbPanelHeader let-opened="opened">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="align-items-center">{{'filter.geographic-scope' | translate}}</div>
                <div class="d-flex align-items-center badge-active" *ngIf="badgeNumber(selectedTerritories, territoriesStore)"
                     [ngClass]="{'empty-badge': !selectedTerritories.length}">
                  <div class="align-self-center">{{badgeNumber(selectedTerritories, territoriesStore)}}</div>
                  <img class="align-self-center" src="assets/images/icon-close.svg" (click)="resetTerritories($event)" alt="icon-close"/>
                </div>
              </div>
              <img *ngIf="!opened" src="assets/images/icon-chevron-down.svg" alt="icon-chevron-down"/>
              <img *ngIf="opened" src="assets/images/icon-chevron-up.svg" alt="icon-chevron-up"/>
            </div>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-template #itemTemplate let-territoryItem="item" let-onCollapseExpand="onCollapseExpand"
                       let-onCheckedChange="onCheckedChange">
            <div class="row-item" [ngClass]="{'hidden': hideItem(territoryItem), 'root-item': territoryItem.isRoot}">
              <div class="left-group">
                <i *ngIf="territoryItem.children && !territoryItem.isRoot" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="territoryItem.collapsed">
                  <img *ngSwitchCase="true" src="assets/images/arrow-ios-right.svg" alt="arrow-ios-right"/>
                  <img *ngSwitchCase="false" src="assets/images/arrow-ios-down.svg" alt="arrow-ios-down"/>
                </i>
                <i *ngIf="territoryItem.isRoot" (click)="collapse()" aria-hidden="true" [ngSwitch]="territoryItem.isRoot"
                   ngbTooltip="{{'alert.tooltip-collapse' | translate}}" placement="auto" container="body">
                  <ng-container *ngSwitchCase="true">
                    <img src="assets/images/icon-expand.svg" alt="icon-expand"/>
                  </ng-container>
                  <ng-container *ngSwitchCase="false" [ngSwitch]="territoryItem.collapsed">
                    <img *ngSwitchCase="true" src="assets/images/arrow-ios-right.svg" alt="arrow-ios-right"/>
                    <img *ngSwitchCase="false" src="assets/images/arrow-ios-down.svg" alt="arrow-ios-down"/>
                  </ng-container>
                </i>
                <div *ngIf="!territoryItem.isRoot" class="treeview-path"></div>
              </div>
              <div class="form-check" [ngClass]="{'node-root': territoryItem.isRoot}">
                <label  class="align-self-center"
                        [ngClass]="{'checkbox' : !territoryItem.outside_department_count,'last-node': !territoryItem.children}">

                  <input *ngIf="!territoryItem.outside_department_count" type="checkbox" class="form-check-input"
                         [checked]="territoryItem.checked" [(ngModel)]="territoryItem.checked" (change)="onCheckedChange();"
                         [disabled]="territoryItem.disabled" [indeterminate]="territoryItem.indeterminate" />
                  <img *ngIf="territoryItem.outside_department_count" class="icon-info"
                       src="assets/images/info.svg" alt="arrow-ios-right"/>


                  <span *ngIf="territoryItem.value"
                        class="checkbox-text"
                        [ngClass]="{'text-line-through': (currentPage == 'admin-doc' && counter(territoryItem) === 0) && showCounter(territoryItem)}"
                        ngbTooltip="{{'filter.no-document-in-city' | translate}}"
                        placement="right" container="body" [disableTooltip]="!((currentPage == 'admin-doc' && counter(territoryItem) === 0) && showCounter(territoryItem))"
                        [innerHTML]="territoryItem.text | filterLocation: filterText">
                  </span>

                  <span *ngIf="territoryItem.text === 'outside_Metropolis_Greater_Paris'" class="checkbox-text info-content writing-size"> {{territoryItem.outside_department_count}} {{'filter.metropolis_of_Greater_Paris_content'| translate}}</span>
                  <span *ngIf="territoryItem.text !== 'outside_Metropolis_Greater_Paris' && territoryItem.outside_department_count === 1" class="checkbox-text info-content writing-size"> {{territoryItem.outside_department_count}} {{'filter.outside_department_content'| translate}}</span>
                  <span *ngIf="territoryItem.text !== 'outside_Metropolis_Greater_Paris' && territoryItem.outside_department_count > 1" class="checkbox-text info-content writing-size"> {{territoryItem.outside_department_count}} {{'filter.outside_department_contents'| translate}}</span>

                  <span *ngIf="!territoryItem.value && !territoryItem.outside_department_count" class="checkbox-text text-uppercase">{{('filter.' + territoryItem.text) | translate}}</span>
                  <span class="{{!territoryItem.outside_department_count ? 'checkmark': ''}}" ngbTooltip="{{'alert.tooltip-all-select' | translate}}"
                        placement="auto" container="body" [disableTooltip]="!territoryItem.isRoot"></span>
                </label>
              </div>
              <ng-container *ngIf="showCounter(territoryItem) && !(currentPage == 'admin-doc' && counter(territoryItem) === 0)">
                <div *ngIf="!inSearch && !inImpacterView" class="counter" [ngClass]="{'checked': territoryItem.checked != false}">{{counter(territoryItem) | insertText: ' ': 3}}</div>
                <div *ngIf="(inImpacterView || inSearch) && territoryItem.infos['searchCount'] != undefined" class="counter" [ngClass]="{'checked': territoryItem.checked != false}">
                  {{counterBySearch(territoryItem) | insertText: ' ': 3}}
                </div>
              </ng-container>
            </div>
          </ng-template>
          <ng-template #headerTemplate let-config="config" let-onFilterTextChange="onFilterTextChange">
            <div *ngIf="config.hasFilter" class="filter-search-container">
                <input class="form-control location-search" type="text" [placeholder]="'filter.search-placeholder' | translate" [(ngModel)]="filterText"
                       (ngModelChange)="onFilterTextChange($event)"/>
                <img *ngIf="!filterText" src="/assets/images/search.svg" class="icon-filter icon-filter--search" alt="search-icon"/>
                <img *ngIf="filterText" src="/assets/images/icon-close-circle-fill.svg" class="icon-filter icon-filter--close" (click)="onFilterTextChange(''); filterText=''" alt="clear-icon"/>
            </div>
          </ng-template>
          <ngx-treeview [itemTemplate]="itemTemplate" [headerTemplate]="headerTemplate" [config]="config" [items]="items" [textChange]="filterText" (filterChange)="onFilterChange($event)"
                        (selectedChange)="onSelectedTerritoryChange($event)">
                        <app-simple-loader [isSynchronizedWithLoaderService]="false" [vertical]="true"></app-simple-loader>
          </ngx-treeview>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>
