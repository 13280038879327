import { Pipe, PipeTransform } from '@angular/core';
import { SearchHelper } from '../helpers/search.helper';

@Pipe({
  name: 'filterLocation'
})
export class FilterLocationPipe implements PipeTransform {
  transform(text: string, searchText: string): any {
    if (!searchText) {
      return text;
    }
    return text.replace(
      SearchHelper.regexpPattern(searchText),
      (str) => `<b>${str}</b>`
    );
  }
}
