import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-occurrence-and-folder-icon-badge',
  templateUrl: './occurrence-and-folder-icon-badge.component.html',
  styleUrls: ['./occurrence-and-folder-icon-badge.component.scss']
})
export class OccurrenceAndFolderIconBadgeComponent implements OnInit {

  @Input() folderId: number;
  @Input() nbOccurrences: number;
  @Input() showOccurrences: boolean;
  @Input() bookmarkPaths: Array<any>;
  @Input() displayBookmarkCreationDate: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
