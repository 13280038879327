import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

interface Image {
  src: string;
  caption: string;
}

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbCarouselConfig]
})
export class ImageCarouselComponent implements AfterViewInit {
  @Input() images: Array<Image>;
  @Input() guestDisplay = false;
  @Input() zoom = false;
  @Output() handleZoom = new EventEmitter<boolean>();

  @ViewChild('carouselSelector', {static: false}) carousel: NgbCarousel;

  constructor(config: NgbCarouselConfig) {
    config.interval = 0;
    config.showNavigationArrows = false;
    config.showNavigationIndicators = false;
  }

  ngAfterViewInit(): void {
    if (this.images?.length > 1) {
      this.carousel.showNavigationIndicators = true;
      this.carousel.showNavigationArrows = true;
      this.carousel.focus();
    }
  }

}
