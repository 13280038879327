import { Component, Input } from '@angular/core';
import { ImpacterForArticle } from '../../../models/impacter/impacter';

@Component({
  selector: 'app-impacters-panel',
  templateUrl: './impacters-panel.component.html',
  styleUrls: ['./impacters-panel.component.scss'],
})
export class ImpactersPanelComponent {
  @Input() impacters: ImpacterForArticle[];
}
