<div class="d-flex" *ngIf="isNavigator">
    <app-menu *ngIf="canDisplayMenu"></app-menu>
    <div [ngClass]="{
      'root-content': true,
      'guest-menu-margin': displayGuestMenu
    }">
        <router-outlet></router-outlet>
    </div>
    <app-toast-message-stack aria-live="polite" aria-atomic="true">
        <div style="position: absolute; bottom: 0; right: 0;"></div>
    </app-toast-message-stack>
</div>
<div *ngIf="!isNavigator">
    <app-navigator></app-navigator>
</div>
<app-zendesk-faq></app-zendesk-faq>
