<div class="container-fluid h-100 nopadding">

  <div class="row h-100 nopadding">
    <div class="col-6 nopadding h-100" style="z-index: 99;">
      <ul class="nav nav-tabs tabs-left d-block d-flex flex-column justify-content-between h-100" role="tablist">
        <li role="presentation"  [ngClass]="{'active': typeBlockToAdd == blockType.simple}"
          (click)="onSelectChange(blockType.simple)">
          <a href="#simple" aria-controls="simple" role="tab" data-toggle="tab" class="orange-type">
            <div class="d-flex block-type-item">
              <div *ngIf="isPrevue" class="ml-4"></div>
              <div *ngIf="!isPrevue"  class="align-self-center rdo">
                <label for="rdo-simple" class="radio">
                  <input type="radio" name="rdo" id="rdo-simple" class="hidden"
                    [checked]="typeBlockToAdd == blockType.simple">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="align-self-center">
                <div class="d-flex">
                  <div class="icon-block icon-block--list align-self-center"></div>
                  <div class="orange-title font-weight-bold title align-self-center">{{'content.list-simple' | translate}}</div>
                </div>
                <div class="block-subtitle" [innerHTML]="'content.list-simple-text' | translate"></div>
              </div>
            </div>
          </a>
        </li>
        <li role="presentation" [ngClass]="{'active': typeBlockToAdd == blockType.combinations}"
          (click)="onSelectChange(blockType.combinations)">
          <a href="#combinations" aria-controls="combinations" role="tab" data-toggle="tab" class="orange-type">
            <div class="d-flex block-type-item">
              <div *ngIf="isPrevue" class="ml-4"></div>
              <div *ngIf="!isPrevue"  class="align-self-center rdo">
                <label for="rdo-combinations" class="radio">
                  <input type="radio" name="rdo" id="rdo-combinations" class="hidden"
                    [checked]="typeBlockToAdd == blockType.combinations">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="align-self-center">
                <div class="d-flex">
                  <div class="icon-block icon-block--shuffle align-self-center"></div>
                  <div class="orange-title font-weight-bold title align-self-center">{{'content.list-combination' | translate}}</div>
                </div>
                <div class="block-subtitle" [innerHTML]="'content.list-combination-text' | translate"></div>
              </div>
            </div>
          </a>
        </li>
        <li role="presentation" [ngClass]="{'active': typeBlockToAdd == blockType.near}"
          (click)="onSelectChange(blockType.near)">
          <a href="#near" aria-controls="near" role="tab" data-toggle="tab" class="blue-type">
            <div class="d-flex block-type-item">
              <div *ngIf="isPrevue" class="ml-4"></div>
              <div *ngIf="!isPrevue" class="align-self-center rdo">
                <label for="rdo-near" class="radio">
                  <input type="radio" name="rdo" id="rdo-near" class="hidden"
                    [checked]="typeBlockToAdd == blockType.near">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="align-self-center">
                <div class="d-flex">
                  <div class="icon-block icon-block--swap align-self-center"></div>
                  <div class="blue-title font-weight-bold title align-self-center">{{'content.list-near' | translate}}
                  </div>
                </div>
                <div class="block-subtitle" [innerHTML]="'content.list-near-text' | translate"></div>
              </div>
            </div>
          </a>
        </li>
        <li *ngIf="isPrevue"  role="presentation" style="border-bottom: solid 0 transparent;" [ngClass]="{'active': typeBlockToAdd == blockType.exclude}"
          (click)="onSelectChange(blockType.exclude)">
          <a href="#exclure" aria-controls="exclure" role="tab" data-toggle="tab" class="red-type h-100">
            <div class="d-flex block-type-item">
              <div *ngIf="isPrevue" class="ml-4"></div>
              <div *ngIf="!isPrevue" class="align-self-center rdo">
                <label for="rdo-near" class="radio">
                  <input type="radio" name="rdo" id="rdo-near" class="hidden"
                    [checked]="typeBlockToAdd == blockType.exclude">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="align-self-center">
                <div class="d-flex">
                  <div class="icon-block icon-block--forbiden align-self-center mr-1"></div>
                  <div class="font-weight-bold title align-self-center red-title">{{'content.content-exclude' | translate}}
                  </div>
                </div>
                <div class="block-subtitle" [innerHTML]="'content.description-exclude' | translate" >
                </div>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <!-- <div class="col-3 nopadding">
      <div class="h-100 w-100 red">df</div>
    </div> -->
    <div class="col-6 nopadding border-col">
      <ng-container *ngIf="!typeBlockToAdd">
        <div class="no-selection-section d-flex w-100 h-100">
          <div class="d-flex w-100 justify-content-center align-self-center font-weight-bold color-basic-600 text">
            <div class="w-100 h-100 text-center" *ngIf="!isPrevue">
              {{'content.select-a-type-block' | translate}}
            </div>
            <div class="w-75 text-center" *ngIf="isPrevue">
              {{'content.select-a-type-block-review' | translate}}
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="typeBlockToAdd">
        <div class="tab-content d-flex align-items-center justify-content-center h-100">
          <div role="tabpanel" class="tab-pane" id="simple">
            <app-simple-type-guide-template></app-simple-type-guide-template>
          </div>
          <div role="tabpanel" class="tab-pane" id="combinations">
            <app-combination-type-guide-template></app-combination-type-guide-template>
          </div>
          <div role="tabpanel" class="tab-pane" id="near">
            <app-near-type-guide-template></app-near-type-guide-template>
          </div>
          <div role="tabpanel" class="tab-pane" id="exclure">
            <app-exclude-type-guide-template></app-exclude-type-guide-template>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
