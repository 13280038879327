import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';


@NgModule({
imports: [
  MatIconModule,
  MatExpansionModule,
  MatDialogModule,
  MatDividerModule
],
exports: [
  MatIconModule,
  MatExpansionModule,
  MatDialogModule,
  MatDividerModule
],
providers: [MatIconRegistry] })

export class MaterialModule {}

