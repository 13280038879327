import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../headers/headers.service';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DbDigestSettings, DigestSettings } from '../../../../models/digest-settings';
import {
  AlertRelatedToTopicsPostResponseBody,
  AlertWithUsers,
  DbAlertRelatedToTopicsPostResponseBody, DbAlertWithUsers,
  DisplayedAlert
} from '../../../../models/alert';
import { DigestStatus } from '../../../../models/alert/digest';
import { AlertOverviewParams } from '../../alert-setting.service';
import {
  AlertDocumentPreview, AlertDocumentPreviewPeriod,
  AlertDocumentPreviewResponseBody,
  DbAlertDocumentPreview,
  DbAlertDocumentPreviewPeriod
} from '../../../../models/alert-document-preview';
import { DbEntityUpdatedPatchResponseBody, EntityUpdatedPatchResponseBody } from '../../../../models/generic/api-update';

@Injectable({
  providedIn: 'root'
})
/**
 * Service taking care of all api calls concerning Alert business object.
 */
export class ApiAlertService {

  constructor(private http: HttpClient,
              private headersService: HeadersService) { }

  /* Common url to all Alert relative endpoints */
  public apiAlertsUrl = `${environment.backV2EndUrl}alerts/`;

  /**
   * POST to create Alert from its settings.
   * @param body - AlertSettings
   */
  createAlert(body): Observable<DigestStatus> {
    return this.http
      .post(
        this.apiAlertsUrl + 'digest',
        body,
        this.headersService.httpHeaders
      )
      .pipe(
        map(res => {
          return new DigestStatus(
            res['digest id'],
            res['digest name'],
            res['status']
          );
        }));
  }

  /**
   * DELETE to delete an Alert.
   * Returns 204 - NO CONTENT
   */
  deleteAlert(alertId): Observable<null> {
    const userId = localStorage.getItem('user_id');

    return this.http
      .delete(
        this.apiAlertsUrl + `digest/${alertId}/user/${userId}`,
        this.headersService.httpHeaders
      )
      .pipe(
        map((response: null) => response));
  }

  /**
   * GET to retrieve setting information of an Alert.
   * @param alertId - the alert's id integer
   */
  getAlertSetting(alertId): Observable<DigestSettings> {
    return this.http
      .get(
        this.apiAlertsUrl + `digest_settings/${alertId}`,
        this.headersService.httpHeaders
      )
      .pipe(
        map((response: DbDigestSettings) => {
          return new DigestSettings(response['digest_settings']);
        }));
  }

  /**
   * POST to retrieving all alerts from the user.
   * @param fields - fields wanted in response
   * @param filters - filters to apply */
  getAlerts(fields: Array<string>, filters: object): Observable<AlertWithUsers[]> {
    const body = {
      fields: fields,
      filters: filters
    };

    return this.http
      .post(
        this.apiAlertsUrl,
        body,
        this.headersService.noCachingHttpHeaders
      )
      .pipe(
        map(res => {
          return res['data'].map(x => new AlertWithUsers(x));
        }));
  }

  /**
   * POST to retrieving all alerts from the user.
   * @param fields - fields wanted in response
   * @param filters - filters to apply */
  getAlertsAsDbAlertWithUsers(fields: Array<string>, filters: object): Observable<DbAlertWithUsers[]> {
    const body = {
      fields: fields,
      filters: filters
    };

    return this.http
      .post(
        this.apiAlertsUrl,
        body,
        this.headersService.httpHeaders
      )
      .pipe(
        map(res => {
          return res['data'];
        }));
  }

  /**
   * Method to find the current user in every alert from its users array, and add it's informations on a new key 'user'.
   * The informations relative to the current user are needed to display the alert activation state
   * @param alerts - DbAlert[] user's alerts. Each one may contain multiple users values.
   */
  addCurrentUserToAlerts(alerts: AlertWithUsers[]): DisplayedAlert[] {
    const currentUserEmail = localStorage.getItem('email');

    const displayedAlerts = new Array<DisplayedAlert>();

    alerts.forEach(alert => {
      displayedAlerts.push(
        new DisplayedAlert(
          alert,
          alert.users.find(elem => elem.email.toLowerCase() === currentUserEmail)
        )
      );
    });

    return displayedAlerts;
  }

  /**
   * POST retrieving documents regarding a certain alert to display on the step overview
   */
  getAlertDocumentPreviews(body: AlertOverviewParams): Observable<AlertDocumentPreviewResponseBody> {
    return this.http
      .post(
        this.apiAlertsUrl + `alert_preview`,
        body,
        this.headersService.httpHeaders
      )
      .pipe(
        map((response: { data: DbAlertDocumentPreview[], period: DbAlertDocumentPreviewPeriod, total_count: number }) => {
          return new AlertDocumentPreviewResponseBody(
            response.data.map((alertDocumentPreviewRaw) => new AlertDocumentPreview(alertDocumentPreviewRaw)),
            new AlertDocumentPreviewPeriod(response.period),
            response.total_count
          );
        }));
  }

  /**
   * POST retrieving alerts linked to a certain topic identified by its id.
   * @param topicId - Id of the concerned topic
   */
  getAlertsRelatedToTopic(topicId: string): Observable<AlertRelatedToTopicsPostResponseBody> {
    const body = {
      fields: ['id', 'name', 'topics'],
      limit: 1000,
      filters: {
        topics_ids: [parseInt(topicId, 10)],
        user_ids: [parseInt(localStorage.getItem('user_id'), 10)]
      }
    };
    return this.http
      .post(
        this.apiAlertsUrl,
        body,
        this.headersService.httpHeaders)
      .pipe(
        map((response: DbAlertRelatedToTopicsPostResponseBody) => {
            return new AlertRelatedToTopicsPostResponseBody(response);
          }
        )
      );
  }

  /**
   * PATCH to replace on of some Topics from within an already set up Alert.
   * This method is called when creating a custom Topic from a default Topic when the
   * user chooses to alter existing Alerts linked to the original default Topic.
   */
  changeAlertTopic(alertId: string, topicIds: string[]): Observable<EntityUpdatedPatchResponseBody> {
    const body = {
      id: alertId,
      topics_ids: topicIds,
    };

    return this.http
      .patch(
        this.apiAlertsUrl,
        body,
        this.headersService.httpHeaders)
      .pipe(map((response: DbEntityUpdatedPatchResponseBody) => {
        return new EntityUpdatedPatchResponseBody(response);
      }));
  }

}
