import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EntityService } from '../../shared/services/entity.service';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BookmarkDocumentService } from '../../shared/services/bookmark-document.service';
import { BookmarkFolderService } from '../../shared/services/bookmark-folder.service';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Folder } from '../../models/folder';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalEditFolderComponent } from '../modal-edit-folder/modal-edit-folder.component';
import { Impacter } from '../../models/impacter/impacter';
import { ImpacterPosition } from '../../models/impacter/impacter-position';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() showContent: boolean;
  @Input() inFolderView: boolean;
  @Input() territoryUid: string;
  public document: object;
  public impacter: Impacter;
  public label: string;
  public preLabel: string;
  public toggled = true;
  public guestDisplay = false;
  public folderView = false;
  public showContentFolder = false;
  public folder: Folder;
  contentRef: Element = document.querySelector('.root-content');
  subscription: Subscription = new Subscription();

  constructor(private entityService: EntityService,
              private route: ActivatedRoute,
              private router: Router,
              private bookmarkDocumentService: BookmarkDocumentService,
              private bookmarkFolderService: BookmarkFolderService,
              private modalService: NgbModal
  ) {
    this.subscription.add(
      this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let routeToSubscribe = this.route.snapshot;
          while (routeToSubscribe.firstChild) {
            routeToSubscribe = routeToSubscribe.firstChild;
          }
          return routeToSubscribe;
        })
      )
      .subscribe((lastChildRoute: ActivatedRouteSnapshot) => {
        this.folderView = !!lastChildRoute.data['folderView'];
        this.showContentFolder = !!lastChildRoute.data['showContentFolder'];
        if (this.folderView) {
          this.bookmarkFolderService.getFolderSubject().subscribe((folder: Folder) => {
            this.folder = folder;
          });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      if (data.guestDisplay) {
        this.guestDisplay = data.guestDisplay;
      }
    });

    this.entityService.getDocument().subscribe(document => {
      this.document = document;
      if (document) {
        this.label = document['title'];
      } else if (this.impacter && this.impacter.name) {
        this.label = this.impacter.name;
      } else {
        this.label = null;
      }

      if (this.impacter && document) {
        this.preLabel = this.impacter.name;
      } else {
        this.preLabel = null;
      }
    });

    this.entityService.getImpacter().subscribe((impacter) => {
      this.impacter = impacter;
      if (impacter) {
        this.label = impacter.name;
        this.preLabel = null;
        this.contentRef.classList.add('min-height');
      } else {
        this.label = null;
        this.preLabel = null;
        this.contentRef.classList.remove('min-height');
      }
    });
  }

  toggle() {
    this.toggled = !this.toggled;
    if (!this.toggled) {
      this.contentRef.classList.add('min-height-expand');
    } else {
      this.contentRef.classList.remove('min-height-expand');
    }
  }

  getPositions(positions: Array<ImpacterPosition>, isCurrent: boolean): Array<ImpacterPosition> {
    return positions.filter(position => position.isOld !== isCurrent);
  }

  openEditFolderModal() {
    const modalRef = this.modalService.open(ModalEditFolderComponent,
      {ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'modal-width-419'});
    modalRef.componentInstance.translationFileSection = 'folder';
    modalRef.componentInstance.folder = this.folder;
    modalRef.result.then((newName: string) => {
      this.folder.name = newName;
      this.bookmarkFolderService.folder = this.folder;
    }, _ => {
      // error handling when dismissing the modal, nothing to do but keep it to prevent console error.
    });
  }
}
