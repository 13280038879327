<div class="container-fluid h-100" *ngIf="article">
  <div class="row h-100 flex-wrap-reverse">
    <div class="left-content">
      <div [ngClass]="{'load-position': show}" >
        <app-loader [show]="show" [inView]="true" [inSearch]="inSearch" [noResult]="searchNotFound"  [topChoice]="'top-loader-article'"></app-loader>

      <div class="view-content">
        <span class="article-title line-clamp">{{article.title | uppercase}}</span>
        <div class="search-bar-container" [ngClass]="{'sticky-position' :!show}">
          <app-control-f
            (onPrev)="controlFService.goToSelected($event)"
            (onNext)="controlFService.goToSelected($event)"
            (onSearch)="controlFService.prev_find($event, articleGuestViewElement,article.text)"
            [totalMatches]="controlFService.totalMatches"
            [documentLoaded]="true">
          </app-control-f>
        </div>
        <app-image-carousel-smart *ngIf="article" [article]="article" [guestDisplay]="true"></app-image-carousel-smart>
        <div class="text text-justify"
             [innerHTML]="article.text | highlight: controlFService.inputSearch"
             #articleGuestView></div>
        <div class="ID-article-0CtgAin" *ngIf="documentProviderId?.length>0">ID article : {{documentProviderId}}</div>
      </div>
    </div>
    </div>
    <div class="info-section">
      <div class="info-title text-uppercase">{{'article.about-the-article' | translate}}</div>
      <app-metadata
        [documentType]="'article'"
        [source]="article.source"
        [publicationDate]="article.publicationDate"></app-metadata>
      <app-welcome-panel [shareToken]="shareToken"></app-welcome-panel>
      <app-territories-panel [article]="article"></app-territories-panel>
    </div>
  </div>
</div>

