import { Component, Input, OnInit } from '@angular/core';
import { ContextService } from '../../shared/services/context.service';
import { DisplayedPublicationType } from '../../models/displayed-publication-type';
import { MatchStem } from '../../models/match-stem';
import { MatchStemsService } from '../../shared/services/match-stems.service';

@Component({
  selector: 'app-admin-doc-card',
  templateUrl: './admin-doc-card.component.html',
  styleUrls: ['./admin-doc-card.component.scss']
})
export class AdminDocCardComponent implements OnInit {
  @Input() docId: string;
  @Input() allowOnClick = true;
  @Input() showOccurrences = true;
  @Input() title: string;
  @Input() publicationDate: Date;
  @Input() nbOccurrences: number;
  @Input() website: string;
  @Input() text: string;
  @Input() url: string;
  @Input() isPreview = false;
  @Input() folderId: number;
  @Input() displayBookmarkCreationDate = false;
  @Input() bookmarkPaths: any;
  @Input() displayedPublicationType: string;
  @Input() matchStems: MatchStem[];

  DisplayedPublicationType = DisplayedPublicationType;

  constructor(private contextService: ContextService,
              private matchStemsService: MatchStemsService) { }

  ngOnInit(): void {
    // Manage case error, no publicationType provided.
    if (!this.displayedPublicationType) {
      this.displayedPublicationType = DisplayedPublicationType.NON_DETECTED;
    }
  }

  onClickCard() {
    this.contextService.setSnippetWithExpiry(this.docId, this.text);
    this.matchStemsService.setMatchStemsWithExpiry(this.docId, this.matchStems);
  }
}
