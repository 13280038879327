<div class="form-steps-container align-middle no-select">

  <div class="h-100 funnel-context-container d-flex justify-content-center">
    <div class="d-table">
      <div class="funnel-icon d-table-cell align-middle">
        <img src="{{funnelIconUrl}}" alt="funnel-img">
      </div>
      <div [style.padding-left]="(funnelContext === FunnelContextEnum.CREATION) ? '12px' : '0'" class="text d-table-cell align-middle">
        {{ 'alert.' + funnelContext | translate | uppercase }}
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center middle-part">
    <div class="steps-container">
      <div *ngFor="let step of steps; let i = index; trackBy: trackByStep"
           [ngClass]="{'success': datumRetrieved && isSuccessStyleList[i],
                       'errored': datumRetrieved && isErroredStyleList[i] && !isSuccessStyleList[i] && (currentStep?.stepIndex !== step.stepIndex),
                       'current': currentStep?.stepIndex === step.stepIndex,
                       'enabled': datumRetrieved && isEnabledStyle[i] && (currentStep?.stepIndex !== step.stepIndex) && !isSuccessStyleList[i] && !isErroredStyleList[i],
                       'other': (currentStep?.stepIndex !== step.stepIndex) && !isSuccessStyleList[i] && !isErroredStyleList[i] && !isEnabledStyle[i]

                  }">

        <div (click)="onStepClick(step)"
             class="step stepper-button d-flex"
             [ngClass]="{ 'step-complete': step?.isComplete,
                          'step-incomplete': (currentStep?.stepIndex < step.stepIndex) && !isEnabledStyle[i]}">

          <div class="step-bubble align-self-center">
            <img *ngIf="datumRetrieved && isErroredStyleList[i] && !isSuccessStyleList[i] && (currentStep?.stepIndex !== step.stepIndex) ; else indexLabel" src="assets/images/danger-500-icon.svg" alt="danger-icon" class="danger-500">

            <ng-template #indexLabel>
              <div class="align-self-center">
                {{ step.stepIndexLabel }}
              </div>
            </ng-template>
          </div>

          <div class="step-name align-self-center">
            {{ step.stepName | translate }}
          </div>

        </div>

      </div>

    </div>
  </div>

  <ng-content class="cancel-element"></ng-content>

</div>

