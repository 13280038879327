<ng-container *ngIf="display">
  <div *ngIf="open === false" class="zendesk-buttons-container">
    <button appPreventDoubleClick class="zendesk-launcher opening-launcher text-capitalize" id='web-widget-launcher'
            (throttledClick)='openWidget()'>
      <mat-icon class="help-icon" svgIcon="help"></mat-icon>
      <span>{{'zendesk.help' | translate}}</span>
    </button>
  </div>
  <ng-container *ngIf="open == true">
    <div class="zendesk-buttons-container">
      <a href="https://help.goodwill.explain.fr/hc/fr/signin" target="_blank"
         class="zendesk-launcher opening-external-link text-capitalize" id='link-to-faq'>
        <span class="no-select">{{'zendesk.open-help-center' | translate}}</span>
        <mat-icon class="external-link-icon" svgIcon="external-link"></mat-icon>
      </a>
      <button class="widget-closer" id="widget-closer" appPreventDoubleClick (throttledClick)="closeWidget()">
        <mat-icon class="close-icon" svgIcon="icon-close"></mat-icon>
      </button>
    </div>
  </ng-container>
</ng-container>

<!-- Start of explain1905 Zendesk Widget script -->
<script id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=b5ac3d0c-9632-48bf-9995-80212e5e0ddf"></script>
<!-- End of explain1905 Zendesk Widget script -->
