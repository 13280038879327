import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { EntityService } from '../../shared/services/entity.service';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PublicationSource } from '../search/publication-source';
import { SearchService } from '../../shared/services/search.service';
import { SearchMode } from '../search/search-mode';
import { Subscription } from 'rxjs';
import { ApiService } from '../../shared/services/api/api.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, AfterViewChecked, OnDestroy {
  SearchMode = SearchMode;
  entityId: string;
  totalCount: number;
  allCount: number;
  nbResource: number;
  currentPage: string;
  publicationSource: PublicationSource;
  territoryUid: string;
  sourcesList: string[];

  /** Subscription to services */
  getEntityIdSubscription: Subscription;
  getTotalCountSubscription: Subscription;
  getAllCountSubscription: Subscription;
  getNbResourceSubscription: Subscription;

  getSourcesListSubscription: Subscription;

  constructor(
    private entityService: EntityService,
    private apiService: ApiService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        const currentUrl = event['urlAfterRedirects'];
        this.currentPage = this.getCurrentPage(currentUrl);
        this.publicationSource = this.getCurrentPublicationSource(currentUrl);
      });

  }

  ngOnInit(): void {
    this.route.params.subscribe(parameters => {
      this.territoryUid = parameters['territoryUid'];
      if (this.territoryUid !== 'FRDEPA00') {
        this.apiService.territory.retrieveTerritoryCounters(this.territoryUid).subscribe();
      }
    });

    this.getEntityIdSubscription =
      this.entityService.getEntityId().subscribe(entityId => {
        this.entityId = entityId;
      });

    this.getTotalCountSubscription =
      this.entityService.getTotalCount().subscribe(totalCount => {
        this.totalCount = totalCount;
      });

    this.getAllCountSubscription =
      this.entityService.getAllCount().subscribe(allCount => {
        this.allCount = allCount;
      });

    this.getNbResourceSubscription =
      this.entityService.getNbResource().subscribe(nbResource => {
        this.nbResource = nbResource;
      });
    this.getSourcesListSubscription =
      this.entityService.getSourcesList().subscribe(sourcesList => {
        this.sourcesList = sourcesList;
        this.sourcesList.sort((s1, s2) => s1.localeCompare(s2));
      });
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    /** Unsubscribe to souscriptions */
    if (this.getEntityIdSubscription) { this.getEntityIdSubscription.unsubscribe(); }
    if (this.getTotalCountSubscription) { this.getTotalCountSubscription.unsubscribe(); }
    if (this.getAllCountSubscription) { this.getAllCountSubscription.unsubscribe(); }
    if (this.getNbResourceSubscription) { this.getNbResourceSubscription.unsubscribe(); }
    if (this.getSourcesListSubscription) { this.getSourcesListSubscription.unsubscribe(); }
  }

  get SearchModeMap(): Map<PublicationSource, SearchMode> {
    return this.searchService.SearchModeMap;
  }

  getCurrentPage(currentUrl: string): string {
    if (currentUrl.includes('/article')) {
      return 'article';
    } else if (currentUrl.includes('/admin-doc')) {
      return 'admin-doc';
    } else {
      return 'impacter';
    }
  }

  getCurrentPublicationSource(currentUrl: string): PublicationSource {
    if (currentUrl.includes('/article')) {
      return PublicationSource.ARTICLES;
    } else if (currentUrl.includes('/admin-doc')) {
      return PublicationSource.ADMIN_DOCS;
    } else {
      return PublicationSource.OTHERS;
    }
  }

  onChangeTab(searchMode: SearchMode) {
    // At the moment, the search mode changes symmetrically for both article & admin-doc sources
    this.searchService.SearchModeMap.set(PublicationSource.ARTICLES, searchMode);
    this.searchService.SearchModeMap.set(PublicationSource.ADMIN_DOCS, searchMode);

    this.searchService.currentPage = 1; // Set to 1 the current page
    this.searchService.resetSearchOffsetMap();

    this.searchService.searchModeMapChanged.next(this.searchService.SearchModeMap);
  }
}
