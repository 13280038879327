import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { MatchStemsSearchInterface, DbSearchKeyword, SearchKeywordTypeEnum } from '../../models/match-stem';

@Component({
  selector: 'app-keywords-custom-toolbar',
  templateUrl: './keywords-custom-toolbar.component.html',
  styleUrls: ['./keywords-custom-toolbar.component.scss']
})
export class KeywordsCustomToolbarComponent implements OnInit {

  SearchKeywordTypeEnum = SearchKeywordTypeEnum; // To use inside the template

  @Input() stems = [];
  @Input() matchTermMatrix: Array<MatchStemsSearchInterface>;
  @Input() globalOccurrencePagesReplaySubject: ReplaySubject<Array<boolean>>;
  @Input() selectedTerms: Array<string>;
  @Input() documentLoading: boolean;
  @Input() $matchTermMatrixUpdate: ReplaySubject<Array<MatchStemsSearchInterface>>;
  displayToolbar = true;
  hoveredKeyword = null;
  occurrencePagesCounter = 0;
  timeoutElapsed = false;


  @Output() isPanelExpanded = new EventEmitter<boolean>();
  @Output() keywordSelectionChanged = new EventEmitter<DbSearchKeyword>();
  @Output() deleteUserInput = new EventEmitter<MatchStemsSearchInterface>();
  userInput = null;

  constructor() { }

  ngOnInit(): void {

    this.globalOccurrencePagesReplaySubject
      .subscribe((result) => {
        this.occurrencePagesCounter = result.filter(elm => elm === true)?.length;
      });

    const intervalKeyword = setInterval(() => {
      if (!this.documentLoading) {
        setTimeout(() => {
          this.timeoutElapsed = true;
        }, 7000);
        clearInterval(intervalKeyword);
      }
    });

    /** On matchTermMatrix updates, check if there are inputs with no occurrence and remove these from the selectedTerms */
    this.$matchTermMatrixUpdate.subscribe( (matchTermMatrix) => {
      this.matchTermMatrix = matchTermMatrix;
      for (const element of matchTermMatrix.filter(term => term.type === SearchKeywordTypeEnum.USER_INPUT &&
                                                                  term.occurrencePages?.length === 0)) {
        const index = this.selectedTerms?.indexOf(element.stem.toLowerCase());
        if (index > -1) {
          this.selectedTerms.splice(index, 1);
        }
      }
    });

  }

  /** Listener to add user input on control+f */
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && (event.code === 'KeyF' || event.code === 'Keyf')) {
      event.preventDefault();
      document.getElementById('searchTermInput').focus();
    }
  }

  onExpandToolbarPanel() {
    this.displayToolbar = !this.displayToolbar;
    this.isPanelExpanded.emit(this.displayToolbar);
  }

  onClickTerm(element: MatchStemsSearchInterface) {
   if (element.occurrencePages?.length !== 0) {
      this.keywordSelectionChanged.emit({type: SearchKeywordTypeEnum.STEM, value: element.stem});
    }
  }

  addUserInput(term: string) {
    if (term.length >= 2) {
      this.keywordSelectionChanged.emit({type: SearchKeywordTypeEnum.USER_INPUT, value: term});
      this.userInput = null;
    }
  }

  trackByStem(index: number, object: MatchStemsSearchInterface) {
    return object.stem;
  }

  onDeleteUserInput(element: MatchStemsSearchInterface) {
    if (this.selectedTerms?.includes(element.stem)) {
      this.keywordSelectionChanged.emit({type: SearchKeywordTypeEnum.STEM, value: element.stem});
      // management of selection when input was selected before deletion
      // we say the type is "STEM" because the function of selection is simpler for stems,
      // and for the deletion we don't need the specific method for inputs
    }
    this.deleteUserInput.emit(element);
  }

}
