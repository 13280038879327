import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntityService } from '../../../shared/services/entity.service';
import { AdminDocument } from '../../../models/admin-document';
import { ApiService } from '../../../shared/services/api/api.service';
import { ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { SharedDocument } from '../../../models/share/share';

@Component({
  selector: 'app-admin-doc-guest-view',
  templateUrl: './admin-doc-guest-view.component.html',
  styleUrls: ['./admin-doc-guest-view.component.scss']
})
export class AdminDocGuestViewComponent implements OnInit, OnDestroy {
  @ViewChild('pdfViewContainer') pdfView: ElementRef;

  shareToken: string;
  adminDocId: string;
  adminDoc: AdminDocument;
  time: number;

  adminDocMatchStems$ = new ReplaySubject<any>();

  constructor(private route: ActivatedRoute,
              private entityService: EntityService,
              private apiService: ApiService) { }

  ngOnInit() {
    // To prevent bugs in case a user with a license opened a document in guest mode, then go back to the app.
    if (localStorage.getItem('api_token')) {
      localStorage.removeItem('api_token');
    }

    this.route.params.subscribe(async (params) => {
      this.adminDocId = params['adminDocId'];
      this.shareToken = params['shareToken'];
      const openSourceId = params['openSourceId'];
      if (this.adminDocId) {
        this.entityService.sendEntityId(this.adminDocId);
        this.time = (new Date()).getTime();
        let query = `${this.adminDocId}?open_source=web_app`;
        if (openSourceId) {
          query = `${this.adminDocId}?open_source=email&open_source_id=${openSourceId}`;
        }

        // Retrieving sharedDocument.
        this.adminDoc = await this.getAdminDoc(query, this.shareToken);

        // Retrieving matchStems from share.
        const sharedDocument = await this.getSharedDocumentFromShareId(+openSourceId, this.shareToken);
        this.adminDoc.matchStems = sharedDocument?.matchStems;

        // Updating observable values.
        this.entityService.sendDocument(this.adminDoc);
        this.adminDocMatchStems$.next(this.adminDoc.matchStems);

      }
    });
  }

  /** Method Retrieving admin doc from opensourceId and shareToken */
  getAdminDoc(query: string, shareToken: string): Promise<AdminDocument> {
    try {
      return this.apiService.adminDoc
        .getSharedAdminDoc(query, shareToken)
        .pipe(first())
        .toPromise();
    } catch (e) {
      console.log(`error retrieving shared document - message : ${e}`);
      return null;
    }
  }

  /** Method Retrieving SharedDocument from shareId and shareToken */
  getSharedDocumentFromShareId(shareId: number, shareToken: string): Promise<SharedDocument> {
    try {
      return this.apiService.share
        .getSharedDocument(String(shareId), shareToken)
        .pipe(first())
        .toPromise();
    } catch (e) {
      console.log(`error retrieving shared document from share id - message : ${e}`);
      return null;
    }
  }

  ngOnDestroy(): void {
    this.entityService.sendEntityId(null);
    this.entityService.sendDocument(null);
  }

}
