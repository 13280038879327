export interface User {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  token: string;
  reset_token: string;
}

export interface DbLoggedInUser {
  id: number;
  group_account_id: number;
  first_name: string;
  last_name: string;
  email: string;
  api_token: string;
}

export class LoggedInUser {
  id: number;
  groupAccountId: number;
  firstName: string;
  lastName: string;
  email: string;
  apiToken: string;

  constructor(dbLoggedInUser: DbLoggedInUser) {
    this.id = dbLoggedInUser.id;
    this.groupAccountId = dbLoggedInUser.group_account_id;
    this.firstName = dbLoggedInUser.first_name;
    this.lastName = dbLoggedInUser.last_name;
    this.email = dbLoggedInUser.email;
    this.apiToken = dbLoggedInUser.api_token;
  }
}
