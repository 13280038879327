import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { EventTypeName } from '../../../../models/user-tracker';

@Injectable({
  providedIn: 'root'
})
export class HeadersService {

  constructor() { }

  /**
   * Method to get httpHeaders containing the user's goodwill Token.
   * Usefull to call the goodwill api.
   */
  get httpHeaders(): { headers: HttpHeaders } {
    return {
      headers:
        new HttpHeaders({
          'accept': 'text/plain',
          'X-API-KEY': localStorage.getItem('api_token'),
          'Content-Type': 'application/json'
        })
    };
  }

  get noCachingHttpHeaders(): { headers: HttpHeaders } {
    return {
      headers:
        new HttpHeaders({
          'accept': 'text/plain',
          'X-API-KEY': localStorage.getItem('api_token'),
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0'
        })
    };
  }

  getSharedHeaders(shareToken): { headers: HttpHeaders } {
    return {
      headers:
        new HttpHeaders({
          'accept': 'text/plain',
          'X-API-SHARE-KEY': shareToken,
          'Content-Type': 'application/json'
        })
    };
  }

  getSharedHeadersWithParams(params: HttpParams, shareToken: string): { headers: HttpHeaders, params: HttpParams } {
    return {
      ...this.getSharedHeaders(shareToken),
      params: params
    };
  }

  /**
   * Method mapping HttpHeaders to a javascript Object {key: value, ...}
   * @param headers - HttpHeaders
   */
  jsonFromHttpHeaders(headers: HttpHeaders) {
    const headersMap = new Map<string, any>();
    for (const key of headers.keys()) {
      headersMap.set(key, headers.get(key));
    }
    // @ts-ignore
    return Object.fromEntries(headersMap);
  }

  /**
   * Méthode pour obtenir des httpHeaders permettant à l'appel d'être tracké.
   * @param trackedEventType - Type d'événement tracké (eg. impacter-search)
   */
  public getTrackedHttpHeaders(trackedEventType: EventTypeName): { headers: HttpHeaders } {
    return {
      headers:
        new HttpHeaders({
          'accept': 'text/plain',
          'X-API-KEY': localStorage.getItem('api_token'),
          'Content-Type': 'application/json',
          'is-tracked': 'true',
          'tracked-method-name': trackedEventType
        })
    };
  }

  /**
   * Method to obtain options objects with headers and given parameters.
   */
  public getHttpOptionsWithParams(
    params: HttpParams,
    trackingEventName?: EventTypeName | ''
  ): { headers: HttpHeaders, params: HttpParams } {
    let headers: { headers: HttpHeaders };
    if (trackingEventName) {
      headers = this.getTrackedHttpHeaders(trackingEventName);
    } else {
      headers = this.httpHeaders;
    }
    return {...headers, params: params};
  }

  public getHeadersForAdminRequest(token?: string) {
    // necessary for insert/update from admin.
    if (token) {
      return {
        headers:
          new HttpHeaders({
            'accept': 'text/plain',
            'X-API-KEY': token,
            'Content-Type': 'application/json'
          })
      };
    } else {
      return this.httpHeaders;
    }
  }

}
