import { Component, OnDestroy, OnInit } from '@angular/core';
import { TerritorySearch } from '../../core/territory-search/territory-search';
import { SearchService } from '../../shared/services/search.service';
import { ApiService } from '../../shared/services/api/api.service';
import { Router } from '@angular/router';

export interface Territory {
  name: string;
  code: number;
  kind: string;
}
@Component({
  selector: 'app-territory-search-page',
  templateUrl: './territory-search-page.component.html',
  styleUrls: ['./territory-search-page.component.scss']
})
export class TerritorySearchPageComponent extends TerritorySearch implements OnInit, OnDestroy {

  public serializedSessionNumber: number;

  constructor(
    protected apiService: ApiService,
    protected searchService: SearchService,
    private router: Router,
    ) {
    super(apiService);
  }

  ngOnInit() {
    /** reset needed to avoid artifacts when comming back and forth from seach component */
    this.searchService.resetSearchServiceMaps();

    this.serializedSessionNumber = Math.random();
  }

  ngOnDestroy() {
    /** reset needed to avoid artifacts when comming back and forth from seach component */
    this.searchService.resetSearchServiceMaps();
  }

  onSelectOrEnterPressed(territory: any) {
    document.getElementById(
      `dropdownList-${this.serializedSessionNumber}`)?.focus();
    if (!this.show) {
      this.show = true;
      this.onSubmitTerritory();
    } else {
      this.onItemClicked(territory);
    }
  }
  onSubmitTerritory() {
    if (this.selectedUid) {
      this.router.navigate([`/search/${this.selectedUid}/article`]).then();
    }
  }
}
