import { DbPagination } from '../pagination';
import { DbUser } from '../user';
import { TerritoryForArticle } from '../territory/territory-utils';
import { MatchStem } from '../match-stem';

export interface DbSharedDocument {
  document_uid: string;
  type: string;
  title: string;
  snippet: string;
  publication_date?: Date;
  territories?: Array<TerritoryForArticle>;
  source?: string;
  nb_images?: number;
  displayed_publication_type?: string;
  match_stems?: MatchStem[];
}

export class SharedDocument {
  private _documentUid: string;
  private _type: string;
  private _title: string;
  private _snippet: string;
  private _publicationDate?: Date;
  private _territories?: Array<TerritoryForArticle>;
  private _source?: string;
  private _nbImages?: number;
  private _displayedPublicationType?: string;
  private _matchStems?: MatchStem[];

  constructor(dbSharedDocument: DbSharedDocument) {
    this._documentUid = dbSharedDocument.document_uid;
    this._type = dbSharedDocument.type;
    this._title = dbSharedDocument.title;
    this._snippet = dbSharedDocument.snippet;
    this._publicationDate = dbSharedDocument.publication_date;
    this._territories = dbSharedDocument.territories;
    this._source = dbSharedDocument.source;
    this._nbImages = dbSharedDocument.nb_images;
    this._displayedPublicationType = dbSharedDocument.displayed_publication_type;
    this._matchStems = dbSharedDocument.match_stems;
  }

  get documentUid(): string {
    return this._documentUid;
  }

  set documentUid(value: string) {
    this._documentUid = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get snippet(): string {
    return this._snippet;
  }

  set snippet(value: string) {
    this._snippet = value;
  }

  get publicationDate(): Date {
    return this._publicationDate;
  }

  set publicationDate(value: Date) {
    this._publicationDate = value;
  }

  get territories(): Array<TerritoryForArticle> {
    return this._territories;
  }

  set territories(value: Array<TerritoryForArticle>) {
    this._territories = value;
  }

  get source(): string {
    return this._source;
  }

  set source(value: string) {
    this._source = value;
  }

  get nbImages(): number {
    return this._nbImages;
  }

  set nbImages(value: number) {
    this._nbImages = value;
  }

  get displayedPublicationType(): string {
    return this._displayedPublicationType;
  }

  set displayedPublicationType(value: string) {
    this._displayedPublicationType = value;
  }

  get matchStems(): MatchStem[] {
    return this._matchStems;
  }

  set matchStems(value: MatchStem[]) {
    this._matchStems = value;
  }
}

export interface DbPutShareResponseBody {
  errored_email: string[];
  message: string;
  share_id: number[];
}

export class PutShareResponseBody {
  private _erroredEmail: string[];
  private _message: string;
  private _shareId: number[];

  constructor(dbPutShareResponseBody: DbPutShareResponseBody) {
    this._erroredEmail = dbPutShareResponseBody.errored_email;
    this._message = dbPutShareResponseBody.message;
    this._shareId = dbPutShareResponseBody.share_id;
  }

  get erroredEmail(): string[] {
    return this._erroredEmail;
  }

  set erroredEmail(value: string[]) {
    this._erroredEmail = value;
  }

  get message(): string {
    return this._message;
  }

  set message(value: string) {
    this._message = value;
  }

  get shareId(): number[] {
    return this._shareId;
  }

  set shareId(value: number[]) {
    this._shareId = value;
  }
}

export interface DbGetContextFromShareResponseBody {
  data: Array<{id: number, shared_document: DbSharedDocument}>;
  pagination: DbPagination;
}

export interface DbGetGuestUserResponseBody {
  data: Array<{id: number, shared_with_user: DbUser}>;
  pagination: DbPagination;
}
