import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityService } from '../../../shared/services/entity.service';
import { first } from 'rxjs/operators';
import Utils from '../../../shared/utils';
import { ContextService } from '../../../shared/services/context.service';
import { AdminDocument } from '../../../models/admin-document';
import { ApiService } from '../../../shared/services/api/api.service';
import { EventTypeName } from '../../../models/user-tracker';
import { HttpParams } from '@angular/common/http';
import { ExportAdminDocMetadata } from '../../../models/export-admin-doc-metadata';
import { SharedDocument } from '../../../models/share/share';
import { DocumentType } from '../../../models/document-entity';
import { MatchStemsService } from '../../../shared/services/match-stems.service';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-admin-doc-view',
  templateUrl: './admin-doc-view.component.html',
  styleUrls: ['./admin-doc-view.component.scss']
})

export class AdminDocViewComponent implements OnInit, OnDestroy {
  @ViewChild('pdfViewContainer') pdfView: ElementRef;

  adminDocId: string;
  adminDoc: AdminDocument;
  documentToBeShared: SharedDocument;
  time: number;
  currentNavigationState: { [k: string]: any; };
  type = DocumentType.ADMIN_DOC;
  trackingEventName: EventTypeName | '' = '';

  isSharingPanelOpen = false; // Managing sharing & export panels
  adminDocRouteParams = {};   // ActivatedRoute params to pass to export panel.
  documentMetaData: ExportAdminDocMetadata; // Useful document metadata for tracking

  adminDocMatchStems$ = new ReplaySubject<any>(1);

  constructor(private route: ActivatedRoute,
              private entityService: EntityService,
              private apiService: ApiService,
              private contextService: ContextService,
              private matchStemsService: MatchStemsService,
              private router: Router,
              private utils: Utils
  ) {
    this.currentNavigationState = this.router.getCurrentNavigation()?.extras?.state;
  }

  async ngOnInit() {
    const openSource = this.route.snapshot.data['openSource'];
    // Set trackingEventName value
    this.setTrackingEventName(openSource);
    // Retrieve all params from activatedRoute
    const params = this.utils.getAllParamsFromRoute(this.route);
    this.adminDocRouteParams = params;
    this.adminDocId = params['adminDocId'];
    this.documentMetaData = new ExportAdminDocMetadata(                 // adminDocMetadata for export panel
      this.adminDocId,
      openSource
    );
    // Retrieving of document information.
    if (this.adminDocId) {
      this.entityService.sendEntityId(this.adminDocId);
      this.time = (new Date()).getTime();
      await this.fetchData(openSource, params['openSourceId'], params['collectiveOrderId'], params['exportId']).then();
    }
    // To store context on alert / share
    if (params['openSourceId']) {
      // Alert / share management of snippet.
      this.storeSnippet(openSource, params['openSourceId'], params['collectiveOrderId']).then();
    }
    if (params['openSourceId'] || params['exportId']) {
      // Alert / share / export management of match stems.
      this.storeMatchStems(openSource, params['openSourceId'], params['collectiveOrderId'], params['exportId']).then();
    }
  }

  setTrackingEventName(openSource: string): void {
    const routeQueryParams = this.route.snapshot.queryParams;
    // Management of trackingEventName in function of all possible openSource value.
    if (openSource === 'digest' && routeQueryParams['rank']) {
      this.trackingEventName = EventTypeName.DOCUMENT_OPENING_FROM_ALERT;
    }
    if (openSource === 'share') {
      this.trackingEventName = EventTypeName.DOCUMENT_OPENING_FROM_SHARE;
    }
    if (openSource === 'web_app') {
      this.trackingEventName = EventTypeName.DOCUMENT_OPENING_FROM_WEB_APP;
    }
    if (openSource === 'export') {
      this.trackingEventName = EventTypeName.DOCUMENT_OPENING_FROM_EXPORT;
    }
  }

  sharingPanelChangeExpandedState(isSharingPanelOpen: boolean) {
    this.isSharingPanelOpen = isSharingPanelOpen;
  }

  ngOnDestroy(): void {
    this.entityService.sendEntityId(null);
    this.entityService.sendDocument(null);
  }

  async retrieveDocAdmin(openSource, openSourceId, collectiveOrderId) {
    if (openSource === 'export') {
      openSource = 'web_app';
    }
    if (openSource !== 'web_app') {
      openSource = 'email';
    }
    const query = `${this.adminDocId}`;
    let params = new HttpParams();
    params = params.append('open_source', openSource);
    const routeQueryParams = this.route.snapshot.queryParams;
    if (this.trackingEventName === EventTypeName.DOCUMENT_OPENING_FROM_ALERT) { // case alert
      params = params.append('rank', routeQueryParams['rank']);
      params = params.append('article_count', routeQueryParams['article_count']);
      params = params.append('admin_doc_count', routeQueryParams['admin_doc_count']);
      params = params.append('document_type', 'admin_doc');
      params = params.append('collective_order_id', collectiveOrderId);
    }
    if (openSourceId) {  // case alert & share
      params = params.append('open_source_id', openSourceId);
    }
    try {
      return await this.apiService.adminDoc.getAdminDoc(query, params, this.trackingEventName).pipe(first()).toPromise();
    } catch (e) {
      console.log(`error when retrieving doc admin: ${e}`);
    }
  }

  async fetchData(openSource, openSourceId, collectiveOrderId, exportId) {
    this.adminDoc = await this.retrieveDocAdmin(openSource, openSourceId, collectiveOrderId);
    this.entityService.sendDocument(this.adminDoc);

    const snippet = await this.contextService
      .getContext(this.currentNavigationState, this.adminDocId, openSource, openSourceId, collectiveOrderId);
    if (snippet) {
      this.adminDoc['snippet'] = snippet;
    }

    // Retrieving of match stems & update of subscription.
    const matchStems = await this.matchStemsService
      .getMatchStems(this.currentNavigationState, this.adminDocId, openSource, openSourceId, collectiveOrderId, exportId);
    this.adminDoc.matchStems = matchStems;
    this.adminDocMatchStems$.next(matchStems);

    console.log('this.adminDoc', this.adminDoc);
    this.documentToBeShared = new SharedDocument({
      document_uid: this.adminDoc.id,
      type: this.type,
      title: this.adminDoc.title,
      snippet: this.adminDoc.snippet,
      publication_date: this.adminDoc.publicationDate,
      displayed_publication_type: this.adminDoc.displayedPublicationType,
      match_stems: this.adminDoc.matchStems
    });
  }

  /** Management of contexte - retrieving and persisting */
  async storeSnippet(openSource, openSourceId, collectiveOrderId) {
    const snippet = await this.contextService
      .getContext(this.currentNavigationState, this.adminDocId, openSource, openSourceId, collectiveOrderId);
    this.contextService.setSnippetWithExpiry(this.adminDocId, snippet);
  }

  /** Management of match_stems - retrieving and persisting */
  async storeMatchStems(openSource, openSourceId, collectiveOrderId, exportIdString) {
    const exportId = +exportIdString;
    const matchStems = await this.matchStemsService
      .getMatchStems(this.currentNavigationState, this.adminDocId, openSource, openSourceId, collectiveOrderId, exportId);
    this.matchStemsService.setMatchStemsWithExpiry(this.adminDocId, matchStems);
  }

  bookmarkPathsUpdated(id: string, event: Array<any>) {
    this.entityService.sendBookmarkPaths(id, event);
  }

}
