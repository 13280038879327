import { BookmarkPath, DbBookmarkPath } from './bookmark-path';

export interface DbDocumentEntity {
  bookmark_paths: Array<DbBookmarkPath>;
  publication_date: Date;
  title: string;
  topics: Array<string>;
  occurrence_count?: number;
  text?: string;
  snippet?: string;
  excerpt?: string;
}

export class DocumentEntity {
  private _bookmarkPaths: Array<BookmarkPath>;
  private _publicationDate: Date;
  private _title: string;
  private _topics: Array<string>;
  private _occurrenceCount?: number;
  private _text?: string;
  private _snippet?: string;
  private _excerpt?: string;

  constructor(documentEntity: DbDocumentEntity) {
    this._bookmarkPaths = documentEntity.bookmark_paths.map((bookmarkPath) => new BookmarkPath(bookmarkPath));
    this._publicationDate = documentEntity.publication_date;
    this._title = documentEntity.title;
    this._topics = documentEntity.topics;
    this._occurrenceCount = documentEntity.occurrence_count;
    this._text = documentEntity.text;
    this._snippet = documentEntity.snippet;
    this._excerpt = documentEntity.excerpt;
  }

  get bookmarkPaths(): Array<BookmarkPath> {
    return this._bookmarkPaths;
  }

  set bookmarkPaths(value: Array<BookmarkPath>) {
    this._bookmarkPaths = value;
  }

  get publicationDate(): Date {
    return this._publicationDate;
  }

  set publicationDate(value: Date) {
    this._publicationDate = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get topics(): Array<string> {
    return this._topics;
  }

  set topics(value: Array<string>) {
    this._topics = value;
  }

  get occurrenceCount(): number {
    return this._occurrenceCount;
  }

  set occurrenceCount(value: number) {
    this._occurrenceCount = value;
  }

  get text(): string {
    return this._text;
  }

  set text(value: string) {
    this._text = value;
  }

  get snippet(): string {
    return this._snippet;
  }

  set snippet(value: string) {
    this._snippet = value;
  }

  get excerpt(): string {
    return this._excerpt;
  }

  set excerpt(value: string) {
    this._excerpt = value;
  }
}

export enum DocumentType {
  ARTICLE = 'article',
  ADMIN_DOC = 'admin_doc'
}
