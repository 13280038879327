import { DbDocumentEntity, DocumentEntity } from './document-entity';
import { MatchStem } from './match-stem';

export interface DbAdminDocument extends DbDocumentEntity {
  id: string;
  publication_type: any;
  url: string;
  website: string;
  displayed_publication_type: string;
  match_stems?: MatchStem[];
}

export class AdminDocument extends DocumentEntity {

  private _id: string;
  private _publicationType: any;
  private _url: string;
  private _website: string;
  private _displayedPublicationType: string;
  private _matchStems?: MatchStem[];

  constructor(dbAdminDocument: DbAdminDocument) {
    super(dbAdminDocument as DbDocumentEntity);
    this._id = dbAdminDocument.id;
    this._publicationType = dbAdminDocument.publication_type;
    this._url = dbAdminDocument.url;
    this._website = dbAdminDocument.website;
    this._displayedPublicationType = dbAdminDocument.displayed_publication_type;
    this._matchStems = dbAdminDocument.match_stems;
  }


  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get publicationType(): any {
    return this._publicationType;
  }

  set publicationType(value: any) {
    this._publicationType = value;
  }

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

  get website(): string {
    return this._website;
  }

  set website(value: string) {
    this._website = value;
  }

  get displayedPublicationType(): string {
    return this._displayedPublicationType;
  }

  set displayedPublicationType(value: string) {
    this._displayedPublicationType = value;
  }

  get matchStems(): MatchStem[] {
    return this._matchStems;
  }

  set matchStems(value: MatchStem[]) {
    this._matchStems = value;
  }
}
