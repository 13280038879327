<app-header></app-header>
<div class="container-fluid">
  <div class="row">
    <div class="content">
      <div class="font-weight-bold title">{{"update-password.title" | translate }}</div>
      <div class="sub-title pb-4">{{"update-password.sub-title"  | translate}}</div>

      <form [formGroup]="formOldPassword" autocomplete="off">
        <div class="form-group">
          <label for="oldPassword"
                 class="login-label-title font-weight-bold"> {{"update-password.actual" | translate | uppercase}}</label>

          <div class="input-group">
            <input id="oldPassword"
                   class="form-control"
                   formControlName="oldPassword"
                   [type]="fieldTextTypeList[0] ? 'text' : 'password'"
                   (blur)="onSubmitOldPassword()"
                   (focus)="userLeftInputPreviousPassword = false"
                   [ngClass]="{ 'is-invalid': oldPasswordIsWrong && userLeftInputPreviousPassword }"/>
            <div class="input-group-append-hide-show">
              <span *ngIf="fieldTextTypeList[0]"
                    (click)="toggleFieldTextType(0)" class="hide-show">
                <img class="hide-show-icon"
                     src="../../../../assets/images/hide-password-icon.svg" alt="icon-hide">
              </span>
              <span *ngIf="!fieldTextTypeList[0]"
                    (click)="toggleFieldTextType(0)" class="hide-show">
                <img class="hide-show-icon"
                     src="../../../../assets/images/show-password-icon.svg" alt="icon-show">
              </span>
            </div>
          </div>
          <div *ngIf="oldPasswordIsWrong && userLeftInputPreviousPassword" class="error-div">
            <div class="no-match-error-message">
              <img class="no-match-icon" src="../../assets/images/icon-alert-triangle.svg" alt="icon-alert-triangle">
              <div class="no-match-text">{{ 'update-password.invalid-previous-password' | translate }} </div>
            </div>
          </div>
        </div>
      </form>

      <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
        <div class="form-group new-password-form-group">
          <label for="newPassword"
                 class="login-label-title font-weight-bold"> {{"update-password.enter-new-password" | translate | uppercase}}</label>

          <div class="input-group">
            <input id="newPassword" [type]="fieldTextTypeList[1] ? 'text' : 'password'" formControlName="newPassword"
                   class="form-control"/>
            <div class="input-group-append-hide-show">
              <span *ngIf="fieldTextTypeList[1]" (click)="toggleFieldTextType(1)" class="hide-show">
                <img class="hide-show-icon"
                     src="../../../../assets/images/hide-password-icon.svg" alt="icon-hide">
              </span>
              <span *ngIf="!fieldTextTypeList[1]" (click)="toggleFieldTextType(1)" class="hide-show">
                <img class="hide-show-icon"
                     src="../../../../assets/images/show-password-icon.svg" alt="icon-show">
              </span>
            </div>
          </div>

          <!-- case with errors -->
          <div *ngIf="f.newPassword.errors" class="password-validators">

            <div class="validator">
              <div *ngIf="f.newPassword.errors.minlength || !f.newPassword.value"><img
                src="../../../../assets/images/icon-radio-button-off.svg" alt="icon-radio-button-off"></div>
              <div *ngIf="!f.newPassword.errors.minlength && f.newPassword.value"><img
                src="../../../../assets/images/icon-green-checkmark-circle.svg" alt="icon-radio-button-on"></div>
              <div
                [ngClass]="{ 'validator-text': true, 'validator-is-valid': !f.newPassword.errors.minlength && f.newPassword.value }">
                {{'update-password.validator1' | translate}}</div>
            </div>
            <div class="validator">
              <div *ngIf="f.newPassword.errors.hasSpecialCharacters || !f.newPassword.value"><img
                src="../../../../assets/images/icon-radio-button-off.svg" alt="icon-radio-button-off"></div>
              <div *ngIf="!f.newPassword.errors.hasSpecialCharacters && f.newPassword.value"><img
                src="../../../../assets/images/icon-green-checkmark-circle.svg" alt="icon-radio-button-on"></div>
              <div
                [ngClass]="{ 'validator-text': true, 'validator-is-valid': !f.newPassword.errors.hasSpecialCharacters && f.newPassword.value}">
                {{'update-password.validator2' | translate}}</div>
            </div>
            <div class="validator">
              <div *ngIf="f.newPassword.errors.hasSmallCase || !f.newPassword.value"><img
                src="../../../../assets/images/icon-radio-button-off.svg" alt="icon-radio-button-off"></div>
              <div *ngIf="!f.newPassword.errors.hasSmallCase && f.newPassword.value"><img
                src="../../../../assets/images/icon-green-checkmark-circle.svg" alt="icon-radio-button-on"></div>
              <div
                [ngClass]="{ 'validator-text': true, 'validator-is-valid': !f.newPassword.errors.hasSmallCase && f.newPassword.value}">
                {{'update-password.validator3' | translate}}</div>
            </div>
            <div class="validator">
              <div *ngIf="f.newPassword.errors.hasNumber || !f.newPassword.value"><img
                src="../../../../assets/images/icon-radio-button-off.svg" alt="icon-radio-button-off"></div>
              <div *ngIf="!f.newPassword.errors.hasNumber && f.newPassword.value"><img
                src="../../../../assets/images/icon-green-checkmark-circle.svg" alt="icon-radio-button-on"></div>
              <div
                [ngClass]="{ 'validator-text': true, 'validator-is-valid': !f.newPassword.errors.hasNumber && f.newPassword.value }">
                {{'update-password.validator4' | translate}}</div>
            </div>
            <div class="validator">
              <div *ngIf="f.newPassword.errors.hasCapitalCase || !f.newPassword.value"><img
                src="../../../../assets/images/icon-radio-button-off.svg" alt="icon-radio-button-off"></div>
              <div *ngIf="!f.newPassword.errors.hasCapitalCase && f.newPassword.value"><img
                src="../../../../assets/images/icon-green-checkmark-circle.svg" alt="icon-radio-button-on"></div>
              <div
                [ngClass]="{ 'validator-text': true, 'validator-is-valid': !f.newPassword.errors.hasCapitalCase && f.newPassword.value}">
                {{'update-password.validator5' | translate}}</div>
            </div>

          </div>

          <!-- case without errors (Else there an issue because f.password.erros = null -->
          <div *ngIf="!f.newPassword.errors" class="password-validators">

            <div class="validator">
              <div><img src="../../../../assets/images/icon-green-checkmark-circle.svg" alt="check-ok"></div>
              <div class="validator-text validator-is-valid">
                {{'update-password.validator1' | translate}}</div>
            </div>
            <div class="validator">
              <div><img src="../../../../assets/images/icon-green-checkmark-circle.svg" alt="check-ok"></div>
              <div class="validator-text validator-is-valid">
                {{'update-password.validator2' | translate}}</div>
            </div>
            <div class="validator">
              <div><img src="../../../../assets/images/icon-green-checkmark-circle.svg" alt="check-ok"></div>
              <div class="validator-text validator-is-valid">
                {{'update-password.validator3' | translate}}</div>
            </div>
            <div class="validator">
              <div><img src="../../../../assets/images/icon-green-checkmark-circle.svg" alt="check-ok"></div>
              <div class="validator-text validator-is-valid">
                {{'update-password.validator4' | translate}}</div>
            </div>
            <div class="validator">
              <div><img src="../../../../assets/images/icon-green-checkmark-circle.svg" alt="check-ok"></div>
              <div class="validator-text validator-is-valid">
                {{'update-password.validator5' | translate}}</div>
            </div>

          </div>

        </div>
        <div class="form-group">
          <label for="confirmPassword"
                 class="login-label-title font-weight-bold"> {{"update-password.confirm-new-password" | translate | uppercase}}</label>

          <div class="input-group">
            <input id="confirmPassword"
                   [ngClass]="{ 'is-invalid': confirmationPasswordIsWrong() && userLeftInputConfirmPassword }"
                   (blur)="userLeftInputConfirmPassword = true"
                   (focus)="userLeftInputConfirmPassword = false"
                   [type]="fieldTextTypeList[2] ? 'text' : 'password'"
                   formControlName="confirmPassword" class="form-control"/>
            <div class="input-group-append-hide-show">
              <span *ngIf="fieldTextTypeList[2]"
                    (click)="toggleFieldTextType(2)"
                    class="hide-show">
                <img class="hide-show-icon"
                     src="../../../../assets/images/hide-password-icon.svg">
              </span>
              <span *ngIf="!fieldTextTypeList[2]"
                    (click)="toggleFieldTextType(2)"
                    class="hide-show">
                <img class="hide-show-icon"
                     src="../../../../assets/images/show-password-icon.svg">
              </span>
            </div>
          </div>
          <div *ngIf="confirmationPasswordIsWrong() && userLeftInputConfirmPassword" class="error-div">
            <div class="no-match-error-message">
              <img class="no-match-icon" src="../../assets/images/icon-alert-triangle.svg"/>
              <div class="no-match-text">{{ 'update-password.invalid-confirmation-password' | translate }} </div>
            </div>
          </div>
        </div>

        <div class="login-btn-container form-group">
          <button [disabled]="loading || (f.newPassword.invalid || f.confirmPassword.invalid)"
                  class="font-weight-bold btn btn-login">{{'update-password.reset' | translate | uppercase}}</button>
        </div>

      </form>
    </div>
  </div>
  <app-toast-message createMessage="update-password.toast-content"></app-toast-message>
</div>

