import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../headers/headers.service';
import { LoaderService } from '../../loader.service';
import { TerritoryCountersService } from '../../territory-counters.service';
import { DbTerritory, Territory } from '../../../../models/territory/territory';
import { DbTerritoryCounters, TerritoryCounters } from '../../../../models/territory/territory-counters';
import { TerritoryManager } from '../../../../models/territory/territory-manager';



@Injectable({
  providedIn: 'root'
})
export class ApiTerritoryService {
  territoryUid: string;
  queryAlert: boolean;
  territoryManager: ReplaySubject<TerritoryManager>;

  constructor(
    private http: HttpClient,
    private headersService: HeadersService,
    private loaderService: LoaderService,
    private territoryCountersService: TerritoryCountersService,
  ) {
    this.territoryManager = new ReplaySubject<TerritoryManager>(0);
  }

  retrieveTerritories(territoryUid: string, queryAlert = false, standalone = false): Observable<TerritoryManager> {
    if (territoryUid === this.territoryUid && this.queryAlert === queryAlert) {
      return this.territoryManager;
    } else {
      return this.http.get(
        `${environment.backV2EndUrl}territory/tree/${territoryUid}/?query_alert=${queryAlert}`,
        this.headersService.httpHeaders
      ).pipe(map((data: DbTerritory) => {
        const territoryTreeResponse = new Territory(data);
        const territoryManager = new TerritoryManager(territoryTreeResponse);
        if (!standalone) {
          this.territoryUid = territoryUid;
          this.queryAlert = queryAlert;
          this.territoryManager.next(territoryManager);
        }
        return territoryManager;
      }));
    }
  }

  retrieveTerritoryCounters(territoryUid: string, standalone?: boolean): Observable<TerritoryCounters> {
    return this.http.get(`${environment.backV2EndUrl}territory/counters/${territoryUid}/`, this.headersService.httpHeaders)
      .pipe(map((dbTerritoryCounters: DbTerritoryCounters) => {
        dbTerritoryCounters.uid = territoryUid;
        const territoryCounters = new TerritoryCounters(dbTerritoryCounters);
        if (!standalone) {
          this.territoryCountersService.countersSubject.next(territoryCounters);
        }
        return territoryCounters;
      }));
  }
}
