import { AfterViewInit, Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { ToastMessageStackService } from '../../../shared/services/toast-message-stack.service';
import { AdminDocument } from '../../../models/admin-document';
import { ApiService } from '../../../shared/services/api/api.service';
import { ExportService } from '../../../shared/services/export.service';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Params } from '@angular/router';
import { ExportAdminDocMetadata } from '../../../models/export-admin-doc-metadata';
import { UserTrackerService } from '../../../shared/services/tracking/user-tracker.service';
import { UserTrackerBody } from '../../../models/user-tracker';

@Component({
  selector: 'app-export-panel',
  templateUrl: './export-panel.component.html',
  styleUrls: ['./export-panel.component.scss']
})
export class ExportPanelComponent implements AfterViewInit {

  @Input() document: AdminDocument;
  @Input() documentMetaData: ExportAdminDocMetadata;  // metadata only used for tracking
  @Input() routeParams: Params;

  @ViewChild('exportInProgressToast', {read: TemplateRef}) exportInProgressToast: TemplateRef<any>;
  @ViewChild('errorInExport', {read: TemplateRef}) errorInExport: TemplateRef<any>;
  @ViewChild('exportButton') exportButton: ElementRef;
  isExportInProgress = false;
  className = 'toast-export-in-progress';

  exportSubscription = new Subscription();

  constructor(
    private toastMessageStackService: ToastMessageStackService,
    private apiService: ApiService,
    private exportService: ExportService,
    private userTrackerService: UserTrackerService
  ) { }

  ngAfterViewInit(): void {
    // Setup className used for toasts. t-e-i-p is short for toast-export-in-progress
    this.className = `toast-export-in-progress t-e-i-p-${this.document.id}`;
  }

  /** Procedure for export - case admin document */
  async onExport() {
    // Display export in progress toast message.
    this.showExportToast();

    // AWAIT exportID - DB insert of export data.
    const exportId = await this.getExportId();

    // Version using a subscription
    this.exportSubscription =
      // Trigger export calling worker
      this.apiService.exportAdminDoc
        .triggerWatermarkingAdminDocument(this.document, exportId, this.documentMetaData, this.routeParams)
        .pipe(first())
        .subscribe((response) => {
            if (!response) {
              this.stopExport(); // Stop procedure if no url retrieved
            }
            this.removeExportToast();

            // Retrieving admin doc for download.
            this.apiService.exportAdminDoc
              .getWatermarkedAdminDocument(response.downloadUrl)
              .pipe(first())
              .subscribe((adminDocPdfBlob) => {
                  const adminDocName = response.downloadUrl.split('/').pop();
                  this.exportService.savePdfToComputer(adminDocPdfBlob, adminDocName);
                },
                (e) => {
                  return this.manageApiError(`retrieving blob`, e);
                });

            // Updating the export public_return_url stocked in DB.
            this.apiService.exportAdminDoc
              .updateExportUrls(exportId, response.downloadUrl, response.frontUrl)
              .pipe(first())
              .subscribe((responseMessage) => {
                console.log(responseMessage);
              });
          },
          (e) => {
            // Tracking error in export call to worker
            const trackJSON = new UserTrackerBody('admin-doc-export-error');
            trackJSON.request_body = {
              ...this.document,
              ...this.documentMetaData,
              ...this.routeParams
            };
            this.userTrackerService.track(trackJSON)
              .pipe(first())
              .subscribe();

            return this.manageApiError(`triggering export`, e);
          });

  }

  /** Method retrieving export id from PUT /Export response */
  async getExportId(): Promise<number> {
    try {
      const exportObject = await this.apiService.exportAdminDoc.insertExport(this.document).pipe(first()).toPromise();
      return exportObject.export_id;
    } catch (e) {
      console.log(`error when retrieving export ID : ${e}`);
      return null;
    }
  }

  /** Removing related toast and killing export procedure */
  stopExport() {
    this.removeExportToast();
    this.exportSubscription.unsubscribe();   // kill export procedure
  }

  /** Remove export toast and display error toast */
  manageApiError(stepName: string, e: any) {
    console.log(`error on export - step : ${stepName} - ${e}`);
    this.removeExportToast();
    this.displayErrorToast();
  }

  /** Display export toast and adapt export button style */
  showExportToast() {
    this.toastMessageStackService.show(this.exportInProgressToast, {classname: this.className + ' toast-shape'});
    this.isExportInProgress = true;
  }

  /** Remove export toast and adapt export button style */
  removeExportToast() {
    // Adapting style
    this.isExportInProgress = false;
    // Manage large export in progress toast
    this.toastMessageStackService.removeByClassName(`t-e-i-p-${this.document.id}`);
    // Manage light toast (hidden export in progress)
    this.toastMessageStackService.removeByClassName(`l-t-${this.document.id}`);
  }

  displayErrorToast() {
    this.toastMessageStackService.show(this.errorInExport, {autohide: true, classname: 'error-toast toast-shape'});
  }

}
