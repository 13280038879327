import { Injectable } from '@angular/core';
import { ItemsService } from './items.service';
import { ReplaySubject } from 'rxjs';

export enum BookmarkDocumentEntityType {
  ARTICLE = 'article',
  ADMIN_DOC = 'admin_doc'
}

export enum OrderByBookmarkDocument {
  PUBLICATION_DATE = 'publication_date',
  BOOKMARK_DATE = 'bookmark_date'
}

@Injectable({
  providedIn: 'root'
})
export class BookmarkDocumentService extends ItemsService {
  private _containerId: number;
  private _containerLabel: string;
  private _entityType: BookmarkDocumentEntityType;

  constructor() {
    super();
    this.item = {};
    this.currentPage = 1;
    this._containerId = 0;
    this.entityType = BookmarkDocumentEntityType.ARTICLE;
    this.limit = 25;
    this.orderBy = OrderByBookmarkDocument.BOOKMARK_DATE;
    this.pageIndex = 0;
    this.reverse = true;
    this.subject = new ReplaySubject<any>(1);
  }

  reset() {
    this.item = {};
    this.currentPage = 1;
    this.limit = 25;
    // this.orderBy = OrderByBookmarkDocument.BOOKMARK_DATE;
    this.entityType = BookmarkDocumentEntityType.ARTICLE;
    this.pageIndex = 0;
  }

  sentLimit(limit: number) {
    this.limit = limit;
    this.currentPage = 1;
    this.pageIndex = 0;
    this.sendPostSettings();
  }

  sentSort(orderBy: OrderByBookmarkDocument) {
    this.orderBy = orderBy;
    this.pageIndex = 0;
    this.currentPage = 1;
    this.sendPostSettings();
  }

  sentPageIndex(pageIndex: number) {
    this.currentPage = pageIndex;
    this.pageIndex = pageIndex - 1;
    this.sendPostSettings();
  }

  sendPostSettings() {
    this.subject.next
    (
      {
        container_id: this._containerId,
        entity_type: this.entityType,
        limit: this.limit,
        page_index: this.pageIndex,
        order_by: this.orderBy,
        reverse: this.reverse
      }
    );
  }

  getCurrentPage(): number {
    return this.currentPage;
  }

  get containerId(): number {
    return this._containerId;
  }

  set containerId(value: number) {
    this._containerId = value;
  }

  get entityType(): BookmarkDocumentEntityType {
    return this._entityType;
  }

  set entityType(value: BookmarkDocumentEntityType) {
    this._entityType = value;
  }

  get containerLabel(): string {
    return this._containerLabel;
  }

  set containerLabel(value: string) {
    this._containerLabel = value;
  }
}
