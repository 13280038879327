<nav class="menu d-flex flex-column text-center">
  <div class="flex-grow-1">
    <div class="menu-body flex-grow-1" routerLinkActive #rla="routerLinkActive">
      <div class="logo" title="retour à l'acceuil" [routerLink]="['/']">
        GW
      </div>
      <div class="menu-content position-relative">
        <div class="menu-item icon-menu icon-menu--search" [routerLink]="['/search']"
             ngbTooltip="{{'menu.search-tooltip' | translate}}"
             routerLinkActive="router-link-active"
             #t1="ngbTooltip"
             [disableTooltip]="isClickedT1"
             (mouseenter)="openTooltipT1()"
             (mouseleave)="closeTooltipT1()"
             (click)="closeTooltipT1()"
             placement="right" tooltipClass="custom-tooltip"></div>
        <div class="menu-item icon-menu icon-menu--folder" [routerLink]="['/folder']"
             ngbTooltip="{{'menu.folder-tooltip' | translate}}"
             routerLinkActive="router-link-active"
             #t1="ngbTooltip"
             [disableTooltip]="isClickedT1"
             (mouseenter)="openTooltipT1()"
             (mouseleave)="closeTooltipT1()"
             (click)="closeTooltipT1()"
             placement="right" tooltipClass="custom-tooltip"></div>
        <div class="menu-item icon-menu icon-menu--bell" [routerLink]="['/alert']"
             ngbTooltip="{{'menu.alert-tooltip' | translate}}"
             routerLinkActive="router-link-active"
             [disableTooltip]="isClickedT1"
             (mouseenter)="openTooltipT1()"
             (mouseleave)="closeTooltipT1()"
             (click)="closeTooltipT1()"
             placement="right" tooltipClass="custom-tooltip"></div>
        <div class="menu-item icon-menu icon-menu--message" [routerLink]="['/topics']"
             ngbTooltip="{{'menu.topic-tooltip' | translate}}"
             routerLinkActive="router-link-active"
             [disableTooltip]="isClickedT1"
             (mouseenter)="openTooltipT1()"
             (mouseleave)="closeTooltipT1()"
             (click)="closeTooltipT1()"
             placement="right" tooltipClass="custom-tooltip"></div>
      </div>
      <div class="text-center menu-content">

        <div appClickOutside
             id="account"
             class="menu-item menu-body-footer icon-menu icon-menu--person position-absolute"
             (clickOutside)="displayPersonnalSettings = false"
             (click)="displayPersonnalSettings = !displayPersonnalSettings">
        </div>

      </div>
    </div>
  </div>

<!-- POP up of personal settings -->
  <div *ngIf="displayPersonnalSettings"
       class="personnal-settings">
    <div class="round-top-corner"></div>
    <div class="btn-container">
      <div (click)="displayPersonnalSettings=false" [routerLink]="['/settings']" class="btn settings cartridge">
        <span class="btn-text">{{'menu.settings' | translate}}</span>
        <img class="personnal-settings-icon" src="./../../../assets/images/icon-settings.svg" alt="icon-settings">
      </div>
      <div class="btn logout cartridge" (click)="onLogOut()">
        <span class="btn-text">{{'menu.logout' | translate}}</span>
        <img class="logout-icon" src="./../../../assets/images/power-icon.svg" alt="logout-icon">
      </div>
    </div>
    <div class="round-bottom-corner"></div>
  </div>

</nav>
