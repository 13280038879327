  <div class="no-topics-placeholder">
    <ng-container *ngIf="type === TopicType.CUSTOM">
      <h4>{{'topic.default-topic-available' | translate}}</h4>
      <p>{{'topic.custom-topic-if-needed' | translate}}</p>
      <p>{{'topic.cover-additional-subject' | translate}}</p>
      <p>{{'topic.enhanced-default-topic' | translate}}</p>
    </ng-container>
    <ng-container *ngIf="type === TopicType.DEFAULT">
      <h4>{{'topic.zero-default-topic' | translate}}</h4>
      <p>{{'topic.you-may-contact' | translate}} <a href="mailto: support@explain.fr">support@explain.fr</a> {{'topic.for-additional-infos' | translate}}</p>
    </ng-container>
  </div>
