<div class="form-perimeter-container">
  <div class="d-flex flex-column justify-content-center">
    <div class="title font-weight-bold">
      {{'wizard.perimeter' | translate | uppercase}}
    </div>
    <div class="department-select d-flex total-step-width">
      <div class="search d-flex">
        <div class="position-relative dropdown"
             [ngClass]="{'show': show}"
             [ngbTooltip]="(territories.length >= maxDepartmentNumber) ? ('wizard.limiters-perimeters' | translate) : ''"
             placement="bottom"
             triggers="click hover focus"
             appClickOutside
             (clickOutside)="clickOutsideDropdownMenu()">
          <input appOutlineNone type="text" placeholder="{{'wizard.select-department' | translate}}"
                 [ngClass]="{'search-input': true, 'not-allowed': (territories.length >= maxDepartmentNumber)}"
                 [(ngModel)]="territoryName"
                 (ngModelChange)="search($event)"
                 (click)="hideOrOpen()"
                 id="dropdownList"
                 autocomplete="off"
                 [disabled]="(territories.length >= maxDepartmentNumber)"
          />
          <div class="icon position-absolute" (click)="hideOrOpen()" aria-haspopup="true" aria-expanded="false"
               [style.pointer-events]="(territories.length >= maxDepartmentNumber) ? 'none' : ''">
          </div>
          <div [hidden]="!filteredList.length"
               class="dropdown-menu no-select"
               [ngClass]="{'show': show}"
               aria-labelledby="dropdownList"
          >
            <ng-container *ngFor="let territory of filteredList ; let i=index">
              <div class="dropdown-item" id="dropdown-item-{{i}}"
                   [ngClass]="{'custom-disabled': checkItemSelected(territory),
                                   'element-list-active': activeDepartmentIndex === i && !checkItemSelected(territory)}"
                   appClickOrEnterConfirmation
                   [index]="i"
                   [itemsList]="filteredList"
                   [activeIndex]="activeDepartmentIndex"
                   (clickMouseOrPressEnter)="onSelectOrEnterPressed(territory)"
                   (arrowUpOrDown)="onArrowKeyPress($event,'dropdown-item-' + i)"
                   (mouseEnters)="onMouseEnter($event)"
                   (clickOrPressDebounceDueTimeOutput)="retrieveDueTime($event)"
              >
                <span> {{territory.code}} - {{territory.name}}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="department-list d-flex flex-column">
      <ng-container *ngFor="let territory of territories; let i = index">
        <div class="card-board total-step-width" [ngClass]="{'new-item': territory['new']}">
          <div class="item">
            <div class="row">
              <div class="col-7 territory-item">
                <div class="name">{{territory['name']}}</div>
                <div class="counter">
                  <div *ngIf="territory['allChecked']">{{'wizard.all-territories' | translate}}</div>
                  <div *ngIf="!territory['allChecked']">
                    <div *ngIf="territory['checkedItems'].length">
                      {{territory['checkedItems'].length}}/{{territory['nbItems']}} {{'wizard.locality' | translate}}
                    </div>
                    <div *ngIf="!territory['checkedItems'].length" class="d-flex">
                      <div class="align-self-center icon icon--triangle"></div>
                      <div class="align-self-center text-color-primary">{{'wizard.no-location' | translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 d-flex setting justify-content-end">
                <button class="d-flex align-self-center" *ngIf="territory['uid'] !== openTab"
                        (click)="openDepartmentTree(territory['uid'])">
                  <mat-icon svgIcon="edit-icon" class="align-self-center"></mat-icon>
                  <div class="align-self-center font-weight-bold text-uppercase">{{'wizard.refine-localities' |
                    translate}}</div>
                </button>
                <button class="d-flex align-self-center btn-save" *ngIf="territory['uid'] === openTab"
                        (click)="closeDepartmentTree()">
                  <img src="assets/images/icon-checkmark.svg" class="align-self-center" alt="icon-checkmark">
                  <div class="align-self-center font-weight-bold text-uppercase">{{'wizard.save' | translate}}</div>
                </button>
              </div>
              <div class="col-1 d-flex">
                <button class="btn-delete d-flex align-self-center" (click)="onRemoveDepartment(i)">
                  <div class="align-self-center icon-block icon-block--trash"></div>
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="territory['uid'] === openTab">
            <ng-template #headerTemplate let-onFilterTextChange="onFilterTextChange">
              <div class="territory-search">
                <input type="text" placeholder="{{'wizard.placeholder-search-territories' | translate}}"
                       [(ngModel)]="filterText" (ngModelChange)="onFilterTextChange($event)"
                       autocomplete="off"/>
                <div class="icon-search"></div>
                <img src="assets/images/icon-close-circle-fill.svg" *ngIf="filterText" alt="icon-close-circle"
                     (click)="onFilterTextChange(''); resetTextSearch();">
              </div>
              <div *ngIf="nbMatchSearch" class="search-counter">
                <span class="font-weight-bold">{{nbMatchSearch}} {{'wizard.locality' | translate}}</span>
                {{'wizard.search-match' | translate}}
                <span class="font-weight-bold btn-clear-search"
                      (click)="onFilterTextChange(''); resetTextSearch();">{{'wizard.clear-search' | translate}}</span>
              </div>
            </ng-template>
            <ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
                         let-onCheckedChange="onCheckedChange">
              <div class="row-item" [ngClass]="{'hidden': hideItem(item), 'root-item': item.isRoot}">
                <div class="left-group">
                  <i *ngIf="item.children && !item.isRoot" (click)="onCollapseExpand()" aria-hidden="true"
                     [ngSwitch]="item.collapsed">
                    <img *ngSwitchCase="true" src="assets/images/arrow-ios-right.svg" alt="arrow-ios-right"/>
                    <img *ngSwitchCase="false" src="assets/images/arrow-ios-down.svg" alt="arrow-ios-down"/>
                  </i>
                  <i *ngIf="item.isRoot" (click)="collapse(i)" aria-hidden="true" [ngSwitch]="item.isRoot"
                     ngbTooltip="{{'alert.tooltip-collapse' | translate}}" placement="auto" container="body">
                    <ng-container *ngSwitchCase="true">
                      <img src="assets/images/icon-expand.svg" alt="icon-expand"/>
                    </ng-container>
                    <ng-container *ngSwitchCase="false" [ngSwitch]="item.collapsed">
                      <img *ngSwitchCase="true" src="assets/images/arrow-ios-right.svg" alt="arrow-ios-right"/>
                      <img *ngSwitchCase="false" src="assets/images/arrow-ios-down.svg" alt="arrow-ios-down"/>
                    </ng-container>
                  </i>
                  <div *ngIf="!item.isRoot" class="treeview-path"></div>
                </div>
                <div class="form-check" [ngClass]="{'node-root': item.isRoot}">
                  <label class="checkbox align-self-center" [ngClass]="{'last-node': !item.children}">
                    <!-- <input type="checkbox" *ngIf="!item.isRoot" class="form-check-input" [checked]="item.checked" [(ngModel)]="item.checked" (change)="onCheckedChange()"
                         [disabled]="item.disabled || !itemInSearch(item.text)" [indeterminate]="item.indeterminate" /> -->
                    <input type="checkbox" *ngIf="!item.isRoot" class="form-check-input" [checked]="item.checked"
                           [(ngModel)]="item.checked" (change)="onCheckedChange()"
                           [indeterminate]="item.indeterminate"/>
                    <input type="checkbox" *ngIf="item.isRoot" class="form-check-input"
                           [checked]="territory['allChecked']" [(ngModel)]="territory['allChecked']"
                           (change)="onSelectAllChange(i)" [indeterminate]="item.indeterminate"/>
                    <span *ngIf="item.value" class="checkbox-text" [innerHTML]="item.text | filterLocation: filterText">
                    </span>
                    <span *ngIf="!item.value" class="checkbox-text text-uppercase">{{('filter.' + item.text) |
                      translate}}</span>
                    <span class="checkmark" ngbTooltip="{{'alert.tooltip-all-select' | translate}}"
                          placement="auto" container="body" [disableTooltip]="!item.isRoot"></span>
                  </label>
                </div>
                <div *ngIf="getTargetLabel(item)" class="target d-flex"
                     (click)="onTargetItem(i); item.checked = true; onCheckedChange();test(item);">
                  <img src="assets/images/target-location.svg" class="align-self-center" alt="target-location">
                  <div class="align-self-center">{{getTargetLabel(item) | translate}}</div>
                </div>
              </div>
            </ng-template>
            <div class="territory-tree" #treeview>
              <ngx-treeview [itemTemplate]="itemTemplate" [headerTemplate]="headerTemplate" [config]="config"
                            [items]="territory['treeviews']" (selectedChange)="onSelectedChange($event)"
                            (filterChange)="onFilterChange($event)" [textChange]="filterText">
              </ngx-treeview>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
