import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './components/login/login.component';
import { ArticlesPageComponent } from './components/articles-page/articles-page.component';
import { ArticleViewComponent } from './components/documents-view/article-view/article-view.component';
import { ContentComponent } from './components/content/content.component';
import { AdminDocsPageComponent } from './components/admin-docs-page/admin-docs-page.component';
import { AdminDocViewComponent } from './components/documents-view/admin-doc-view/admin-doc-view.component';
import { TerritorySearchPageComponent } from './components/territory-search-page/territory-search-page.component';
import { ImpactersPageComponent } from './components/impacters-page/impacters-page.component';
import { ImpacterViewComponent } from './components/impacter-view/impacter-view.component';
import { AlertsPageComponent } from './components/alerts-page/alerts-page.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { TopicsPageComponent } from './components/topics-page/topics-page.component';
import { TopicFormComponent } from './components/topic-form/topic-form.component';
import { ForgotPasswordComponent } from './components/reset-password/forgot-password.component';
import { ForgotPasswordExpiredComponent } from './components/reset-password/forgot-password-expired/forgot-password-expired.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password/reset-password.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AdminTopicComponent } from './components/admin-topic/admin-topic.component';
import { AdminDocGuestViewComponent } from './components/documents-view/admin-doc-guest-view/admin-doc-guest-view.component';
import { ArticleGuestViewComponent } from './components/documents-view/article-guest-view/article-guest-view.component';
import { GuestContentComponent } from './components/guest-content/guest-content.component';
import { IsLoggedInRedirectResolver } from './shared/resolvers/is-logged-in-redirect.resolver';
import { FoldersPageComponent } from './components/folders-page/folders-page.component';
import { FolderViewComponent } from './components/folder-view/folder-view.component';
import { FolderContentComponent } from './components/folder-content/folder-content.component';
import { FolderViewContainerComponent } from './components/folder-view-container/folder-view-container.component';
import { SingleSignOnPageComponent } from './components/single-sign-on-page/single-sign-on-page.component';
import { SuggestionMode } from './models/zendesk/suggestion-mode';
// tslint:disable-next-line:max-line-length
import { ForgotPasswordIncorrectComponent } from './components/reset-password/forgot-password-incorrect/forgot-password-incorrect.component';

export const routes: Routes = [
  //region _____GUEST_____
  {
    path: 'guest',
    component: GuestContentComponent,
    data: {
      guestDisplay: true,
    },
    children: [
      {
        path: 'article/:shareToken/:articleId',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ArticleGuestViewComponent,
            data: {
              displayNavBar: false
            }
          },
          {
            path: 'share/:openSourceId',
            pathMatch: 'full',
            component: ArticleGuestViewComponent,
            data: {
              displayNavBar: false
            }
          }
        ],
      },
      {
        path: 'admin-doc/:shareToken/:adminDocId',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: AdminDocGuestViewComponent,
            data: {
              displayNavBar: false
            }
          },
          {
            path: 'share/:openSourceId',
            pathMatch: 'full',
            component: AdminDocGuestViewComponent,
            data: {
              displayNavBar: false
            }
          }
        ]
      }
    ]
  },
  //endregion
  //region _____SEARCH_____
  {
    path: '',
    canActivate: [AuthGuard],
    data: {
      guestDisplay: false,
      breadcrumb: 'breadcrumb.search',
      widgetSearch: {
        mode: SuggestionMode.LABELS,
        value: [
          'BC-sources-guide',
          'FAQ-recherche-départements',
          'FAQ-recherche-articles'
        ]
      }
    },
    children: [
      {
        path: '',
        redirectTo: 'search',
        pathMatch: 'full',
        data: {
          widgetSearch: {
            mode: SuggestionMode.LABELS,
            value: [
              'BC-sources-guide',
              'FAQ-recherche-départements',
              'FAQ-recherche-articles'
            ]
          }
        }
      },
      {
        path: 'search',
        component: TerritorySearchPageComponent,
        data: {
          widgetSearch: {
            mode: SuggestionMode.LABELS,
            value: [
              'BC-sources-guide',
              'FAQ-recherche-départements',
              'FAQ-recherche-articles'
            ]
          }
        }
      },
      {
        path: 'search/:territoryUid',
        component: ContentComponent,
        children: [
          {
            path: '',
            redirectTo: 'article',
            pathMatch: 'full',
            data: {
              widgetSearch: {
                mode: SuggestionMode.LABELS,
                value: [
                  'BC-recherche-contenus',
                  'FAQ-sources-presse',
                  'FAQ-recherche-articles'
                ]
              }
            }
          },
          {
            path: 'admin-doc',
            component: AdminDocsPageComponent,
            data: {
              breadcrumb: 'breadcrumb.search_result',
              widgetSearch: {
                mode: SuggestionMode.LABELS,
                value: [
                  'BC-recherche-contenus',
                  'FAQ-sources-admins',
                  'FAQ-recherche-admins',
                  'FAQ-SP-3',
                  'FAQ-SP-4'
                ]
              }
            },
            children: [
              {
                path: ':adminDocId',
                component: AdminDocViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.admin-doc',
                  openSource: 'web_app'
                }
              },
              {
                path: ':adminDocId/email/:openSourceId/collective-order/:collectiveOrderId',
                component: AdminDocViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.admin-doc',
                  openSource: 'digest'
                }
              },
              {
                path: ':adminDocId/email/:openSourceId',
                component: AdminDocViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.admin-doc',
                  openSource: 'digest'
                }
              },
              {
                path: ':adminDocId/share/:openSourceId',
                component: AdminDocViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.admin-doc',
                  openSource: 'share'
                }
              },
              {
                path: ':adminDocId/export',
                component: AdminDocViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.admin-doc',
                  openSource: 'export'
                }
              },
              {
                path: ':adminDocId/export/:exportId',
                component: AdminDocViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.admin-doc',
                  openSource: 'export'
                }
              }
            ]
          },
          {
            path: 'article',
            component: ArticlesPageComponent,
            data: {
              breadcrumb: 'breadcrumb.search_result',
              widgetSearch: {
                mode: SuggestionMode.LABELS,
                value: [
                  'BC-recherche-contenus',
                  'FAQ-sources-presse',
                  'FAQ-recherche-articles',
                  'FAQ-SP-3',
                  'FAQ-SP-4'
                ]
              }
            },
            children: [
              {
                path: ':articleId',
                component: ArticleViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.article',
                  openSource: 'web_app'
                }
              },
              {
                path: ':articleId/email/:openSourceId/collective-order/:collectiveOrderId',
                component: ArticleViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.article',
                  openSource: 'digest'
                }
              },
              {
                path: ':articleId/email/:openSourceId',
                component: ArticleViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.article',
                  openSource: 'digest'
                }
              },
              {
                path: ':articleId/share/:openSourceId',
                component: ArticleViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.article',
                  openSource: 'share'
                }
              }
            ]
          },
          {
            path: 'impacter',
            component: ImpactersPageComponent,
            data: {
              breadcrumb: 'breadcrumb.search_result',
              widgetSearch: {
                mode: SuggestionMode.LABELS,
                value: [
                  'BC-impacters-guide',
                  'FAQ-impacters-1',
                  'FAQ-impacters-2'
                ]
              }
            },
            children: [
              {
                path: ':impacterId',
                component: ImpacterViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.impacter'
                },
                children: [
                  {
                    path: ':articleId',
                    component: ArticleViewComponent,
                    data: {
                      breadcrumb: 'breadcrumb.article',
                      openSource: 'web_app'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  //endregion
  //region _____FOLDER_____
  {
    path: 'folder',
    canActivate: [AuthGuard],
    component: FolderContentComponent,
    data: {
      breadcrumb: 'breadcrumb.folder',
      widgetSearch: {
        mode: SuggestionMode.LABELS,
        value: [
          'BC-SP-guide',
          'FAQ-SP-1',
          'FAQ-SP-2'
        ]
      }
    },
    children: [
      {
        path: '',
        component: FoldersPageComponent
      },
      {
        path: ':folderId',
        component: FolderViewContainerComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'article'
          },
          {
            path: 'article',
            component: FolderViewComponent,
            data: {
              breadcrumb: 'breadcrumb.folder-name',
              folderView: true,
              widgetSearch: {
                mode: SuggestionMode.LABELS,
                value: [
                  'BC-SP-sauvegarde',
                  'FAQ-SP-1',
                  'FAQ-recherche-admins'
                ]
              }
            },
            children: [
              {
                path: ':articleId',
                component: ArticleViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.article',
                  openSource: 'web_app',
                  showContentFolder: true,
                  folderView: false
                }
              }
            ]
          },
          {
            path: 'admin-doc',
            component: FolderViewComponent,
            data: {
              breadcrumb: 'breadcrumb.folder-name',
              folderView: true,
              widgetSearch: {
                mode: SuggestionMode.LABELS,
                value: [
                  'BC-SP-sauvegarde',
                  'FAQ-SP-1',
                  'FAQ-recherche-admins'
                ]
              }
            },
            children: [
              {
                path: ':adminDocId',
                component: AdminDocViewComponent,
                data: {
                  breadcrumb: 'breadcrumb.admin-doc',
                  openSource: 'web_app',
                  showContentFolder: true,
                  folderView: false
                }
              }
            ]
          }
        ]
      }
    ]
  },
  //endregion
  //region _____ALERT_____
  {
    path: 'alert',
    canActivate: [AuthGuard],
    component: AlertsPageComponent,
    data: {
      breadcrumb: 'breadcrumb.alert',
      widgetSearch: {
        mode: SuggestionMode.LABELS,
        value: [
          'BC-alertes-guide',
          'FAQ-alertes-1',
          'FAQ-alertes-2'
        ]
      }
    }
  },
  {
    path: 'alert-form',
    canActivate: [AuthGuard],
    component: WizardComponent,
    data: {
      displayNavBar: false,
      widgetSearch: {
        mode: SuggestionMode.LABELS,
        value: [
          'BC-alertes-guide',
          'FAQ-alertes-1',
          'FAQ-alertes-2'
        ]
      }
    }
  },
  {
    path: 'alert-form/:alertId',
    canActivate: [AuthGuard],
    component: WizardComponent,
    data: {
      displayNavBar: false,
      widgetSearch: {
        mode: SuggestionMode.LABELS,
        value: [
          'BC-alertes-guide',
          'FAQ-alertes-1',
          'FAQ-alertes-2'
        ]
      }
    }
  },
  //endregion
  //region _____TOPICS_____
  {
    path: 'topics',
    canActivate: [AuthGuard],
    component: TopicsPageComponent,
    data: {
      breadcrumb: 'breadcrumb.topic',
      widgetSearch: {
        mode: SuggestionMode.LABELS,
        value: [
          'BC-thèmes-guide',
          'BC-thèmes-rôle',
          'BC-thèmes-défaut'
        ]
      }
    }
  },
  {
    path: 'topic-form',
    canActivate: [AuthGuard],
    component: TopicFormComponent,
    data: {
      displayNavBar: false,
      widgetSearch: {
        mode: SuggestionMode.LABELS,
        value: [
          'BC-thèmes-guide',
          'BC-thèmes-rôle',
          'BC-thèmes-défaut'
        ]
      }
    }
  },
  {
    path: 'topic-form/:topicId',
    canActivate: [AuthGuard],
    component: TopicFormComponent,
    data: {
      displayNavBar: false,
      widgetSearch: {
        mode: SuggestionMode.LABELS,
        value: [
          'BC-thèmes-guide',
          'BC-thèmes-rôle',
          'BC-thèmes-défaut'
        ]
      }
    }
  },
  //endregion
  //region _____SETTINGS_____
  {
    path: 'settings',
    canActivate: [AuthGuard],
    component: SettingsComponent,
    data: {
      breadcrumb: 'breadcrumb.settings',
      widgetSearch: {
        mode: SuggestionMode.SEARCH,
        value: 'compte'
      }
    }
  },
  //endregion
  //region _____LOGIN_____
  {
    path: 'login',
    component: LoginComponent,
    resolve: [IsLoggedInRedirectResolver],
    data: {
      displayNavBar: false
    }
  },
  {
    path: 'login/forgot-password',
    component: ForgotPasswordComponent,
    data: {
      displayNavBar: false
    }
  },
  {
    path: 'login/reset-password',
    component: ResetPasswordComponent,
    data: {
      displayNavBar: false
    }
  },
  {
    path: 'login/reset-password-expired',
    component: ForgotPasswordExpiredComponent,
    data: {
      displayNavBar: false
    }
  },
  {
    path: 'login/reset-password-incorrect',
    component: ForgotPasswordIncorrectComponent,
    data: {
      displayNavBar: false
    }
  },
  //endregion
  //region _____SSO_ZENDESK_____
  {
    path: 'sso',
    canActivate: [AuthGuard],
    component: SingleSignOnPageComponent,
    data: {
      displayNavBar: false
    }
  },
  //endregion
  //region _____ADMIN_CONNEXION_____
  {
    /*
    c'est une page qui permet de créer ou modifier un topic (theme) à partir
    de l'admin, sans avoir authentification front authentifier
    Method: POST pour écrire / PUT pour modifier un topic
    Token: pour verifier qu'il s'agit bien de l'admin (car l'acces pour cette page
    est anonyme)
    TopicId: id de topic, 0 en cas d'ecriture
    */
    path: 'admin-topic/:method/:token/:topicId',
    component: AdminTopicComponent,
    data: {
      displayNavBar: false
    }
  },
  //endregion
  //region _____DEFAULT_ROUTE_____
  {
    path: '**', // every other value.
    redirectTo: '',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
