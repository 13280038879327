interface UserLevel {
  id?: number;
  level: string;
  max_credit: number;
  remaining_credits: number;
}

interface GroupAccountUser {
  id: number;
  is_active: boolean;
  name: string;
}

export interface DbUserPost {
  kind: 'DbUserPost';
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  user_level: UserLevel;
  group_account_id: number;
  newDest?: boolean;
  created_at?: string;
  updated_at?: string;
  is_active: boolean;
}

// Omit allow to extend interface DbUserPost and replacing its kind attribute.
export interface NewUserFromSharingPanel extends Omit<DbUserPost, 'kind'> {
  kind: 'NewUserFromSharingPanel';
}

export interface DbUserGet {
  kind: 'DbUserGet';
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  user_level: UserLevel;
  group_account: GroupAccountUser;
  newDest?: boolean;
  created_at?: string;
  updated_at?: string;
  is_active: boolean;
  user_territories: any;
  user_topics: any;
}

// Property 'kind' act as a discriminator for constructor method of the class User.
export type DbUser = DbUserGet | DbUserPost | NewUserFromSharingPanel;

export class User {
  id?: number;
  firstName: string;
  lastName: string;
  fullName?: string;
  email: string;
  userLevel: UserLevel;
  groupAccountId: number;
  company?: string;
  newDest?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  isActive: boolean;

  constructor(user: DbUser) {
    this.id = user.id;
    this.firstName = user.first_name;
    this.lastName = user.last_name;
    this.email = user.email;
    this.userLevel = user.user_level;
    if (user.kind === 'DbUserGet') {
      this.groupAccountId = user.group_account.id;
      this.company = user.group_account.name;
    } else {
      this.groupAccountId = user.group_account_id;
    }
    if (user.created_at) {
      this.createdAt = new Date(user.created_at);
      this.updatedAt = new Date(user.updated_at);
    }
    this.newDest = user.newDest;
    this.isActive = user.is_active;
    if (this.firstName) {
      this.fullName = `${this.firstName} ${this.lastName}`.trim();
    }
  }
}
