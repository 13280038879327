import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DbLoggedInUser, LoggedInUser } from 'src/app/core/user/user.model';
import { HeadersService } from './api/headers/headers.service';

const MEPConfiguration = require('src/assets/mep-configuration/version.json');

@Injectable({ providedIn: 'root'})
export class AuthenticationService {

  private currentUser: LoggedInUser;

  constructor(
    private http: HttpClient,
    private headersService: HeadersService,
  ) {
    this.currentUser = null;
  }

  get currentUserValue(): LoggedInUser {
    return this.currentUser;
  }

  get goodwillToken(): string {
    return localStorage.getItem('api_token');
  }

  newLogin(email: string, password: string) {
    const sanitizedEmail = email.trim().toLowerCase();

    return this.http.post(`${environment.backV2EndUrl}account/session`, {email: sanitizedEmail, password})
      .pipe(map((user: DbLoggedInUser) => {
        if (user && user.api_token) {
          console.log(`logged in user : ${user.email}`);
          localStorage.removeItem('api_token');
          localStorage.setItem('api_token', user.api_token);
          localStorage.setItem('email', sanitizedEmail);
          localStorage.setItem('user_id', String(user.id));
          localStorage.setItem('group_account_id', String(user.group_account_id));
          // UPDATE this.currentUser
          this.currentUser = new LoggedInUser(user);
        }
        return user;
      }));
  }

  checkCurrentPassword(password: string) {
    const email = localStorage.getItem('email');

    return this.http.post(`${environment.backV2EndUrl}account/session`, {email, password})
      .pipe(map((user: DbLoggedInUser) => {
        if (user && user.api_token) {
          localStorage.removeItem('api_token');
          localStorage.setItem('api_token', user.api_token);
          // UPDATE this.currentUser
          this.currentUser = new LoggedInUser(user);
        }
        return user;
      }));
  }

  logout() {
    // Storing new-feature-seen status - part 1/2
    // Only the new feature item will be stored. Old ones are cleared.
    const newFeatureAdminDocExportSeen = localStorage.getItem(MEPConfiguration.new_feature_localstorage_item_name);
    // Clearing all items on localStorage
    localStorage.clear();
    // Setting back new-feature-seen status - part 2/2
    if (newFeatureAdminDocExportSeen) {
      localStorage.setItem(MEPConfiguration.new_feature_localstorage_item_name, newFeatureAdminDocExportSeen);
    }
    this.currentUser = null;
  }

  /**
   * POST method to request a new password.
   * */
  forgotPassword(email: string) {
    const sanitizedEmail = email.trim().toLowerCase();
    return this.http.post(`${environment.backV2EndUrl}account/password/request_reset`, { email: sanitizedEmail});
  }

  /**
   * Méthode POST pour le reset du mot de passe.
   * Fonctionne à la fois pour tester la validité du reset_password_token à l'ouverture de la page.
   * Et également pour la modification effective du mot de passe.
   */
  resetPassword(newPassword: string, resetPasswordToken: string) {
    return this.http.post(`${environment.backV2EndUrl}account/password/reset`, {
      new_password: newPassword,
      reset_password_token: resetPasswordToken
    });

  }

  /**
   * POST method to update the password of a logged in user.
   * @param new_password - new password
   * @param password - previous password
   */
  updatePassword(new_password: string, password: string) {
    const body = {
      'new_password': new_password,
      'password': password
    };

    return this.http.post(`${environment.backV2EndUrl}account/password/update`, body, this.headersService.httpHeaders);

  }

  /**
   * POST method to check validity of the reset_password_token
   * @param token - reset_password_token
   */
  checkResetPasswordToken(token: string) {

    return this.http.post(`${environment.backV2EndUrl}account/password/reset`, { 'reset_password_token': token});

  }


}
