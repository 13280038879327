import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RegexpSearch } from '../../shared/helpers/regex';
import { ApiService } from '../../shared/services/api/api.service';
import { DisplayedAlert } from '../../models/alert';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-alerts-page',
  templateUrl: './alerts-page.component.html',
  styleUrls: ['./alerts-page.component.scss']
})
export class AlertsPageComponent implements OnInit {

  alerts: Array<DisplayedAlert>;
  filteredAlerts: Array<DisplayedAlert>;
  filterAlertNameFormControl = new FormControl() ;
  showClearIcon = false;

  constructor(
    private apiService: ApiService,
  ) {
  }

  ngOnInit(): void {

    const userId = localStorage.getItem('user_id');
    const fields: Array <string> = ['id', 'name', 'users', 'is_flagged', 'created_at', 'updated_at'];
    const filters: object = { 'user_ids': [parseInt(userId, 10)], 'digest_type': 'topics_digest' };

    this.apiService.alert.getAlerts(fields, filters)
      .pipe(first())
      .subscribe((retrievedAlerts) => {
        this.alerts = this.apiService.alert.addCurrentUserToAlerts(retrievedAlerts);
        this.filteredAlerts = this.alerts;
    });

    this.filterAlertNameFormControl.valueChanges
      .subscribe(searchText => {
        if ((searchText != null) && (searchText.length > 0)) {
          this.filterAlerts(searchText);
          this.showClearIcon = true;
        } else {
          this.resetFilteredAlerts();
          this.showClearIcon = false;
        }
    });
  }

  clearText() {
    this.filterAlertNameFormControl.setValue('') ;
  }

  filterAlerts(searchText: string) {
    this.filteredAlerts = this.alerts.filter(item => RegexpSearch.Search(item.name, searchText));
  }

  resetFilteredAlerts() {
    this.filteredAlerts = this.alerts;
  }

  onSelectChange(value): void {
    const { itemId, isActive } = value;
    this.apiService.userDigests.switchAlertActiveState(itemId, isActive).subscribe(
      () => {},
      error => {
        console.log(error);
      }
    );
  }

  deleteAlert(value): void {
    this.apiService.alert.deleteAlert(value)
      .subscribe(
      () => {},
      error => {
        console.log(error);
      }
    );
  }
}
