import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

interface StatsModel {
  finished: boolean;
  name: string;
  id?: string;
  duplication?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {
  finishedSubject = new ReplaySubject<StatsModel>(0);

  constructor() { }


  setFinished(finished: boolean, name?: string, id?: string, duplication?: boolean): void {
    this.finishedSubject.next({ finished, name, id, duplication });
  }

  getSettingFinished(): Observable<StatsModel> {
    return this.finishedSubject;
  }
}
