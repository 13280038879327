export const SearchHelper = {
  searchRegExp,
  regexpPattern
};

function regexpPattern(search) {
  // It replaces listed special characters with a backslash.
  let pattern = search.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  // Split pattern at each whitespace and join them with the pattern '[\\s+]*[ --;%.*+?^${}()]?'.
  // Pattern is 0 to infinite whitespace and 0 or 1 of any characters in this list : ' !"#$%&'()*+,-;%.*+?^${}()'
  // this parts ' --' represent the following characters: ' !"#$%&'()*+,-'
  // the rest is a plain list of characters i literal match ';%.*+?^${}()';
  // Seems to me that there are doubles in the regex, but in the case I am wrong, I won't change it for now.
  pattern = pattern.split(' ').filter((t) => {
    return t.length > 0;
  }).join('[\\s+]*[ --;%.*+?^${}()]?');
  // Find if there is a literal match of a hyphen, if so, match either the hyphen either a white space.
  pattern = pattern.replace(/\\-/g, '\[ \-\]');
  pattern = addAccents(pattern);
  return new RegExp(pattern, 'gi');
}

function searchRegExp(text: string, search) {
  const regex = regexpPattern(search);
  return text.match(regex);
}

function addAccents(input) {
  let pattern = input;
  const replacements = {
    'e': '([eèéêë])',
    'c': '([cç])',
    'i': '([iîï])',
    'u': '([uùûü])',
    'y': '([yÿ])',
    'ss': '(ss|ß)',
    'a': '([aàâä])',
    'o': '([oôö])',
    'ae': '(ae|æ)',
    'oe': '(oe|œ)',
    '\'': '([\'’])'
  };
  Object.keys(replacements).forEach(key => pattern = pattern.replace(new RegExp(key, 'gi'), replacements[key]));

  return pattern;
}
