import { EventEmitter, Injectable } from '@angular/core';
import { TerritoryManager } from '../../models/territory/territory-manager';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertSettingService {
  name = null;
  frequent: Array<string> = [];
  regionalDailyPress = false;
  publicationTerritoriesKind = new Array<string>();
  topicIds = new Array<string>();
  competitorMonitoring = false;
  territories = new Array<object>();
  syndicates = new Array<string>();
  territoryManagers = new BehaviorSubject<{[uid: string]: TerritoryManager}>({});
  onPerimeterChange = new EventEmitter();
  constructor() {
  }

  reset(): void {
    this.name = null;
    this.frequent = [];
    this.regionalDailyPress = false;
    this.publicationTerritoriesKind = [];
    this.topicIds = [];
    this.competitorMonitoring = false;
    this.territories = [];
    this.syndicates = [];
    this.territoryManagers = new BehaviorSubject<{[uid: string]: TerritoryManager}>({});
  }

  setTopics(topicIds: string[]): void {
    this.topicIds = topicIds;
  }

  setCompetitorMonitoring(competitorMonitoring: boolean): void {
    this.competitorMonitoring = competitorMonitoring;
  }

  setPublicationTerritoriesKind(publicationTerritoriesKind: Array<string>): void {
    this.publicationTerritoriesKind = publicationTerritoriesKind;
  }

  setRegionalDailyPress(regionalDailyPress: boolean): void {
    this.regionalDailyPress = regionalDailyPress;
  }

  setFrequent(frequent: Array<string>): void {
    this.frequent = frequent;
  }

  setName(name: string): void {
    this.name = name;
  }

  getName(): string {
    return this.name;
  }

  setTerritories(territories: Array<object>): void {
    this.territories = territories;
    this.onPerimeterChange.emit(territories);
  }

  setSyndicates(syndicates: Array<string>): void {
    this.syndicates = syndicates;
  }

  addTerritoryManager(key: string, manager: TerritoryManager) {
    this.territoryManagers.next({...this.territoryManagers.value, [key]: manager});
  }

  removeTerritoryManager(key: string) {
    const territoryManagers = {...this.territoryManagers.value};
    delete territoryManagers[key];
    this.territoryManagers.next(territoryManagers);
  }

  getSetting(): object {
    return {
      name: this.name,
      day_of_week: this.frequent.join(),
      regional_daily_press: this.regionalDailyPress,
      publication_territories_kind: this.publicationTerritoriesKind.join(),
      topics_ids: this.topicIds,
      territories: this.territories,
      syndicates: this.syndicates
    };
  }

  getOverviewParams(): AlertOverviewParams {
    return new AlertOverviewParams(
      this.frequent.join(),
      this.regionalDailyPress,
      this.publicationTerritoriesKind,
      this.topicIds,
      this.territories,
      this.syndicates,
      25,
      0,
      0
    );
  }
}

export interface DbAlertOverviewParams {
  day_of_week: string;
  regional_daily_press: boolean;
  publication_territories_kind: string[];
  topics_ids: string[];
  territories: object[];
  limit: number;
  alert_offset?: number;
  offset?: number;
}

export class AlertOverviewParams {
  day_of_week: string;
  regional_daily_press: boolean;
  publication_territories_kind: string[];
  topics_ids: string[];
  territories: object[];
  syndicates: string[];
  limit: number;
  alert_offset?: number;
  offset?: number;

  constructor(day_of_week?: string,
              regional_daily_press?: boolean,
              publication_territories_kind?: string[],
              topics_ids?: string[],
              territories?: object[],
              syndicates?: string[],
              limit?: number,
              alert_offset?: number,
              offset?: number) {
    this.day_of_week = day_of_week;
    this.regional_daily_press = regional_daily_press;
    this.publication_territories_kind = publication_territories_kind;
    this.topics_ids = topics_ids;
    this.territories = territories;
    this.syndicates = syndicates;
    this.limit = limit;
    this.alert_offset = alert_offset;
    this.offset = offset;
  }
}
