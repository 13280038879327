import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCreationComponent } from '../modal-creation/modal-creation.component';
import { ModalDeleteFolderComponent } from '../modal-delete-folder/modal-delete-folder.component';

/**
 * Generic component to display items, the way they are displayed on foldersPage and TopicsPage.
 * translationFileSection - correspond to the section inside translation file. Exemple "folder".
 * To be used, use folder as an example for the naming of attributes inside translation file (-> fr.json).
 */
@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit {

  @Input() items: Array<any> = []; // list of any. accepts Classes
  @Input() type?: any;
  @Input() translationFileSection: string;
  @Input() columns: any;
  @Input() itemListLength = 0;
  @Input() hasFilter = false;
  @Input() updateOption = true;
  @Input() iconName = '';

  @Output() refreshListItem = new EventEmitter<void>();
  @Output() selectItem = new EventEmitter<any>();
  @Output() deleteItem = new EventEmitter<number>();
  @Output() updateItem = new EventEmitter<string>();

  forceUpdate = 0;
  indexItemSelected: number;
  itemIdSelected: number;
  itemIdHovered: number;

  currentOrder = {
    orderBy: 'name',
    reverse: false
  };

  constructor(private modalService: NgbModal) {
  }

  ngOnInit() {
  }

  changeOrder(value) {
    if (this.currentOrder.orderBy === value) {
      this.currentOrder.reverse = !this.currentOrder.reverse;
    } else {
      this.currentOrder = {
        orderBy: value,
        reverse: false
      };
      this.forceUpdate++;
    }
  }

  onHoverItem(itemId: number) {
    this.itemIdHovered = itemId;
  }

  openDeleteModal(item, itemIndex) {
    this.itemIdSelected = item.id;
    this.indexItemSelected = itemIndex;
    const modalRef = this.modalService.open(ModalDeleteFolderComponent,
      {ariaLabelledBy: 'modal-delete-folder', centered: true, windowClass: 'modal-width-560'});
    modalRef.componentInstance.translationFileSection = 'folder';
    modalRef.componentInstance.folder = item;
    modalRef.result.then((deleted: boolean) => {
      if (deleted) {
        this.itemListLength--;
        this.items.splice(this.indexItemSelected, 1);
        this.modalService.dismissAll();
      }
    });
  }

  openCreationModal() {
    const modalRef = this.modalService.open(ModalCreationComponent,
      { ariaLabelledBy: 'modal-create-folder', centered: true, windowClass: 'modal-width-419'});
    modalRef.componentInstance.translationFileSection = 'folder';
    modalRef.result.then(_ => {
      this.refreshListItem.emit();
    }, _ => {
      // error handling when dismissing the modal, nothing to do but keep it to prevent console error.
    });
  }

  onClickItem(item: any) {
    this.selectItem.emit(item);
  }

}
