import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalExplanationShareComponent } from '../../modal-explanation-share/modal-explanation-share.component';
import { User } from '../../../models/user';
import { MatExpansionPanel } from '@angular/material/expansion';
import { email } from '@sideway/address';
import { ApiService } from '../../../shared/services/api/api.service';
import { SharedDocument } from '../../../models/share/share';


@Component({
  selector: 'app-sharing-panel',
  templateUrl: './sharing-panel.component.html',
  styleUrls: ['./sharing-panel.component.scss'],
})
export class SharingPanelComponent implements OnInit {
  addresseeDropdownMenuDisplayed = false;
  shareMessage = '';
  personalInfos = {
    groupAccountId: parseInt(localStorage.getItem('group_account_id'), 10),
    domainName: localStorage.getItem('email').split('@')[1],
    id: parseInt(localStorage.getItem('user_id'), 10)
  };
  options: User[] = [];
  filteredOptions$: Observable<User[]> = new Observable<User[]>();
  filteredOptions: User[] = [];
  selectedUser: User = null;
  indexSelected = 0;
  errors = {
    email: false
  };

  @Input() document: SharedDocument;
  @ViewChild('sharePanel') sharePanel: MatExpansionPanel;
  @ViewChild('autoInput') input: ElementRef;
  @ViewChild('users') userTable: ElementRef;
  @ViewChild('tooltipCreditLeft') tooltipCreditLeft: TemplateRef<any>;
  @ViewChild('tooltipNoCreditLeft') tooltipNoCreditLeft: TemplateRef<any>;
  @ViewChild('tooltipCreditUnavailable') tooltipCreditUnavailable: TemplateRef<any>;
  @Output() isPanelOpen: EventEmitter<boolean>;
  @Input() withExportPanel: boolean;

  constructor(
    private modalService: NgbModal,
    private apiService: ApiService,
  ) {
    this.isPanelOpen = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
    this.getUsers();
  }

  onSubmit() {
    this.apiService.share.insertShare(this.document, this.shareMessage, this.selectedUser.email)
      .subscribe((res) => {
        if (res.erroredEmail.length > 0) {
          this.openModal(true);
        } else {
          if (this.options.findIndex(option => option.email === this.selectedUser.email) === -1) {
            delete this.selectedUser.newDest;
            this.options.unshift(this.selectedUser);
            this.sortOptions();
          }
          if (this.isGuestUser(this.selectedUser) && this.isInSameCompany(this.selectedUser)) {
            this.options = this.options.map(option => {
              if (option.email === this.selectedUser.email) {
                this.apiService.user.searchUsers({email: option.email})
                  .subscribe(user => {
                    let creditUpdate = true;
                    if (option.userLevel.remaining_credits === user[0].userLevel.remaining_credits) {
                      creditUpdate = false;
                    } else {
                      option.userLevel.remaining_credits = user[0].userLevel.remaining_credits;
                    }
                    this.openModal(false, creditUpdate);
                  });
              }
              return option;
            });
          } else {
            this.openModal(false);
          }
        }
      }, error => {
        console.log(error);
        this.openModal(true);
      });
  }

  openModal(error = false, creditUpdate = true) {
    const options = {
      centered: true,
      windowClass: 'modal-width-419'
    };
    if (error === false) {
      // communicate the panel is closing.
      this.sharePanel.close();
      this.isPanelOpen.emit(false);
    }
    const modalRef = this.modalService.open(ModalExplanationShareComponent, options);
    modalRef.componentInstance.error = error;
    modalRef.componentInstance.user = this.selectedUser;
    modalRef.componentInstance.personalGroupAccountId = this.personalInfos.groupAccountId;
    modalRef.componentInstance.personalDomainName = this.personalInfos.domainName;
    modalRef.componentInstance.creditUpdate = creditUpdate;
    if (error === false) {
      // reset fields.
      this.selectedUser = null;
      this.shareMessage = '';
    }
  }

  filterOptions() {
    // remove self user and duplicates from the array based on id
    this.options = this.options.filter((value, index, self) => {
      return index === self.findIndex((t) => (
        t.id === value.id && t.id !== this.personalInfos.id
      ));
    });
  }

  sortOptions() {
    this.options = this.options.sort((a, b) => {
      if (this.isInSameCompany(a) && a.firstName && this.isInSameCompany(b) && b.firstName) {
        return a.firstName.localeCompare(b.firstName) > 0 ? 1 : -1;
      } else if (this.isInSameCompany(a) && a.firstName) {
        return a.firstName.localeCompare(b.email) > 0 ? 1 : -1;
      } else if (this.isInSameCompany(b) && b.firstName) {
        return a.email.localeCompare(b.firstName) > 0 ? 1 : -1;
      } else {
        return a.email.localeCompare(b.email) > 0 ? 1 : -1;
      }
    });
  }

  // handling logic of addressee's list
  getUsers() {
    const filters = {
      'group_account': {
        'id': this.personalInfos.groupAccountId,
      },
      'user_level': {
        'level': 'simple_user'
      },
      'is_active': true
    };
    const observables = [
      this.apiService.user.searchUsers(filters),
      this.apiService.share.searchSharedWithUsers()
    ];
    this.options = [];
    forkJoin(observables).subscribe(responseList => {
      for (const response of responseList) {
        this.options = this.options.concat(response);
      }
      this.filterOptions();
      this.sortOptions();
      this.filteredOptions$ = of(this.options);
      this.filteredOptions = this.options;
    });
  }

  searchUser(mailAddress) {
    const sanitizedEmail = mailAddress.trim().toLowerCase();
    if (this.addresseeDropdownMenuDisplayed === false) {
      this.toggleDropdown(true);
      return;
    }
    const defaultUser = this.getDefaultSelect();
    if (defaultUser) {
      this.onSelectAddressee(defaultUser);
    } else if (this.checkEmailFormat(sanitizedEmail) === false) {
      this.errors.email = true;
      this.addresseeDropdownMenuDisplayed = false;
    } else {
      this.apiService.user.searchUsers({email: sanitizedEmail}).subscribe((user: User[]) => {
        if (user?.length > 0 && user[0].email === sanitizedEmail) {
          this.selectedUser = user[0];
          this.selectedUser.newDest = true;
        } else {
          this.selectedUser = new User({
            kind: 'NewUserFromSharingPanel',
            email: sanitizedEmail,
            first_name: null,
            last_name: null,
            user_level: {
              level: 'guest_user',
              max_credit: 20,
              remaining_credits: 20
            },
            group_account_id: this.personalInfos.groupAccountId,
            is_active: true
          });
        }
        this.onSelectAddressee(this.selectedUser);
      });
    }
  }

  getDefaultSelect() {
    let defaultSelect = null;
    if (this.filteredOptions.length > this.indexSelected) {
      defaultSelect = this.filteredOptions[this.indexSelected];
    }
    return defaultSelect;
  }

  private filter(value: string): User[] {
    const filterValue = value;
    return this.options.filter(optionValue => {
      return optionValue.firstName?.toLowerCase().includes(filterValue.toLowerCase())
        || optionValue.email.toLowerCase().includes(filterValue.toLowerCase());
    });
  }

  getFilteredOptions(value: string): Observable<User[]> {
    return of(value).pipe(
      map((filterString: string) => this.filter(filterString)),
    );
  }

  onClickPanel(panelExpanded: boolean) {
    this.isPanelOpen.emit(panelExpanded);
  }

  onChange() {
    const inputValue = this.input.nativeElement.value;
    this.filteredOptions$ = this.getFilteredOptions(inputValue);
    this.filteredOptions$.subscribe(filteredOptions => this.filteredOptions = filteredOptions).unsubscribe();
    this.indexSelected = 0;
    const elementsAddresseeList = document.getElementsByClassName('option-item');
    if (elementsAddresseeList.length) {
      elementsAddresseeList[this.indexSelected].scrollIntoView({block: 'nearest', inline: 'nearest'});
    }
    if (this.selectedUser) {
      this.selectedUser = null;
    }
    if (inputValue) {
      this.toggleDropdown(true);
    }
    if (this.errors.email) {
      this.errors.email = inputValue ? !this.checkEmailFormat(inputValue) : false;
    }
  }

  onSelectAddressee(user: User) {
    if (this.isInSameCompany(user) && this.isGuestUser(user) && user.userLevel.remaining_credits < 1) {
      this.input.nativeElement.focus();
      return;
    }
    this.errors.email = false;
    this.addresseeDropdownMenuDisplayed = false;
    this.input.nativeElement.value = '';
    this.onChange();
    this.selectedUser = user;
  }

  move(direction) {
    if (!this.addresseeDropdownMenuDisplayed) {
      return;
    }
    const maxIndex = this.filteredOptions.length - 1;
    if (direction === 'down') {
      if (maxIndex > this.indexSelected) {
        this.indexSelected++;
      } else {
        this.indexSelected = 0;
      }
    } else {
      if (this.indexSelected > 0) {
        this.indexSelected--;
      } else {
        this.indexSelected = maxIndex;
      }
    }
    document.getElementsByClassName('option-item')[this.indexSelected].scrollIntoView({block: 'nearest', inline: 'nearest'});
  }

  // visual logic helpers.
  getCreditTooltip(user) {
    if (user.newDest) {
      return null;
    }
    if (this.isInSameCompany(user)) {
      if (this.isSimpleUser(user)) {
        return null;
      } else if (user.userLevel.remaining_credits > 0) {
        return this.tooltipCreditLeft;
      } else {
        return this.tooltipNoCreditLeft;
      }
    } else {
      return this.tooltipCreditUnavailable;
    }
  }

  onClosePanel() {
    this.sharePanel.close();
    this.isPanelOpen.emit(false);
  }

  panelStateUpdate(expanded: boolean) {
    if (expanded === false) {
      this.input.nativeElement.value = '';
      this.selectedUser = null;
      this.shareMessage = '';
      this.errors = {
        email: false
      };
      this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
      this.filteredOptions$.subscribe(filteredOptions => this.filteredOptions = filteredOptions).unsubscribe();
      this.indexSelected = 0;
    }
  }

  toggleDropdown(display: boolean = null, focus = true) {
    if (focus) {
      this.input.nativeElement.focus();
    }
    if (display !== null) {
      this.addresseeDropdownMenuDisplayed = display;
    } else {
      this.addresseeDropdownMenuDisplayed = !this.addresseeDropdownMenuDisplayed;
    }
  }

  checkEmailFormat(mailAddress) {
    return email.isValid(mailAddress);
  }

  isGuestUser(user) {
    if (user.userLevel.level === 'simple_user') {
      return !user.isActive;
    }
    return user.userLevel.level === 'guest_user';
  }

  isSimpleUser(user) {
    return user.userLevel.level === 'simple_user' && user.isActive;
  }

  isInSameCompany(user) {
    const userDomainName = user.email.split('@')[1];
    return user.groupAccountId === this.personalInfos.groupAccountId
      || userDomainName === this.personalInfos.domainName;
  }

}
