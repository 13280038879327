import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, Inject, InjectionToken, ErrorHandler } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxPopper } from 'angular-popper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import localeFr from '@angular/common/locales/fr';
import { TreeviewModule } from './lib/ngx-treeview';

import { WINDOW_PROVIDERS } from './shared/services/window.service';
import { registerLocaleData } from '@angular/common';
import { SafeHtmlPipe } from './shared/pipes/safeHtml.pipe';
import { SortPipe } from './shared/pipes/sort.pipe';
import { SortTextPipe } from './shared/pipes/sortText.pipe';
import { TruncatePipe } from './shared/pipes/truncate.pipe';
import { InterleavePipe } from './shared/pipes/interleave.pipe';
import { FilterPipe } from './shared/pipes/filter.pipe';
import { HighlightPipe } from './shared/pipes/highlight.pipe';
import { FilterLocationPipe } from './shared/pipes/filterLocation.pipe';
import { ArticlesPageComponent } from './components/articles-page/articles-page.component';
import { MenuComponent } from './components/menu/menu.component';
import { CustomInterceptor } from './shared/helpers/custom.interceptor';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';
import { LoginComponent } from './components/login/login.component';
import Rollbar from 'rollbar';
import { environment } from 'src/environments/environment';
import { HeaderComponent } from './components/header/header.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PageTabComponent } from './components/page-tab/page-tab.component';
import { FilterComponent } from './components/filter/filter.component';
import { SearchComponent } from './components/search/search.component';
import { StatsPageComponent } from './components/stats-page/stats-page.component';
import { TaskBarComponent } from './components/task-bar/task-bar.component';
import { AdminDocCardComponent } from './components/admin-doc-card/admin-doc-card.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { InsertTextPipe } from './shared/pipes/insert-text.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { ArticleCardComponent } from './components/article-card/article-card.component';
import { ContentComponent } from './components/content/content.component';
import { ArticleViewComponent } from './components/documents-view/article-view/article-view.component';
import { AdminDocsPageComponent } from './components/admin-docs-page/admin-docs-page.component';
import { AdminDocViewComponent } from './components/documents-view/admin-doc-view/admin-doc-view.component';
import { ControlFComponent } from './components/control-f/control-f.component';
import { ResultMessageComponent } from './components/result-message/result-message.component';
import { TerritorySearchPageComponent } from './components/territory-search-page/territory-search-page.component';
import { ImpactersPageComponent } from './components/impacters-page/impacters-page.component';
import { ImpacterCardComponent } from './components/impacter-card/impacter-card.component';
import { ImpacterViewComponent } from './components/impacter-view/impacter-view.component';
import { AlertsPageComponent } from './components/alerts-page/alerts-page.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { StepsComponent } from './components/steps/steps.component';
import { StepHeaderComponent } from './components/step-header/step-header.component';
import { TopicStepTemplateComponent } from './components/topic-step-template/topic-step-template.component';
import { SourceStepTemplateComponent } from './components/source-step-template/source-step-template.component';
import { FrequentStepTemplateComponent } from './components/frequent-step-template/frequent-step-template.component';
import { OverviewStepTemplateComponent } from './components/overview-step-template/overview-step-template.component';
import { PerimeterStepTemplateComponent } from './components/perimeter-step-template/perimeter-step-template.component';
import { SortDatePipe } from './shared/pipes/sortDate.pipe';
import { TopicBlockComponent } from './components/topic-block/topic-block.component';
import { TopicTermComponent } from './components/topic-term/topic-term.component';
import { TopicsPageComponent } from './components/topics-page/topics-page.component';
import { ListTemplateComponent } from './components/list-template/list-template.component';
import { TopicFormComponent } from './components/topic-form/topic-form.component';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';
import { SimpleTypeGuideTemplateComponent } from './components/simple-type-guide-template/simple-type-guide-template.component';
import { NearTypeGuideTemplateComponent } from './components/near-type-guide-template/near-type-guide-template.component';
import { CombinationTypeGuideTemplateComponent } from './components/combination-type-guide-template/combination-type-guide-template.component';
import { ExampleGuideTemplateComponent } from './components/example-guide-template/example-guide-template.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { TopicBlockSelectComponent } from './components/topic-block-select/topic-block-select.component';
import { ExcludeTypeGuideTemplateComponent } from './components/exclude-type-guide-template/exclude-type-guide-template.component';
import { ExplicativeBlockOneComponent } from './components/explicative-block-one/explicative-block-one.component';
import { ExplicativeBlockTwoComponent } from './components/explicative-block-two/explicative-block-two.component';
import { NavigatorComponent } from './components/navigator/navigator.component';
import { SimpleLoaderComponent } from './components/simple-loader/simple-loader.component';
import { OutlineNoneDirective } from './shared/directives/appearanceDirective/outline-none.directive';
import { ForgotPasswordComponent } from './components/reset-password/forgot-password.component';
import { ForgotPasswordExpiredComponent } from './components/reset-password/forgot-password-expired/forgot-password-expired.component';
// tslint:disable-next-line:max-line-length
import { ForgotPasswordIncorrectComponent } from './components/reset-password/forgot-password-incorrect/forgot-password-incorrect.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password/reset-password.component';
import { ResetPasswordSuccessComponent } from './components/reset-password/reset-password-success/reset-password-success.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ClickOutsideDirective } from './shared/directives/behaviourDirective/click-outside.directive';
import { AdminTopicComponent } from './components/admin-topic/admin-topic.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderdialogComponent } from './components/loaderdialog/loaderdialog.component';
import { TopicListTemplateComponent } from './components/topic-list-template/topic-list-template.component';
import { PlaceholderNoTopicComponent } from './components/placeholder-no-topic/placeholder-no-topic.component';
import { TestKarmaComponent } from './components/test-karma/test-karma.component';
import { ModalExplanationComponent } from './components/modal-explanation/modal-explanation.component';
import { ToastMessageStackComponent } from './components/toast-message-stack/toast-message-stack.component';
import { ReversePipe } from './shared/pipes/reverse.pipe';
import { SharingPanelComponent } from './components/documents-view/sharing-panel/sharing-panel.component';
import { MaterialModule } from './material/material.module';
import { IconModule } from './shared/icon/icon.module';
import { ShowIfTruncatedDirective } from './shared/directives/appearanceDirective/show-if-truncated.directive';
import { ModalExplanationShareComponent } from './components/modal-explanation-share/modal-explanation-share.component';
import { ArticleGuestViewComponent } from './components/documents-view/article-guest-view/article-guest-view.component';
import { AdminDocGuestViewComponent } from './components/documents-view/admin-doc-guest-view/admin-doc-guest-view.component';
import { GuestContentComponent } from './components/guest-content/guest-content.component';
import { TerritoriesPanelComponent } from './components/documents-view/territories-panel/territories-panel.component';
import { WelcomePanelComponent } from './components/documents-view/welcome-panel/welcome-panel.component';
import { AdminDocPdfComponent } from './components/documents-view/admin-doc-pdf/admin-doc-pdf.component';
import { IsLoggedInRedirectResolver } from './shared/resolvers/is-logged-in-redirect.resolver';
import { FoldersPageComponent } from './components/folders-page/folders-page.component';
import { ItemListComponent } from './components/item-list/item-list.component';
import { FolderViewComponent } from './components/folder-view/folder-view.component';
import { ItemListPaginationComponent } from './components/item-list-pagination/item-list-pagination.component';
import { FolderContentComponent } from './components/folder-content/folder-content.component';
import { SavingPanelComponent } from './components/documents-view/saving-panel/saving-panel.component';
import Utils from './shared/utils';
import { ModalCreationComponent } from './components/modal-creation/modal-creation.component';
import { FolderViewContainerComponent } from './components/folder-view-container/folder-view-container.component';
import { ModalEditFolderComponent } from './components/modal-edit-folder/modal-edit-folder.component';
import { ModalDeleteFolderComponent } from './components/modal-delete-folder/modal-delete-folder.component';
import { BookmarkCreatedAtPipe } from './shared/pipes/bookmark-created-at.pipe';
import { OccurrenceAndFolderIconBadgeComponent } from './components/occurrence-and-folder-icon-badge/occurrence-and-folder-icon-badge.component';
import { PaginationSectionComponent } from './components/documents-view/admin-doc-pdf/custom-toolbar/pagination-section/pagination-section.component';
import { CustomToolbarComponent } from './components/documents-view/admin-doc-pdf/custom-toolbar/custom-toolbar.component';
import { MetadataComponent } from './components/documents-view/metadata/metadata.component';
import { ImageCarouselComponent } from './components/documents-view/image-carousel-smart/image-carousel/image-carousel.component';
import { ImageCarouselSmartComponent } from './components/documents-view/image-carousel-smart/image-carousel-smart.component';
import { ImageFallbackDirective } from './shared/directives/appearanceDirective/image-fallback.directive';
import { ModalNPSFormComponent } from './components/modal-nps-form/modal-nps-form.component';
import { SafeResourceUrlPipe } from './shared/pipes/safe-resource-url.pipe';
import { PreventDoubleClickDirective } from './shared/directives/behaviourDirective/prevent-double-click.directive';
import { ArticleTitleCasePipe } from './shared/pipes/article-title-case.pipe';
import { ZendeskFaqComponent } from './components/zendesk-faq/zendesk-faq.component';
import { SingleSignOnPageComponent } from './components/single-sign-on-page/single-sign-on-page.component';
import { ZendeskModule } from './shared/modules/zendesk/zendesk.module';
import { ExportPanelComponent } from './components/documents-view/export-panel/export-panel.component';
import { AdminDocSearchPaginationComponent } from './components/admin-doc-search-pagination/admin-doc-search-pagination.component';
import { KeywordsCustomToolbarComponent } from './components/keywords-custom-toolbar/keywords-custom-toolbar.component';
import { AdminDocPdfMenusComponent } from './components/admin-doc-pdf-menus/admin-doc-pdf-menus.component';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import { AdminDocSidebarButtonComponent } from './components/admin-doc-sidebar-button/admin-doc-sidebar-button.component';
import { ClickOrEnterConfirmationDirective } from './shared/directives/behaviourDirective/click-or-enter-confirmation.directive';
import { ReferencePipe } from './shared/pipes/reference.pipe';
import { ImpactersPanelComponent } from './components/documents-view/impacters-panel/impacters-panel.component';
import { EntitiesAccordionComponent } from './components/documents-view/entities-accordion/entities-accordion.component';
import { ToastErrorComponent } from './components/toast-error/toast-error.component';

const rollbarConfig = {
  accessToken: 'b6b684e6d7b84fc09b4ee2ec42740834',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environment.ROLLBAR_ENV
  },
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) { }

  handleError(err: object): void {
    if (!environment.production) {
      console.error(err);
    }
    this.rollbar.error(err['originalError'] || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SafeHtmlPipe,
    SortPipe,
    SortTextPipe,
    SortDatePipe,
    TruncatePipe,
    InterleavePipe,
    FilterPipe,
    FilterLocationPipe,
    HighlightPipe,
    ReferencePipe,
    ArticlesPageComponent,
    MenuComponent,
    HeaderComponent,
    BreadcrumbComponent,
    PageTabComponent,
    FilterComponent,
    SearchComponent,
    StatsPageComponent,
    TaskBarComponent,
    AdminDocCardComponent,
    PaginationComponent,
    InsertTextPipe,
    LoaderComponent,
    ArticleCardComponent,
    ContentComponent,
    ArticleViewComponent,
    AdminDocsPageComponent,
    AdminDocViewComponent,
    ControlFComponent,
    ResultMessageComponent,
    TerritorySearchPageComponent,
    ImpactersPageComponent,
    ImpacterCardComponent,
    ImpacterViewComponent,
    AlertsPageComponent,
    WizardComponent,
    StepsComponent,
    StepHeaderComponent,
    TopicStepTemplateComponent,
    SourceStepTemplateComponent,
    FrequentStepTemplateComponent,
    OverviewStepTemplateComponent,
    PerimeterStepTemplateComponent,
    TopicBlockComponent,
    TopicTermComponent,
    TopicsPageComponent,
    ListTemplateComponent,
    TopicFormComponent,
    ModalConfirmationComponent,
    SimpleTypeGuideTemplateComponent,
    NearTypeGuideTemplateComponent,
    CombinationTypeGuideTemplateComponent,
    ExampleGuideTemplateComponent,
    ToastMessageComponent,
    TopicBlockSelectComponent,
    ExcludeTypeGuideTemplateComponent,
    ExplicativeBlockOneComponent,
    ExplicativeBlockTwoComponent,
    NavigatorComponent,
    SimpleLoaderComponent,
    OutlineNoneDirective,
    ForgotPasswordComponent,
    ForgotPasswordExpiredComponent,
    ForgotPasswordIncorrectComponent,
    ResetPasswordComponent,
    ResetPasswordSuccessComponent,
    SettingsComponent,
    ClickOutsideDirective,
    AdminTopicComponent,
    LoaderdialogComponent,
    TopicListTemplateComponent,
    PlaceholderNoTopicComponent,
    ProgressBarComponent,
    LoaderdialogComponent,
    TestKarmaComponent,
    ModalExplanationComponent,
    ToastMessageStackComponent,
    ReversePipe,
    SharingPanelComponent,
    ShowIfTruncatedDirective,
    ModalExplanationShareComponent,
    ArticleGuestViewComponent,
    AdminDocGuestViewComponent,
    GuestContentComponent,
    TerritoriesPanelComponent,
    WelcomePanelComponent,
    AdminDocPdfComponent,
    FoldersPageComponent,
    ItemListComponent,
    FolderViewComponent,
    ItemListPaginationComponent,
    FolderContentComponent,
    SavingPanelComponent,
    ModalCreationComponent,
    FolderViewContainerComponent,
    ModalEditFolderComponent,
    ModalDeleteFolderComponent,
    BookmarkCreatedAtPipe,
    OccurrenceAndFolderIconBadgeComponent,
    PaginationSectionComponent,
    CustomToolbarComponent,
    MetadataComponent,
    ImageCarouselComponent,
    ImageCarouselSmartComponent,
    ImageFallbackDirective,
    ModalNPSFormComponent,
    SafeResourceUrlPipe,
    PreventDoubleClickDirective,
    ArticleTitleCasePipe,
    ZendeskFaqComponent,
    SingleSignOnPageComponent,
    ExportPanelComponent,
    AdminDocSearchPaginationComponent,
    KeywordsCustomToolbarComponent,
    AdminDocPdfMenusComponent,
    AdminDocSidebarButtonComponent,
    ClickOrEnterConfirmationDirective,
    ExportPanelComponent,
    ImpactersPanelComponent,
    EntitiesAccordionComponent,
    ToastErrorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxPopper,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    AngularMyDatePickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TreeviewModule.forRoot(),
    NgxExtendedPdfViewerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BrowserAnimationsModule,
    MaterialModule,
    IconModule,
    ZendeskModule,
    AutoSizeInputModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    WINDOW_PROVIDERS,
    IsLoggedInRedirectResolver,
    Utils
  ],
  bootstrap: [AppComponent],
  exports: [
    ProgressBarComponent
  ],
  entryComponents: [
    ModalConfirmationComponent,
    LoaderdialogComponent
  ]
})
export class AppModule { }

registerLocaleData(localeFr, 'fr');
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cb=' + new Date().getTime());
}
