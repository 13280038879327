import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IpService {

  ipRetrieverServiceUrl = 'https://api.ipify.org/?format=json&callback=';
  private _userIp: string;

  constructor(private http: HttpClient) {
    this._userIp = 'unresolved';
  }

  getUserIp() {
    return this.http.get(this.ipRetrieverServiceUrl);
  }

  resolveUserIp() {
    this.getUserIp().subscribe(jsonIp => {
      this._userIp = jsonIp['ip'];
    });
  }

  get UserIp(): string {
    return this._userIp;
  }

  set UserIp(value: string) {
    this._userIp = value;
  }

}
