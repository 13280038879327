import { Component, Input, OnInit } from '@angular/core';
import { TerritoryForArticle } from '../../../models/territory/territory-utils';

@Component({
  selector: 'app-territories-panel',
  templateUrl: './territories-panel.component.html',
  styleUrls: ['./territories-panel.component.scss']
})
export class TerritoriesPanelComponent implements OnInit {
  @Input() territories: TerritoryForArticle[];

  constructor() { }

  ngOnInit(): void {
  }

}
