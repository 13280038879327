import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BookmarkDocumentService,
  OrderByBookmarkDocument
} from '../../shared/services/bookmark-document.service';
import { BookmarkFolderService } from '../../shared/services/bookmark-folder.service';
import { EntityService } from '../../shared/services/entity.service';
import { UrlService } from '../../shared/services/url.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BookmarkEntityType } from '../../models/bookmark-folder';
import { Folder } from '../../models/folder';
import { ApiService } from '../../shared/services/api/api.service';

@Component({
  selector: 'app-folder-view-container',
  templateUrl: './folder-view-container.component.html',
  styleUrls: ['./folder-view-container.component.scss']
})
export class FolderViewContainerComponent implements OnInit, OnDestroy {
  folder: Folder;
  folderId: number;
  entityId: string;

  currentSort: object;
  sortList: Array<object> = [
    {
      field: 'bookmark_date',
      dir: 'asc'
    },
    {
      field: 'publication_date',
      dir: 'asc'
    }
  ];
  componentSubscription: Subscription;
  nbArticle: number;
  nbAdminDoc: number;

  constructor(public bookmarkDocumentService: BookmarkDocumentService,
              private bookmarkFolderService: BookmarkFolderService,
              private apiService: ApiService,
              private entityService: EntityService,
              private urlService: UrlService,
              private route: ActivatedRoute,
              private router: Router) {
    this.bookmarkDocumentService.reset();
    this.bookmarkDocumentService.orderBy = OrderByBookmarkDocument.BOOKMARK_DATE;
    this.folder = null;
    this.folderId = 0;
    this.entityId = '';
    this.currentSort = this.sortList[0];
    this.componentSubscription = new Subscription();
  }

  ngOnInit(): void {
    this.componentSubscription.add(
      this.route.params.subscribe(params => {
        this.folderId = parseInt(params['folderId'], 10);
        this.bookmarkDocumentService.containerId = this.folderId;

        this.componentSubscription.add(
          this.apiService.bookmark
            .getBookmarkEntityByTypeAndId(BookmarkEntityType.FOLDER, this.bookmarkDocumentService.containerId)
            .subscribe((folder) => {
              this.bookmarkFolderService.folder = folder;
              this.componentSubscription.add(
                this.bookmarkFolderService.getFolderSubject()
                  .subscribe((folderSubject: Folder) => {
                    this.folder = folderSubject;
                    this.nbArticle = folderSubject.articlesCount;
                    this.nbAdminDoc = folderSubject.admin_docsCount;
                  }));
            }));

      })
    );

    this.componentSubscription.add(
      this.entityService.getEntityId()
        .subscribe(entityId => {
          this.entityId = entityId;
        })
    );
  }

  ngOnDestroy() {
    this.componentSubscription.unsubscribe();
    this.bookmarkFolderService.folder = null;
  }

  filterSortList() {
    return this.sortList;
  }

  onSortChange(sortBy: object): void {
    if (sortBy['field'] === this.currentSort['field']) {
      return;
    }
    this.currentSort = sortBy;
    // LOGIC to send new search on service
    this.bookmarkDocumentService.sentSort(this.currentSort['field'] as OrderByBookmarkDocument);
  }

  get currentUrl(): string {
    return this.router.url;
  }

  onTabClick(tabName: string) {
    if (this.currentUrl.includes('article')) {
      this.router.navigateByUrl(this.currentUrl.replace('article', tabName)).then();
    }
    if (this.currentUrl.includes('admin-doc')) {
      this.router.navigateByUrl(this.currentUrl.replace('admin-doc', tabName)).then();
    }
  }
}
