<div class="template-container">
    <div class="card-block">
      <div class="block-body d-flex">
        <div class="block-container">
          <div class="font-weight-bold title">{{'template.configuration' | translate}}</div>
          <div class="d-flex justify-content-between">
            <div class="term-block">
              <div class="font-weight-600">{{'template.bulding' | translate}}</div>
              <div class="font-weight-600">{{'template.shade-house' | translate}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="block-footer">
        <div class="font-weight-bold title">{{'template.results' | translate}}</div>
        <div class="request-chain">
          <div class="d-inline-block color-basic-600 font-weight-600 pl-0 text-uppercase">
            {{'content.except' | translate}}
          </div>
          <div class="d-inline-block font-weight-bold">{{'template.bulding' | translate}} </div>
          <div class="d-inline-block color-basic-600 font-weight-600 pl-1 text-uppercase">
            {{'content.except' | translate}}
          </div>
          <div class="d-inline-block font-weight-bold">{{'template.shade-house' | translate}} </div>
        </div>
      </div>
    </div>
    <app-example-guide-template [examples]="examples"></app-example-guide-template>
  </div>
  