import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';


export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  override lazyLoad = true;
  accountUrl = 'explain1905.zendesk.com';
  callback(zE: any) {
    zE.hide();
  }
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig)
  ]
})
export class ZendeskModule { }
