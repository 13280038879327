import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, TemplateRef, OnInit } from '@angular/core';
import { TreeviewItem, TreeviewSelection } from '../../models/treeview-item';
import { TreeviewConfig } from '../../models/treeview-config';
import { TreeviewHeaderTemplateContext } from '../../models/treeview-header-template-context';
import { TreeviewItemTemplateContext } from '../../models/treeview-item-template-context';
import { TreeviewHelper } from '../../helpers/treeview-helper';
import { TreeviewEventParser } from '../../helpers/treeview-event-parser';
import { SearchHelper } from '../../../../shared/helpers/search.helper';
import { NotiferService } from 'src/app/shared/services/notifer.service';

class FilterTreeviewItem extends TreeviewItem {
  private readonly refItem: TreeviewItem;

  constructor(item: TreeviewItem) {
    super({
      text: item.text,
      value: item.value,
      infos: item.infos,
      isRoot: item.isRoot,
      disabled: item.disabled,
      checked: item.checked,
      collapsed: item.collapsed,
      children: item.children
    });
    this.refItem = item;
  }

  updateRefChecked(): void {
    this.children.forEach(child => {
      if (child instanceof FilterTreeviewItem) {
        child.updateRefChecked();
      }
    });

    let refChecked = this.checked;
    if (refChecked) {
      for (const refChild of this.refItem.children) {
        if (!refChild.checked) {
          refChecked = false;
          break;
        }
      }
    }
    this.refItem.checked = refChecked;
  }
}

@Component({
  selector: 'ngx-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: ['./treeview.component.scss']
})
export class TreeviewComponent implements OnChanges, OnInit {
  @Input() headerTemplate: TemplateRef<TreeviewHeaderTemplateContext>;
  @Input() itemTemplate: TemplateRef<TreeviewItemTemplateContext>;
  @Input() notItemFoundTemplate: TemplateRef<any>;
  @Input() items: TreeviewItem[];
  @Input() textChange: string;
  @Input() config: TreeviewConfig;
  @Output() selectedChange = new EventEmitter<any[]>();
  @Output() filterChange = new EventEmitter<string>();
  headerTemplateContext: TreeviewHeaderTemplateContext;
  allItem: TreeviewItem;
  filterText = '';
  filterItems: TreeviewItem[];
  selection: TreeviewSelection;

  constructor(
    private notif: NotiferService,
    private defaultConfig: TreeviewConfig,
    private eventParser: TreeviewEventParser
  ) {
    this.config = this.defaultConfig;
    this.allItem = new TreeviewItem({text: 'All', value: undefined});
  }

  get hasFilterItems(): boolean {
    return this.filterItems && this.filterItems.length > 0;
  }

  get maxHeight(): string {
    return `${this.config.maxHeight}`;
  }

  ngOnInit(): void {
    this.createHeaderTemplateContext();
    this.generateSelection();
    this.notif.missionConfirmed$.subscribe(value => {
      if (value != null) {
        this.makeAllEpciChecked(value);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const itemsSimpleChange = changes.items;
    if (itemsSimpleChange && this.items) {
      this.updateFilterItems();
      this.updateCollapsedOfAll();
      this.raiseSelectedChange();
    }

    if (changes.textChange && this.items) {
      if (!changes.textChange.currentValue) {
        this.onFilterTextChange('');
      }
    }
  }

  onAllCollapseExpand(): void {
    this.allItem.collapsed = !this.allItem.collapsed;
    this.filterItems.forEach(item => item.setCollapsedRecursive(this.allItem.collapsed));
  }

  onFilterTextChange(text: string): void {
    this.filterText = text;
    this.filterChange.emit(text);
    this.updateFilterItems();
    this.items.forEach(item => item.setCollapsedRecursive(false));
  }

  onAllCheckedChange(): void {
    const checked = this.allItem.checked;
    this.filterItems.forEach(item => {
      item.setCheckedRecursive(checked);
      if (item instanceof FilterTreeviewItem) {
        item.updateRefChecked();
      }
    });

    this.raiseSelectedChange();
  }

  onItemCheckedChange(): void {
    this.raiseSelectedChange();
  }

  raiseSelectedChange(): void {
    this.generateSelection();
    const values = this.eventParser.getSelectedChange(this);
    this.updateFilterItems();
    setTimeout(() => {
      this.selectedChange.emit(values);
    });
  }

  private createHeaderTemplateContext(): void {
    this.headerTemplateContext = {
      config: this.config,
      item: this.allItem,
      onCheckedChange: () => this.onAllCheckedChange(),
      onCollapseExpand: () => this.onAllCollapseExpand(),
      onFilterTextChange: (text) => this.onFilterTextChange(text)
    };
  }

  private generateSelection(): void {
    let checkedItems: TreeviewItem[] = [];
    let uncheckedItems: TreeviewItem[] = [];
    if (this.items) {
      const selection = TreeviewHelper.concatSelection(this.items, checkedItems, uncheckedItems);
      checkedItems = selection.checked;
      uncheckedItems = selection.unchecked;
    }

    this.selection = {
      checkedItems,
      uncheckedItems
    };
  }

  private updateFilterItems(): void {
    if (this.filterText !== '') {
      const filterItems: TreeviewItem[] = [];
      const filterText = this.filterText.toLowerCase();
      this.items.forEach(item => {
        const newItem = this.filterItem(item, filterText);
        if (newItem) {
          filterItems.push(newItem);
        }
      });
      this.filterItems = filterItems;
    } else {
      this.filterItems = this.items;
    }

    this.updateCheckedOfAll();
  }

  private makeAllEpciChecked(value: TreeviewItem) {
    /*  cette fonction permet de rendre tous l'epci séléctionner si elle est cibler dans la recherche */
    const kind: string = value['infos']['kind'];
    const code: string = value['infos']['code'];
    let index = -1;
    if (kind === 'FRDEPA') {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].infos?.['code'] === code) {
          index = i;
          if (!value['internalChecked'] === false) {
            this.items[i].setCheckedRecursive(false);
          } else {
            this.items[i].setCheckedRecursive(true);
          }
        }
      }
      if (index >= 0) {
        this.items[index].checked = false;
        this.updateFilterItems();
      }
    }

    if (kind === 'FREPCI') {
      this.items.forEach(dep => {
        dep.children.forEach(epci => {
          if (epci.infos?.code === code) {
            epci.setCheckedRecursive(true);
            this.updateFilterItems();
          }
        });
      });
    }
  }

  private filterItem(item: TreeviewItem, filterText: string): TreeviewItem {
    const isMatch = SearchHelper.searchRegExp(item.text, filterText);

    if (isMatch) {
      return item;
    } else {
      if (item.children) {
        const children: TreeviewItem[] = [];
        item.children.forEach(child => {
          const newChild = this.filterItem(child, filterText);
          if (newChild) {
            children.push(newChild);
          }
        });
        if (children.length > 0) {
          const newItem = new FilterTreeviewItem(item);
          newItem.collapsed = false;
          newItem.children = children;
          return newItem;
        }
      }
    }

    return undefined;
  }

  private updateCheckedOfAll(): void {
    this.getCorrectChecked(this.items);
  }

  private getCorrectChecked(filterItems): void {
    for (const filterItem of filterItems) {
      let itemChecked: boolean = null;

      if (filterItem.children) {
        filterItem.children.forEach(child => {
          if (itemChecked === null) {
            itemChecked = child.checked;
          } else {
            if (child.checked !== itemChecked) {
              itemChecked = undefined;
              return;
            }
          }
          this.getCorrectChecked(filterItem.children);
        });
        if (filterItem.checked === undefined && !itemChecked) {
          itemChecked = undefined;
        }
      } else {
        itemChecked = filterItem.checked;
      }
      filterItem.checked = itemChecked;
    }
  }

  private updateCollapsedOfAll(): void {
    let hasItemExpanded = false;
    for (const filterItem of this.filterItems) {
      if (!filterItem.collapsed) {
        hasItemExpanded = true;
        break;
      }
    }

    this.allItem.collapsed = !hasItemExpanded;
  }
}
