import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ApiService } from '../../shared/services/api/api.service';
import { HttpParams } from '@angular/common/http';


@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  isError = false;
  submitted = false;
  returnUrl: string;
  returnQueryParams: HttpParams;
  fieldTextType1 = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private apiService: ApiService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']).then();
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.route.queryParams
      .subscribe((params) => {
          if (String(params.fromUrl).includes('?')) {
            const urlParts = String(params.fromUrl).split('?');
            this.returnUrl = urlParts[0];
            this.returnQueryParams = new HttpParams();
            if (urlParts[1]) {
              const queryParams: Array<string> = urlParts[1].split('&');
              for (const queryParam of queryParams) {
                if (queryParam.includes('=')) {
                  const queryParamKeyValue = queryParam.split('=');
                  this.returnQueryParams = this.returnQueryParams.append(queryParamKeyValue[0], queryParamKeyValue[1]);
                }
              }
            }
          } else {
            this.returnUrl = params.fromUrl;
          }
        }
      );
    // get return url from route parameters or default to '/'
    this.returnUrl = this.returnUrl || '/';
  }

  newLogin() {
    this.loading = true;
    this.isError = false;
    this.fieldTextType1 = false;

    this.authenticationService
      .newLogin(this.f.username.value.trim(), this.f.password.value)
      .pipe(first())
      .subscribe(
        () => {
          this.apiService.gwStatistics.setAccountInfos();
          this.apiService.gwStatistics.setGoodwillStatistics();
          if (this.returnQueryParams) {
            this.router.navigate([this.returnUrl], {
              queryParams: this.returnQueryParams
            }).then();
          } else {
            this.router.navigate([this.returnUrl]).then();
          }
        },
        (e) => {
          console.log(`error on new login step - error : ${e}`);
          this.loading = false;
          this.isError = true;
        });

  }

  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType1;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }


}
