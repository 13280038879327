import { Component, Input, OnInit } from '@angular/core';
import { TopicType } from '../../models/topic';


@Component({
  selector: 'app-placeholder-no-topic',
  templateUrl: './placeholder-no-topic.component.html',
  styleUrls: ['./placeholder-no-topic.component.scss']
})

export class PlaceholderNoTopicComponent implements OnInit {
  @Input() type: TopicType;
  TopicType = TopicType;

  constructor() {
  }

  ngOnInit(): void {
  }

}
