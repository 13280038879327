import { DbImpacterPosition, ImpacterPosition, TrackPositions } from './impacter-position';
import { DbMention, Mention } from '../mention';

//region __Impacter
/** Structure of Impacter as received from the gw api */
export interface DbImpacter {
  id: number;
  name: string;
  positions: Array<DbImpacterPosition>;
}

/** Corresponding class */
export class Impacter {
  id: number;
  name: string;
  positions: ImpacterPosition[];

  constructor(dbImpacter: DbImpacter) {
    this.id = dbImpacter.id;
    this.name = dbImpacter.name;
    this.positions = dbImpacter.positions.map(x => new ImpacterPosition(x));
  }

}

//endregion

//region __ImpacterWithByFilter
/** Structure of Impacter, with by_filter attribute, as received from the gw api */
export interface DbImpacterWithByFilter extends DbImpacter {
  by_filter: boolean;
}

/** Corresponding class */
export class ImpacterWithByFilter extends Impacter {
  byFilter: boolean;

  constructor(dbImpacterWithByFilter: DbImpacterWithByFilter) {
    super(dbImpacterWithByFilter);
    this.byFilter = dbImpacterWithByFilter.by_filter;
  }
}
//endregion

//region __TrackImpacter
/** Class of impacter as we want to track it */
export class TrackImpacter {
  id: number;
  name: string;
  positions: TrackPositions;

  constructor(id: number,
              name: string,
              positions: TrackPositions) {
    this.id = id;
    this.name = name;
    this.positions = positions;
  }
}
//endregion

//region __RetrieveImpacters typed req and res
export class RetrieveImpactersTypedResponseBody {
  impacters: ImpacterWithByFilter[];
  filteredCount: number;
  totalCount: number;

  constructor(retrieveImpactersResponseBody: {
    data: DbImpacterWithByFilter[],
    filtered_count: number,
    total_count: number
  }) {
    this.impacters = retrieveImpactersResponseBody.data.map(x => new ImpacterWithByFilter(x));
    this.filteredCount = retrieveImpactersResponseBody.filtered_count;
    this.totalCount = retrieveImpactersResponseBody.total_count;
  }
}

export interface DbRetrieveImpactersRequestBody {
  filters: {
    titles: string[],
    with_old_position: boolean,
    territories: string[],
    text: string
  };
  limit: number;
  offset: number;
  perimeter: string[];
  sort: {
    dir: string,
    field: string
  };
}
//endregion

//region __ImpacterForArticle

export interface DbImpacterForArticle extends DbImpacter {
  firstname: string;
  lastname: string;
  mentions: Array<DbMention>;
}

export class ImpacterForArticle extends Impacter {
  firstname: string;
  lastname: string;
  mentions: Array<Mention>;
  relevantPositions: ImpacterPosition[];
  oldestPositionYear: number;
  url: string;
  articleCount: number;

  constructor(db: DbImpacterForArticle, url?: string) {
    super(db);
    this.firstname = db.firstname;
    this.lastname = db.lastname;
    this.mentions = db.mentions.map(x => new Mention(x));
    const thirdPosition = this.positions.slice(2, 1);
    this.relevantPositions = [...this.positions.slice(0, 2), ...(thirdPosition.length && !thirdPosition[0].isOld) ? thirdPosition : []];
    this.oldestPositionYear = this.positions.reduce(
      (oldest, position) => {
        const year = +position.startDate.substring(0, 4);
        return year < oldest ? year : oldest;
      }, +this.positions[0].startDate.substring(0, 4)
    );
    this.url = url;
  }
}

//endregion
