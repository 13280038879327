import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { ItemsService } from '../../shared/services/items.service';

@Component({
  selector: 'app-item-list-pagination',
  templateUrl: './item-list-pagination.component.html',
  styleUrls: ['./item-list-pagination.component.scss']
})
export class ItemListPaginationComponent implements OnInit, OnChanges {

  @Input() itemsService: ItemsService;
  @Input() nbPages: number;
  @Input() currentPage = 1;

  listOfPage: Array<number>;
  maxNbPagesShow = 7;
  maxNbPagesConsecutive = 5;

  constructor() {
    this.currentPage = 1;
  }

  ngOnInit(): void {
    this.currentPage = this.itemsService.currentPage;

    this.createPageList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.nbPages) {
      this.nbPages = changes.nbPages.currentValue;
      this.createPageList();
    }
    this.currentPage = this.itemsService.currentPage;
  }

  onPageClick(pageIndex: number): void {
    if (pageIndex === this.currentPage || pageIndex < 1 || pageIndex > this.nbPages) {
      return;
    }
    this.currentPage = pageIndex;
    this.itemsService.sentPageIndex(this.currentPage);
  }

  showNextPrevPage(pageIndex: number): boolean {
    return this.currentPage <= pageIndex + 1 && this.currentPage >= pageIndex - 1;
  }

  showWhenInFirstPages(pageIndex: number): boolean {
    return this.currentPage <= 4 && pageIndex <= this.maxNbPagesConsecutive;
  }

  showWhenInLastPages(pageIndex: number): boolean {
    return this.currentPage >= this.nbPages - 3 && pageIndex >= this.nbPages - this.maxNbPagesConsecutive + 1;
  }

  notShowFirstLastPage(index: number): boolean {
    return index > 0 && index < this.nbPages - 1;
  }

  createPageList(): void {
    this.listOfPage = Array.from({length: this.nbPages}, (_, i) => i + 1);
  }

}
