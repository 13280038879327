import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
/**
 * Class taking care of exporting PDF
 * Uses npm library FILE-SAVER, to save document onto user's computer.
 */
export class ExportService {

  /**
   * Method to save Blob as a PDF to the user's computer.
   */
  savePdfToComputer(adminDocPdfBlob: Blob, documentName: string): void {
    saveAs(adminDocPdfBlob, documentName);
  }

}
