import { Injectable } from '@angular/core';
import { SearchService } from './search.service';

@Injectable({ providedIn: 'root' })
export class ArticleImSearchService extends SearchService {

  sendSearch() {
    this.subject.next
    (
      {
        text: this.text,
        limit: this.limit,
        offset: this.offset,
        filters: this.filters,
        perimeter: this.perimeter,
        sortBy: this.sortBy
      }
    );
  }
}
