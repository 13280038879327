<div class="new-item-modal">
  <div class="modal-header">
    <h4 class="modal-title">{{translationFileSection + '.modal-new-item-title' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
      <span aria-hidden="true"><img src="assets/images/icon-close-circle.svg" alt="icon-close-circle"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="font-weight-bold message pr-3">
      <div>
        {{translationFileSection + '.modal-new-item-sub-title' | translate}}
      </div>
    </div>
  </div>
  <div class="input-name">
    <div class="input-container">
      <input
        #checkIfExistingInput
        autocomplete="off"
        type="text"
        id="checkIfExisting"
        class=""
        [formControl]="newItemNameForm"
        [value]="newItemNameForm.value"
        maxlength="40"
        (keyup.enter)="createItem()"
      >
    </div>
    <div *ngIf="newItemNameForm.dirty && (f?.errors || displayAlreadyExists || displayNameNotAllowed)"
         class="error-container d-flex align-items-baseline">
      <div *ngIf="f?.errors || displayAlreadyExists || displayNameNotAllowed" class="error-icon">
        <img src="../../../assets/images/icon-alert-triangle.svg" alt="error-icon">
      </div>
      <div *ngIf="f?.errors?.required" class="error-text">
        {{translationFileSection + '.modal-new-item-error-required' | translate}}
      </div>
      <div *ngIf="f?.errors?.maxlength" class="error-text">
        {{translationFileSection + '.modal-new-item-error-maxlength' | translate}}
      </div>
      <div *ngIf="submitted && !f?.errors?.required && displayAlreadyExists" class="error-text">
        {{translationFileSection + '.modal-new-item-error-existing-name' | translate}}
      </div>
      <div *ngIf="submitted && !f?.errors?.required && displayNameNotAllowed" class="error-text"
           [innerHTML]="translationFileSection+'.modal-new-item-error-not-allowed-name' | translate"
      >
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="cancel font-weight-bold"
            (click)="dismissModal(); submitted = false">{{translationFileSection + '.modal-new-item-cancellation' |
      translate}}</button>
    <button [disabled]="f?.errors" type="button" class="btn confirm-delete d-flex" (click)="createItem()">
      <span class="text-uppercase font-weight-bold align-self-center">{{translationFileSection + '.modal-new-item-validation' | translate}}</span>
    </button>
  </div>
</div>
