<div id="toolbar-container" class="toolbar-container no-select">

  <div class="toolbar-header d-table w-100" (click)="onExpandToolbarPanel()">

    <div class="left-part d-table-cell align-middle">
      <div *ngIf="selectedTerms?.length === 0"
           class="d-table-cell align-middle">{{'document.keyword-search-header-no-selection' | translate | uppercase}}</div>
      <div *ngIf="selectedTerms?.length > 0"
           class="d-table-cell align-middle">
        <span class="d-flex">
          <div class="selected-terms-number">{{selectedTerms?.length}}</div>
          <div *ngIf="selectedTerms?.length === 1">{{'document.keyword-search-header-one-selection' | translate | uppercase}}</div>
          <div *ngIf="selectedTerms?.length > 1">{{'document.keyword-search-header-multi-selection' | translate | uppercase}}</div>
        </span>
      </div>
    </div>

    <div class="right-part d-table">
      <span *ngIf="displayToolbar" class="d-table-cell align-middle right-part-text">
        {{ 'document.panel-close' | translate | uppercase }}
      </span>
      <span *ngIf="!displayToolbar" class="d-table-cell align-middle right-part-text">
        {{ 'document.panel-open' | translate | uppercase }}
      </span>
      <span class="d-table-cell align-middle">
        <mat-icon svgIcon="double-chevron-down-white"
                  class="icon-16 mr-1 d-table-cell align-middle"
                  [style.transform]="displayToolbar ? 'rotate(180deg)' : 'rotate(0deg)'"></mat-icon>
      </span>
    </div>
  </div>

  <!-- Part for tooltip on no occurrence -->
  <ng-template #noOccurrenceTooltipStem>
    <span>{{'document.no-occurrence-for-stem' | translate}}</span>
  </ng-template>
  <ng-template #noOccurrenceTooltipInput>
    <span>{{'document.no-occurrence-for-input' | translate}}</span>
  </ng-template>

  <div class="toolbar-body">

    <div *ngIf="displayToolbar" class="keywords-section">

      <div class="stem-container d-flex">
        <div *ngFor="let element of matchTermMatrix; trackBy: trackByStem"
             [ngClass]="{'selected': selectedTerms?.includes(element.stem),
                         'unselected': !selectedTerms?.includes(element.stem),
                         'keyword-type-input': element.type === SearchKeywordTypeEnum.USER_INPUT,
                         'no-occurrence': element.occurrencePages?.length === 0}"
             class="stem-button"
             (click)="onClickTerm(element)"
             (mouseenter)="hoveredKeyword = element"
             (mouseleave)="hoveredKeyword = null"
             [ngbTooltip]="element.occurrencePages?.length === 0 ?
              element.type === SearchKeywordTypeEnum.USER_INPUT ?
                noOccurrenceTooltipInput :
                noOccurrenceTooltipStem
              : ''"
             tooltipClass="default-tooltip-class {{element.type === SearchKeywordTypeEnum.USER_INPUT ? 'tooltip-max-190' : 'tooltip-max-300'}}"
             placement="bottom"
             container="body"
             openDelay="500"
        >

          <div class="d-table">
            <!-- edit icons for user inputs -->
            <!-- edit input unselected -->
            <span *ngIf="element.type === SearchKeywordTypeEnum.USER_INPUT &&
                        !selectedTerms?.includes(element.stem)"
                  [ngClass]="{'filter-no-occurrence-icon': element.occurrencePages?.length === 0}"
                  class="d-table-cell align-middle">
              <mat-icon svgIcon="edit-800" class="icon-12 mr-1 d-table-cell align-middle edit-icon"></mat-icon>
            </span>
            <!-- edit input selected -->
            <span *ngIf="element.type === SearchKeywordTypeEnum.USER_INPUT &&
                         selectedTerms?.includes(element.stem)"
                  class="d-table-cell align-middle">
              <mat-icon svgIcon="edit-500" class="icon-12 mr-1 d-table-cell align-middle edit-icon"></mat-icon>
            </span>

            <!-- keyword value displayed (stem or input) -->
            <span [ngClass]="{'text-line-through': element.occurrencePages?.length === 0 &&
                                                    element.type === SearchKeywordTypeEnum.USER_INPUT}"
                  class = "d-table-cell stem-text align-middle">
              <span>{{element.stem | articleTitleCase }}</span>
            </span>

            <!-- occurrence counters & loader -->
            <span *ngIf="element.occurrencePages !== null &&
                        !(element.type === SearchKeywordTypeEnum.USER_INPUT &&
                        hoveredKeyword === element)"
                  class="d-table-cell stem-count align-middle">
              <span>{{element.occurrencePages?.length}}</span>
            </span>

            <span *ngIf="element.occurrencePages === null && !timeoutElapsed && element.type !== SearchKeywordTypeEnum.USER_INPUT"
                  class="d-table-cell stem-count align-middle">
                  <div class="stem-count-loader"></div>
            </span>

            <span *ngIf="element.occurrencePages === null && timeoutElapsed && element.type !== SearchKeywordTypeEnum.USER_INPUT"
                  class="d-table-cell stem-count align-middle">
                  <div>?</div>
            </span>

            <span *ngIf="element.occurrencePages === null && element.type === SearchKeywordTypeEnum.USER_INPUT"
                  class="d-table-cell stem-count align-middle">
                  <div class="stem-count-loader"></div>
            </span>


            <!-- icons to delete user input when selected and unselected -->
            <!-- delete input selected -->
            <span *ngIf="element.type === SearchKeywordTypeEnum.USER_INPUT &&
                     hoveredKeyword === element &&
                     selectedTerms?.includes(element.stem) &&
                     element.occurrencePages !== null"
                  class="d-table-cell align-middle">
              <img src="../../../assets/images/27-icon-close-circle-500.svg"
                   alt="delete-user-input-icon"
                   [style.cursor]="'pointer'"
                   class="icon-16 d-table-cell align-middle delete-input-icon"
                   (click)="onDeleteUserInput(element)"
                   onmouseover="this.src='../../../assets/images/27-icon-close-circle-fill-500.svg'"
                   onmouseout="this.src='../../../assets/images/27-icon-close-circle-500.svg'"
              >
            </span>
            <!-- delete input unselected -->
            <span *ngIf="element.type === SearchKeywordTypeEnum.USER_INPUT &&
                     hoveredKeyword === element &&
                     !selectedTerms?.includes(element.stem) &&
                     element.occurrencePages !== null"
                  [ngClass]="{'filter-no-occurrence-icon': element.occurrencePages?.length === 0}"
                  class="d-table-cell align-middle">
              <img src="../../../assets/images/27-icon-close-circle-800.svg"
                   alt="delete-user-input-icon"
                   [style.cursor]="'pointer'"
                   class="icon-16 d-table-cell align-middle delete-input-icon"
                   (click)="onDeleteUserInput(element)"
                   onmouseover="this.src='../../../assets/images/27-icon-close-circle-fill-800.svg'"
                   onmouseout="this.src='../../../assets/images/27-icon-close-circle-800.svg'"
              >
            </span>

          </div>
        </div>

        <!-- user input (ctrl-F) -->
        <span class="stem-button input-text-container d-flex">
          <div class="d-table">
          <mat-icon svgIcon="edit-800"
                    class="icon-12 mr-1 d-table-cell align-middle input-edit-icon"></mat-icon>
            </div>
          <div class="d-table">
          <input
            #searchTermInput
            (keyup.enter)="addUserInput(searchTermInput.value)"
            [(ngModel)]="userInput"
            [style.padding-right]="userInput?.length < 2 ? '6px' : '0'"
            [usePlaceHolderWhenEmpty]="true"
            appOutlineNone
            autoSizeInput
            autocomplete="off"
            class="input-text d-table-cell align-middle"
            id="searchTermInput"
            placeholder="{{'document.first-input' | translate}}"
            type="text"
          /></div>
            <div class="d-table h-100">
                <img *ngIf="userInput?.length >= 2"
                     src="../../../assets/images/27-icon-checkmark-validate.svg"
                     alt="input-validation-icon"
                     class="icon-17 mr-1 d-table-cell align-middle cursor-pointer input-validation-icon"
                     onmouseover="this.src='../../../assets/images/27-icon-checkmark-validate-fill.svg'"
                     onmouseout="this.src='../../../assets/images/27-icon-checkmark-validate.svg'"
                     (click)="addUserInput(userInput)">
            </div>
        </span>

        <div *ngIf="stems === null && matchTermMatrix.length === 0" class="no-match-stems d-table">
          <div class="d-table-cell align-middle font-italic">
            {{'document.unavailable-suggestions' | translate}}
          </div>
        </div>

      </div>

    </div>


    <div class="search-pagination-section">

      <div class="pagination-part d-flex">

        <div class="page-counter d-table">
          <div class="page-counter-container d-table-cell align-middle">
            <div *ngIf="occurrencePagesCounter"  class="d-flex">
              <span class="page-counter">{{occurrencePagesCounter}}</span>
              <span *ngIf="occurrencePagesCounter === 1" class="page-text">
                {{'document.keyword-search-header-one-occ-page' | translate | uppercase}}</span>
              <span *ngIf="occurrencePagesCounter > 1" class="page-text">
                {{'document.keyword-search-header-multi-occ-page' | translate | uppercase}}</span>
            </div>
          </div>
        </div>

        <div class="search-pagination">
          <ng-content class="search-pagination" select="[searchPagination]"></ng-content>
        </div>

      </div>

      <!-- Part for PDF menus -->
      <ng-content class="pdf-menus" select="[pdfMenus]"></ng-content>

    </div>

  </div>



</div>
