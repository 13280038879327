import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalNPSFormComponent } from '../../components/modal-nps-form/modal-nps-form.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { ApiService } from './api/api.service';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root'
})
export class DisplayNpsModalService {
  user: User;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService
  ) {
  }

  public get httpHeaders(): object {
    return {
      headers:
        new HttpHeaders({
          'Content-Type': 'application/json'
        })
    };
  }

  async displayNpsModalIfNeeded(): Promise<void> {
    if (this.isLoggedIn() && await this.isNpsToBeAsked() && !this.modalService.hasOpenModals()) {
      this.displayModal();
    } else {
      console.log('NPS form should not be displayed.');
    }
  }

  isLoggedIn(): boolean {
    return !!this.authenticationService.goodwillToken;
  }

  async isNpsToBeAsked(): Promise<boolean> {
    if (environment.npsFormShouldBeDisplayed && this.isSurveyPeriod() && this.reminderNpsIsOutdated()) {
      this.user = await this.getUser();
      return this.userIsOldEnough() && await this.getUserResponseIsFound() === false;
    }
    return false;
  }

  isSurveyPeriod(): boolean {
    const today = new Date();
    return today > new Date(environment.npsMinDate) && today < new Date(environment.npsMaxDate);
  }

  reminderNpsIsOutdated(): boolean {
    const reminderNps = localStorage.getItem('reminderNps');
    if (!reminderNps) {
      return true;
    }
    const dateReminder = new Date(reminderNps);
    const date = new Date();

    return dateReminder < date;
  }

  async getUser(): Promise<User> {
    try {
      return await this.apiService.user.getOwnUser().pipe(first()).toPromise();
    } catch (e) {
      console.log('@getUser error', e);
    }
  }

  userIsOldEnough(): boolean {
    const creationDateLimit = new Date();
    creationDateLimit.setDate(creationDateLimit.getDate() - environment.npsMinAge);
    return this.user.createdAt < creationDateLimit;
  }

  async getUserResponseIsFound(): Promise<boolean> {
    try {
      const response = await this.retrieveResponse(this.user.email).pipe(first()).toPromise();
      return response['body'] && JSON.parse(response['body'])['is_found'];
    } catch (e) {
      console.log('@getUserResponseIsFound error', e);
    }
  }

  retrieveResponse(email: string): any {
    const body = {
      'email': email
    };
    return this.http.post(
      environment.retrieveResponseUrl,
      body,
      this.httpHeaders);
  }

  displayModal(): void {
    const modalRef = this.modalService.open(ModalNPSFormComponent,
      {
        ariaLabelledBy: 'modal-nps-form',
        centered: true,
        windowClass: 'modal-width-716',
        backdrop: 'static',
        keyboard: false
      }
    );
    modalRef.componentInstance.email = this.user.email;
    modalRef.componentInstance.company = this.user.company;
    modalRef.result.then(_ => {
    }, (error) => {
      if (error === 'reminderNps') {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        date.setHours(7, 0, 0, 0);
        localStorage.setItem('reminderNps', date.toString());
      }
    });
  }

}
