import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Topic, TopicType } from '../../models/topic';
import { SearchHelper } from '../../shared/helpers/search.helper';
import { ApiService } from '../../shared/services/api/api.service';
import { AlertRelatedToTopic } from '../../models/alert';


@Component({
  selector: 'app-topic-list-template',
  templateUrl: './topic-list-template.component.html',
  styleUrls: ['./topic-list-template.component.scss']
})

export class TopicListTemplateComponent implements OnInit, OnChanges {
  @Input() topics: Array<Topic> = [];
  @Input() defaultTopics: Array<Topic> = [];
  @Input() type: TopicType;
  @Output() deleteTopic = new EventEmitter<string>();
  @Input() topicListLength = 0; // nombre total des topic custom or default qu'on a (sans filter)
  @Input() hasFilter = false ;
  TopicType = TopicType;
  forceUpdate = 0;
  public indexItemSelected: number;
  public itemIdSelected: string;
  currentOrder = {
    orderBy: 'name',
    reverse: false
  };
  alertsRelatedToTopic: AlertRelatedToTopic[] = [];
  formFromScratch = true;
  defaultTopicsFiltered: Array<Topic> = [];
  defaultTopicName: string = null;
  selectedTopic = null;
  show = false;
  stateDropDown = false;

  constructor(private modalService: NgbModal,
              private apiService: ApiService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.defaultTopicsFiltered = this.defaultTopics;
  }

  search(value: string) {
    this.show = true;
    this.stateDropDown = true;
    this.selectedTopic = null;
    this.defaultTopicsFiltered = this.defaultTopics.filter((v) => SearchHelper.searchRegExp(v.name, value));
  }

  hideOrOpen(event?) {
    event?.stopPropagation();
    if (this.formFromScratch === true) {
      this.formFromScratch = false;
    }
    this.stateDropDown = !this.stateDropDown;
    this.show = this.stateDropDown;
  }

  hideIfOpen() {
    if (this.stateDropDown === true) {
      this.hideOrOpen();
    }
  }

  onItemClicked(topic) {
    if (this.formFromScratch === true) {
      return;
    }
    this.hideOrOpen();
    this.defaultTopicName = topic.name;
    this.selectedTopic = topic.id;
    this.defaultTopicsFiltered = this.defaultTopics;
  }

  changeOrder(value) {
    if (this.currentOrder.orderBy === value) {
      this.currentOrder.reverse = !this.currentOrder.reverse;
    } else {
      this.currentOrder = {
        orderBy: value,
        reverse: false
      };
      this.forceUpdate++;
    }
  }

  openModal(content, itemId, itemIndex) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true});
    this.indexItemSelected = itemIndex;
    this.itemIdSelected = itemId;
    this.apiService.alert.getAlertsRelatedToTopic(itemId)
      .subscribe(response => {
        this.alertsRelatedToTopic = response.alerts;
      });
  }

  openCreationModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true});
  }

  onDelete(): void {
    this.deleteTopic.emit(this.itemIdSelected);
    this.topicListLength--;
    this.topics.splice(this.indexItemSelected, 1);
    this.modalService.dismissAll();
  }

}
