<div class="container-fluid">
  <div class="row">
    <div class="col-sm-6 left-side"></div>
    <div class="col-sm-6 right-side d-flex">
      <div class="content">
        <div class="font-weight-bold title">{{"reset-password.title" | translate }}</div>
        <div class="sub-title pb-4">{{"reset-password.sub-title"  | translate}}</div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="email"
                   class="login-label-title font-weight-bold"> {{"reset-password.email" | translate | uppercase}}</label>
            <input type="text" formControlName="email" class="form-control login-form-control" id="email"
                   [ngClass]="{ 'is-invalid': isError }"/>
            <div class="error-mail">
              <div *ngIf="isError" class="no-match-error-message">
                <img class="no-match-icon" src="../../../assets/images/icon-alert-triangle.svg" alt="icon-alert-triangle"/>
                <div class="no-match-text">{{ 'reset-password.no-match' | translate }} </div>
              </div>
            </div>
            <div *ngIf="isEmailSend && !f.email.errors" class="sub-title-send">
              <div> {{ 'reset-password.email-send-down' | translate }} </div>
            </div>
            <div class="login-btn-container form-group">
              <button
                class="font-weight-bold btn btn-login">{{'reset-password.reset' | translate | uppercase}}</button>
              <div [routerLink]="['/login']"
                   class="btn btn-password-forgotten">{{'reset-password.back-to-login' | translate}}</div>
            </div>
          </div>
        </form>
      </div>
      <img class="logo" src="../../../assets/images/logo.jpg" alt="logo-explain"/>
    </div>
  </div>
</div>


