import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stats-page',
  templateUrl: './stats-page.component.html',
  styleUrls: ['./stats-page.component.scss']
})
export class StatsPageComponent implements OnInit {
  @Input() pageName: string;
  @Input() totalCount: number;
  @Input() sourceCount: number;
  constructor() {
  }

  ngOnInit(): void {
  }
}
