<div #exportButton class="main-container d-table no-select"
     [style.opacity]="isExportInProgress ? '0.31' : '1'"
     [style.pointer-events]="isExportInProgress ? 'none' : 'auto'"
     (click)="onExport()">
  <div class="d-table-cell align-middle">
    <div class="d-flex">
      <mat-icon class="export-icon fill-white" svgIcon="export-white"></mat-icon>
      <div *ngIf="!isExportInProgress" class="export-text">
        {{'export.export' | translate | uppercase}}
      </div>
      <div *ngIf="isExportInProgress" class="export-text">
        {{'export.export-in-progress' | translate | uppercase}}
      </div>
    </div>
  </div>
</div>

<ng-template #exportInProgressToast let-toast="toast" let-toastService="toastService">
  <div class="awaiting-toast-container no-select">
    <div class="left-part d-flex">
      <div class="align-middle justify-content-center">
        <div class="waiting-circle-full"></div>
      </div>
    </div>
    <div class="center-part">
      <div class="title color-primary-500 font-weight-bold">
        <span class="d-table-cell align-middle">{{'export.awaiting-export-toast-title' | translate | uppercase}}</span>
      </div>
      <div class="text font-italic font-weight-bold color-basic-800">
        <span class="d-table-cell align-middle">{{'export.awaiting-export-toast-text' | translate}}</span>
      </div>
      <div class="cancel-export color-basic-600 font-weight-bold d-table">
        <span class="d-table-cell align-middle"
              (click)="toastService.remove(toast); stopExport();">{{'export.awaiting-export-toast-cancel' | translate}}</span>
      </div>
    </div>
    <div class="right-part">
      <div class="hide-icon"
           ngbTooltip="{{'export.hide' | translate}}"
           placement="top">
        <mat-icon svgIcon="hide-600" class="icon-20 strict mr-2"
                  (click)="toastService.hide(toast,
                                             exportInProgressToast,
                                            'toast-export-in-progress toast-shape ' + ('t-e-i-p-' + document.id),
                                             document.id,
                                             lightToast)"
        >
        </mat-icon>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #lightToast let-toast="toast" let-toastService="toastService">
  <div class="mini-toast-container d-flex">
    <div class="separator"></div>
    <div class="mini-toast" (click)="toastService.unhide(toast, document.id)">
      <div class="mini-waiting-circle d-table-cell align-middle align-content-center">
        <div class="waiting-circle-mini"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #errorInExport>
  <app-toast-error
    [title]="'export.export-error-title'"
    [text]="'export.export-error-text'">
  </app-toast-error>
</ng-template>
