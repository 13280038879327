import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';

@Injectable()
export class IsLoggedInRedirectResolver {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  resolve(): void {
    const currentUser = this.authenticationService.currentUserValue;
    const goodWillToken = this.authenticationService.goodwillToken;
    if (currentUser || goodWillToken) {
      this.router.navigate(['/']).then();
    }
  }
}
