<div class="container-fluid h-100" #pdfViewContainer>
  <div class="row h-100 flex-wrap-reverse">
    <div class="left-content">
      <app-admin-doc-pdf *ngIf="adminDoc"
                         [adminDoc]="adminDoc"
                         [adminDocMatchStems$]="adminDocMatchStems$"
                         [pdfView]="pdfView"
                         [time]="time"
                         [guestView]="true"></app-admin-doc-pdf>
    </div>
    <div *ngIf="adminDoc" class="info-section">
      <div class="info-title text-uppercase">{{'document.about-the-document' | translate}}</div>
      <app-metadata
        [documentType]="'admin_doc'"
        [website]="adminDoc.website"
        [publicationDate]="adminDoc.publicationDate"
        [displayedPublicationType]="adminDoc.displayedPublicationType">
      </app-metadata>
      <app-welcome-panel [shareToken]="shareToken"></app-welcome-panel>
    </div>
  </div>
</div>
