export enum EventTypeName {
  IMPACTER_SEARCH = 'impacter-search',
  IMPACTER_OPENING = 'impacter-opening',
  DOCUMENT_OPENING_FROM_ALERT = 'document-opening-from-alert',
  DOCUMENT_OPENING_FROM_SHARE = 'document-opening-from-share',
  DOCUMENT_OPENING_FROM_WEB_APP = 'document-opening-from-web-app',
  DOCUMENT_OPENING_FROM_EXPORT = 'document-opening-from-export',
  IMPACTER_ARTICLE_SEARCH = 'impacter-article-search',
  ADMIN_DOC_EXPORT_TRIGGERED = 'admin-doc-export-triggered',
  ADMIN_DOC_EXPORT_DOWNLOADED = 'admin-doc-export-downloaded',
  IMPACTER_OPENING_FROM_ARTICLE = 'impacter-opening-from-article',
  ARTICLE_OPENING_FROM_IMPACTER = 'article-opening-from-impacter'
}

export class UserTrackerBody {
  event_timestamp: string;
  user_id: number;
  group_account_id: number;
  event_type: string;
  properties: TrackProperties;
  parameters: TrackParameters;
  request_headers?: any;
  request_body: any;
  response_headers?: any;
  response_body: any;

  constructor(eventTypeName: string) {
    // Common values
    this.event_timestamp = this.getEventTimestamp();
    this.user_id = parseInt(this.getUserId(), 10);
    this.group_account_id = parseInt(this.getGroupAccountId(), 10);
    this.event_type = eventTypeName;

    // Values to precise
    this.properties = new TrackProperties();
    this.parameters = new TrackParameters();
    this.request_headers = {};
    this.request_body = {};
    this.response_headers = {};
    this.response_body = {};
  }

  getEventTimestamp() {
    return (new Date()).toISOString();
  }

  getUserId() {
    return localStorage.getItem('user_id');
  }

  getGroupAccountId() {
    return localStorage.getItem('group_account_id');
  }
}

export class TrackProperties {
  consumed_endpoint_type: string;
  consumed_endpoint_url: string;
  current_frontend_url: string;
  ip: string;
  user_details: TrackUserDetails;

  constructor(consumed_endpoint_type?: string,
              consumed_endpoint_url?: string,
              current_frontend_url?: string,
              ip?: string,
              user_details?: TrackUserDetails) {
    this.consumed_endpoint_type = consumed_endpoint_type;
    this.consumed_endpoint_url = consumed_endpoint_url;
    this.current_frontend_url = current_frontend_url;
    this.ip = ip;
    this.user_details = user_details;
  }
}

export class TrackUserDetails {
  api_token: string;
  email: string;

  constructor(api_token: string, email: string) {
    this.api_token = api_token;
    this.email = email;
  }
}

export class TrackParameters {
  /** All params must be optional */
  search_territory_uid?: string;
  impacter_id?: number;
  exemple?: string; // legacy
  rank?: number;
  article_count?: number;
  admin_doc_count?: number;
  document_type?: string;
  digest_id?: number;
  collective_order_id?: number;

  constructor() {
  }
}
