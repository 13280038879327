import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { StepModel } from '../../core/step/step.model';
import { DigestSettings } from '../../models/digest-settings';

const STEPS = [
  {stepIndex: 0, stepIndexLabel: 1, stepName: 'wizard.perimeter', isComplete: false},
  {stepIndex: 1, stepIndexLabel: 2, stepName: 'wizard.topics-competitors', isComplete: false},
  {stepIndex: 2, stepIndexLabel: 3, stepName: 'wizard.sources', isComplete: false},
  {stepIndex: 3, stepIndexLabel: 4, stepName: 'wizard.send-parameters', isComplete: false},
  {stepIndex: 4, stepIndexLabel: 5, stepName: 'wizard.overview', isComplete: false}
  ];

export enum FunnelContextEnum {
  CREATION = 'creation',
  MODIFICATION = 'modification',
  DUPLICATION = 'duplication'
}

export interface DbStyleList {
  canNext: boolean[];
  isEnabledStyle: boolean[];
  isSuccessStyle: boolean[];
  isErroredStyle: boolean[];
}
export class StyleLists {
  canNext: boolean[];
  isErroredStyle: boolean[];
  isEnabledStyle: boolean[];
  isSuccessStyle: boolean[];
  constructor() {
    this.canNext = [];
    this.isErroredStyle = [];
    this.isEnabledStyle = [];
    this.isSuccessStyle = [];
  }

}

@Injectable({
  providedIn: 'root'
})
export class StepsService {
  steps$: BehaviorSubject<StepModel[]>;
  currentStep$: BehaviorSubject<StepModel>;
  arePreviewsRetrieved = false;
  arePerimetersRetrieved = false;
  arePerimetersRetrieved$ = new ReplaySubject(1);
  areTopicsRetrieved = false;
  areTopicsRetrieved$ = new ReplaySubject(1);
  steps: StepModel[];
  funnelContext: FunnelContextEnum; // Could be used for custom canBeClicked list rules.
  styleLists: StyleLists;
  styleListsReplaySubject = new ReplaySubject<StyleLists>(1);
  duplicatedFromAlertId: number = null;
  duplicatedFromAlertSettings: DigestSettings = null;

  constructor() {
    this.funnelContext = FunnelContextEnum.CREATION;
    this.initServiceObjects();
  }

  initServiceObjects() {
    this.duplicatedFromAlertId = null;
    this.duplicatedFromAlertSettings = null;
    this.steps$ = new BehaviorSubject<StepModel[]>(STEPS);
    this.currentStep$ = new BehaviorSubject<StepModel>(STEPS[0]);
    this.styleLists = new StyleLists();
  }

  setCurrentStep(step: StepModel): void {
    this.currentStep$.next(step);
  }

  getCurrentStep$(): Observable<StepModel> {
    return this.currentStep$.asObservable();
  }

  getSteps$(): Observable<StepModel[]> {
    return this.steps$.asObservable();
  }

  moveToNextStep(): void {
    const index = this.currentStep$.value.stepIndex;

    if (index < (this.steps$.value.length - 1)) {
      this.currentStep$.next(this.steps$.value[index + 1]);
      this.updateStyleLists();
    }

  }

  moveToPrevStep(): void {
    const index = this.currentStep$.value.stepIndex;

    if (index > 0) {
      this.currentStep$.next(this.steps$.value[index - 1]);
      this.updateStyleLists();
    }
    this.arePreviewsRetrieved = false;
  }

  isLastStep(): boolean {
    return this.currentStep$.value.stepIndex === (this.steps$.value.length - 1);
  }

  isFirstStep(): boolean {
    return this.currentStep$.value.stepIndex === 0;
  }

  resetSteps() {
    this.initServiceObjects();

    this.arePerimetersRetrieved = false;
    this.arePerimetersRetrieved$.next(false);
    this.updateTopicsRetrievingState(false);
  }

  updateStepCompletionState(stepIndex: number, isComplete: boolean) {
    this.steps$.value[stepIndex].isComplete = isComplete;
    this.steps$.next(this.steps$.value);
    // update of completion lists
    this.updateStyleLists();
  }

  updateStyleLists() {
    // update of completion lists
    this.updateCanNextList();
    this.updateIsEnabledStyleList();
    this.updateIsSuccessStyleList();
    this.updateIsErroredStyleList();
    this.styleListsReplaySubject.next(this.styleLists);
  }

  updateCanNextList() {
    this.steps$.value?.forEach((step, index) => {
      this.styleLists.canNext[0] = this.steps$.value[0].isComplete; // for perimeter, the question is step is complete or not.
      if (index > 0) {
        this.styleLists.canNext[index]
          = this.steps$.value?.slice(0, index).reduce((accumulator, elem) => accumulator && elem.isComplete, true);
      }
    });
  }

  updateIsEnabledStyleList() {
    this.steps$.value?.forEach((step, index) => {
      if (index > 0) {
        this.styleLists.isEnabledStyle[index]
          = this.steps$.value?.slice(0, index).reduce((accumulator, elem) => accumulator && elem.isComplete, true);
      }
    });
  }

  updateIsSuccessStyleList() {
    this.steps$.value?.forEach((step, index) => {
      this.styleLists.isSuccessStyle[index] = step.isComplete;
    });
    this.styleLists.isSuccessStyle[4] = this.styleLists.isSuccessStyle.slice(0, 3)
      .reduce((acc, value) => acc && value, true) && this.steps$.value[4].isComplete;
  }

  updateIsErroredStyleList() {
    if (!this.arePerimetersRetrieved) {
      return; // break method if perimeters not retrieved.
    }
    if (this.funnelContext !== FunnelContextEnum.CREATION) {
      this.steps$.value?.forEach((step, index) => {
        // If a step is incomplete.
        if (!step.isComplete) {
          // and if there is a step after it that is complete.
          // then this step is errored.
          this.styleLists.isErroredStyle[index] =
            this.steps$.value?.filter((elm) => elm.isComplete && (elm.stepIndex > index)).length > 0;
        }
      });
    } else {
      this.styleLists.isErroredStyle = new Array<boolean>(STEPS.length).fill(false);
    }
  }

  /** Method to avoid displaying step errored style when perimeters are not retrieved yet. */
  updatePerimetersRetrievingState(arePerimetersRetrieved: boolean) {
    this.arePerimetersRetrieved = arePerimetersRetrieved;
    this.arePerimetersRetrieved$.next(arePerimetersRetrieved);
    this.updateIsErroredStyleList();
    this.styleListsReplaySubject.next(this.styleLists);
  }

  /** Method to avoid displaying step errored style when perimeters are not retrieved yet. */
  updateTopicsRetrievingState(areTopicsRetrieved: boolean) {
    this.areTopicsRetrieved = areTopicsRetrieved;
    this.areTopicsRetrieved$.next(areTopicsRetrieved);
  }
}
