import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loader: NodeListOf<Element>;
  backdrop: NodeListOf<Element>;

  constructor() {
  }

  displayLoader(loader: boolean) {
    if (!loader) {
      return;
    }
    this.loader = document.querySelectorAll('.loader:not(.not-synchronized)');
    this.backdrop = document.querySelectorAll('.backdrop');
    this.loader.forEach(e => {
      e.classList.add('center');
      e.classList.add('show');
    });
    this.backdrop.forEach(e => e.classList.add('show'));
  }

  hideLoader(loader: boolean) {
    if (!loader) {
      return;
    }
    setTimeout(() => {
      if (this.loader) {
        this.loader.forEach(e => {
          e.classList.remove('show');
          e.classList.remove('center');
        });
      }
      if (this.backdrop) {
        this.backdrop.forEach(e => e.classList.remove('show'));
      }
    }, 500);
  }
}
