<div class="territories">
  <div class="text-left territory d-flex" *ngFor="let territory of territories">
    <div class="align-self-center">
      <span class="territory-name">{{territory.name}}</span>
      <span class="territory-code">({{territory.code}})</span>
    </div>
    <ng-template #tipContent>
      <div class="font-weight-bold pb-3">{{'article.tooltip-title' | translate:({alias: territory['alias']})}}</div>
      <div
        *ngIf="territory['other_aliased_territories'].length <= 5">{{'article.tooltip-sub-title' | translate}}</div>
      <div *ngIf="territory['other_aliased_territories'].length > 5">
        {{'article.tooltip-sub-title-with-length' | translate:({length: territory['other_aliased_territories'].length})}}
      </div>
      <ul>
        <ng-container *ngFor="let ter of territory['other_aliased_territories']; let i = index">
          <li *ngIf="territory['other_aliased_territories'].length <= 5 || i < 3">
            {{ter.name}} ({{ter.code}})
          </li>
        </ng-container>
      </ul>
    </ng-template>
    <img class="align-self-center"
         *ngIf="territory['is_ambiguous']"
         [ngbTooltip]="tipContent"
         container="body"
         placement="bottom-right" tooltipClass="custom-tooltip-alias"
         src="../../../../assets/images/icon-alert-circle.svg"
         alt="alert-circle"/>
  </div>
</div>
