<div class="container-fluid h-100" #pdfViewContainer>
  <div class="row h-100 flex-wrap-reverse">
    <div class="left-content">
      <app-admin-doc-pdf *ngIf="adminDoc"
                         [adminDoc]="adminDoc"
                         [pdfView]="pdfView"
                         [adminDocMatchStems$]="adminDocMatchStems$"
                         [time]="time"></app-admin-doc-pdf>
    </div>
    <div *ngIf="adminDoc" class="info-section">
      <div class="info-title text-uppercase">{{'document.about-the-document' | translate}}</div>
          <app-metadata
            [documentType]="'admin_doc'"
            [website]="adminDoc.website"
            [publicationDate]="adminDoc.publicationDate"
            [displayedPublicationType]="adminDoc.displayedPublicationType"
          ></app-metadata>
      <app-saving-panel [document]="{
                          id: adminDoc.id,
                          type: type,
                          title: adminDoc.title,
                          snippet: adminDoc.snippet,
                          bookmarkPaths: adminDoc.bookmarkPaths,
                          matchStems: adminDoc.matchStems
                        }"
                        (bookmarkPathsUpdated)="bookmarkPathsUpdated(adminDoc.id, $event)">
      </app-saving-panel>
      <div class="d-flex">
        <app-sharing-panel
          class="sharing-panel"
          [document]="documentToBeShared"
          [withExportPanel]="true"
          (isPanelOpen)="sharingPanelChangeExpandedState($event)"
        ></app-sharing-panel>
        <app-export-panel [hidden]="isSharingPanelOpen"
                          [document]="adminDoc"
                          [documentMetaData]="documentMetaData"
                          [routeParams]="adminDocRouteParams">
        </app-export-panel>
      </div>
    </div>
  </div>
</div>
