<div class="modal-header">
  <h4 class="modal-title--delete"
      id="modal-basic-title">{{translationFileSection + '.modal-delete-item-title' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true"><img src="assets/images/icon-close-circle.svg" alt="icon-close-circle"></span>
  </button>
</div>
<div class="modal-body--delete">
  <span class="font-weight-bold">{{translationFileSection + '.modal-delete-item-sub-title' | translate}}</span>
  <span class="">{{translationFileSection + '.modal-delete-item-content' | translate}}</span>
</div>
<div class="modal-footer modal-delete">
  <button type="button" class="cancel font-weight-bold"
          (click)="closeModal()">{{translationFileSection + '.modal-delete-cancellation' |
    translate}}</button>
  <button type="button" class="btn confirm-delete d-flex" (click)="onDelete()">
    <mat-icon svgIcon="trash-icon"></mat-icon>
    <span
      class="font-weight-bold align-self-center">{{translationFileSection + '.modal-delete-validation' | translate | uppercase}}</span>
  </button>
</div>
