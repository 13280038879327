import { TerritoryForArticle } from './territory/territory-utils';
import { DbDocumentEntity, DocumentEntity } from './document-entity';
import { DbImpacterForArticle, ImpacterForArticle } from './impacter/impacter';

export interface DbArticle extends DbDocumentEntity {
  id: string;
  data_provider: string;
  data_provider_document_id: string;
  source: string;
  territories: Array<TerritoryForArticle>;
  impacters: Array<DbImpacterForArticle>;
  html?: {
    html_key: string;
    nb_images: number;
  };
  reference_department: string;
}

export class Article extends DocumentEntity {

  private _id: string;
  private _dataProvider: string;
  private _dataProviderDocumentId: string;
  private _source: string;
  private _territories: Array<TerritoryForArticle>;
  private readonly _impacters: Array<ImpacterForArticle>;
  private _html: {
    htmlKey: string;
    nbImages: number;
  };
  private readonly _referenceDepartment: string;

  constructor(dbArticle: DbArticle) {
    super(dbArticle as DbDocumentEntity);
    this._id = dbArticle.id;
    this._dataProvider = dbArticle.data_provider;
    this._dataProviderDocumentId = dbArticle.data_provider_document_id;
    this._source = dbArticle.source;
    this._territories = dbArticle.territories;
    this._impacters = dbArticle.impacters?.map((x) => new ImpacterForArticle(
      x,
      `/search/${dbArticle.reference_department}/impacter/${x.id}`
    )) ?? []; // In case the API doesn't return impacters, should be useless after API update.
    this._html = dbArticle.html ? {
      htmlKey: dbArticle.html.html_key,
      nbImages: dbArticle.html.nb_images
    } : null;
    this._referenceDepartment = dbArticle.reference_department;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get dataProvider(): string {
    return this._dataProvider;
  }

  set dataProvider(value: string) {
    this._dataProvider = value;
  }

  get dataProviderDocumentId(): string {
    return this._dataProviderDocumentId;
  }

  set dataProviderDocumentId(value: string) {
    this._dataProviderDocumentId = value;
  }

  get source(): string {
    return this._source;
  }

  set source(value: string) {
    this._source = value;
  }

  get territories(): Array<TerritoryForArticle> {
    return this._territories;
  }

  set territories(value: Array<TerritoryForArticle>) {
    this._territories = value;
  }

  get impacters(): Array<ImpacterForArticle> {
    return this._impacters;
  }

  get html(): { htmlKey: string; nbImages: number } {
    return this._html;
  }

  set html(value: { htmlKey: string; nbImages: number }) {
    this._html = value;
  }

  get referenceDepartment() {
    return this._referenceDepartment;
  }
}
