import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AlertOverviewParams, AlertSettingService } from '../../shared/services/alert-setting.service';
import { ApiService } from '../../shared/services/api/api.service';
import { AlertDocumentPreview, AlertDocumentPreviewPeriod } from '../../models/alert-document-preview';
import { StepsService } from '../../shared/services/steps.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-overview-step-template',
  templateUrl: './overview-step-template.component.html',
  styleUrls: ['./overview-step-template.component.scss']
})
export class OverviewStepTemplateComponent implements OnInit, AfterViewInit, OnDestroy {
  // Note that 138px is the height of the thin abstract overview panel.
  @Input() isScrollDown138px = false;
  @Input() previewsListScrollValue = 0;
  @Output() nbPreviewedDocuments = new EventEmitter<number>();
  @Output() overviewStepIsDestroyed = new EventEmitter<null>();

  private params: AlertOverviewParams;
  public alertDocumentPreviewPeriod: AlertDocumentPreviewPeriod;
  public alertDocumentPreviews: Array<AlertDocumentPreview>;
  public totalCount: number;
  public displayError500Panels = false;

  constructor(private alertSettingService: AlertSettingService,
              private apiService: ApiService,
              private stepsService: StepsService) {
    this.params = new AlertOverviewParams();
  }

  /** Initializing params and get documents preview */
  ngOnInit(): void {
    this.params = this.alertSettingService.getOverviewParams();
    this.getAlertDocumentPreviews();
  }

  ngAfterViewInit() {
    this.stepsService.updateStepCompletionState(4, false);
  }

  ngOnDestroy() {
    this.overviewStepIsDestroyed.emit(null);
  }

  get alertName(): string {
    return this.alertSettingService?.name;
  }

  getAlertDocumentPreviews(): void {
    this.stepsService.arePreviewsRetrieved = false;
    this.stepsService.updateStepCompletionState(4, false);

    this.apiService.alert.getAlertDocumentPreviews(this.params)
      .pipe(first())
      .subscribe(
        (response) => {
          this.stepsService.arePreviewsRetrieved = true;
          this.alertDocumentPreviews = [];
          this.alertDocumentPreviewPeriod = response.alertPreviewPeriod;
          this.nbPreviewedDocuments.emit(this.alertDocumentPreviewPeriod.nbArticle + this.alertDocumentPreviewPeriod.nbAdminDoc);
          this.alertDocumentPreviews = response.alertDocumentPreviews;
          this.totalCount = response.totalCount;
          this.stepsService.updateStepCompletionState(4, true);
        },
        () => {
          // Manage data in case of error.
          this.displayError500Panels = true;
          // We allow completion of alert creation even in case of broken preview (timeout)
          this.stepsService.updateStepCompletionState(4, true);
          this.stepsService.arePreviewsRetrieved = true;
          // Emit random value to let previous/creation buttons displayed on header.
          this.nbPreviewedDocuments.emit(null);
        });
  }

  onPrevPreview(): void {
    if (this.params.alert_offset > 0) {
      this.params.alert_offset--;
      this.params.offset = 0;
      this.getAlertDocumentPreviews();
    }
  }

  onNextPreview(): void {
    this.params.alert_offset++;
    this.params.offset = 0;
    this.getAlertDocumentPreviews();
  }

  get Params(): AlertOverviewParams {
    return this.params;
  }

  get fetchingComplete() {
    return this.stepsService.arePreviewsRetrieved;
  }

  getPartialUpLoaderToValueInPx() {
    return 16 + this.previewsListScrollValue;
  }
}
