import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, pairwise, takeUntil } from 'rxjs/operators';
import { ActivationStart, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { UpdateModalService, UpdateModalTypeEnum } from './shared/services/update-modal.service';
import { UrlService } from './shared/services/url.service';
import { environment } from '../environments/environment';
import { LocalStorageService } from './shared/services/local-storage.service';
import { StoredCollectionNames } from './models/stored-collection-names';
import { DisplayNpsModalService } from './shared/services/display-nps-modal.service';
import { Subject } from 'rxjs';
import { IpService } from './shared/services/ip.service';
import { ApiGwStatisticsService } from './shared/services/api/gw-statistics/api-gw-statistics.service';
import { ZendeskWebWidgetService } from './shared/services/zendesk/zendesk-web-widget.service';

const MEPConfiguration = require('src/assets/mep-configuration/version.json');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  private readonly destroy$ = new Subject();
  canDisplayMenu: boolean;
  displayGuestMenu = false;
  isNavigator = true;

  constructor(private translate: TranslateService,
              private router: Router,
              private updateModalService: UpdateModalService,
              private localStorageService: LocalStorageService,
              private urlService: UrlService,
              private displayNpsModalService: DisplayNpsModalService,
              private ipService: IpService,
              private apiGwStatisticsService: ApiGwStatisticsService,
              private zendeskWebWidgetService: ZendeskWebWidgetService
  ) {

    this.translate.setDefaultLang('fr');

    this.router.events.pipe(
      filter(event => event instanceof ActivationStart)
    ).subscribe(event => {
      this.canDisplayMenu = true;
      if (undefined !== event['snapshot'].data.displayNavBar) {
        this.canDisplayMenu = event['snapshot'].data.displayNavBar;
      }
      if (undefined !== event['snapshot'].data.guestDisplay) {
        this.displayGuestMenu = event['snapshot'].data.guestDisplay;
      }
    });
    if (this.getBrowserName() != null) {
      this.isNavigator = (this.getBrowserName() === 'chrome') ||
        (this.getBrowserName() === 'firefox') || (this.getBrowserName() === 'edge');
    }

    /** settings previous and current urls inside UrlService */
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.urlService.PreviousUrl = events[0].urlAfterRedirects;
        this.urlService.CurrentUrl = events[1].urlAfterRedirects;
      });

    /** Part needed for displaying components depending on navigation changes.
     * e.g: zendeskWebWidget should not be shown on login page,
     * this code allow to display the widget after user successfully log in. **/
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
      this.displayNpsModalService.displayNpsModalIfNeeded().then();
      this.zendeskWebWidgetService.handleDisplay();
    });

    /** Part for update modal displaying */
    if (environment.withServiceWorker) {
      this.updateModalService.setUpdateDetectionTimer(5000); // Second modal is displayed 2 minutes after first
    }

    /** Part for sanitizing localStorage - stored_contexts && stored_match_stems */
    this.localStorageService.sanitizeLocalStorage(StoredCollectionNames.STORED_CONTEXTS);
    this.localStorageService.sanitizeLocalStorage(StoredCollectionNames.STORED_MATCH_STEMS);

    /** Part for resolving user's IP and storing it into IpService */
    this.ipService.resolveUserIp();

    /** Part settings Goodwill Statistics (nb documents, articles, webstites, etc...) */
    this.apiGwStatisticsService.setGoodwillStatistics();

    /** Part settings user's Account Info (mainly territories) */
    this.apiGwStatisticsService.setAccountInfos();

    /** ___ Management of new feature explained modal ___
     * This new modale can only be displayed if legacy Update modale is set to be displayed too */
    if (localStorage.getItem('api_token') == null) { // user NEED to be connected
      return;
    } else {
      if (
        MEPConfiguration.display_update_modal && // display M1
        MEPConfiguration.display_new_feature_explained_modal && // display M2
        !(localStorage.getItem(MEPConfiguration.new_feature_localstorage_item_name) === 'true') // never seen the modal M2
      ) {
        console.log('new feature modale displayed - it should occur after classic update modale display.');
        this.updateModalService.openUpdateModal(true, UpdateModalTypeEnum.NEW_FEATURE_EXPLAINED);
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edg') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any> window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

}
