<div class="block-container position-relative block-{{blockType}}" [ngSwitch]="blockType">
  <!--pour afficher le titre du block-->
  <div class="block-name position-absolute d-flex">
    <ng-container *ngSwitchCase="'simple_list'">
      <div class="align-self-center icon-block icon-block--list"></div>
      <div class="align-self-center">{{'wizard.list-simple' | translate}}</div>
    </ng-container>
    <ng-container *ngSwitchCase="'nearby_terms'">
      <div class="align-self-center icon-block icon-block--swap"></div>
      <div class="align-self-center">{{'wizard.term-near' | translate}}</div>
    </ng-container>
    <ng-container *ngSwitchCase="'expressions_combinations'">
      <div class="align-self-center icon-block icon-block--shuffle"></div>
      <div class="align-self-center">{{'wizard.list-combination' | translate}}</div>
    </ng-container>
    <ng-container *ngSwitchCase="'terms_to_exclude'">
      <div class="align-self-center icon-block icon-block--list"></div>
      <div class="align-self-center">{{'wizard.block-exclude' | translate}}</div>
    </ng-container>
  </div>

  <!-- le boutton supprimer-->
  <button *ngIf="canDelete" class="btn-delete-block" (click)="openModalDelete(deleteModal)">
    <div class="icon-block icon-block--trash align-self-center"></div>
  </button>

  <!-- block un des termes-->
  <div class="block-body container-fluid" [ngClass]="{'with-footer': requestList[0]?.length}">
    <div class="row">
      <div class="col-4">
        <app-topic-term
          [termType]="blockType"
          [blockIndex]="blockIndex"
          [columnIndex]="0"
          [terms]="getTerm(0)"
          [blockActive]="isBlockActive()"
          [complexityLimiterAchieved]="topicComplexity >= topicComplexityMaxValue"
          (onTermsChangeGenerated)="onTermsChangeGenerated($event,0)"
          (onTermsChange)="onTermsChange($event)">
        </app-topic-term>
      </div>
      <div class="col-4 position-relative" *ngIf="!isForOneColumn()">
        <div class="align-self-center d-flex position-absolute h-100 inter-block">
          <div *ngIf="blockType == typeBlock.combinations" class="align-self-center icon-block icon-block--shuffle">
          </div>
          <div *ngIf="blockType == typeBlock.near" class="align-self-center text-center nearby-label"
            [innerHTML]="'content.near-by-label' | translate"></div>
        </div>
        <app-topic-term [termType]="blockType"
                        [blockIndex]="blockIndex"
                        [columnIndex]="1"
                        [terms]="getTerm(1)"
                        [blockActive]="isBlockActive()"
                        [complexityLimiterAchieved]="topicComplexity >= topicComplexityMaxValue"
                        (onTermsChangeGenerated)="onTermsChangeGenerated($event,1)"
                        (onTermsChange)="onTermsChange($event)">
        </app-topic-term>
      </div>
      <div class="col-4 position-relative" *ngIf="!isForOneColumn()">
        <div class="align-self-center d-flex position-absolute h-100 inter-block">
          <div *ngIf="blockType == typeBlock.combinations" class="align-self-center icon-block icon-block--shuffle">
          </div>
          <div *ngIf="blockType == typeBlock.near" class="align-self-center text-center nearby-label"
            [innerHTML]="'content.near-by-label' | translate"></div>
        </div>
        <app-topic-term [termType]="blockType"
                        [blockIndex]="blockIndex"
                        [columnIndex]="2"
                        [terms]="getTerm(2)"
                        [optional]="true"
                        [blockActive]="isBlockActive()"
                        [complexityLimiterAchieved]="topicComplexity >= topicComplexityMaxValue"
                        (onTermsChangeGenerated)="onTermsChangeGenerated($event,2)"
                        (onTermsChange)="onTermsChange($event)">
        </app-topic-term>
      </div>
    </div>
  </div>

  <div class="block-footer" *ngIf="requestList[0]?.length">
    <div class="text-uppercase font-weight-bold footer-title">{{'wizard.request-generated' | translate}}</div>
    <div class="request-chain maxlignes" #content
      [ngClass]="{'min_height': shouldIShowlines==false, 'max_height' : shouldIShowlines==true }">
      <ng-container *ngFor="let request of requestList; let requestIndex = index">
        <span *ngIf="requestIndex > 0" class="color-basic-600 font-weight-600 d-inline-block">
          <span *ngIf="blockType != typeBlock.exclude">&nbsp;{{'content.or' | translate}}&nbsp;</span>
          <span *ngIf="blockType == typeBlock.exclude">&nbsp;,&nbsp;</span>
        </span>
        <div class="color-basic-600 font-weight-600 d-inline-block">(&nbsp;</div>
        <ng-container *ngFor="let term of request; let termIndex = index">
          <ng-container *ngIf="termIndex > 0">
            <ng-container *ngSwitchCase="'expressions_combinations'">
              <span class="d-inline-block text-uppercase color-basic-600 font-weight-600 ">&nbsp;</span>
            </ng-container>
            <ng-container *ngSwitchCase="'nearby_terms'">
              <span class="text-uppercase color-basic-600 font-weight-600">&nbsp;{{'content.near-by' |
                translate}}&nbsp;</span>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'terms_to_exclude'">
            <span class="text-uppercase color-basic-600 font-weight-600">&nbsp;{{'content.except' |
              translate}}&nbsp;</span>
          </ng-container>
          <div *ngIf="term.locked" class="icon-block icon-block--lock d-inline-block"></div>
          <!-- to display text -->
          <ng-container *ngSwitchCase="'simple_list'">
            <strong>{{term.term}}</strong>
          </ng-container>
          <ng-container *ngSwitchCase="'terms_to_exclude'">
            <strong>{{term.term}}</strong>
          </ng-container>
          <ng-container *ngSwitchCase="'expressions_combinations'">
            <strong>{{term.term}}<span *ngIf="request.length==1"> ...</span></strong>
          </ng-container>
          <ng-container *ngSwitchCase="'nearby_terms'">
            <strong>
              {{term.term}}
              <span *ngIf="request.length==1">
                <span class="text-uppercase color-basic-600 font-weight-600">&nbsp;{{'content.near-by' |
                  translate}}&nbsp;</span>
                ...
              </span>
            </strong>
          </ng-container>
        </ng-container>
        <div class="color-basic-600 font-weight-600 d-inline-block">&nbsp;)</div>
      </ng-container>
    </div>
    <div class="w-100 d-flex justify-content-end">
      <button class="seemore" *ngIf="shouldIShowMoreButton"
        (click)="showMore()">...{{'wizard.seemore'|translate}}</button>
      <button class="seemore" *ngIf="clic" (click)="showLimit()">{{'wizard.seelimit'|translate}}</button>

    </div>
  </div>

  <!-- generated list -->
  <div *ngIf="generatedTexte.length>0" class="row zone-encours nopadding d-flex align-items-center">
    <div class="col-2 h-100 nopadding">
      <div class="zone-title-encours footer-title-encours w-100 h-100 text-center">{{"wizard.encours"|translate}}</div>
    </div>
    <div class="col-8 nopadding h-100">
      <div class="block-footer2 d-flex w-100" [ngSwitch]="blockType" #gr
        [ngClass]="{'min_heightGR': showBadgeGR, 'max_heightGR' : !showBadgeGR }">
        <div *ngSwitchCase="'simple_list'">
          <div class="d-flex">
            <span *ngIf="(requestList[0]?.length>=1)"
              class="color-basic-600 font-weight-600 d-inline-block ml-2 footer-title">
              <span *ngIf="blockType != typeBlock.exclude">&nbsp;{{'content.or' | translate}}&nbsp;</span>
              <span *ngIf="blockType == typeBlock.exclude">&nbsp;,</span>
            </span>
            <div class="ml-1 footer-title">{{generatedTexte}}</div>
          </div>
        </div>
        <div *ngSwitchCase="'terms_to_exclude'">
          <div class="d-flex">
            <span *ngIf="generatedTexte" class="color-basic-600 font-weight-600 d-inline-block ml-2 footer-title">
              <span class="text-uppercase color-basic-600 font-weight-600 footer-title">&nbsp;{{'content.except' |
                translate}}&nbsp;</span>
            </span>
            <div class="ml-1 footer-title">{{generatedTexte}}</div>
          </div>
        </div>
        <div *ngSwitchCase="'expressions_combinations'" class="w-100">
          <div class="ml-1 footer-title" *ngIf="requestList_encours[0]?.length==0">
            {{generatedTexte}}
          </div>
          <div class="ml-1 footer-title" [ngClass]="{'elipse': showBadgeGR}">
            <span *ngIf="requestList[0]?.length>=1" class="color-basic-600 font-weight-600 d-inline-block">
              <span *ngIf="blockType != typeBlock.exclude">&nbsp;{{'content.or' | translate}}&nbsp;</span>
            </span>
            <ng-container *ngFor="let request of requestList_encours; let requestIndex = index">
              <span *ngIf="requestIndex > 0" class="color-basic-600 font-weight-600 d-inline-block">
                <span *ngIf="blockType != typeBlock.exclude">&nbsp;{{'content.or' | translate}}&nbsp;</span>
                <span *ngIf="blockType == typeBlock.exclude">&nbsp;,</span>
              </span>
              <div *ngIf="request.length > 1" class="color-basic-600 font-weight-600 d-inline-block">(&nbsp;</div>
              <ng-container *ngFor="let term of request; let termIndex = index">
                <ng-container *ngIf="termIndex > 0">
                  <ng-container *ngSwitchCase="'expressions_combinations'">
                    <span class="d-inline-block text-uppercase color-basic-600 font-weight-600 ">&nbsp;</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'nearby_terms'">
                    <span class="text-uppercase color-basic-600 font-weight-600">&nbsp;{{'content.near-by' |
                      translate}}&nbsp;</span>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'terms_to_exclude'">
                  <span class="text-uppercase color-basic-600 font-weight-600">&nbsp;{{'content.except' |
                    translate}}&nbsp;</span>
                </ng-container>
                <div *ngIf="term.locked" class="icon-block icon-block--lock d-inline-block"></div>
                <strong>{{term.term}}</strong>
              </ng-container>
              <div *ngIf="request.length > 1" class="color-basic-600 font-weight-600 d-inline-block">&nbsp;)</div>
            </ng-container>
          </div>
        </div>
        <div *ngSwitchCase="'nearby_terms'" class="w-100">
          <div class="ml-1 footer-title" *ngIf="requestList_encours[0]?.length==0">
            {{generatedTexte}}
          </div>
          <div class="ml-1 footer-title" [ngClass]="{'elipse': showBadgeGR}">
            <span *ngIf="requestList[0]?.length>=1" class="color-basic-600 font-weight-600 d-inline-block">
              <span *ngIf="blockType != typeBlock.exclude">&nbsp;{{'content.or' | translate}}&nbsp;</span>
            </span>
            <ng-container *ngFor="let request of requestList_encours; let requestIndex = index">
              <span *ngIf="requestIndex > 0" class="color-basic-600 font-weight-600 d-inline-block">
                <span *ngIf="blockType != typeBlock.exclude">&nbsp;{{'content.or' | translate}}&nbsp;</span>
                <span *ngIf="blockType == typeBlock.exclude">&nbsp;,</span>
              </span>
              <div *ngIf="request.length > 1" class="color-basic-600 font-weight-600 d-inline-block">(&nbsp;</div>
              <ng-container *ngFor="let term of request; let termIndex = index">
                <ng-container *ngIf="termIndex > 0">
                  <ng-container *ngSwitchCase="'expressions_combinations'">
                    <span class="d-inline-block text-uppercase color-basic-600 font-weight-600 ">&nbsp;</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'nearby_terms'">
                    <span class="text-uppercase color-basic-600 font-weight-600">&nbsp;{{'content.near-by' |
                      translate}}&nbsp;</span>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'terms_to_exclude'">
                  <span class="text-uppercase color-basic-600 font-weight-600">&nbsp;{{'content.except' |
                    translate}}&nbsp;</span>
                </ng-container>
                <div *ngIf="term.locked" class="icon-block icon-block--lock d-inline-block"></div>
                <strong>{{term.term}}</strong>
              </ng-container>
              <div *ngIf="request.length > 1" class="color-basic-600 font-weight-600 d-inline-block">&nbsp;)</div>
            </ng-container>
          </div>
        </div>
        <!---->
      </div>
    </div>
    <div class="col-2 nopadding d-flex justify-content-end">
      <div *ngIf="showBadgeGR" class="badge mr-1 ml-1 w-100">
        <span *ngIf="requestList_encours[0].length>=1">
          {{requestList_encours.length}}
          {{'wizard.autrescombinaisons'|translate}}
        </span>
        <span *ngIf="requestList_encours[0].length==0">
          {{'wizard.autrescombinaisons'|translate}}
        </span>
      </div>
    </div>
  </div>


</div>

<ng-template #deleteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'content.modal-delete-block-title' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true"><img src="assets/images/icon-close-circle.svg" alt="icon-close"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="font-weight-bold message">{{'content.modal-block-body-title' | translate}}</div>
  </div>
  <div class="modal-footer">
    <button type="button" class="cancel font-weight-bold" (click)="modal.close()">{{'alert.cancel' |
      translate}}</button>
    <button type="button" class="btn confirm-delete d-flex" (click)="onDeleteBlock()">
      <div class="icon icon--trash align-self-center"></div>
      <div class="text-uppercase font-weight-bold align-self-center">{{'button.delete-this-block' | translate}}</div>
    </button>
  </div>
</ng-template>
