<div class="label-occurrence align-self-center" *ngIf="showOccurrences">
  {{nbOccurrences}} {{ (nbOccurrences > 1 ? 'content.occurrences':'content.occurrence') | translate}}
</div>
<div *ngIf="bookmarkPaths?.length > 0" class="label-folder d-flex align-items-center">
  <ng-container *ngIf="displayBookmarkCreationDate">
    <span class="creation-date">{{'folder.added-at' | translate}} {{bookmarkPaths | bookmarkCreatedAt: folderId | date:'dd MMMM yyyy':'':'fr'}}</span>
  </ng-container>
  <div class="folder-icon-container">
    <mat-icon class="folder-icon" svgIcon="saved-folder-icon-green"></mat-icon>
  </div>
</div>
