<div class="header sticky-top"
     [ngClass]="{
                  'search-view': showContent && !document && !impacter,
                  'impacter-view': !!impacter && !document,
                  'folder-view': folderView,
                  'no-collapsed': !toggled,
                  'guest-display': guestDisplay
                 }">
  <app-breadcrumb [guestDisplay]="guestDisplay" [label]="label" [preLabel]="preLabel"></app-breadcrumb>
  <ng-container *ngIf="folderView">
    <div class="folder-name-part-container">
      <div class="folder-name-part d-flex">
        <div class="folder-icon-container d-table h-100">
          <img class="folder-icon d-table-cell align-middle" src="../../../assets/images/icon-folder-fill-dark.svg" alt="folder-icon">
        </div>
        <div class="folder-name-container d-table h-100">
          <div class="folder-name d-table-cell align-middle">
            {{folder?.name}}
          </div>
        </div>
        <div class="edition-container d-flex" (click)="openEditFolderModal()">
          <div class="icon-container d-table h-100">
            <mat-icon class="edit-icon d-table-cell align-middle" svgIcon="edit-icon"></mat-icon>
          </div>
          <div class="d-table h-100">
            <div class="edition d-table-cell align-middle">
              {{'folder.edit-name' | translate | titlecase}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="guestDisplay">
    <div class="guest-menu">
      <div class="logo-guest">
        <img class="logo-img" src="../../../assets/images/goodwill_GW-logo@2x.png" alt="goodwill_logo">
      </div>
      <div class="contact-div">
        <span class="contact-us">{{'login.contact-us' | translate | titlecase }}</span>
        <a class="mail-to" href="mailto: support@explain.fr">support@explain.fr</a>
      </div>
    </div>
  </ng-container>
  <div [hidden]="!!document || (!showContent && !showContentFolder)" class="header-content">
    <app-page-tab [hidden]="impacter" [territoryUid]="territoryUid"></app-page-tab>
    <div *ngIf="impacter && !document">
      <div class="document-info d-flex">
        <div class="icon icon--impacter align-self-center title-icon"></div>
        <div class="document-title impacter-name align-self-center text-uppercase">{{impacter.name}}</div>
      </div>
      <div class="positions" *ngIf="toggled">
        <div class="row">
          <div class="col-6">
            <div class="text-uppercase label">{{'content.current-functions' | translate}}</div>
          </div>
          <div class="col-6">
            <div class="text-uppercase label">{{'content.old-functions' | translate}}</div>
          </div>
        </div>
        <div class="row positions-section">
          <div class="col-6">
            <div *ngIf="!getPositions(impacter.positions, true).length" class="role">
              <span class="period">{{'content.no-current-function' | translate}}</span>
            </div>
            <div *ngFor="let position of getPositions(impacter.positions, true)" class="role">
              <span class="font-weight-bold">{{position.role}}</span>
              <span>{{position.territory.name}}</span>
              <span class="period">
              ({{'content.since' | translate}}
                {{position.startDate | date:'yyyy'}} •
                {{position.nbPositions}} {{(position.nbPositions > 1 ? 'content.positions' : 'content.position') | translate}})
            </span>
            </div>
          </div>
          <div class="col-6">
            <div *ngIf="!getPositions(impacter.positions, false).length" class="role">
              <span class="period">{{'content.no-old-function' | translate}}</span>
            </div>
            <div *ngFor="let position of getPositions(impacter.positions, false)" class="role">
              <span class="font-weight-bold">{{position.role}}</span>
              <span>{{position.territory.name}}</span>
              <span class="period">
              ({{position.startDate | date:'yyyy'}} > {{position.endDate | date:'yyyy'}} •
                {{position.nbPositions}}
                {{(position.nbPositions > 1 ? 'content.positions' : 'content.position') | translate}})</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <button (click)="toggle()" class="d-flex">
          <img *ngIf="!toggled" src="assets/images/icon-chevron-down.svg" class="align-self-center" alt="icon-chevron-down"/>
          <img *ngIf="toggled" src="assets/images/icon-chevron-up.svg" class="align-self-center" alt="icon-chevron-up"/>
          <div class="align-self-center">
            <ng-container *ngIf="toggled">{{'content.hide-functions' | translate}}</ng-container>
            <ng-container *ngIf="!toggled">{{'content.show-functions' | translate}}</ng-container>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
