import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { BookmarkEntityType, OrderByBookmarkFolder } from '../../../models/bookmark-folder';
import { MatExpansionPanel } from '@angular/material/expansion';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCreationComponent } from '../../modal-creation/modal-creation.component';
import { BookmarkPath } from '../../../models/bookmark-path';
import { Folder } from '../../../models/folder';
import { ActivatedRoute, Router } from '@angular/router';
import Utils from '../../../shared/utils';
import { BookmarkFolderService } from '../../../shared/services/bookmark-folder.service';
import { BookmarkDocumentService } from '../../../shared/services/bookmark-document.service';
import { ApiService } from '../../../shared/services/api/api.service';
import { SavingPanelDocumentInput } from '../../../models/saving-panel-document';

@Component({
  selector: 'app-saving-panel',
  templateUrl: './saving-panel.component.html',
  styleUrls: ['./saving-panel.component.scss']
})
export class SavingPanelComponent implements OnInit, OnChanges {
  databaseBookmarkPaths: Array<BookmarkPath> = null;
  newBookmarkPaths: Array<BookmarkPath> = null;
  folderList: Array<Folder> = null;
  folderId: number = null;

  @Input() document: SavingPanelDocumentInput;
  @Output() bookmarkPathsUpdated: EventEmitter<Array<any>>;
  @ViewChild('savePanel') savePanel: MatExpansionPanel;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private bookmarkFolderService: BookmarkFolderService,
    private bookmarkDocumentService: BookmarkDocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private utils: Utils
  ) {
    this.bookmarkPathsUpdated = new EventEmitter<Array<any>>();
  }

  ngOnInit(): void {
    this.folderId = parseInt(this.utils.getAllParamsFromRoute(this.route)['folderId'], 10);
    this.retrieveUserBookmarks().then();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['document']) {
      this.databaseBookmarkPaths = changes['document'].currentValue['bookmarkPaths'];
      this.newBookmarkPaths = [...this.databaseBookmarkPaths];
      if (this.folderList) {
        this.initiateFolderList();
      }
    }
  }

  onClosePanel() {
    this.savePanel.close();
  }

  togglePanel() {
    this.newBookmarkPaths = [...this.databaseBookmarkPaths];
    this.initiateFolderList();
  }

  initiateFolderList() {
    if (this.document) {
      this.folderList = this.folderList.map((folder) => {
        folder.containsDocument = this.containsDocument(folder.id);
        return folder;
      });
    }
  }

  containsDocument(folderId) {
    return this.newBookmarkPaths.some((bookmark) => {
      return bookmark.bookmarkFolderId === folderId;
    });
  }

  toggleCheckbox(event, folder) {
    // Might want to do something more explicit here. It changes value in the array folderList and folderListFiltered by reference
    folder.containsDocument = event.target.checked;
    if (event.target.checked) {
      let newBookmark = this.databaseBookmarkPaths.find((bookmark) => bookmark.bookmarkFolderId === folder.id);
      if (newBookmark === undefined) {
        newBookmark = new BookmarkPath({
          'date': null,
          'entity_id': null,
          'folder': {
            'entity_id': folder.id,
            'entity_label': folder.name
          }
        });
      }
      this.newBookmarkPaths.push(newBookmark);
    } else {
      this.newBookmarkPaths = this.newBookmarkPaths.filter((bookmark) => bookmark.bookmarkFolderId !== folder.id);
    }
  }

  insertFolder(newFolder: Folder) {
    this.folderList.push(newFolder);
    this.folderList.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    this.toggleCheckbox({ target: {checked: true}}, newFolder);
  }

  openModalCreationFolder() {
    const modalRef = this.modalService.open(ModalCreationComponent,
      {ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'modal-width-419'});
    modalRef.componentInstance.translationFileSection = 'folder';
    modalRef.result.then((newFolder: Folder) => {
      this.insertFolder(newFolder);
    }, _ => {
      // error handling when dismissing the modal, nothing to do but keep it to prevent console error.
    });
  }

  async retrieveUserBookmarks() {
    try {
      const body = {
        'entity_type': 'folder',
        'limit': 1000,
        'page_index': 0,
        'order_by': OrderByBookmarkFolder.ENTITY_LABEL,
        'reverse': false
      };
      this.folderList = await this.apiService.bookmark.retrieveBookmarkFolders(body).pipe(first()).toPromise();
      this.initiateFolderList();
    } catch (e) {
      console.log(`error when retrieving user bookmarks: ${e}`);
    }
  }

  async updateFolder() {
      this.bookmarkDocumentService.pageIndex = 0;
      this.bookmarkDocumentService.currentPage = 1;
      this.bookmarkFolderService.folder = await this.getBookmarkEntityByTypeAndId(
        BookmarkEntityType.FOLDER,
        this.folderId
      );
  }

  async updateBookmarks() {
    for (const newBookmark of this.newBookmarkPaths) {
      if (!this.databaseBookmarkPaths.find((bookmark) => bookmark.bookmarkFolderId === newBookmark.bookmarkFolderId)) {
        const response = await this.createDocumentBookmark(newBookmark.bookmarkFolderId);
        this.bookmarkDocumentService.sendPostSettings();
        newBookmark.bookmarkId = response['id'];
      }
    }
    for (const databaseBookmark of this.databaseBookmarkPaths) {
      if (!this.newBookmarkPaths.find((newBookmark) => newBookmark.bookmarkFolderId === databaseBookmark.bookmarkFolderId)) {
        await this.deleteDocumentBookmark(databaseBookmark.bookmarkId);
      }
    }
    this.databaseBookmarkPaths = [...this.newBookmarkPaths];
    this.bookmarkPathsUpdated.emit(this.databaseBookmarkPaths);
    if (this.folderId) {
      await this.updateFolder();
    }
    this.savePanel.close();
  }

  /** Calling api-bookmark service to retrieve BookmarkEntity by its type and Id */
  async getBookmarkEntityByTypeAndId(entityType: BookmarkEntityType, entityId: number) {
    try {
      return await this.apiService.bookmark.getBookmarkEntityByTypeAndId(entityType, entityId)
        .pipe(first())
        .toPromise();
    } catch (e) {
      console.log(`error when getting bookmarkEntity: ${e}`);
    }
  }

  /** Calling api-bookmark service to create new Document Bookmark */
  async createDocumentBookmark(containerId) {
    try {
      return await this.apiService.bookmark.createBookmarkDocument(this.document, containerId)
        .pipe(first())
        .toPromise();
    } catch (e) {
      console.log(`error when creating document bookmark: ${e}`);
    }
  }

  /** Calling api-bookmark service to delete a Document Bookmark */
  async deleteDocumentBookmark(entityId) {
    try {
      await this.apiService.bookmark.deleteBookmarkEntity(entityId, this.document.type as unknown as BookmarkEntityType)
        .pipe(first())
        .toPromise();
      // Update folder's bookmarkDocuments.
      this.bookmarkDocumentService.sendPostSettings();
    } catch (e) {
      console.log(`error when deleting document bookmark: ${e}`);
    }
  }
}
