import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HeadersService } from '../headers/headers.service';
import { map } from 'rxjs/operators';
import { DbTopic, Topic } from '../../../../models/topic';
import { Item } from '../../../../models/generic/item';

@Injectable({
  providedIn: 'root'
})
export class ApiTopicService {

  constructor(private http: HttpClient,
              private headersService: HeadersService) { }

  /**
   * GET method to retrieve info of a Topic with its id.
   */
  getTopic(topicId: string): Observable<Topic> {
    return this.http
      .get(
        `${environment.backV2EndUrl}topics/topic?id=${topicId}`,
        this.headersService.httpHeaders
      )
      .pipe(
        map((res: DbTopic) => {
          return new Topic(res);
        }));
  }

  /**
   * PUT method to create a topic
   * @param topic infos of the topic
   * @param token x-api-token. This method can be used from CSM or gw_front.
   */
  insertTopic(topic: DbTopic, token?: string): Observable<Item> {
    const headers = this.headersService.getHeadersForAdminRequest(token);
    return this.http
      .put(
        `${environment.backV2EndUrl}topics/topic`,
        topic,
        headers
      )
      .pipe(
        map((itemCreated: Item) => {
          return itemCreated;
        }));
  }

  /**
   * PATCH method to update a topic
   * @param topic infos of the topic
   * @param token x-api-token. This method can be used from CSM or gw_front.
   */
  updateTopic(topic: DbTopic, token?: string): Observable<Item> {
    const headers = this.headersService.getHeadersForAdminRequest(token);
    return this.http
      .patch(
        `${environment.backV2EndUrl}topic`,
        topic,
        headers
      )
      .pipe(
        map((itemUpdated: Item) => {
          return itemUpdated;
        }));
  }

  /**
   * DELETE method to delete a topic from its Id.
   * @param topicId : id of the topic to delete.
   */
  deleteTopic(topicId: string): Observable<null> {
    return this.http
      .delete(
        `${environment.backV2EndUrl}topics/topic?id=${topicId}`,
        this.headersService.httpHeaders
      )
      .pipe(
        map((response: null) => response));
  }

  /**
   * GET method retrieving all user's topics.
   * Topics retrieved have light information (no design_body)
   */
  getUserTopics(): Observable<Topic[]> {
    const userId = localStorage.getItem('user_id');
    return this.http
      .get(
        `${environment.backV2EndUrl}user/` + userId,
        this.headersService.httpHeaders)
      .pipe(
        map(data => {
          return data['user_topics'].map(dbTopic => {
            return new Topic(dbTopic);
          });
        }));
  }

  /**
   * PUT method to copy a default_topic as a custom_topic
   */
  copyDefaultTopic(topic: DbTopic): Observable<Item> {
    delete topic.id;
    return this.http
      .put(
        `${environment.backV2EndUrl}topics/topic`,
        topic,
        this.headersService.httpHeaders)
      .pipe(
        map((copiedTopic: Item) => {
        return copiedTopic;
      }));
  }

  /**
   * GET method to retrieve topic information when not logged in.
   * This method is usefull for the goodwill CSM application.
   */
  getTopicAsAnonymous(token: string, topicId: string): Observable<Topic> {
    const headers = this.headersService.getHeadersForAdminRequest(token);
    return this.http
      .get(
        `${environment.backV2EndUrl}topics/topic?id=` + topicId,
        headers
      )
      .pipe(
        map((dbTopic: DbTopic) => {
          return new Topic(dbTopic);
        }));
  }

}
