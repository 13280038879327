import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/services/api/api.service';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-single-sign-on-page',
  templateUrl: './single-sign-on-page.component.html',
  styleUrls: ['./single-sign-on-page.component.scss']
})
export class SingleSignOnPageComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService
  ) { }

  async ngOnInit(): Promise<void> {
    try {
      const queryParams = this.route.snapshot.queryParams;
      await this.apiService.zendesk.getJwtTokenForZendeskAuthentication(queryParams['return_to']).pipe(first()).toPromise();
    } catch (e) {
      console.log('error trying to fetch JWT for zendesk authentication');
    }
  }

}
