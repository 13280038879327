<mat-expansion-panel [ngClass]="{
                        'right-border-entity': true,
                        'margin-bottom-8': true
                     }"
                     class="no-select"
                     expanded="false"
                     hideToggle="true"
                     (afterExpand)="togglePanel()"
                     (afterCollapse)="togglePanel()"
                     #savePanel
>
  <mat-expansion-panel-header
    class="right-border-entity-header"
    [style.background-color]="databaseBookmarkPaths?.length > 0 ? 'var(--login-green-validation)' : 'var(--primary-500)'">
    <mat-panel-title>
      <div class="header-content">
        <div *ngIf="databaseBookmarkPaths?.length === 0" class="header-left-content">
          <mat-icon class="fill-white icon-folder" svgIcon="save-folder-icon"></mat-icon>
          <span class="title color-white">{{'folder.panel-header-save' | translate | uppercase}}</span>
        </div>
        <div *ngIf="databaseBookmarkPaths?.length > 0" class="header-left-content">
          <mat-icon class="fill-white icon-folder" svgIcon="saved-folder-icon-white"></mat-icon>
          <span class="title color-white">{{'folder.panel-header-saved' | translate | uppercase}}</span>
          <span class="title color-white"> ({{databaseBookmarkPaths.length}})</span>
        </div>
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div *ngIf="savePanel.expanded" class="expansion-content">
    <ng-container *ngIf="folderList">
      <ng-container *ngIf="folderList.length > 0; else noFolderPlaceholder">
        <div class="ignore-parent-padding folder-list">
          <div class="d-flex" *ngFor="let folder of folderList">
            <label class="container d-flex">
              <span class="checkbox-label">{{folder.name}}</span>
              <input type="checkbox" class="form-check-input"
                     [checked]="folder.containsDocument"
                     (change)="toggleCheckbox($event, folder)"
              >
              <span class="checkmark"></span>
              <span *ngIf="folder.id === folderId"
                    class="this-folder-badge">{{'folder.this-folder-badge' | translate}}</span>
            </label>
          </div>
        </div>
        <mat-divider class="ignore-parent-padding color-basic-500"></mat-divider>
        <div class="footer-padding d-table">
          <button class="new-folder" (click)="openModalCreationFolder()">
            <mat-icon svgIcon="add-folder-icon-primary-500"></mat-icon>
            <span class="new-folder-text">{{'folder.new-item-button' | translate | uppercase}}</span>
          </button>
          <span class="folder-separator d-table-cell align-content-center"></span>
          <div class="cancel-button d-table-cell align-content-center"
                (click)="onClosePanel()">{{'folder.cancel-button' | translate | titlecase}}</div>
          <div class="button-save d-table-cell align-content-center" onclick="this.blur()"
                (click)="updateBookmarks()"> {{'folder.save' | translate | uppercase}}
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</mat-expansion-panel>

<ng-template #noFolderPlaceholder>
  <div class="placeholder-no-folder">
    <span class="title">{{'folder.no-folder-yet' | translate}}</span>
    <span class="sub-title">{{'folder.no-folder-create-first' | translate}}</span>
  </div>
  <mat-divider class="ignore-parent-padding color-basic-500"></mat-divider>
  <div class="footer-padding d-table">
    <button class="new-folder" (click)="openModalCreationFolder()">
      <mat-icon svgIcon="add-folder-icon-primary-500"></mat-icon>
      <span class="new-folder-text">{{'folder.new-item-button' | translate | uppercase}}</span>
    </button>
    <span class="d-table-cell align-content-center" [style.width]="'100%'"></span>
    <div class="cancel-button d-table-cell align-content-center"
         (click)="onClosePanel()">{{'folder.cancel-button' | translate | uppercase}}</div>
  </div>
</ng-template>
