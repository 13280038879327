import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-impacter-card',
  templateUrl: './impacter-card.component.html',
  styleUrls: ['./impacter-card.component.scss']
})
export class ImpacterCardComponent implements OnInit {
  @Input() impacterId: string;
  @Input() name: string;
  @Input() byFilter: boolean;
  @Input() positions: Array<object>;
  constructor() { }

  ngOnInit(): void {
  }

}
