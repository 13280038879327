import { AfterViewChecked, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TreeviewConfig, TreeviewItem } from '../../lib/ngx-treeview';
import { SearchService } from '../../shared/services/search.service';
import {
  ActiveView,
  AngularMyDatePickerDirective,
  DefaultView,
  HeaderAction,
  IAngularMyDpOptions,
  IMyDateModel
} from 'angular-mydatepicker';
import label from '../../../assets/data/dateLabel.json';
import roles from '../../../assets/data/roles.json';
import { SearchHelper } from '../../shared/helpers/search.helper';
import { SubKind, TerritoryKind } from '../../core/territory-kind/territory-kind.enum';
import { IMyInputFieldChanged } from 'angular-mydatepicker/lib/interfaces/my-input-field-changed.interface';
import { ArticleImSearchService } from '../../shared/services/article-im-search.service';
import { makeSyndicatesTreeview, TerritoryHelper } from '../../shared/helpers/territory.helper';
import { Subscription } from 'rxjs';
import { TerritoryCountService } from '../../shared/services/territory-count.service';
import { ApiService } from '../../shared/services/api/api.service';
import { TerritoryManager } from '../../models/territory/territory-manager';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, AfterViewChecked, OnDestroy, OnChanges {
  @ViewChild('dpEnd') dpEnd: AngularMyDatePickerDirective;
  @Input() currentPage: string;
  @Input() territoryUid: string;
  @Input() inImpacterView: boolean;
  @Input() inDocumentView: boolean;
  items: TreeviewItem[];
  syndicates: TreeviewItem[];
  functionItems: TreeviewItem[];
  values: number[];
  filterText: string;
  filter: object;
  inSearch = false;
  isCollapsed = false;
  isAllChecked = true;
  territoriesStore: Array<string>;
  syndicatesStore: Array<string>;
  titlesStore: Array<string>;
  selectedTerritories: Array<string>;
  selectedSyndicates: Array<string>;
  validSyndicates: Array<string>;
  oldPosition = false;
  listTerritoriesKind: Array<object> = [
    { value: TerritoryKind.COMMUNE, checked: true},
    { value: TerritoryKind.EPCI, checked: true},
    { value: SubKind.SYNDICAT, checked: true}
  ];
  territoryManager: TerritoryManager;
  config = TreeviewConfig.create({
    hasFilter: true,
  });
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',
    showSelectorArrow: false,
    sunHighlight: false,
    selectorHeight: '365px',
    selectorWidth: '280px',
    monthLabels: { ...label.monthLabels},
    dayLabels: { ...label.dayLabels},
    markCurrentDay: true,
    markCurrentYear: false,
    markCurrentMonth: false,
    defaultView: DefaultView.Year,
    monthSelector: true
  };
  myBeginDpOptions: IAngularMyDpOptions;
  myEndDpOptions: IAngularMyDpOptions;
  beginDate: IMyDateModel = null;
  endDate: IMyDateModel = null;
  inputEndDate: String = '';
  inputBeginDate: String = '';
  currentView: ActiveView = ActiveView.Year;

  retrieveTerritoriesSubscription: Subscription;
  territoriesCountSubscription: Subscription;

  constructor(
    private apiService: ApiService,
    private searchService: SearchService,
    private articleImSearchService: ArticleImSearchService,
    private territoryCountService: TerritoryCountService,
    private translate: TranslateService
  ) {
    this.setDpOption();
    this.filterText = '';
    this.filter = {
      territories_kind: this.getTerritoriesKindChecked(),
    };
    this.functionItems = this.itemsToTreeView(roles.data);
    this.titlesStore = this.functionItems.reduce((acc, val) => {
      return acc.concat(val.getSelection().checkedItems.map(k => k.value));
    }, []);
    this.filter['titles'] = [...this.titlesStore];
    this.filter['with_old_position'] = this.oldPosition;
  }

  ngOnInit(): void {
    this.retrieveTerritoriesSubscription =
      this.apiService.territory.retrieveTerritories(this.territoryUid).subscribe(
        (territoryManager: TerritoryManager) => {
          this.territoryManager = territoryManager;
          this.items = TerritoryHelper.transferToTreeview([territoryManager.territory], true);
          this.items.forEach(child => child.setCheckedRecursive(true));

          this.syndicates = makeSyndicatesTreeview(this.territoryManager.syndicates, this.translate.instant('filter.all-syndicates'));

          this.territoriesStore = this.items.reduce((acc, val) => {
            return acc.concat(val.getSelection().checkedItems.map(k => k.value));
          }, []);
          this.selectedTerritories = this.territoriesStore;
          this.syndicatesStore = this.syndicates.reduce((acc, val) => {
            return acc.concat(val.getSelection().checkedItems.map(k => k.value));
          }, []);
          this.selectedSyndicates = this.syndicatesStore;
          this.validSyndicates = this.territoryManager.getCorrespondingSyndicates(this.selectedTerritories);

          if (this.inImpacterView) {
            this.articleImSearchService.sendPerimeter(this.territoriesStore);
          } else {
            this.searchService.sendPerimeter([...this.territoriesStore, ...this.syndicatesStore]);
          }
          this.setSelectedFilter();
        });

    this.territoriesCountSubscription =
      this.territoryCountService.territoriesCount.subscribe(
        territoriesCount => {
          this.searchService.getSearch().subscribe(
            search => {
              this.items.forEach(child => child.setSearchCountRecursive(territoriesCount));
              this.inSearch = !!search['text'] || !!search['topics'];
            });
        });
  }
  ngOnChanges(): void {
    this.updateResourceCount();
  }
  ngOnDestroy() {
    if (this.retrieveTerritoriesSubscription) { this.retrieveTerritoriesSubscription.unsubscribe(); }
    if (this.territoriesCountSubscription) { this.territoriesCountSubscription.unsubscribe(); }
  }

  ngAfterViewChecked() {
    if (this.items) {
      const treeviewContainerRef = document.querySelectorAll<HTMLElement>('.treeview-container');
      if (!treeviewContainerRef) {
        return;
      }
      const MARGIN = 16;
      const tabHeight = this.getElementHeight('.card-header', MARGIN);
      const filterHeight = this.getElementHeight('.filter');
      const treeviewHeaderHeight = this.getElementHeight('.treeview-header');
      let height = filterHeight - treeviewHeaderHeight - tabHeight - MARGIN;

      const roleLabel = document.querySelector('.role-label');
      if (roleLabel) {
        const footerHeight = document.querySelector('.footer').clientHeight;
        height -= roleLabel.clientHeight + footerHeight;
      }


      this.config = TreeviewConfig.create({
        ...this.config,
        maxHeight: height
      });
    }
  }

  getElementHeight(selector: string, outerHeight = 0): number {
    let height = 0;
    document.querySelectorAll(selector).forEach(c => {
      if (c.clientHeight > 0) {
        height += (c.clientHeight + outerHeight);
      }
    });
    return height;
  }

  setSelectedFilter(): void {
    this.filter['territories'] = [
      ...this.selectedTerritories,
      ...this.selectedSyndicates.filter(value => this.validSyndicates.includes(value))
    ];
    this.sendFilter();
  }

  itemsToTreeView(items) {
    if (!items) {
      return;
    }
    return items.map(item => new TreeviewItem({
      text: item['text'],
      value: item['value'],
      isRoot: !!item['isRoot'],
      children: this.itemsToTreeView(item['children'])
    }));
  }


  onFunctionChange(value: Array<any>): void {
    if (this.filter['titles'].toString() === value.toString()) {
      return;
    }

    this.filter['titles'] = value;
    this.searchService.sentFilter(this.filter);
  }

  onOldPositionChange(): void {
    this.filter['with_old_position'] = this.oldPosition;
    this.searchService.sentFilter(this.filter);
  }

  onFilterChange( _: string): void {
    // console.log('filter:', value);
  }

  onSelectedSyndicateChange(value: Array<any>): void {
    const currentSelected = this.selectedSyndicates;
    if (currentSelected.toString() === value.toString()) {
      return;
    }
    this.selectedSyndicates = value;
    this.setSelectedFilter();
  }

  onSelectedTerritoryChange(value: Array<any>): void {
    const currentSelected = this.selectedTerritories;
    if (currentSelected.toString() === value.toString()) {
      return;
    }
    if (currentSelected.length === 0) {
      this.isAllChecked = true;
    }
    if (currentSelected.length === this.territoriesStore.length) {
      this.isAllChecked = false;
    }
    this.selectedTerritories = value;
    this.validSyndicates = this.territoryManager.getCorrespondingSyndicates(value);
    this.setSelectedFilter();
  }

  sendFilter() {
    this.updateResourceCount();
    if (this.inImpacterView) {
      this.articleImSearchService.sentFilter(this.filter);
    } else {
      this.searchService.sentFilter(this.filter);
    }
  }

  onKindChange(): void {
    this.filter['territories_kind'] = this.getTerritoriesKindChecked();
    this.searchService.sentFilter(this.filter);
  }

  getTerritoriesKindChecked(): Array<string> {
    return this.listTerritoriesKind.filter(x => x['checked']).map(x => x['value']);
  }

  itemInSearch(text): boolean {
    return !!SearchHelper.searchRegExp(text, this.filterText);
  }

  hideItem(item: TreeviewItem): boolean {
    if (item.children) {
      if (item.value) {
        return false;
      } else {
        const childInSearch = item.children.some(x => this.itemInSearch(x.text));
        return !childInSearch;
      }
    } else {
      return !this.itemInSearch(item.text);
    }
  }

  countChecked(item: TreeviewItem) {
    return item.children.reduce((acc, child) => acc + (child.checked ? 1 : 0), 0);
  }

  onInputDateChange(value: IMyInputFieldChanged, isBegin: boolean) {
    if (isBegin) {
      this.inputBeginDate = value.value;
    } else {
      this.inputEndDate = value.value;
    }
  }

  onDateChanged(isBegin: boolean): void {
    setTimeout(() => {
      this.setDpOption();
      if (this.beginDate && isBegin) {
        const year = (new Date()).getFullYear();
        this.dpEnd.openCalendar();
        if (year === this.beginDate.singleDate.date.year) {
          this.dpEnd.headerAction(HeaderAction.YearBtnClick);
        }
      }
      if (this.beginDate && this.endDate) {
        const beginSingleDate = this.beginDate.singleDate.date;
        const endSingleDate = this.endDate.singleDate.date;
        this.filter['period'] = {
          from: `${beginSingleDate.year}-${beginSingleDate.month}-${beginSingleDate.day}`,
          to: `${endSingleDate.year}-${endSingleDate.month}-${endSingleDate.day}`
        };
        this.sendFilter();
      }

      if (!this.beginDate && !this.endDate && this.filter['period']) {
        delete this.filter['period'];
        this.sendFilter();
      }
    });
  }

  onViewActivated(event: ActiveView, myDp: AngularMyDatePickerDirective) {
    if (event === ActiveView.Date && this.currentView === ActiveView.Year) {
      myDp.headerAction(HeaderAction.MonthBtnClick);
    }
    this.currentView = event;
  }

  clearDate(event): void {
    this.beginDate = null;
    this.endDate = null;
    this.inputEndDate = '';
    this.inputBeginDate = '';
    document.querySelectorAll('.dp-input').forEach(element => {
      (element as HTMLInputElement).value = '';
    });
    this.setDpOption();
    delete this.filter['period'];
    if (event) {
      event.stopPropagation();
      this.sendFilter();
    }
  }

  reset(): void {
    this.resetTerritories(false);
    this.resetSyndicates(false);
    this.resetTitles(false);
    this.clearDate(false);
    this.resetTerritoriesKind(false);
  }

  resetTerritoriesKind(event): void {
    if (event) {
      event.stopPropagation();
    }
    this.listTerritoriesKind.forEach(i => i['checked'] = true);
    this.onKindChange();
  }

  resetTerritories(event): void {
    this.filterText = '';
    this.items.forEach(child => child.setCheckedRecursive(true));
    this.selectedTerritories = this.territoriesStore;
    this.validSyndicates = this.territoryManager.getCorrespondingSyndicates(this.selectedTerritories);
    this.setSelectedFilter();
    if (event) {
      event.stopPropagation();
      this.sendFilter();
    }
  }

  resetSyndicates(event): void {
    this.syndicates.forEach(child => child.setCheckedRecursive(true));
    this.selectedSyndicates = this.syndicatesStore;
    this.setSelectedFilter();
    if (event) {
      event.stopPropagation();
      this.sendFilter();
    }
  }

  resetTitles(event): void {
    this.functionItems.forEach(child => child.setCheckedRecursive(true));
    this.oldPosition = false;
    this.filter['titles'] = [...this.titlesStore];
    this.filter['with_old_position'] = this.oldPosition;

    if (event) {
      event.stopPropagation();
      this.searchService.sentFilter(this.filter);
    }
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    this.items.forEach(child => child.setCollapsedRecursive(this.isCollapsed));
  }

  onAllSelectChange() {
    this.items.forEach(child => child.setCheckedRecursive(this.isAllChecked));
    if (this.isAllChecked) {
      this.filter['territories'] = [...this.territoriesStore];
    } else {
      this.filter['territories'] = [];
    }
    this.sendFilter();
  }

  badgeNumber(currentValue: Array<string>, allValue: Array<any>): string {
    if (!currentValue || !allValue) {
      return;
    }
    const selectedCount = currentValue.length;
    const allCount = allValue.length;
    if (selectedCount < allCount) {
      return `${selectedCount}/${allCount}`;
    }
  }

  setDpOption() {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    const tomorrow = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()};
    const dateDefault = { year: 0, month: 0, day: 0};
    this.myBeginDpOptions = {
      ...this.myDpOptions,
      disableSince: this.endDate ? this.endDate.singleDate.date : tomorrow
    };
    this.myEndDpOptions = {
      ...this.myDpOptions,
      disableSince: tomorrow,
      disableUntil: this.beginDate ? this.beginDate.singleDate.date : dateDefault
    };
  }

  counter(item: TreeviewItem): number {
    return this.isArticlePage() ? item.infos['articleCount'] : item.infos['adminDocCount'];
  }

  showCounter(item: TreeviewItem): boolean {
    if (this.isImpacterPage()) {
      return false;
    }
    let toShow = item.infos['kind'] === TerritoryKind.COMMUNE;
    if (!this.isArticlePage()) {
      toShow = toShow || item.infos['kind'] === TerritoryKind.EPCI;
    }
    return toShow;
  }

  counterBySearch(item: TreeviewItem): number {
    if (!this.isArticlePage() && !this.filter['territories_kind'].includes(item.infos['kind'])) {
      return 0;
    } else {
      return item.infos['searchCount'];
    }
  }

  isArticlePage(): boolean {
    return this.currentPage === 'article';
  }

  isImpacterPage(): boolean {
    return this.currentPage === 'impacter';
  }

  updateResourceCount(): void {
    this.filter['resources_count'] = this.items[0].getSelection().checkedItems
      .filter(item => this.counter(item)).length;
  }
}
