<div class="form-frequent-container container-fluid">
  <div class="d-flex justify-content-center">
    <div class="total-step-width">
      <div class="font-weight-bold title">{{'wizard.alert-name' | translate| uppercase}}
        <span class="asterisk" [hidden]="name?.length > 1">*</span>
      </div>
      <div class="alert-name-input-field-container">
        <input id="alert-name-input-field"
               type="text"
               placeholder="{{'wizard.alert-name' | translate}}"
               [(ngModel)]="name"
               (ngModelChange)="onNameChange()"
               autocomplete="off">
      </div>
      <div [hidden]="isNameUnique" class="name-uniqueness-error">
        <div class="d-table">
          <div class="img d-table-cell align-middle align-items-center">
            <img class="d-table-cell align-middle align-items-center"
                 src="/assets/images/icon-alert-triangle.svg"
                 alt="icon-error">
          </div>
          <div class="text d-table-cell align-middle align-items-center">{{'wizard.alert-name-error' | translate}}</div>
        </div>
      </div>
      <div [hidden]="!isNameUnique" class="name-uniqueness-error-empty-space"></div>
      <div class="font-weight-bold sub-title">{{'wizard.which-days' | translate | uppercase}}</div>
      <div class="reception-days-container">

        <div class="d-flex">
          <div *ngFor="let day of days; let i = index" class="day-round"
               [ngClass]="{'selected': day['selected']}"
               (click)="onSelectDay(i)">
            {{day['label']}}
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <div class="info-section total-step-width">
            <div class="d-flex">
              <img src="assets/images/icon-info.svg" alt="icon-info">
              <div class="font-weight-bold">{{'wizard.frequent-info-title' | translate}}</div>
            </div>
            <div class="text">{{'wizard.frequent-info-text' | translate}}</div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
