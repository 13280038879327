import { Item } from './generic/item';
import { BlockModel } from '../core/block/block.model';

export enum TopicType {
  DEFAULT = 'default_topic',
  CUSTOM = 'custom_topic'
}

export interface DbTopic {
  description: string;
  design_body?: {
    blocks: Array<BlockModel>
  };
  id?: number;
  name: string;
  owner_user_id?: number;
  type?: TopicType;
  created_at?: Date;
  updated_at?: Date;
  is_active?: boolean;
}

export class Topic extends Item {
  private _description: string;
  private _designBody?: {
    blocks?: Array<BlockModel>
  };
  private _ownerUserId?: number;
  private _type?: TopicType;
  private _createdAt?: Date;
  private _updatedAt?: Date;
  private _isActive?: boolean;

  constructor(dbTopic: DbTopic) {
    super(dbTopic.id, dbTopic.name);
    this._description = dbTopic.description;
    this._designBody = dbTopic?.design_body;
    this._ownerUserId = dbTopic?.owner_user_id;
    this._type = dbTopic?.type;
    this._createdAt = dbTopic?.created_at;
    this._updatedAt = dbTopic?.updated_at;
    this._isActive = dbTopic?.is_active;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get designBody(): { blocks?: Array<BlockModel> } {
    return this._designBody;
  }

  set designBody(value: { blocks?: Array<BlockModel> }) {
    this._designBody = value;
  }

  get ownerUserId(): number {
    return this._ownerUserId;
  }

  set ownerUserId(value: number) {
    this._ownerUserId = value;
  }

  get type(): TopicType {
    return this._type;
  }

  set type(value: TopicType) {
    this._type = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  get updatedAt(): Date {
    return this._updatedAt;
  }

  set updatedAt(value: Date) {
    this._updatedAt = value;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  set isActive(value: boolean) {
    this._isActive = value;
  }

}

