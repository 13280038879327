import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { first } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { SuggestionParameters } from '../../../models/zendesk/suggestion-parameters';

@Injectable({
  providedIn: 'root'
})
export class ZendeskWebWidgetService {
  token: string | null = null;
  readonly _widgetShouldBeDisplayed = new BehaviorSubject<boolean>(false);
  readonly _panelOpeningState = new BehaviorSubject<boolean>(false);
  helpCenterSuggestion = new BehaviorSubject<SuggestionParameters>(null);
  helpCenterSuggestionSubscription: Subscription;

  constructor(
    private ngxZendeskWebWidgetService: NgxZendeskWebwidgetService,
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {
    this._widgetShouldBeDisplayed.subscribe((display) => {
      if (display) {
        this.setUpWidget().then();
      } else {
        this.hideWidget();
      }
    });
  }

  // region GETTERS

  /* Necessary for observing value evolution during test. */
  get widgetShouldBeDisplayed(): BehaviorSubject<boolean> {
    return this._widgetShouldBeDisplayed;
  }

  get panelOpeningState(): BehaviorSubject<boolean> {
    return this._panelOpeningState;
  }

  // endregion

  // region FUNCTIONS TRIGGERRED ON ROUTE CHANGE.

  // region SUB FUNCTION OF SET UP WIDGET

  /* Function called by zendesk widget when opening it and user is not authenticated. */
  async authenticateUser(callback: any) {
    try {
      this.token = await this.apiService.zendesk.getJwtTokenForWebWidget().pipe(first()).toPromise();
      callback(this.token);
    } catch (e) {
      console.log('error authenticating user to zendesk', e);
    }
  }

  /* This function triggers the setSuggestion's function of help center. */
  subscribeToHelpCenterSuggestion(): void {
    if (!this.helpCenterSuggestionSubscription || this.helpCenterSuggestionSubscription.closed) {
      this.helpCenterSuggestionSubscription = this.helpCenterSuggestion.subscribe((parameters) => {
        if (parameters) {
          this.ngxZendeskWebWidgetService.zE('webWidget', 'helpCenter:setSuggestions', {[parameters.mode]: parameters.value});
        }
      });
    }
  }

  /* This function is needed, it allows zendesk-faq.component to keep up with panel state. */
  subscribeToPanelStateEvent() {
    this.ngxZendeskWebWidgetService.zE('webWidget:on', 'open', () => {
      this.ngxZendeskWebWidgetService.zE('webWidget', 'show');
      this._panelOpeningState.next(true);
    });
    this.ngxZendeskWebWidgetService.zE('webWidget:on', 'close', () => {
      this.ngxZendeskWebWidgetService.zE('webWidget', 'hide');
      this._panelOpeningState.next(false);
    });
  }

  // endregion

  /* Initialize zendesk, authenticates user and subscribe events.
  * Needed at each route change.
  * Reauthenticate is needed to connect to help center before first open of the widget.
  * => needed for search's suggestion. */
  async setUpWidget() {
    if (this.ngxZendeskWebWidgetService.isInitialized === false) {
      await this.ngxZendeskWebWidgetService.initZendesk();
    }
    if (!this.token) {
      this.ngxZendeskWebWidgetService.zE('webWidget', 'updateSettings',
        {
          authenticate: {
            jwtFn: (callback: any) => this.authenticateUser(callback)
          },
          offset: {
            vertical: '40px',
            horizontal: '-8px'
          }
        }
      );
    }
    this.ngxZendeskWebWidgetService.zE('webWidget', 'helpCenter:reauthenticate');
    this.subscribeToHelpCenterSuggestion();
    this.subscribeToPanelStateEvent();
  }

  /* At logout we hide the widget, logout from the restricted content,
   * We have to resubscribe at new login for synchronicity of suggestion
   * => We also unsubscribe to helpCenterSuggestion during logout.  */
  hideWidget(): void {
    if (this.ngxZendeskWebWidgetService.isInitialized === true) {
      this.token = null;
      this.helpCenterSuggestionSubscription.unsubscribe();
      this.closeWebWidget();
      this.ngxZendeskWebWidgetService.zE('webWidget', 'logout');
    }
  }

  handleDisplay() {
    this._widgetShouldBeDisplayed.next(this.shouldDisplayHelp());
  }

  // endregion

  // region FUNCTIONS NEEDED TO DECIDE WHETHER OR NOT THE WIDGET SHOULD BE DISPLAYED.

  /** It will emit the changes of displaying state of the widget to subscribers (zendesk-faq.component.ts). */

  shouldDisplayHelp() {
    return this.isLoggedIn() && this.urlIsAllowedToDisplayHelp();
  }

  isLoggedIn(): boolean {
    return !!this.authenticationService.goodwillToken;
  }

  /** This function might evolve in the future of Goodwill. */
  urlIsAllowedToDisplayHelp() {
    return !this.router.url.startsWith('/sso');
  }

  // endregion OF FUNCTIONS NEEDED TO DECIDE WHETHER OR NOT THE WIDGET SHOULD BE DISPLAYED. */

  //region FUNCTIONS COMPONENTS INTERACT WITH.

  /* Use of a Subject: we have to wait for the authentication to set help center suggestion. */
  updateSearchSuggestion(params: SuggestionParameters) {
    if (params) {
      this.helpCenterSuggestion.next(params);
    }
  }

  /* Open the web widget panel.
  * Triggering the event subscribeToPanelStateEvent.open:
  * - display the web widget on screen
  * - emit a new value for the opening state to subscribers (zendesk-faq.component.ts) */
  openWebWidget() {
    this.ngxZendeskWebWidgetService.zE('webWidget', 'open');
  }

  /* Close the web widget panel.
  * Triggering the event subscribeToPanelStateEvent.close:
  * - hide the web widget of the screen
  * - emit a new value for the opening state to subscribers (zendesk-faq.component.ts) */
  closeWebWidget() {
    this.ngxZendeskWebWidgetService.zE('webWidget', 'close');
  }

  //endregion
}
