<div class="{{confirmationNumber === 4}}? modal-header-error : modal-header">
  <h4 *ngIf="confirmationNumber === 1" class="modal-title update-modal" >
     {{ 'share.confirmation1.modal-title' | translate}}
  </h4>
  <h4 *ngIf="confirmationNumber === 2 || confirmationNumber === 5" class="modal-title update-modal" >
     {{ 'share.confirmation2.modal-title' | translate}}
  </h4>
  <h4 *ngIf="confirmationNumber === 3" class="modal-title update-modal" >
     {{ 'share.confirmation3.modal-title' | translate}}
  </h4>
  <h4 *ngIf="confirmationNumber === 4" class="content-error modal-title " >
     {{ 'share.confirmation4.modal-title' | translate}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <mat-icon svgIcon="close-circle"></mat-icon>
  </button>
</div>

<div class="modal-body d-flex flex-column">

  <ng-container *ngIf="confirmationNumber === 1">
    <div>
      <span class="content-info">{{user.fullName | titlecase}}</span>{{ 'share.confirmation1.modal-content1' | translate}}
     </div>
    <br/>
     <div>
       <span>{{'share.confirmation1.modal-content2' | translate}}<span class="content-info">{{ 'share.subscriber' | translate}}</span></span>
       <span>{{'share.confirmation1.modal-content3' | translate}}<span class="content-info">{{ 'share.unlimited' | translate}}</span>.</span>
     </div>
  </ng-container>

  <ng-container *ngIf="confirmationNumber === 2">
    <div>
      {{ 'share.confirmation2.modal-content1' | translate}} <span class="content-info">  {{user.email}}</span>.
    </div>
    <br/>
    <div>
      <span>  {{ 'share.confirmation2.modal-content2' | translate}} </span>
      <span class="content-info"> {{user.userLevel.remaining_credits}}/{{user.userLevel.max_credit}} {{ 'share.credit' | translate}}</span>.
    </div>
  </ng-container>

  <ng-container *ngIf="confirmationNumber === 3">
    <div>
      <span class="content-info">{{user.email}} </span>{{ 'share.confirmation3.modal-content1' | translate}}
    </div>
    <br/>
    <div>
      <span class="font-italic">{{ 'share.confirmation3.modal-content2' | translate}}.</span>
    </div>
  </ng-container>

  <ng-container *ngIf="confirmationNumber === 4">
    <div>
      <span aria-hidden="true"><img src="assets/images/alert-triangle.svg" alt="icon-close-circle">  </span>
      <span  class="content-error add-padding">  {{ 'share.confirmation4.modal-content1' | translate}} </span>
    </div>
    <br/>
    <div>
      <span>  {{ 'share.confirmation4.modal-content2' | translate}}
        <a class="content-error" href="mailto:{{'share.mailto' | translate}}">{{'share.mailto' | translate}}</a>
      </span>.
    </div>
  </ng-container>

  <ng-container *ngIf="confirmationNumber === 5">
    <div>
      <span>{{ 'share.confirmation5.modal-content1' | translate}}</span>
      <span class="content-info">  {{user.email}} </span>
      <span>{{ 'share.confirmation5.modal-content2' | translate}}</span>
    </div>
    <br/>
    <div>
      <span>  {{ 'share.confirmation5.modal-content3' | translate}} </span>
      <span class="content-info"> {{user.userLevel.remaining_credits}}/{{user.userLevel.max_credit}} {{ 'share.credit' | translate}}</span>.
    </div>
  </ng-container>

 </div>

 <div class="modal-footer">
   <ng-container>
     <button *ngIf="confirmationNumber" type="button" class=" btn d-flex {{confirmationNumber==4 ? 'confirm-close-error' : 'confirm-close'}}" (click)="close()">
       <span class="text-uppercase font-weight-bold align-self-center">
        {{'share.btnName' | translate}}
       </span>
     </button>
   </ng-container>
 </div>
