<div class="form-source-container container-fluid">
  <div class="row">
    <div class="total-step-width">
      <div class="card-label">{{'wizard.regional-daily-press' | translate}}</div>
      <div class="card-board">
        <div class="row">
          <div class="col-12 d-flex">
            <label class="switch checkbox align-self-center">
              <input type="checkbox"
                     [(ngModel)]="regionalDailyPress"
                     (ngModelChange)="onRegionalDailyPressChange()">
              <span class="slider round"></span>
            </label>
            <div class="font-weight-bold ml-2 name align-self-center">{{'wizard.regional-daily-press' | translate}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="total-step-width">
      <div class="card-label--default">{{'wizard.community-publications' | translate}}</div>
      <ngb-accordion>
        <ngb-panel id="static-1">
          <ng-template ngbPanelHeader let-opened="opened">
            <ul class="territory-kinds">
              <li *ngFor="let kind of territoriesKind">
                <label class="switch checkbox">
                  <input type="checkbox"
                         [checked]=kind.checked
                         [(ngModel)]="kind.checked"
                         (ngModelChange)="onTerritoriesKindChange()">
                  <span class="slider round"></span>
                </label>
                <div class="name ml-2">{{('filter.'+kind.value) | translate}}</div>
                <div class="d-flex align-items-center badge-active"
                     *ngIf="kind.value === 'SYND' && badgeNumber(selectedSyndicates, syndicatesStore) && kind.checked"
                     [ngClass]="{'empty-badge': !selectedSyndicates.length}">
                  <div class="align-self-center">{{badgeNumber(selectedSyndicates, syndicatesStore)}}</div>
                  <img class="align-self-center" src="assets/images/icon-close.svg" (click)="resetSyndicates()" alt="icon-close"/>
                </div>
                <button ngbPanelToggle *ngIf="kind.value === 'SYND'" class="flex-fill btn btn-link d-flex">
                  <img *ngIf="!opened" src="assets/images/icon-chevron-down.svg" alt="icon-chevron-down" class="ml-auto"/>
                  <img *ngIf="opened" src="assets/images/icon-chevron-up.svg" alt="icon-chevron-up" class="ml-auto"/>
                </button>
              </li>
            </ul>
          </ng-template>
          <ng-template ngbPanelContent>
            <ng-template #itemTemplate
                         let-syndicate="item"
                         let-onCollapseExpand="onCollapseExpand"
                         let-onCheckedChange="onCheckedChange">
              <div class="row-item" [ngClass]="{'root-item': syndicate.isRoot}">
                <div class="left-group">
                  <i *ngIf="syndicate.children && !syndicate.isRoot" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="syndicate.collapsed">
                    <img *ngSwitchCase="true" src="assets/images/arrow-ios-right.svg" alt="arrow-ios-right"/>
                    <img *ngSwitchCase="false" src="assets/images/arrow-ios-down.svg" alt="arrow-ios-down"/>
                  </i>
                  <i *ngIf="syndicate.isRoot" class="pl-3">
                  </i>
                  <div *ngIf="!syndicate.isRoot" class="treeview-path"></div>
                </div>
                <div class="form-check" [ngClass]="{'node-root': syndicate.isRoot}">
                  <label  class="align-self-center checkbox"
                          [ngClass]="{'last-node': !syndicate.children}">
                    <input type="checkbox" class="form-check-input" [checked]="syndicate.checked" [(ngModel)]="syndicate.checked" (change)="onCheckedChange()"
                           [disabled]="syndicate.disabled || !territoriesKind[2].checked" [indeterminate]="syndicate.indeterminate" />
                    <span class="checkbox-text"
                          [ngClass]="{'text-line-through': syndicate.value && !validSyndicates.includes(syndicate.value) || !territoriesKind[2].checked}"
                          ngbTooltip="{{'filter.no-city-in-syndicate' | translate}}"
                          placement="right" container="body" [disableTooltip]="!(syndicate.value && !validSyndicates.includes(syndicate.value))"
                    >{{syndicate.text}} {{!syndicate.isRoot && !syndicate.value ? '('+countChecked(syndicate)+'/'+syndicate.children.length+')' : ''}}</span>
                    <span class="checkmark" ngbTooltip="{{'alert.tooltip-all-select' | translate}}"
                          placement="auto" container="body" [disableTooltip]="!syndicate.isRoot"></span>
                    <img *ngIf="syndicate.infos?.fullname" src="assets/images/icon-info-filled.svg" class="filter-basic-500" alt="full-name-icon"
                         ngbTooltip="{{syndicate.infos['fullname']}}" placement="auto" container="body"/>
                  </label>
                </div>
              </div>
            </ng-template>
            <ngx-treeview [itemTemplate]="itemTemplate" [config]="config" [items]="syndicates"
                          (selectedChange)="onSelectedSyndicateChange($event)">
            </ngx-treeview>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
</div>
