import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AlertSettingService } from '../../shared/services/alert-setting.service';
import { StepModel } from '../../core/step/step.model';
import { FormControl } from '@angular/forms';
import { RegexpSearch } from 'src/app/shared/helpers/regex';
import { ActivatedRoute } from '@angular/router';
import { StepsService } from '../../shared/services/steps.service';
import { first } from 'rxjs/operators';
import { ApiService } from '../../shared/services/api/api.service';
import { Topic, TopicType } from '../../models/topic';

@Component({
  selector: 'app-topic-step-template',
  templateUrl: './topic-step-template.component.html',
  styleUrls: ['./topic-step-template.component.scss']
})
export class TopicStepTemplateComponent implements OnInit, OnChanges {
  @Input() topicsSetting: Array<number>;
  @Input() step: StepModel;
  @Input() limiterAchieved: boolean;
  @Output() totalTopics = new EventEmitter<{ total: number, context: string }>();
  @Output() selectedTopics = new EventEmitter<{ selected: number, context: string }>();

  topics: Array<Topic> = [];
  customTopics: Array<Topic> = [];
  defaultTopics: Array<Topic> = [];
  searchValue = '';
  alertId = '';
  competitorMonitoring: boolean;

  search = new FormControl();

  constructor(
    private stepsService: StepsService,
    private router: ActivatedRoute,
    private alertSettingService: AlertSettingService,
    private apiService: ApiService
  ) {
    // set step completion to false on component creation.
    this.stepsService.updateStepCompletionState(1, false);
  }

  ngOnInit(): void {
    this.alertId = this.router.snapshot.params['alertId'];
    this.search.valueChanges.subscribe(val => {
      this.searchValue = val;
      if ((val !== null) && (val.length > 0)) {
        this.filterTopics(val);
      } else {
        this.setTopics();
      }
    });
    this.apiService.topic.getUserTopics()
      .pipe(first())
      .subscribe((topics) => {
        this.topics = topics;
        this.setActiveTopics();
        this.setTopics();
        this.totalTopics.emit({total: this.topics.length, context: 'topics'}); // emission to parent of total number of topics
        this.stepsService.updateTopicsRetrievingState(true);
      });
  }


  setTopics() {
    this.defaultTopics = this.topics.filter(topic => topic.type === TopicType.DEFAULT);
    this.customTopics = this.topics.filter(topic => topic.type === TopicType.CUSTOM);
  }

  filterTopics(searchString: string) {
    this.setTopics();
    this.customTopics = this.customTopics.filter(item => RegexpSearch.Search(item.name, searchString));
    this.defaultTopics = this.defaultTopics.filter(item => RegexpSearch.Search(item.name, searchString));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.topicsSetting?.currentValue && this.topics) {
      this.topicsSetting = changes.topicsSetting.currentValue;
      this.setActiveTopics();
    }
  }

  setActiveTopics(): void {
    if (this.topicsSetting) {
      this.topics = this.topics.map(topic => {
          topic.isActive = this.topicsSetting.includes(topic.id);
          return topic;
        }
      );
    }
    this.onTopicsChange();
  }

  onTopicsChange(): void {
    const activeTopicIds = this.getActiveTopicIds();
    // emission to parent of selected number of topics
    this.selectedTopics.emit({selected: activeTopicIds.length, context: 'topics'});

    if (activeTopicIds?.length > 0) {
      // Any additive change invalidates the overview step.
      this.stepsService.updateStepCompletionState(4, false);
      this.stepsService.updateStepCompletionState(1, true);
    } else {
      this.stepsService.updateStepCompletionState(1, false);
    }
    this.alertSettingService.setTopics(activeTopicIds);
  }

  onCompetitorChange() {
    this.alertSettingService.setCompetitorMonitoring(this.competitorMonitoring);
  }

  getActiveTopicIds(): Array<string> {
    return this.topics?.filter(topic => topic.isActive).map(topic => {
      return topic.id;
    });
  }
}
