import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { SearchService } from '../../shared/services/search.service';
import { EntityService } from '../../shared/services/entity.service';
import { ArticleImSearchService } from '../../shared/services/article-im-search.service';
import { Subscription } from 'rxjs';

export const sortDefault = {
  field: 'publication_date',
  dir: 'desc'
};
const limitDefault = 25;

@Component({
  selector: 'app-task-bar',
  templateUrl: './task-bar.component.html',
  styleUrls: ['./task-bar.component.scss']
})

export class TaskBarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() currentPage: string;
  @Input() resultCount: number;
  @Input() allCount: number;
  @Input() nbResource: number;
  @Input() inImpacterView: boolean;
  @Input() sourcesList: string[];
  sortList: Array<object> = [
    sortDefault,
    {
      field: 'publication_date',
      dir: 'asc'
    }
    // Hide sort by score
    // {
    //   field: '_score',
    //   dir: 'desc'
    // }
  ];
  limitValues: Array<number> = [10, 25, 50, 100];
  currentSort: object;
  currentLimit: number;
  pageName: string;
  inSearch: boolean;

  /** Subscriptions */
  getArticleIMTotalCountSubscription: Subscription;
  getTerritoryCountersSubscription: Subscription;
  getSearchSubscription: Subscription;

  constructor(private searchService: SearchService,
              private entityService: EntityService,
              private articleImSearchService: ArticleImSearchService,
              ) {
    this.currentSort = sortDefault;
    this.currentLimit = limitDefault;
  }

  ngOnInit(): void {
    this.getArticleIMTotalCountSubscription =
      this.entityService.getArticleIMTotalCount().subscribe(count => {
        if (count) {
          this.allCount = count;
        }
      });

    this.checkInSearch(this.inImpacterView);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentPage) {
      this.pageName = changes.currentPage.currentValue;
    }

    if (changes.inImpacterView) {
      this.checkInSearch(changes.inImpacterView.currentValue);
    }
  }

  checkInSearch(inImpacterView: boolean): void {
    if (inImpacterView) {
      this.getSearchSubscription =
        this.articleImSearchService.getSearch().subscribe(search => {
          this.inSearch = !!search['text'];
          if (!this.inSearch && this.currentSort['field'] === '_score') {
            this.currentSort = sortDefault;
          }
        });
    } else {
      this.getSearchSubscription =
        this.searchService.getSearch().subscribe(search => {
          this.inSearch = !!search['text'];
        });
    }
  }

  filterSortList() {
    if (!this.inSearch && this.inImpacterView) {
      return this.sortList.filter(x => x['field'] !== '_score');
    } else {
      return this.sortList;
    }
  }

  ngOnDestroy() {
    /** Unsubscriptions */
    if (this.getArticleIMTotalCountSubscription) { this.getArticleIMTotalCountSubscription.unsubscribe(); }
    if (this.getTerritoryCountersSubscription) { this.getTerritoryCountersSubscription.unsubscribe(); }
    if (this.getSearchSubscription) { this.getSearchSubscription.unsubscribe(); }

    if (this.inImpacterView) {
    } else {
      this.searchService.sentSort(sortDefault);
      this.searchService.sentLimit(limitDefault);
    }
  }

  onLimitChange(limit: number): void {
    if (limit === this.currentLimit) {
      return;
    }
    this.currentLimit =  limit;
    if (this.inImpacterView) {
      this.articleImSearchService.sentLimit(limit);
    } else {
      this.searchService.sentLimit(limit);
    }
  }

  onSortChange(sortBy: object): void {
    if (sortBy['field'] === this.currentSort['field'] && sortBy['dir'] === this.currentSort['dir']) {
      return;
    }
    this.currentSort =  sortBy;
    if (this.inImpacterView) {
      this.articleImSearchService.sentSort(sortBy);
    } else {
      this.searchService.sentSort(sortBy);
    }
  }
}
