<mat-expansion-panel [ngClass]="{
                      'right-border-entity': true,
                      'margin-bottom-16': true,
                      'full-width': !withExportPanel || sharePanel.expanded,
                      'mid-width': withExportPanel && !sharePanel.expanded
                     }"
                     class="no-select"
                     expanded="false"
                     hideToggle="true"
                     (afterCollapse)="panelStateUpdate(false)"
                     (click)="onClickPanel(sharePanel.expanded)"
                     #sharePanel
>
  <mat-expansion-panel-header class="right-border-entity-header">
    <mat-panel-title>
      <div class="header-content">
        <div class="header-left-content">
          <mat-icon class="icon-share" svgIcon="share-white"></mat-icon>
          <span class="title">{{'share.panel-header' | translate | uppercase}}</span>
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div *ngIf="sharePanel.expanded">
    <div class="expansion-content">
      <div class="w-100">
      <div class="padding-top-16 sub-title">
        {{'share.addressee' | translate | uppercase}}
      </div>
      <div class="input-container" appClickOutside (clickOutside)="toggleDropdown(false, false)">
        <div class="position-relative">
          <input #autoInput
                 appOutlineNone
                 type="text"
                 (input)="onChange()"
                 (click)="toggleDropdown()"
                 (keyup.enter)="searchUser(autoInput.value)"
                 (keyup.arrowDown)="move('down')"
                 (keyup.arrowUp)="move('up')"
                 [placeholder]="!selectedUser ? ('share.addressee' | translate) : ''"
                 [ngClass]="{
                 'addressee-input': true,
                 'error-input': errors.email
                 }"/>
          <div *ngIf="errors.email" class="d-inline-flex align-items-center invalid-email">
            <mat-icon svgIcon="alert-triangle-fill"></mat-icon>
            <span>{{'share.invalid-email' | translate}}</span>
          </div>
          <mat-icon *ngIf="!addresseeDropdownMenuDisplayed" class="toggle-autocomplete-dropdown" svgIcon="chevron-down"></mat-icon>
          <mat-icon *ngIf="addresseeDropdownMenuDisplayed" class="toggle-autocomplete-dropdown" svgIcon="chevron-up"></mat-icon>
        </div>
        <div *ngIf="selectedUser" class="input-selected-user"
             (click)="toggleDropdown()"
        >
          <div class="user-infos user-main"
                  appShowIfTruncated
                  [ngbTooltip]="tooltipEmailCaller"
                  tooltipClass="share-tooltip-default tooltip-max-300"
                  disableTooltip="true"
                  placement="bottom"
                  container="body">
            <ng-template #tooltipEmailCaller>
              <ng-container *ngTemplateOutlet="tooltipEmail; context: {$implicit: selectedUser.email}"></ng-container>
            </ng-template>
            <ng-container
              *ngIf="isSimpleUser(selectedUser) && isInSameCompany(selectedUser); else noName">
              <span class="user-main-infos">{{selectedUser.fullName | titlecase}}&nbsp;</span>
              <span class="user-details">({{selectedUser.email}})</span>
            </ng-container>
            <ng-template #noName>
                  <span class="user-main-infos">
                    {{selectedUser.email}}
                  </span>
            </ng-template>
          </div>
          <div class="user-credits"
                    [ngbTooltip]="getCreditTooltip(selectedUser)"
                    tooltipClass="share-tooltip-default tooltip-fixed-226"
                    placement="bottom"
                    container="body">
            <span *ngIf="selectedUser.newDest; else existingAddressee" class="unlimited-credit">
              {{'share.new-addressee-short' | translate}}
            </span>
            <ng-template #existingAddressee>
              <ng-container *ngIf="isInSameCompany(selectedUser); else differentCompany">
              <span *ngIf="isSimpleUser(selectedUser)" class="unlimited-credit">
                {{'share.unlimited-credits-subscriber' | translate}}
              </span>
              <span *ngIf="isGuestUser(selectedUser)"
                    [ngClass]="{
                       'credit-span': true,
                       'remaining-credit-color': selectedUser.userLevel.remaining_credits > 0,
                       'no-credit-color': selectedUser.userLevel.remaining_credits < 1
                     }">
                  {{selectedUser.userLevel.remaining_credits}}/{{selectedUser.userLevel.max_credit}}
                {{'share.credits' | translate | lowercase}}
              </span>
              </ng-container>
              <ng-template #differentCompany>
                <span class="unavailable-credit credit-span">
                  {{'share.unavailable' | translate}}
                </span>
              </ng-template>
            </ng-template>
          </div>
        </div>
        <table aria-roledescription="list of possible addressee" *ngIf="addresseeDropdownMenuDisplayed" class="user-table">
          <thead class="user-table-header">
            <tr class="user-table-row">
              <th>{{'share.addressees' | translate | uppercase}}</th>
              <th class="d-flex flex-row align-items-center">
                {{'share.credits' | translate | uppercase}}
                <mat-icon class="icon-info d-flex" svgIcon="info"
                          [ngbTooltip]="tooltipInfo"
                          tooltipClass="tooltip-bottom-middle-right"
                          placement="bottom-right"
                          container="body">
                </mat-icon>
              </th>
            </tr>
          </thead>
          <tbody #users id="users">
            <tr *ngIf="(filteredOptions$ | async)?.length === 0; else options"
                (click)="searchUser(autoInput.value)">
              <td class="new-addressee">
                <span class="new-addressee-label">{{'share.new-addressee' | translate | uppercase}}</span>
                <span class="user-main-infos">{{autoInput.value}}</span>
              </td>
            </tr>
            <ng-template #options>
            <tr class="user-table-row option-item w-100"
              *ngFor="let user of filteredOptions$ | async; let index = index"
              (click)="onSelectAddressee(user)"
              [ngClass]="{
              'active': index === indexSelected,
              'disabled' : isInSameCompany(user) && isGuestUser(user) && user.userLevel.remaining_credits < 1
              }"
              id="{{user.email}}"
            >
              <td [ngClass]="{
                  'user-main': true,
                  'user-selected': selectedUser?.email === user.email
                  }"
                  appShowIfTruncated
                  [ngbTooltip]="tooltipEmailCaller"
                  tooltipClass="share-tooltip-default tooltip-max-300"
                  disableTooltip="true"
                  placement="bottom"
                  container="body"
              >
              <ng-template #tooltipEmailCaller>
                <ng-container *ngTemplateOutlet="tooltipEmail; context: {$implicit: user.email}"></ng-container>
              </ng-template>
              <ng-container *ngIf="isSimpleUser(user) && isInSameCompany(user); else noNameInTable">
                <span class="user-main-infos">{{user.fullName | titlecase}}&nbsp;</span>
                <span class="user-details">({{user.email}})</span>
              </ng-container>
              <ng-template #noNameInTable>
                  <span [ngClass]="{
                    'user-main-infos': true,
                    'crossed': isInSameCompany(user) && isGuestUser(user) && user.userLevel.remaining_credits < 1
                  }">
                    {{user.email}}
                  </span>
              </ng-template>
              </td>
              <td>
                <div class="width-fit-content"
                  [ngbTooltip]="getCreditTooltip(user)"
                  tooltipClass="share-tooltip-default tooltip-fixed-226"
                  placement="bottom"
                  container="body">
                <span *ngIf="user.newDest; else existingAddressee" class="unlimited-credit">
                  {{'share.new-addressee-short' | translate}}
                </span>
                <ng-template #existingAddressee>
                  <ng-container *ngIf="isInSameCompany(user); else differentCompany">
                    <span *ngIf="isSimpleUser(user)" class="unlimited-credit">
                      {{'share.unlimited-credits-subscriber' | translate}}
                    </span>
                    <span *ngIf="isGuestUser(user)"
                          [ngClass]="{
                           'credit-span': true,
                           'remaining-credit-color': user.userLevel.remaining_credits > 0,
                           'no-credit-color': user.userLevel.remaining_credits < 1
                          }"
                    >
                      {{user.userLevel.remaining_credits}}/{{user.userLevel.max_credit}}
                      {{'share.credits' | translate | lowercase}}
                    </span>
                  </ng-container>
                  <ng-template #differentCompany>
                    <span class="unavailable-credit credit-span">
                      {{'share.unavailable' | translate}}
                    </span>
                  </ng-template>
                </ng-template>
                </div>
            </td>
            </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>


      <div class="message-part w-100">

      <div class="sub-title">
        {{'share.message' | translate | uppercase}}
        <span class="font-italic"> {{'share.optional' | translate | lowercase}}</span>
      </div>

      <div class="container-textarea">
        <textarea appOutlineNone
          class="input-share-message"
          maxlength="1000"
          [(ngModel)]="shareMessage"
        >
        </textarea>
        <span class="counter-textarea">{{shareMessage.length}}/1000</span>
      </div>

    </div>
      <mat-divider class="ignore-parent-padding color-basic-500"></mat-divider>
      <div class="validation-part w-100 d-table">
        <div class="w-100 d-table-cell"> </div>
        <div class="action d-table-cell align-middle"
             (click)="onClosePanel()">{{'share.close' | translate | titlecase }}
        </div>
        <div class=" d-table-cell align-middle">
          <button class="btn-send" type="submit" onclick="this.blur()"
                  (click)="onSubmit()"
                  [disabled]="!selectedUser">
            <span>{{'share.send' | translate | uppercase}}</span>
          </button>
        </div>
      </div>

    </div>
  </div>
</mat-expansion-panel>
<ng-template #tooltipInfo>
  <span>{{'share.tooltipInfo1' | translate}}</span>
  <br/>
  <br/>
  <span>{{'share.tooltipInfo2' | translate}}</span>
</ng-template>
<ng-template #tooltipCreditLeft>
  <span>{{'share.tooltipCreditLeft1' | translate}}</span>
  <br/>
  <br/>
  <span>{{'share.tooltipCreditLeft2' | translate}}</span>
</ng-template>
<ng-template #tooltipNoCreditLeft>
  <span>{{'share.tooltipNoCreditLeft' | translate}}</span>
</ng-template>
<ng-template #tooltipCreditUnavailable>
  <span>{{'share.tooltipCreditUnavailable' | translate}}</span>
</ng-template>
<ng-template #tooltipEmail let-email>
    <span>{{email}}</span>
</ng-template>
