export const environment = {
  production: true,
  backEndUrl: 'https://api.beta-goodwill.explain.fr/api/v2/',
  backV2EndUrl: 'https://api.beta-goodwill.explain.fr/api/v1/',
  withBlob: false,
  withMunicipal: true,
  withServiceWorker: true,
  ROLLBAR_ENV: 'staging',
  npsFormShouldBeDisplayed: true,
  npsFormUrl: 'https://satisfaction.staging-goodwill.explain.fr/',
  retrieveResponseUrl: 'https://scagn3utp8.execute-api.eu-west-3.amazonaws.com/staging',
  npsMinAge: 30,
  npsMinDate: '2022-06-20',
  npsMaxDate: '2022-07-22',
  userTrackerApiUrl: 'https://4nach7l1ee.execute-api.eu-west-3.amazonaws.com/staging/lam-dev-user-tracker',
  exportAdminDocumentApiUrl: 'https://export.external.staging-goodwill.explain.fr/',
  appBaseUrl: 'https://beta-goodwill.explain.fr',
  s3DeduplicatedDigestResultsBaseUrl: `https://s3-dev-deduplicated-digest-results.s3.eu-west-3.amazonaws.com`
};
