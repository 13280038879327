import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JsonGettersService {
  sessionLimiters: any;

  constructor(
    private http: HttpClient,
  ) {
  }

  getDisplayUpdateModal(): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Content-Type': 'application/json'
      })
    };

    return this.http.get('/assets/mep-configuration/version.json', httpOptions)
      .pipe(map(data => {
        return data['display_update_modal'];
      }));
  }


  getComplexityLimiters(): Observable<any> {
    return this.http.get('/assets/ux-ui-configuration/complexity-limiters.json')
      .pipe(map(data => {
        this.sessionLimiters = data;
        return data;
      }));
  }

}
