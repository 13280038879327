import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() show = false;
  @Input() center = false;
  @Input() topChoice = '';
  @Input() inView = false;
  @Input() inSearch = false;
  @Input() noResult = false;
  @Input() inImpacterView = false;

  ngOnInit(): void {
  }

}
