import { Component, TemplateRef } from '@angular/core';
import { ToastMessageStackService } from '../../shared/services/toast-message-stack.service';

@Component({
    selector: 'app-toast-message-stack',
    templateUrl: './toast-message-stack.component.html',
    styleUrls: ['./toast-message-stack.component.scss'],
    host: { '[class.ngb-toasts]': 'true'}
})

export class ToastMessageStackComponent {

    constructor(public toastService: ToastMessageStackService) {
    }

    isTemplate(toast) {
        return toast.textOrTpl instanceof TemplateRef;
    }

}
