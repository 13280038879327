<div class="template-container">
  <div class="card-block">
    <div class="block-body d-flex">
      <div class="block-container">
        <div class="font-weight-bold title">{{'template.configuration' | translate}}</div>
        <div class="d-flex justify-content-between">
          <div class="term-block">
            <div class="font-weight-600">{{'template.photovoltaic' | translate}}</div>
            <div class="font-weight-600">{{'template.solar' | translate}}</div>
          </div>
          <div class="term-block">
            <div class="font-weight-600">{{'template.petition' | translate}}</div>
            <div class="font-weight-600">{{'template.unfavorable' | translate}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="block-footer">
      <div class="font-weight-bold title">{{'template.results' | translate}}</div>
      <div class="request-chain">
        <div>
          <div class="d-inline-block color-basic-600 font-weight-600 pl-0">(</div>
          <div class="d-inline-block font-weight-bold">{{'template.photovoltaic' | translate}}</div>
          <div class="d-inline-block color-basic-600 font-weight-600">{{'content.near-by' | translate}}</div>
          <div class="d-inline-block font-weight-bold">{{'template.petition' | translate}}</div>
          <div class="d-inline-block color-basic-600 font-weight-600">)</div>
        </div>
        <div>
          <div class="d-inline-block color-basic-600 font-weight-600">{{'content.or' | translate}}</div>
          <div class="d-inline-block color-basic-600 font-weight-600">(</div>
          <div class="d-inline-block font-weight-bold">{{'template.photovoltaic' | translate}}</div>
          <div class="d-inline-block color-basic-600 font-weight-600">{{'content.near-by' | translate}}</div>
          <div class="d-inline-block font-weight-bold">{{'template.unfavorable' | translate}}</div>
          <div class="d-inline-block color-basic-600 font-weight-600">)</div>
        </div>
        <div>
          <div class="d-inline-block color-basic-600 font-weight-600">{{'content.or' | translate}}</div>
          <div class="d-inline-block color-basic-600 font-weight-600">(</div>
          <div class="d-inline-block font-weight-bold">{{'template.solar' | translate}}</div>
          <div class="d-inline-block color-basic-600 font-weight-600">{{'content.near-by' | translate}}</div>
          <div class="d-inline-block font-weight-bold">{{'template.petition' | translate}}</div>
          <div class="d-inline-block color-basic-600 font-weight-600">)</div>
        </div>
        <div>
          <div class="d-inline-block color-basic-600 font-weight-600">{{'content.or' | translate}}</div>
          <div class="d-inline-block color-basic-600 font-weight-600">(</div>
          <div class="d-inline-block font-weight-bold">{{'template.solar' | translate}}</div>
          <div class="d-inline-block color-basic-600 font-weight-600">{{'content.near-by' | translate}}</div>
          <div class="d-inline-block font-weight-bold">{{'template.unfavorable' | translate}}</div>
          <div class="d-inline-block color-basic-600 font-weight-600 pr-0">)</div>
        </div>
      </div>
    </div>
  </div>
  <app-example-guide-template [examples]="examples"></app-example-guide-template>
</div>
