import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FunnelContextEnum, StepsService, StyleLists } from '../../shared/services/steps.service';
import { AlertSettingService } from '../../shared/services/alert-setting.service';
import { ToastMessageService } from '../../shared/services/toast-message.service';
import { Router } from '@angular/router';
import { ApiService } from '../../shared/services/api/api.service';
import { Subscription } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { StepModel } from '../../core/step/step.model';
import { UserTrackerService } from '../../shared/services/tracking/user-tracker.service';

@Component({
  selector: 'app-step-header',
  templateUrl: './step-header.component.html',
  styleUrls: ['./step-header.component.scss']
})
export class StepHeaderComponent implements OnInit, OnDestroy {
  @Input() stepTitle: string;
  @Input() step: StepModel;
  @Input() stepSubTitle: string;
  @Input() stepSubTitleEmptySelection: string;
  @Input() alertId: string;
  @Input() content: number;
  @Input() myClass: string;
  @Input() warning: boolean;
  @Input() error: boolean;

  @Input() valueName = '';
  @Input() maxSelectionValue = 0;
  @Input() currentSelectionValue = 0;
  @Input() selectionComplexityLimitationValue = 0;
  @Input() otherUserAlerts = [];
  public progressBarWidth = 152;
  styleLists: StyleLists;
  validationButtonText = 'button.create-alert';
  subscription = new Subscription();
  awaitingSubmission = false;

  constructor(private stepsService: StepsService,
              private alertSettingService: AlertSettingService,
              private router: Router,
              private toastMessageService: ToastMessageService,
              private apiService: ApiService,
              private userTrackerService: UserTrackerService
              ) {
    this.styleLists = new StyleLists();
  }

  ngOnInit(): void {
    // Management of text displayed on validation button.
    this.attributeValidationButtonText();

    // Custom management of the progressbar width.
    this.subscription.add(
      this.stepsService.getCurrentStep$()
      .subscribe(step => {
        if (step.stepIndex === 0) {
          this.progressBarWidth = 152;
        }
        if (step.stepIndex === 1) {
          this.progressBarWidth = 122;
        }
      })
    );

    this.subscription.add(
      this.stepsService.styleListsReplaySubject
        .pipe(debounceTime(300))
        .subscribe((styleLists) => {
          this.styleLists = styleLists;
        })
    );
  }

  attributeValidationButtonText() {
    switch (this.stepsService.funnelContext) {
      case FunnelContextEnum.MODIFICATION:
        this.validationButtonText = 'button.save-modification';
        break;
      case FunnelContextEnum.DUPLICATION:
        this.validationButtonText = 'button.save-duplication';
        break;
      default:
        this.validationButtonText = 'button.create-alert';
        break;
    }
  }

  get currentStep() {
    return this.stepsService.getCurrentStep$();
  }

  onNextStep() {
    if (!this.stepsService.isLastStep()) {
      this.stepsService.moveToNextStep();
    } else {
      this.onSubmit();
    }
  }

  onPrevStep() {
    this.stepsService.moveToPrevStep();
  }

  isLastStep() {
    return this.stepsService.isLastStep();
  }

  isFirstStep() {
    return this.stepsService.isFirstStep();
  }

  onSubmit(): void {
    this.awaitingSubmission = true;
    const setting = this.alertSettingService.getSetting();
    if (this.alertId) {
      setting['id'] = this.alertId;
    }
    this.apiService.alert.createAlert(setting)
      .pipe(first())
      .subscribe((res) => {
        this.trackFirstKPIOnDuplicationFeature(res?.id);

        const name = this.alertSettingService.getName();
        const isDuplication = this.stepsService.funnelContext === FunnelContextEnum.DUPLICATION;
        this.toastMessageService.setFinished(true, name, this.alertId, isDuplication);
        this.router.navigate(['/alert']).then();
      }, () => {
        this.awaitingSubmission = false;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  //region *** TRACKING ***

  /**
   * Method to find out if the user had an identical alert at the moment of the creation / modification / duplication.
   * We keep track of all almost identical (lookalike) alert ids.
   */
  trackFirstKPIOnDuplicationFeature(alertId: number) {
    const identicalTerritoriesAlertsIds = [];
    const identicalTopicsAlertsIds = [];
    let currentAlertTerritoryUids = [];
    let currentAlertFRDEPAs = [];
    let duplicateFromAlertFRDEPAs = [];
    let duplicateFromAlertTopicIds = [];
    const currentAlertTopicIds = this.alertSettingService?.topicIds.map(elm => Number(elm)).sort((a, b) => a - b);

    this.alertSettingService?.territories?.forEach((elm) => {
      // Element object is like {territory: "FRDEPA01", values: ["FRDEPA01", "FRCOMM122133", ...]}
      elm['values']?.forEach((terr) => {
        currentAlertTerritoryUids.push(terr);
        if (String(terr).includes('FRDEPA')) {
          currentAlertFRDEPAs.push(terr);
        }
      });
    });
    currentAlertTerritoryUids = currentAlertTerritoryUids.sort();
    currentAlertFRDEPAs = currentAlertFRDEPAs.sort();

    if (this.stepsService.funnelContext === FunnelContextEnum.DUPLICATION) {
      this.stepsService.duplicatedFromAlertSettings?.territories?.forEach((digestTerritory) => {
        digestTerritory.values?.filter(elm => elm.includes('FRDEPA'))?.forEach((departmentCode) => {
          duplicateFromAlertFRDEPAs.push(departmentCode);
        });
      });
      duplicateFromAlertFRDEPAs = duplicateFromAlertFRDEPAs.sort();
      duplicateFromAlertTopicIds = this.stepsService.duplicatedFromAlertSettings?.topicsIds?.sort((a, b) => a - b);
    }

    this.otherUserAlerts?.forEach((alert) => {
      const alertTopicsIds = alert.topics?.map((topic) => topic.id).sort();
      // alert.territory_uid is like : "FRDEPA01, ..., FRCOMM122133"
      const alertTerritories = alert?.territory_uid?.split(',').sort();
      // Checking existence of identical alerts comparing territories.
      if (JSON.stringify(alertTerritories) === JSON.stringify(currentAlertTerritoryUids)) {
        identicalTerritoriesAlertsIds.push(alert.id);
      }
      // Checking existence of identical alerts comparing topics.
      if (JSON.stringify(alertTopicsIds) === JSON.stringify(currentAlertTopicIds)) {
        identicalTopicsAlertsIds.push(alert.id);
      }
    });

    const body = {
      event_timestamp: (new Date()).toISOString(),
      event_type: 'lookalike-alerts-on-funnel-submission',
      ...this.userTrackerService.buildBasicUserInformations(),
      digest_id: alertId,
      digest_departments: currentAlertFRDEPAs,
      digest_topics: currentAlertTopicIds,
      submission_type: this.stepsService.funnelContext,
      duplicated_from_digest_id: this.stepsService.duplicatedFromAlertId,
      duplicated_from_digest_departments: duplicateFromAlertFRDEPAs,
      duplicated_from_digest_topics: duplicateFromAlertTopicIds,
      identical_digest_ids_by_territories: identicalTerritoriesAlertsIds,
      identical_digest_ids_by_topics: identicalTopicsAlertsIds
    };

    this.userTrackerService.track(body)
      .pipe(first())
      .subscribe();
  }

  //endregion

}
