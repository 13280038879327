<div class="treeview-header">
  <ng-template [ngTemplateOutlet]="headerTemplate"
    [ngTemplateOutletContext]="headerTemplateContext">
  </ng-template>
</div>
<div id="loader" *ngIf="!items">
  <ng-content></ng-content>
</div>
<div [ngSwitch]="!!items?.length">
  <div *ngSwitchCase="true" class="treeview-container" [style.max-height.px]="maxHeight">
    <ngx-treeview-item class="main-treeview-item" *ngFor="let item of items" [config]="config" [item]="item"
      [template]="itemTemplate" (checkedChange)="onItemCheckedChange()" [textChange]="textChange">
    </ngx-treeview-item>
  </div>
  <div *ngSwitchCase="false" class="treeview-text">
    <ng-template [ngTemplateOutlet]="notItemFoundTemplate">
    </ng-template>
  </div>
</div>
