import { Component, Input, OnInit } from '@angular/core';
import { DisplayedPublicationType } from '../../../models/displayed-publication-type';

@Component({
  selector: 'app-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss']
})
export class MetadataComponent implements OnInit {
  @Input() publicationDate: Date;
  @Input() source: string;
  @Input() website: string;
  @Input() displayedPublicationType?: string;
  @Input() documentType: string;

  DisplayedPublicationType = DisplayedPublicationType;

  ngOnInit(): void {
    // Manage case of displayedPublicationType not provided
    if (!this.displayedPublicationType) {
      this.displayedPublicationType = DisplayedPublicationType.NON_DETECTED;
    }
  }

}
