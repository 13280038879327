import { EntityService } from '../../shared/services/entity.service';

export abstract class Entity {
  entities: Array<object>;
  totalCount: number;
  adminDocTotalCount: number;
  nbPages = 0;
  dataUpdated = false;
  protected constructor(protected entityService: EntityService) {
  }

  abstract buildBody(search): object;

  setEntities(data: object, limit: number, offset: number): void {
    this.entities = data['data'];
    this.totalCount = data['total_count'];
    this.adminDocTotalCount = data['adminDocTotalCount'];
    // if (offset === 0) {
      this.nbPages = Math.ceil(this.totalCount / limit);
      this.dataUpdated = !this.dataUpdated;
    // }
  }
}
