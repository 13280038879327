import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../models/user';


@Component({
  selector: 'app-modal-explanation-share',
  templateUrl: './modal-explanation-share.component.html',
  styleUrls: ['./modal-explanation-share.component.scss']
})
export class ModalExplanationShareComponent implements OnInit {

  @Input() error: boolean;
  @Input() user: User;
  @Input() personalGroupAccountId: number;
  @Input() personalDomainName: string;
  @Input() creditUpdate = true;
  confirmationNumber: number;
  constructor(private activeModal: NgbActiveModal) {
  }

  close() {
    this.activeModal.close();
  }

  ngOnInit(): void {
    this.updateExplanation();
  }

  updateExplanation() {
    if (this.error) {
      this.confirmationNumber = 4;
    } else if (this.personalGroupAccountId === this.user.groupAccountId || this.haveSameDomain()) {
      if (this.isSimpleUser()) {
        this.confirmationNumber = 1;
      } else if (this.user.userLevel.remaining_credits >= 0) {
        if (this.creditUpdate) {
          this.confirmationNumber = 2;
        } else {
          this.confirmationNumber = 5;
        }
      }
    } else {
      if (this.isSimpleUser() || this.user.userLevel.remaining_credits > 0) {
        this.confirmationNumber = 3;
      } else {
        this.confirmationNumber = 4;
      }
    }
  }

  isSimpleUser(): boolean {
    return this.user.userLevel.level !== 'guest_user' && this.user.isActive;
  }

  haveSameDomain(): boolean {
    const sharedWithEmailDomain = this.user.email.substring(this.user.email.lastIndexOf('@') + 1);

    return this.personalDomainName === sharedWithEmailDomain;
  }

}
