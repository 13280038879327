<a [routerLink]="allowOnClick ? docId : null"
   (contextmenu)="onClickCard()"
   (click)="onClickCard()"
   [state]="{context: text}">
  <div [ngClass]="{'preview': isPreview}" class="card-board">
    <div class="row">

      <div class="col-9 card-info d-flex">
        <div class="d-flex align-self-center">
          <div class="icon icon--calendar"></div>
          <div *ngIf="publicationDate" class="text-capitalize">{{publicationDate | date:'dd MMMM yyyy':'':'fr' }}</div>
          <div *ngIf="!publicationDate" class="text-capitalize">{{'content.date_not_detected' | translate}}</div>
        </div>
        <div class="d-flex align-self-center">
          <mat-icon svgIcon="website" class="icon-16 fill-basic-600 mr-1"></mat-icon>
          <div>{{website}}</div>
        </div>

      </div>

      <div class="col-3 occurrence-and-folder d-flex justify-content-end position-static">
        <app-occurrence-and-folder-icon-badge
          class="d-flex"
          [folderId]="folderId"
          [nbOccurrences]="nbOccurrences"
          [showOccurrences]="showOccurrences"
          [bookmarkPaths]="bookmarkPaths"
          [displayBookmarkCreationDate]="displayBookmarkCreationDate">
        </app-occurrence-and-folder-icon-badge>
      </div>
    </div>

    <div class="card-title position-static text-truncate">{{title}}</div>

    <div class="publication-category d-flex align-self-center">
      <mat-icon *ngIf="(displayedPublicationType !== DisplayedPublicationType.NON_DETECTED)"
                class="fill-primary-500 icon-16 mr-1"
                svgIcon="docs-primary-500"></mat-icon>
      <mat-icon *ngIf="(displayedPublicationType === DisplayedPublicationType.NON_DETECTED)"
                svgIcon="docs-basic-600" class="icon-16 mr-1"></mat-icon>
      <div [ngClass]="(displayedPublicationType === DisplayedPublicationType.NON_DETECTED) ? 'color-basic-600' : 'color-primary-500'"
           [style.font-style]="(displayedPublicationType === DisplayedPublicationType.NON_DETECTED) ? 'italic' : 'normal'"
           class="category-name font-weight-bold">{{displayedPublicationType}}</div>
    </div>

    <div class="card-text" [innerHTML]="text"></div>
  </div>
</a>
