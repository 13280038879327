<div class="metadata">
  <div *ngIf="documentType === 'admin_doc'" class="displayed-publication-type">
    <mat-icon *ngIf="displayedPublicationType === DisplayedPublicationType.NON_DETECTED"
              [ngClass]="(displayedPublicationType === DisplayedPublicationType.NON_DETECTED) ? 'fill-basic-600' : 'fill-primary-500'"
              svgIcon="docs-basic-600" class="icon-20 fill-basic-600 strict mr-2"></mat-icon>
    <span *ngIf="displayedPublicationType === DisplayedPublicationType.NON_DETECTED"
          class="color-basic-600" [style.font-style]="'italic'">
      {{displayedPublicationType}}</span>
    <mat-icon *ngIf="displayedPublicationType !== DisplayedPublicationType.NON_DETECTED"
              svgIcon="docs" class="icon-20 fill-primary-500 strict mr-2"></mat-icon>
    <span *ngIf="displayedPublicationType !== DisplayedPublicationType.NON_DETECTED"
          class="color-primary-500">
      {{displayedPublicationType}}</span>
  </div>
  <div class="publicationDate">
    <mat-icon svgIcon="calendar" class="icon-20 strict fill-basic-600 mr-2"></mat-icon>
    <span *ngIf="!publicationDate; else publicationDateDisplay">{{'content.date_not_detected' | translate}}</span>
  </div>
  <div *ngIf="source" class="source">
    <mat-icon svgIcon="article" class="icon-20 strict fill-basic-600 mr-2"></mat-icon>
    <span>{{source}}</span>
  </div>
  <div *ngIf="website" class="website">
    <mat-icon svgIcon="website" class="icon-20 strict fill-basic-600 mr-2"></mat-icon>
    <span>{{website}}</span>
  </div>
</div>

<ng-template #publicationDateDisplay>
    <span *ngIf="source">{{publicationDate | date: 'dd MMMM yyyy':'':'fr'}}</span>
    <span *ngIf="website">{{publicationDate | date: 'dd MMMM yyyy':'':'fr' | titlecase}}</span>
</ng-template>
