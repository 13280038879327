import { TreeviewItem } from '../../lib/ngx-treeview';
import { Territory } from '../../models/territory/territory';

export const TerritoryHelper = {
  transferToTreeview,
  transferToList
};

function containsTerritoryOutsideDepartment(element: Territory) {
  return (element.name === 'outside_department') || (element.name === 'outside_Metropolis_Greater_Paris') ;
}

function getTerritoryChildren(territory: Territory) {
  let children = territory.territories.filter((child) => !child.isSyndicate);

  if (territory.kind === 'FREPCI' && territory.territories.length >= 2 && territory.territories[1].name === 'outside_department') {
    const territory_outside_department = new Territory({
      name: 'outside_department',
      territories: [],
      search_key: '',
      kind: '',
      code: '',
      outside_department_count: territory.territories[1].territories.length
    });

    if (territory.territories[0].territories[0].name === 'Paris') {
      territory_outside_department.name = 'outside_Metropolis_Greater_Paris';
    }
    if (!territory.territories[0].territories.some(containsTerritoryOutsideDepartment)) {
      territory.territories[0].territories.push(territory_outside_department);
    }

    children = territory.territories[0].territories;

  }
  return children;
}
function transferToTreeview(
  territories: Array<Territory>,
  isRoot,
  collapsed = false,
  checked: boolean | Array<string> = true
): TreeviewItem[] {
  return territories.map((territory) => {
    const children = getTerritoryChildren(territory);
    const uid = territory.uid;
    return new TreeviewItem({
      text: territory.name,
      value: territory.uid,
      isRoot: isRoot,
      collapsed: collapsed && !isRoot,
      outside_department_count: territory.outside_department_count,
      infos: {
        kind: territory.kind,
        code: territory.code,
        articleCount: territory.articleCount,
        adminDocCount: territory.adminDocCount
      },
      checked: typeof checked === 'boolean' ? checked : checked.includes(uid),
      children: transferToTreeview(children, false, collapsed, checked)
    });
  });
}

function transferToList(territories: Array<Territory>): Set<string> {
  const territoriesList = new Set<string>();
  territories.forEach((territory) => {
    if (territory.uid) {
      territoriesList.add(territory.uid);
    }
    const children = getTerritoryChildren(territory);
    transferToList(children).forEach(c => territoriesList.add(c));
  });
  return territoriesList;
}
export function territoriesToUIDArray(territories: Array<Territory>) {
  return territories.reduce((prev, territory) => [...prev, territory.uid, ...territoriesToUIDArray(territory.territories)], []);
}

export function makeSyndicatesTreeview(tree: {[p: string]: Territory[]}, rootText= 'All') {
  const scopeFilter = Object.entries(tree).map(
    ([scope, syndicates]) => new TreeviewItem({
      text: scope,
      value: '',
      checked: true,
      collapsed: true,
      children: syndicates.map((syndicate) => new TreeviewItem({
        text: syndicate.name,
        value: syndicate.uid,
        checked: true,
        infos: {
          fullname: syndicate.syndicateName
        }
      }))
    }));
  return [new TreeviewItem({
    text: rootText,
    value: '',
    isRoot: true,
    checked: true,
    children: scopeFilter
  })];
}
