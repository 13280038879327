import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../shared/services/authentication.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public displayPersonnalSettings: boolean;
  public isClickedT1: boolean;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    this.displayPersonnalSettings = false;
    this.isClickedT1 = false;
  }

  ngOnInit() {
    // console.log(document.hasFocus());
  }

  onLogOut() {
    this.authenticationService.logout();
    this.router.navigate(['/login']).then();
    this.displayPersonnalSettings = false;
  }

  closeTooltipT1() {
    this.isClickedT1 = true;
  }
  openTooltipT1() {
    this.isClickedT1 = false;
  }

}
