import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { StepModel } from '../../core/step/step.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FunnelContextEnum, StepsService } from '../../shared/services/steps.service';
import { AlertSettingService } from '../../shared/services/alert-setting.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';
import { ToastMessageService } from '../../shared/services/toast-message.service';
import { first } from 'rxjs/operators';
import { DigestSettings } from '../../models/digest-settings';
import { ApiService } from '../../shared/services/api/api.service';
import { JsonGettersService } from '../../shared/services/json-getters.service';
import { DbAlertWithUsers } from '../../models/alert';

interface ComplexityLimiters {
  department_limitation: number;
  topics_limitation: number;
  topic_complexity_limitation: number;
  warning_content_threshold: number;
  alert_content_threshold: number;
}

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})

export class WizardComponent implements OnInit, OnDestroy {
  currentStep$: Observable<StepModel>;
  alertId: string;
  settings: DigestSettings;
  nbPreviewedDocuments: number;
  load = true;
  currentStep = null;

  maxValueDepartments = 0;
  currentValueDepartments = 0;
  maxValueTopics = 0;
  currentValueTopics = 0;
  complexityLimiters: ComplexityLimiters;
  steps: StepModel[];
  subscription = new Subscription();
  alertSettingRetrieved$ = new ReplaySubject<boolean>(0);
  otherUserAlerts = [];
  modalOptions = { backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-basic-title', centered: true } as NgbModalOptions;

  @ViewChild('overviewStepContainer') overviewStepContainer!: ElementRef;
  isScrolledDown138px = false;
  previewsListScrollValue = 0;

  constructor(private stepsService: StepsService,
              private router: Router,
              private modalService: NgbModal,
              private jsonGettersService: JsonGettersService,
              private apiService: ApiService,
              private alertSettingService: AlertSettingService,
              private route: ActivatedRoute,
              private toastMessageService: ToastMessageService) {}

  ngOnInit(): void {
    // Reset steps
    this.stepsService.resetSteps();

    // Management of cases alert creation and modification.
    this.route.params
      .pipe(first())
      .subscribe((params) => {
        this.alertId = params['alertId'];
        if (this.alertId) {
          this.stepsService.funnelContext = FunnelContextEnum.MODIFICATION;
          // Retrieving settings.
          this.apiService.alert.getAlertSetting(this.alertId)
            .pipe(first())
            .subscribe((data) => {
              this.settings = data;
              this.alertSettingRetrieved$.next(true);
            });
        } else {
          this.stepsService.funnelContext = FunnelContextEnum.CREATION;
        }
      });

    // Management of funnelContext in case of alert duplication.
    this.route.queryParams
      .pipe(first())
      .subscribe((queryParams) => {
        if (queryParams?.funnelContext) { // only in case of duplication.
          this.alertId = queryParams.alertId;
          this.stepsService.funnelContext = FunnelContextEnum.DUPLICATION;
          this.stepsService.duplicatedFromAlertId = Number(queryParams.fromAlertId);
          // Retrieving settings.
          this.apiService.alert.getAlertSetting(this.alertId)
            .pipe(first())
            .subscribe((data: DigestSettings) => {
              this.settings = data;
              this.alertSettingRetrieved$.next(true);
              this.stepsService.duplicatedFromAlertSettings = data;
            });
        }
      });

    this.subscription.add(
      this.stepsService.getSteps$()
        .subscribe(steps => {
          this.steps = steps;
        })
    );

    this.currentStep$ = this.stepsService.getCurrentStep$();

    // retrieve of current step value from stepsService
    this.subscription.add(
      this.stepsService.getCurrentStep$()
        .subscribe((currentStep) => {
          this.load = this.stepsService.arePreviewsRetrieved;
          this.currentStep = currentStep;
        })
    );

    this.toastMessageService.setFinished(false);

    // No need to add on subscription here thanks to the pipe(first())
    this.jsonGettersService.getComplexityLimiters()
      .pipe(first())
      .subscribe(complexityLimiters => {
        this.complexityLimiters = complexityLimiters;
      });
  }

  lastStepDestroyed() {
    this.scrollAlertPreviewsResultsUp();
  }

  onOverviewResultsScroll() {
    // Note that 138px is the height of the thin abstract overview panel.
    // This value will need to be changed when increasing number of sources.
    this.previewsListScrollValue = this.overviewStepContainer.nativeElement.scrollTop;
    this.isScrolledDown138px = this.previewsListScrollValue > 138;
  }

  scrollAlertPreviewsResultsUp() {
    this.overviewStepContainer.nativeElement.scrollTop = 0;
    this.onOverviewResultsScroll(); // to set previewListScroll to 0 and isScroll to false;
  }

  setNbPreviewedDocuments(event) {
    this.nbPreviewedDocuments = event;
    this.load = true;
    this.scrollAlertPreviewsResultsUp();
  }

  onRetrieveProgressBarMaxValue(event: any) {
    switch (event.context) {
      case 'departments' : {
        this.maxValueDepartments = event.total;
        break;
      }
      case 'topics' : {
        this.maxValueTopics = event.total;
        break;
      }
    }
  }

  onRetrieveProgressBarCurrentValue(event: any) {
    switch (event.context) {
      case 'departments' : {
        this.currentValueDepartments = event.selected;
        break;
      }
      case 'topics' : {
        this.currentValueTopics = event.selected;
        break;
      }
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    event.preventDefault();
  }

  openModal() {
    const modal = this.modalService.open(ModalConfirmationComponent, this.modalOptions);
    modal.componentInstance.returnUrl = '/alert';
    if (this.alertId) {
      modal.componentInstance.title = this.stepsService.funnelContext === FunnelContextEnum.MODIFICATION ?
        'wizard.modal-edit-cancel-title' : 'wizard.modal-duplicate-cancel-title';
      modal.componentInstance.content = this.stepsService.funnelContext === FunnelContextEnum.MODIFICATION ?
        'wizard.modal-edit-cancel-content' : 'wizard.modal-duplicate-cancel-content';
      modal.componentInstance.btnName = this.stepsService.funnelContext === FunnelContextEnum.MODIFICATION ?
        'button.modal-edit-cancel-yes' : 'button.modal-duplicate-cancel-yes';

      // Add a custom cancel function to manage suppression of newly duplicated alert in case of cancellation.
      if (this.stepsService.funnelContext === FunnelContextEnum.DUPLICATION) {
        modal.componentInstance.delayToApplyOnCancellation = 1000;
        modal.componentInstance.cancellationFunction = () => {
          return this.apiService.alert.deleteAlert(Number(this.alertId))
            .pipe(first());
        };
      }
    } else {
      modal.componentInstance.title = 'wizard.modal-cancel-title';
      modal.componentInstance.content = 'wizard.modal-cancel-content';
      modal.componentInstance.btnName = 'button.modal-cancel-yes';
    }
  }

  updateOtherUserAlerts(otherUserAlerts: DbAlertWithUsers[]) {
    this.otherUserAlerts = otherUserAlerts;
  }

  ngOnDestroy(): void {
    this.alertSettingService.reset();
    this.subscription.unsubscribe();
  }

}
