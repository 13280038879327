<div class="wizard-container">

  <app-steps [alertId]="alertId">
    <div class="align-self-center d-flex wizard-header">
      <div class="align-self-center d-flex cancel-button" (click)="openModal()">
        <div class="align-self-center icon-wizard icon-wizard--close"></div>
        <div class="align-self-center">{{'button.cancel' | translate | uppercase}}</div>
      </div>
    </div>
  </app-steps>

  <div class="step-page-container">
    <div [hidden]="(currentStep$ | async)?.stepIndex !== 0"
         class="form-step">
      <app-step-header [myClass]="'form-header-container'"
                       [step]="steps[0]"
                       [alertId]="alertId"
                       [maxSelectionValue]="maxValueDepartments"
                       [currentSelectionValue]="currentValueDepartments"
                       [selectionComplexityLimitationValue]="complexityLimiters?.department_limitation"
                       [valueName]="'wizard.perimeters'"
                       [stepTitle]="'wizard.perimeter-step-title'"
                       [stepSubTitle]="(maxValueDepartments <= complexityLimiters?.department_limitation) ?
                       'wizard.perimeter-step-sub-title' : 'wizard.perimeter-step-limiter-sub-title'"
                       [stepSubTitleEmptySelection]="(maxValueDepartments <= complexityLimiters?.department_limitation) ?
                       'wizard.perimeter-step-sub-title-empty' : 'wizard.perimeter-step-limiter-sub-title-empty'">
      </app-step-header>
      <div class="step-container">
        <app-perimeter-step-template (totalDepartments)="onRetrieveProgressBarMaxValue($event)"
                                     (selectedDepartments)="onRetrieveProgressBarCurrentValue($event)"
                                     [isComponentHidden]="(currentStep$ | async)?.stepIndex !== 0"
                                     [territoriesSetting]="settings?.territories"
                                     [maxDepartmentNumber]="complexityLimiters?.department_limitation">
        </app-perimeter-step-template>
      </div>
    </div>

    <div [hidden]="(currentStep$ | async)?.stepIndex !== 1"
         class="form-step">
      <app-step-header [myClass]="'form-header-container'"
                       [alertId]="alertId"
                       [step]="steps[1]"
                       [maxSelectionValue]="maxValueTopics"
                       [currentSelectionValue]="currentValueTopics"
                       [selectionComplexityLimitationValue]="complexityLimiters?.topics_limitation"
                       [valueName]="'wizard.topics'"
                       [stepTitle]="'wizard.topics-step-title'"
                       [stepSubTitle]="(maxValueTopics <= complexityLimiters?.topics_limitation) ?
                                       'wizard.topics-step-sub-title' : 'wizard.topics-step-limiter-sub-title'"
                       [stepSubTitleEmptySelection]="(maxValueTopics <= complexityLimiters?.topics_limitation) ?
                                       'wizard.topics-step-sub-title-empty' : 'wizard.topics-step-limiter-sub-title-empty'">
      </app-step-header>
      <div class="step-container">
        <app-topic-step-template (totalTopics)="onRetrieveProgressBarMaxValue($event)"
                                 (selectedTopics)="onRetrieveProgressBarCurrentValue($event)"
                                 [topicsSetting]="settings?.topicsIds"
                                 [limiterAchieved]="currentValueTopics >= complexityLimiters?.topics_limitation">
        </app-topic-step-template>
      </div>
    </div>

    <div [hidden]="(currentStep$ | async)?.stepIndex !== 2"
         class="form-step">
      <app-step-header [myClass]="'form-header-container'"
                       [alertId]="alertId"
                       [step]="steps[2]"
                       [stepTitle]="'wizard.sources-step-title'"
                       [stepSubTitle]="'wizard.sources-step-sub-title'"
                       [stepSubTitleEmptySelection]="'wizard.sources-step-sub-title-empty'">
      </app-step-header>

      <div class="step-container">
        <app-source-step-template [regionalDailyPress]="settings ? settings.regionalDailyPress : true"
                                  [territoriesKindSetting]="settings?.publicationTerritoriesKinds"
                                  [syndicatesSetting]="settings?.syndicates">
        </app-source-step-template>
      </div>
    </div>

    <div [hidden]="(currentStep$ | async)?.stepIndex !== 3"
         class="form-step">
      <app-step-header [myClass]="'form-header-container'"
                       [alertId]="alertId"
                       [step]="steps[3]"
                       [stepTitle]="'wizard.parameters-step-title'"
                       [stepSubTitle]="'wizard.parameters-step-sub-title'"
                       [stepSubTitleEmptySelection]="'wizard.parameters-step-sub-title'"
      ></app-step-header>
      <app-frequent-step-template [daysSetting]="settings?.dayOfWeek"
                                  [alertId]="alertId"
                                  [name]="settings?.name"
                                  [alertSettingsRetrieved$]="alertSettingRetrieved$"
                                  (otherUserAlerts)="updateOtherUserAlerts($event)"></app-frequent-step-template>
    </div>

    <div *ngIf="(currentStep$ | async)?.stepIndex === 4"
         class="form-step">
      <app-step-header *ngIf="nbPreviewedDocuments < complexityLimiters?.warning_content_threshold &&
                              load"
                       [myClass]="'form-header-container'"
                       [alertId]="alertId"
                       [step]="steps[0]"
                       [otherUserAlerts]="otherUserAlerts"
                       [stepTitle]="'wizard.overview-step-title'"
                       [stepSubTitle]="'wizard.overview-step-sub-title'"
                       [stepSubTitleEmptySelection]="'wizard.overview-step-sub-title'">
      </app-step-header>

      <app-step-header *ngIf="nbPreviewedDocuments >= complexityLimiters?.warning_content_threshold &&
                              nbPreviewedDocuments <= complexityLimiters?.alert_content_threshold &&
                              load"
                       [warning]=true
                       [myClass]="'form-header-container-warning'"
                       [alertId]="alertId"
                       [otherUserAlerts]="otherUserAlerts"
                       [stepTitle]="'wizard.overview-step-title-warning'"
                       [stepSubTitle]="'wizard.overview-step-sub-title-warning'"
                       [stepSubTitleEmptySelection]="'wizard.overview-step-sub-title-warning'">
      </app-step-header>

      <app-step-header *ngIf="nbPreviewedDocuments > complexityLimiters?.alert_content_threshold &&
                              load"
                       [error]=true
                       [myClass]="'form-header-container-error'"
                       [alertId]="alertId"
                       [otherUserAlerts]="otherUserAlerts"
                       [stepTitle]="'wizard.overview-step-title-error'"
                       [stepSubTitle]="'wizard.overview-step-sub-title-error'"
                       [stepSubTitleEmptySelection]="'wizard.overview-step-sub-title-error'">
      </app-step-header>

      <div id="overviewStepContainer" #overviewStepContainer
           class="step-container"
           (scroll)="onOverviewResultsScroll()">

        <app-overview-step-template
          (nbPreviewedDocuments)="setNbPreviewedDocuments($event)"
          (overviewStepIsDestroyed)="lastStepDestroyed()"
          [isScrollDown138px]="isScrolledDown138px"
          [previewsListScrollValue]="previewsListScrollValue">
        </app-overview-step-template>

      </div>

    </div>

  </div>

</div>
