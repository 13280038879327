import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  FindResultMatchesCount,
  NgxExtendedPdfViewerComponent,
  NgxExtendedPdfViewerService,
  PagesLoadedEvent
} from 'ngx-extended-pdf-viewer';
import { interval, ReplaySubject } from 'rxjs';
import { AdminDocument } from '../../../models/admin-document';
import { debounceTime, first } from 'rxjs/operators';
import { DbSearchKeyword, MatchStem, MatchStemsSearchInterface, SearchKeywordTypeEnum } from '../../../models/match-stem';
import { KeywordsPdfSearchService } from '../../../shared/services/keywords-pdf-search.service';
import { UserTrackerService } from '../../../shared/services/tracking/user-tracker.service';

const SEARCH_TIMEOUT = 15000;

@Component({
  selector: 'app-admin-doc-pdf',
  templateUrl: './admin-doc-pdf.component.html',
  styleUrls: ['./admin-doc-pdf.component.scss']
})
export class AdminDocPdfComponent implements OnInit, AfterViewInit {
  @ViewChild('pdf') pdf: NgxExtendedPdfViewerComponent;

  @Input() pdfView: ElementRef;
  @Input() adminDoc: AdminDocument;
  @Input() time;
  @Input() guestView = false;

  private highlightAll = true;
  private matchCase = false;
  private wholeWord = true; // Always on
  private ignoreAccents = true;
  private searchTime: number;
  public totalMatches: number;
  public page = 1;
  public $page = new ReplaySubject<number>(1);
  public nbPages: number;
  loader: Element;
  backdrop: Element;
  inSearch = true; // always true (because of retrieving and searching match_stem)
  searchNotFound = false;
  documentLoaderCount = 0;
  rotation: 0 | 90 | 180 | 270;
  sidebarVisible = false;
  toolbarHeight = 0;
  toolbarHeight$ = new ReplaySubject<number>(1);
  firstTextLayer = true;

  /** Attributes to help matchStems */
  @Input() adminDocMatchStems$: ReplaySubject<any>;
  matchTermMatrix = new Array<MatchStemsSearchInterface>(); // original matrix -> reference for 1st calculation of occ
  selectedItemsMatrix = new Array<MatchStemsSearchInterface>(); // stem or searched word (new searched word would be created as a MSSI)

  /** Management of occurence pages */
  globalOccurrencePages: Array<boolean>;
  globalOccurrencePagesReplaySubject = new ReplaySubject<Array<boolean>>(1);

  searchReplaySubject = new ReplaySubject(1); // 1st search to init occ for various buttons.
  newSearchReplaySubject = new ReplaySubject(1); // For others researchs
  $matchTermMatrixUpdate = new ReplaySubject(1);
  selectedTerms = new Array<string>(); // ui selection purpose
  isCustomToolbarPanelExpanded = false;

  debounceTimeFirstSearch = 1000; // ms
  debounceTimeNewSearch = 500; // ms
  firstSearchBufferTime = 500; // ms

  /** Management of tracking keywords feature - kpi2 */
  numberOfSearch = 0;
  searchSessionStartTimestamp = '';
  componentBirthTime = 0;
  InteractionLeadingToNewPage = InteractionLeadingToNewPage;
  // Abstract of pages containing occurrences viewed (e.g. [1, 0, 1, 0, 0] for a 5 pages document)
  abstractPagesWithOccurrencesViewed = new Array<number>();
  // Abstract of pages where occurrences were found on a certain search (e.g. [1, 1, 1, 1, 0] for a 5 pages document)
  abstractPagesWithOccurrencesFound = new Array<number>();

  constructor(
    private ngxExtendedPdfViewerService: NgxExtendedPdfViewerService,
    private ref: ChangeDetectorRef,
    private keywordsPdfSearchService: KeywordsPdfSearchService,
    private userTrackerService: UserTrackerService
  ) {
    this.rotation = 0;
  }

  ngOnInit(): void {
    this.searchSessionStartTimestamp = (new Date()).toISOString(); // timestamp
    /** Subscription to first search.
     * in charge of setting occurrences on existing buttons */
    this.searchReplaySubject
      .pipe(debounceTime(this.debounceTimeFirstSearch))
      .subscribe((matchesColor: Array<any>) => {
        // stop if not retrieved match stems.
        if (this.searchReplaySubject.isStopped) { return; }

        console.log(`INITIAL SEARCH retrieved : colors then MatchStemMatrix (ms) ${Date.now() - this.componentBirthTime}`);
        console.log(matchesColor);
        this.keywordsPdfSearchService.updateSearchMatrix(matchesColor, this.matchTermMatrix);
        console.log(this.matchTermMatrix);
        this.initGlobalOccurrencePages();

        this.newPageNumber(1, InteractionLeadingToNewPage.SEARCH);
        this.ngxExtendedPdfViewerService.scrollPageIntoView(1, {top: 0, left: 0});
        this.ngxExtendedPdfViewerService.find('');
        this.closeSearchLoader();

        this.searchReplaySubject.complete();
        this.ref.detectChanges();
      });

    this.manageSearchTimeout();

    /** Subscription to every other search */
    this.newSearchReplaySubject
      .pipe(debounceTime(this.debounceTimeNewSearch))
      .subscribe((newSearchMatchesColors: Array<any>) => {

        this.keywordsPdfSearchService.setUpMatrixWithCurrentSearchTermList(this.selectedItemsMatrix);
        this.keywordsPdfSearchService.updateSearchMatrix(newSearchMatchesColors, this.selectedItemsMatrix);
        this.$matchTermMatrixUpdate.next(this.matchTermMatrix);

        this.initGlobalOccurrencePages();
        this.updateGlobalOccurrencePages(this.selectedItemsMatrix);
        this.inSearch = false;
      });
  }

  ngAfterViewInit() {
    this.componentBirthTime = Date.now();
    this.onExpandPanel(true);
  }

  pdfLoaded() {
    console.log(`Pdf loaded !`);
    this.documentLoaderCount++;

    if (this.documentLoaderCount === 1) {

      this.ngxExtendedPdfViewerService.getCurrentDocumentAsBlob().then(blob => {
        console.log(`Blob retrieved !`);
        this.pdf.src = blob;

        /** Generating matchStemMatrix and triggering initial search */
        /** Interval of 0 seconde to test if tab is active on browser */
        const intervalId = setInterval(() => {
          // if tab is Active.
          // if (!document.hidden && this.documentLoaderCount === 3) {
          if (this.documentLoaderCount === 3) {
            this.detectLateralPanel();
            this.adminDocMatchStems$
              .subscribe((result: MatchStem[]) => {
                // Case Match Stems not retrieved
                if (result === null) {
                  console.log(`case match_stems null`);
                  this.closeSearchLoader();
                  this.searchReplaySubject.complete();
                  clearInterval(intervalId);
                  return;
                }
                // Case Match Stems retrieved
                const {matchTermMatrix, initialTermsToSearch} =
                  this.keywordsPdfSearchService.generateStemMatrixAndItemsToSearch(result);
                this.matchTermMatrix = matchTermMatrix;

                console.log(`Before first search (ms) : ${(Date.now() - this.componentBirthTime)}`);

                // Buffer time to try avoiding infinite loading at first search.
                setTimeout(() => {
                  this.ngxExtendedPdfViewerService.findMultiple(initialTermsToSearch, this.getPdfSearchOptions());
                }, this.firstSearchBufferTime);

                clearInterval(intervalId);
              });
          }
        });
      });
    }

  }

  updateFindMatchesCount(result: FindResultMatchesCount) {
    if (result.matchesColor) {
      if (!this.searchReplaySubject.isStopped) {
        // Notification to replaySubject that a result.matchesColor is available
        this.searchReplaySubject.next(result.matchesColor);
      } else {
        // Taking care of other searches, after the initial one.
        if (this.searchReplaySubject.isStopped) {
          this.newSearchReplaySubject.next(result.matchesColor);
        }
      }
    }
    this.totalMatches = result.total;
    this.ref.detectChanges();
  }

  updateGlobalOccurrencePages(matrix: MatchStemsSearchInterface[]) {
    /** Part to set occurrence pages */
    for (const element of matrix) {
      element.distinctOccurrencePages?.forEach((pageNb) => {
        this.globalOccurrencePages[pageNb - 1] = true;
      });
    }
    /** Update of tracking object */
    this.updateAbstractPagesWithOccurrencesFound();
    this.trackUserKeywordSearchPageViewed(InteractionLeadingToNewPage.SEARCH);
    /** version moins optimale possible avec décomptes pour chaque page */
    this.globalOccurrencePagesReplaySubject.next(this.globalOccurrencePages);

  }

  //region __PDF BASICS__

    //region _Pages Management_

  /** Event emitted when all pages are loaded (first moment we are sure to get pdf.nbPoages) */
  onPagesLoaded(value: PagesLoadedEvent): void {
    this.nbPages = value.pagesCount;
    this.keywordsPdfSearchService.nbPages = this.nbPages; // affecting nbPages to helper service
    // Initialisation de globalOccurrencePages.
    this.initGlobalOccurrencePages();
    this.initKPI2TrackingLists();
  }

  initGlobalOccurrencePages() {
    this.globalOccurrencePages = new Array(this.nbPages).fill(false);
  }

    //endregion

  getPdfSearchOptions() {
    return {
      highlightAll: this.highlightAll,
      matchCase: this.matchCase,
      wholeWords: this.wholeWord,
      ignoreAccents: this.ignoreAccents
    };
  }

  newPageNumber($event: number, interaction: InteractionLeadingToNewPage): void {
    this.page = +$event;
    this.$page.next(+$event);
    // Tracking new page viewed when using pagination component.
    this.trackUserKeywordSearchPageViewed(interaction);
  }
  //endregion

  //region __INTERACTIONS__

  /** Management of the toolbar when expanding / shrinking */
  onExpandPanel(event) {
    this.isCustomToolbarPanelExpanded = event.value;
    this.onToolbarSizeChange();
  }

  /** Method reaction to changing keywords selection (stem or new search text) */
  keywordSelectionChanged(searchKeyword: DbSearchKeyword) {
    searchKeyword.type === 'stem' ?
      this.stemSelectionChanged(searchKeyword.value) :     // case stem
      this.userInputSelectionChanged(searchKeyword.value); // case input

    // update search
    this.updatePdfMultiSearch();

    // tracking event
    this.trackUserKeywordsSelection(searchKeyword.type, searchKeyword.value);

    // increment of search number counter.
    this.numberOfSearch++;
  }

  /** Method reacting to changing in the stem selection (both selection or deselection) */
  stemSelectionChanged(stem: string) {
    this.selectedTerms.includes(stem) ?
      this.selectedTerms = this.selectedTerms.filter(elem => elem !== stem) : // if true
      this.selectedTerms.push(stem); // if false
  }

  /** Method reacting to changing in the input selection (both selection or deselection) */
  userInputSelectionChanged(newInput: string) {
    // sanitize the input
    const input = newInput.trim();
    // searching as lowerCase to avoid duplicates
    const alreadySelected = this.selectedTerms.filter((elm) => elm.toLowerCase() === input.toLowerCase())?.length > 0;
    alreadySelected ?
      this.selectedTerms = this.selectedTerms.filter(elem => elem.toLowerCase() !== input.toLowerCase()) : // if true
      this.selectedTerms.push(input.toLowerCase()); // if false

    // Add new input as MatchStemsSearchInterface in matchTermMatrix
    if (!this.matchTermMatrix.find(element => element.stem.toLowerCase() === input.toLowerCase())) {
      this.matchTermMatrix.push({
        stem: input.toLowerCase(),
        items: [input.toLowerCase()],
        itemsIndexesAmongSearchList: [],
        distinctOccurrencePages: null,
        occurrencePages: null,
        type: SearchKeywordTypeEnum.USER_INPUT
      });
    }
    this.onToolbarSizeChange();
  }

  deleteUserInput(input: MatchStemsSearchInterface) {
    this.matchTermMatrix = this.matchTermMatrix.filter(elm => elm.stem !== input.stem);
    this.onToolbarSizeChange();
  }

  /** Send a new search to ngxExtendedPdfViewer containing all selected items of selectedItemsMatrix */
  updatePdfMultiSearch() {
    this.inSearch = true;
    // New search
    const newSearchList = [];
    // Update de selectedItemsMatrix
    this.selectedItemsMatrix = this.matchTermMatrix.filter(elem => this.selectedTerms.includes(elem.stem));

    this.selectedItemsMatrix?.forEach((element) => {
      element.items?.forEach((word) => {
        newSearchList.push(word);
      });
    });

    // New multiSearch
    newSearchList?.length ?
      this.ngxExtendedPdfViewerService.findMultiple(newSearchList, this.getPdfSearchOptions()) :
      this.ngxExtendedPdfViewerService.find('');
  }

  rotatePdf(_event: string) {
    if (this.rotation === 270) {
      this.rotation = 0;
    } else {
      this.rotation += 90;
    }
  }

  detectLateralPanel() {
    const outlineView = document.getElementById('outlineView');
    let lastClass = outlineView.classList.contains('treeWithDeepNesting');
    function classAddedCallback(mutationsList) {
      mutationsList.forEach(mutation => {
        if (mutation.attributeName === 'class' && mutation.target.classList.contains('treeWithDeepNesting')) {
          if (!lastClass) {
            lastClass = true;
            const mainContainer = document.getElementById('mainContainer');
            const sidebarButton = document.getElementById('sidebarButton');
            console.log('outlineView exists !!!');
            sidebarButton.style.setProperty('display', 'table-cell', 'important');
            mainContainer.insertAdjacentElement('afterbegin', sidebarButton);
          }
        }
      });
    }
    const mutationObserver = new MutationObserver(classAddedCallback);
    mutationObserver.observe(outlineView, { attributes: true });
  }

  displayLateralPanel(_event: boolean) {
    this.sidebarVisible = !this.sidebarVisible;
  }

  onToolbarSizeChange() {
    setTimeout(() => {
      this.toolbarHeight = document?.getElementById('toolbar-container').clientHeight;
      this.toolbarHeight$.next(this.toolbarHeight);
      const viewerContainer = document?.getElementById('viewerContainer');
      const sidebarContainer = document?.getElementById('sidebarContainer');
      // @ts-ignore — false positive
      viewerContainer?.style.position = 'absolute';
      // @ts-ignore — false positive
      sidebarContainer?.style.position = 'absolute';
      // @ts-ignore — false positive
      viewerContainer?.style.top = this.toolbarHeight + 'px';
      // @ts-ignore — false positive
      sidebarContainer?.style.top = this.toolbarHeight + 'px';
    }, 10);
  }

  /** Native method of ngx-extended-pdf-reader -> scroll or new search auto movement */
  pageChange(event: number) {
    this.$page.next(event); // update current page
    // Tracking of event.
    this.trackUserKeywordSearchPageViewed(this.inSearch ?
      InteractionLeadingToNewPage.AUTO_MOVEMENT_INDUCED_BY_SEARCH :
      InteractionLeadingToNewPage.SCROLL);
  }

  //endregion

  //region __legacy__

  closeSearchLoader(): void {
    this.hideLoader();
    this.inSearch = false;
  }

  find(value: string): void {
    if (value) {
      this.inSearch = true;
      this.searchTime = (new Date()).getTime();
      this.displayLoader();
    } else {
      this.inSearch = false;
      this.hideLoader();
    }
  }

  manageSearchTimeout() {
    interval(1000).subscribe(() => {
      if (!this.inSearch) {
        return;
      }
      const now = (new Date()).getTime();
      if (now - this.searchTime > SEARCH_TIMEOUT) {
        this.searchNotFound = true;
        setTimeout(() => {
          this.closeSearchLoader();
          this.searchNotFound = false;
        }, 1000);
      }
    });
  }

  displayLoader(): void {
    if (!this.loader || !this.backdrop) {
      return;
    }
    this.loader.classList.add('show');
    this.backdrop.classList.add('show');
  }

  hideLoader(): void {
    if (!this.pdfView) {
      return;
    }
    this.loader = this.pdfView.nativeElement.querySelector('.loader');
    this.backdrop = this.pdfView.nativeElement.querySelector('.backdrop');
    if (this.loader && this.backdrop) {
      this.loader.classList.remove('show');
      this.backdrop.classList.remove('show');
    }
  }

  countOccurrencePages(occurrencePages: boolean[]): number {
    return occurrencePages?.reduce((accumulator, currentValue) => {
      if (currentValue) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);
  }

  //endregion


  /**
   * keywords search feature - KPI1
   * Custom method to track user interaction w/ keywords or inputs */
  trackUserKeywordsSelection(type: SearchKeywordTypeEnum, word: string) {
    const inSelection = this.selectedTerms.find(elem => elem.toLowerCase() === word.toLowerCase());
    const selection_event_type = (inSelection?.length > 0) ? 'selection' : 'deselection';
    const user_details = this.userTrackerService.getTrackUserDetails();
    const body = {
      event_timestamp: (new Date()).toISOString(),
      event_type: 'admin-doc-keywords-selection-changed',
      group_account_id: localStorage.getItem('group_account_id'),
      user_id: localStorage.getItem('user_id'),
      user_details,
      selection_event_type,
      keyword_type: type,
      keyword_value: word,
      admin_doc: this.adminDoc,
      updated_selected_items_matrix: this.selectedItemsMatrix,
    };
    // Calling tracking api
    this.userTrackerService.track(body as any)
      .pipe(first())
      .subscribe();
  }

  /**
   * keywords search feature - KPI2
   * Custom method to track user interaction : page consultation when a search is triggered */
  trackUserKeywordSearchPageViewed(interactionType: InteractionLeadingToNewPage) {
    if (interactionType === InteractionLeadingToNewPage.AUTO_MOVEMENT_INDUCED_BY_SEARCH) {
      // case search already managed.
      return;
    }
    if (this.numberOfSearch > 0) {
      this.updateAbstractPagesWithOccurrencesViewed(this.page);
    }
    const body = {
      event_type: 'admin-doc-keywords-search-page-viewed',
      search_session_start_timestamp: this.searchSessionStartTimestamp,
      event_timestamp: (new Date()).toISOString(),
      number_of_search: this.numberOfSearch,
      user_id: +localStorage.getItem('user_id'),
      group_account_id: +localStorage.getItem('group_account_id'),
      email: localStorage.getItem('email'),
      document: this.adminDoc,
      page: +this.page,
      document_total_number_of_page: this.nbPages,
      interaction_type: interactionType, // by scroll or click
      in_search: this.inSearch,
      is_page_containing_occurrences: this.globalOccurrencePages[this.page - 1],
      current_total_number_of_pages_containing_occurrences: this.countOccurrencePages(this.globalOccurrencePages),
      abstract_pages_with_occurrences_viewed: this.abstractPagesWithOccurrencesViewed,
      abstract_pages_with_occurrences_found: this.abstractPagesWithOccurrencesFound,
      abstract_current_pages_with_occurrences: this.globalOccurrencePages.map(elm => elm ? 1 : 0),
      sum_pages_with_occurrences_viewed: this.abstractPagesWithOccurrencesViewed.reduce((sum, val) => sum + val, 0),
      sum_pages_with_occurrences_found: this.abstractPagesWithOccurrencesFound.reduce((sum, val) => sum + val, 0)
    };

    // Calling tracking api
    this.userTrackerService.track(body as any)
      .pipe(first())
      .subscribe();
  }


  initKPI2TrackingLists() {
    const arrayFilledWithZeros = new Array(this.nbPages).fill(0);
    this.abstractPagesWithOccurrencesFound = arrayFilledWithZeros;
    this.abstractPagesWithOccurrencesViewed = arrayFilledWithZeros;
  }

  updateAbstractPagesWithOccurrencesViewed(currentPage: number) {
    // pageViewed contains occ ? then 1 : else old value.
    this.abstractPagesWithOccurrencesViewed[currentPage - 1] =
      (this.globalOccurrencePages[currentPage - 1] === true) ? 1 : this.abstractPagesWithOccurrencesViewed[currentPage - 1];
  }

  updateAbstractPagesWithOccurrencesFound() {
    this.abstractPagesWithOccurrencesFound = this.abstractPagesWithOccurrencesFound.map((value, index) => {
      return (this.globalOccurrencePages[index] === true) ? 1 : value;
    });
  }

}

export enum InteractionLeadingToNewPage {
  SCROLL = 'scroll',
  SEARCH = 'search',
  AUTO_MOVEMENT_INDUCED_BY_SEARCH = 'auto-movement-induced-by-search',
  PAGINATION_COMPONENT_CLICK = 'pagination-click',
  PDF_PAGE_INPUT = 'pdf-input'
}
