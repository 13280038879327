import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../headers/headers.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AdminDocument } from '../../../../models/admin-document';
import { environment } from '../../../../../environments/environment';
import { ExportAdminDocMetadata } from '../../../../models/export-admin-doc-metadata';
import { EventTypeName } from '../../../../models/user-tracker';
import { DbMatchStem } from '../../../../models/match-stem';

@Injectable({
  providedIn: 'root'
})
export class ApiExportAdminDocService {

  constructor(private http: HttpClient,
              private headersService: HeadersService) {
  }

  /** POST method to trigger the worker in charge of watermarking the admin document that will be downloaded by the user */
  triggerWatermarkingAdminDocument(adminDocument: AdminDocument,
                                   exportId: number,
                                   exportAdminDocMetadata: ExportAdminDocMetadata,
                                   routeParams: {}): Observable<{downloadUrl: string, frontUrl: string}> {
    const body = this.generateTriggerWatermarkingAdminDocumentBody(adminDocument, exportId, exportAdminDocMetadata, routeParams);

    return this.http
      .post(
        `${environment.exportAdminDocumentApiUrl}`,
        body,
        this.headersService.getTrackedHttpHeaders(EventTypeName.ADMIN_DOC_EXPORT_TRIGGERED)    // tracking this EP
      )
      .pipe(
        map((response: DbTriggerWatermarkingAdminDocumentResponseBody) => {
          return {
            downloadUrl: response.s3_download_doc_url,
            frontUrl: response.public_return_url
          };
        },
          (error) => {
          return error;
          }
        ));

  }

  /** GET method to retrieve the watermarked admin document */
  getWatermarkedAdminDocument(downloadUrl: string): Observable<Blob> {
    return this.http.get(
      downloadUrl,
      {
        headers: this.headersService.getTrackedHttpHeaders(EventTypeName.ADMIN_DOC_EXPORT_DOWNLOADED).headers,
        responseType: 'blob'
      }
    ).pipe(
      map((blob) => {
        return blob;
      })
    );
  }

  /** PUT export */
  insertExport(adminDocument: AdminDocument): Observable<any> {
    return this.http.put(
      `${environment.backV2EndUrl}export`,
      {
        document_uid: adminDocument.id,
        context: {
          match_stems: adminDocument?.matchStems
        }
      }, this.headersService.httpHeaders)
      .pipe(
        map((resp: { export_id: number, message: string }) => {
          return resp;
        })
      );
  }

  /** PATCH publicReturnUrl of export identified by its id */
  updateExportUrls(exportId: number, s3ExportDocumentsUri: string, publicReturnUrl: string): Observable<string> {
    return this.http.patch(
      `${environment.backV2EndUrl}export`,
      {
        id: exportId,
        s3_export_documents_uri: s3ExportDocumentsUri,
        public_return_url: publicReturnUrl
      },
      this.headersService.httpHeaders
    ).pipe(map((response: {message: string} ) => {
      return response.message;
    }));
  }

  /** GET method to retrieve export document match stems */
  getExportDocumentMatchStems(exportId: number): Observable<DbMatchStem[]> {
    return this.http.get(
      `${environment.backV2EndUrl}export/${exportId}`,
      this.headersService.httpHeaders
    )
      .pipe(
        map((response: { match_stems: DbMatchStem[] }) => {
            return response?.match_stems;
          }
        )
      );
  }

  /** Method to generate correct request body structure, expected to be sent to the trigger watermarking EP */
  generateTriggerWatermarkingAdminDocumentBody(adminDocument: AdminDocument,
                                               exportId: number,
                                               exportAdminDocMetadata: ExportAdminDocMetadata,
                                               routeParams: {}): DbTriggerWatermarkingAdminDocumentRequestBody {
    let document_url;
    if (routeParams['territoryUid']) {
      document_url = `${environment.appBaseUrl}/search/${routeParams['territoryUid']}/admin-doc/${routeParams['adminDocId']}`;
    } else { // case document exported from a route without territoryUid (e.g. inside folder)
      document_url = `${environment.appBaseUrl}/search/FRDEPA00/admin-doc/${adminDocument.id}`;
    }
    return {
      export_id: exportId,
      s3_root_doc_url: adminDocument.url,
      doc_goodwill_url: document_url,
      publication_date: adminDocument.publicationDate.toString(),
      displayed_publication_type: adminDocument.displayedPublicationType,
      title: adminDocument.title,
      metadata: exportAdminDocMetadata
    };
  }
}

/** Structure of request body to send to trigger watermarking EP */
export interface DbTriggerWatermarkingAdminDocumentRequestBody {
  export_id: number;
  s3_root_doc_url: string;
  doc_goodwill_url: string;
  publication_date: string;
  displayed_publication_type: string;
  title: string;
  metadata: ExportAdminDocMetadata;
}

/** Structure of response body returned by trigger watermarking EP */
export interface DbTriggerWatermarkingAdminDocumentResponseBody {
  message: string;
  s3_download_doc_url: string;
  public_return_url: string;
}
