<div class="position-absolute toast-card" *ngIf="showToast">
  <div class="d-flex position-relative h-100">
    <div class="icon icon--close position-absolute align-self-center" (click)="closeToast()"></div>
    <img src="assets/images/icon-checkmark-circle.svg" class="align-self-center" alt="icon-checkmark">
    <div class="font-weight-bold align-self-center toast-text">
      <ng-container *ngIf="!editMode && !duplication">
        {{ createMessage | translate:({name: name})}}
      </ng-container>
      <ng-container *ngIf="editMode && !duplication">
        {{ editMessage | translate:({name: name})}}
      </ng-container>
      <ng-container *ngIf="duplication">
        {{ duplicationMessage | translate:({name: name})}}
      </ng-container>
    </div>
  </div>
</div>
