<div *ngIf="guestUser" class="welcome-card">
  <div class="header-card">
    <div class="header-side-part">
      <div class="background-icon rounded-circle">
        <mat-icon svgIcon="icon-person-fill"></mat-icon>
      </div>
      <div class="d-flex flex-column">
        <span class="header-title">{{'welcome-panel.welcome-to-goodwill' | translate}}</span>
        <span class="header-subtitle">{{guestUser.email}}</span>
      </div>
    </div>
      <div class="d-flex flex-column">
      <app-progress-bar
        [nameValue]="'welcome-panel.credits'"
        [minValue]="0"
        [maxValue]="guestUser.userLevel.max_credit"
        [currentValue]="guestUser.userLevel.remaining_credits"
        [progressBarWidth]="120"
        [progressBarClass]="'credit-span-container'"
      >
      </app-progress-bar>
<!--      <span class="header-subtitle-info">{{'welcome-panel.until' | translate}} {{guestUser.renewalDate}}</span>-->
      </div>
  </div>
  <div class="body-card">
    <div class="collapse-block">
      <div class="collapse-title" (click)="collapse1.toggle()" [attr.aria-expanded]="!isCollapsed1">
        <span>{{'welcome-panel.computation-ask' | translate}}</span>
        <mat-icon *ngIf="isCollapsed1" svgIcon="chevron-down"></mat-icon>
        <mat-icon *ngIf="!isCollapsed1" svgIcon="chevron-up"></mat-icon>
      </div>
      <div class="collapse-content" #collapse1="ngbCollapse" [(ngbCollapse)]="isCollapsed1">
        <span>{{'welcome-panel.computation' | translate}}</span>
      </div>
    </div>
    <div class="collapse-block">
      <div class="collapse-title" (click)="collapse2.toggle()" [attr.aria-expanded]="!isCollapsed2">
        <span>{{'welcome-panel.what-is-goodwill-ask' | translate}}</span>
        <mat-icon *ngIf="isCollapsed2" svgIcon="chevron-down"></mat-icon>
        <mat-icon *ngIf="!isCollapsed2" svgIcon="chevron-up"></mat-icon>
      </div>
      <div class="collapse-content" #collapse2="ngbCollapse" [(ngbCollapse)]="isCollapsed2">
        <span>{{'welcome-panel.what-is-goodwill-1' | translate}}</span>
        <br>
        <span>{{'welcome-panel.what-is-goodwill-2' | translate}} </span>
        <a class="href-underline" href="https://explain.fr" target="_blank" rel="noopener noreferrer">
          {{'welcome-panel.our-website' | translate}}</a>
        <span>&nbsp;{{'welcome-panel.what-is-goodwill-3' | translate}}</span>
      </div>
    </div>
    <div class="contact-container">
      <span>{{'welcome-panel.any-other-question' | translate}}</span>
      <a class="href-underline" href="mailto: support@explain.fr">
        {{'welcome-panel.contact-email' | translate}}
      </a>
    </div>
  </div>
</div>
