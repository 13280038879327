import { Injectable } from '@angular/core';
import { ApiArticleService } from './articles/api-article.service';
import { ApiAdminDocService } from './api-admin-doc.service';
import { ApiImpacterService } from './impacter/api-impacter.service';
import { ApiShareService } from './share/api-share.service';
import { ApiTerritoryService } from './territory/api-territory.service';
import { ApiBookmarkService } from './bookmark-service/api-bookmark.service';
import { ApiAlertService } from './alert/api-alert.service';
import { ApiTopicService } from './topic/api-topic.service';
import { ApiGwStatisticsService } from './gw-statistics/api-gw-statistics.service';
import { ApiUserService } from './user/api-user.service';
import { ApiUserDigestsService } from './user-digests/api-user-digests.service';
import { ApiAlertsSendingService } from './alerts-sending/api-alerts-sending.service';
import { ApiExportAdminDocService } from './export/api-export-admin-doc.service';
import { ApiZendeskService } from './zendesk/api-zendesk.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private _articleService: ApiArticleService,
    private _adminDocService: ApiAdminDocService,
    private _impacterService: ApiImpacterService,
    private _territoryService: ApiTerritoryService,
    private _shareService: ApiShareService,
    private _bookmarkService: ApiBookmarkService,
    private _alertService: ApiAlertService,
    private _topicService: ApiTopicService,
    private _apiGwStatistics: ApiGwStatisticsService,
    private _apiUserService: ApiUserService,
    private _apiUserDigestsService: ApiUserDigestsService,
    private _apiAlertsSendingService: ApiAlertsSendingService,
    private _apiExportAdminDoc: ApiExportAdminDocService,
    private _apiZendeskService: ApiZendeskService
  ) {
  }

  get article(): ApiArticleService {
    return this._articleService;
  }

  get adminDoc(): ApiAdminDocService {
    return this._adminDocService;
  }

  get impacter(): ApiImpacterService {
    return this._impacterService;
  }

  get territory(): ApiTerritoryService {
    return this._territoryService;
  }

  get share(): ApiShareService {
    return this._shareService;
  }

  get bookmark(): ApiBookmarkService {
    return this._bookmarkService;
  }

  get alert(): ApiAlertService {
    return this._alertService;
  }

  get topic(): ApiTopicService {
    return this._topicService;
  }

  get gwStatistics(): ApiGwStatisticsService {
    return this._apiGwStatistics;
  }

  get user(): ApiUserService {
    return this._apiUserService;
  }

  get userDigests(): ApiUserDigestsService {
    return this._apiUserDigestsService;
  }

  get alertsSending(): ApiAlertsSendingService {
    return this._apiAlertsSendingService;
  }

  get exportAdminDoc(): ApiExportAdminDocService {
    return this._apiExportAdminDoc;
  }

  get zendesk(): ApiZendeskService {
    return this._apiZendeskService;
  }

}
