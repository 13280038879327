import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SearchService } from '../../shared/services/search.service';
import { ArticleImSearchService } from '../../shared/services/article-im-search.service';
import { SearchMode } from './search-mode';
import { PublicationSource } from './publication-source';
import { RegexpSearch } from '../../shared/helpers/regex';
import { first } from 'rxjs/operators';
import { ApiService } from '../../shared/services/api/api.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  SearchMode = SearchMode; // Allowing to use enum in the DOM

  @Input() currentPage: string;
  @Input() inImpacterView: boolean;
  @Input() haveTip: boolean;
  @Input() publicationSource?: PublicationSource;
  public serializedSessionNumber: number;

  searchText = '';
  searchMode: SearchMode = 1;

  topics: Array<any> = [];
  filteredTopics: Array<any> = [];
  filteredTopicName = '';

  selectedTopics = new Array<any>();
  showDropDownMenu = false;

  constructor(private searchService: SearchService,
              private articleImSearchService: ArticleImSearchService,
              private apiService: ApiService) {
  }

  ngOnInit(): void {
    if (this.inImpacterView) { // Inside component impacter page
      this.searchText = this.searchService.cachedImpacterSearchText; // on affiche le texte en cache
    } else {

      this.searchMode = this.searchService.SearchModeMap.get(this.publicationSource);
      this.serializedSessionNumber = Math.random();

      // TODO victor : intervenir ici pour améliorer Impacter part.
      if (this.currentPage === 'impacter') { // Inside component impacters page (currentPage impacter && !this.inImpacterView)

        this.searchText = this.searchService.CachedImpacterName;

      } else {
        this.searchText = this.searchService.CachedTextMap.get(this.searchMode);
        this.selectedTopics = this.searchService.CachedSelectedTopics; // No search-topics allowed at the moment
        /** Subscription to topics */
          this.apiService.topic.getUserTopics()
            .pipe(first())
            .subscribe((topics) => {
            this.topics = topics;
            this.filteredTopics = topics;
          });

        // Subscription to search call to ensure filteredTopicName updated.
        this.searchService.getSearch().subscribe(() => {
          if (!this.searchText) {
            this.selectedTopics = [];
          }
          this.filteredTopicName = this.selectedTopics[0]?.name;
        });

        this.searchMode = this.searchService.SearchModeMap.get(this.publicationSource);
        this.onSearch();
      }
    }
  }

  ngOnDestroy() {

    /** Reset of variables */
    this.searchText = '';
    this.filteredTopicName = '';
    this.selectedTopics = [];
    this.topics = [];

  }

  onSearch() {
    if (this.inImpacterView) {
      // The text search is not cached inside Impacter View
      this.searchService.cachedImpacterSearchText = this.searchText;
      this.articleImSearchService.sentPageIndex(1);
    } else {
      if (this.currentPage === 'impacter') {
        this.searchService.CachedImpacterName = this.searchText; // Cached name of impacter
      } else {
        const newCachedTextMap = this.searchService.CachedTextMap;
        if (newCachedTextMap.get(this.searchMode) !== this.searchText) { // update only if new search text
          newCachedTextMap.set(this.searchMode, this.searchText);
          this.searchService.CachedTextMap = newCachedTextMap; // Cached text + cachedTextMapChanged next() inside the setter
          this.searchService.sentPageIndex(1);
        }
      }
    }
    this.sendSearchText();
  }

  onFilterTopicName() {
    // Case there is no text inside the filter input.
    if (!this.filteredTopicName) {
      this.resetTopicRelatedFields();
      this.onSearch();
    } else { // Case there is text inside the filter input.
      this.filteredTopics = this.topics.filter(topic =>
        RegexpSearch.Search(topic?.name, this.filteredTopicName)
      );
    }
  }

  resetFilteredTopicName() {
    this.filteredTopics = this.topics;
  }

  reset() {
    if (!this.searchText) {
      this.sendSearchText();
    }
  }

  get SearchModeMap(): Map<PublicationSource, SearchMode> {
    return this.searchService.SearchModeMap;
  }


  sendSearchText(): void {
    if (this.currentPage === 'impacter') {
      this.searchService.sentName(this.searchText);
    } else {
      if (this.inImpacterView) {
        if (this.searchText) {
          this.articleImSearchService.sentText(this.searchText);
        } else {
          this.articleImSearchService.resetText();
        }
      } else {
        this.searchService.sentText(this.searchText);
      }
    }
  }

  hideOrOpen() {
    this.showDropDownMenu = !this.showDropDownMenu;

    // Case filteredTopicName undefined
    if (this.filteredTopicName !== '') {
      // Case opening the dropDownMenu with no selected topic and no text in input.
      if (this.showDropDownMenu &&
        (this.searchMode === SearchMode.TOPICS) &&
        this.selectedTopics.length < 1) {
        this.resetTopicRelatedFields();
      }
    }
  }

  hideIfOpen() {
    if (this.showDropDownMenu) {
      this.showDropDownMenu = false;
    }
  }

  onTopicClicked(topic: Object) {
    if (topic === {}) {
      this.resetTopicRelatedFields();
    } else {
      this.searchText = topic['id'];
      this.filteredTopicName = topic['name'];
      this.selectedTopics = [topic];
      this.searchService.CachedSelectedTopics = this.selectedTopics;
    }
    this.hideIfOpen();
    this.onSearch();
  }

  resetTopicRelatedFields() {
    this.searchText = '';
    this.filteredTopicName = '';
    this.selectedTopics = [];
    this.searchService.CachedSelectedTopics = [];
    this.filteredTopics = this.topics;
  }


  isSelectedTopic(topic: Object): boolean {
    if (this.searchText === '') {
      return false;
    } else if (this.selectedTopics) {
      if (Number(this.searchText) === topic['id']) {
        return true;
      }
    }
    return false;
  }

}

