<a [routerLink]="impacterId">
  <div class="card-board" [ngClass]="{'suggestion-card' : !byFilter}">
    <div class="row content">
      <div class="col-3 text-uppercase font-weight-bold name align-self-center" [innerHTML]="name"></div>
      <div class="col-7 align-self-center">
        <div class="row position-item" [ngClass]="{'old-position' : position['is_old']}" *ngFor="let position of positions | sort:'is_old' | slice:0:2">
          <div class="col-7 align-self-center role d-flex">
            <span *ngIf="position['is_old']" class="old-title">{{'content.old' | translate}}</span>
            <span>{{position['role']}}</span>
            <span class="dot"></span>
          </div>
          <div class="col-5 align-self-end">
            <span>{{position['territory']['name']}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
