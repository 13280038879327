<div *ngIf="!updateMode" class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ title | translate}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true"><img src="assets/images/icon-close-circle.svg" alt="icon-close-circle"></span>
  </button>
</div>

<!--Modal update mode-->
<div *ngIf="updateMode" class="modal-header">
  <h4 class="modal-title update-modal" id="modal-update-title">
    {{ title | translate}}
  </h4>
</div>

<div class="modal-body d-flex flex-column">
  <div class="font-weight-bold message">
    {{content | translate}}
  </div>

  <p *ngIf="isNewFeatureExplainedModal">
    {{explanation | translate}}
  </p>
  <p></p>

  <!--New feature modal (here export)-->
  <img *ngIf="isNewFeatureExplainedModal"
       src="./assets/images/impacters-articles-new-feature-modal-2x.png" alt="image-impacters-articles-feature-explanation">
  <!--Historic update modal (no precision on new feature)-->
  <div *ngIf="!isNewFeatureExplainedModal" class="font-weight-bold message">
    {{content2 | translate}}
  </div>

</div>

<!--Modal update mode /w snooze-->
<div *ngIf="snooze" class="modal-body explanation">
  <div class="message">
    <!--New feature modal (here export)-->
    <span *ngIf="isNewFeatureExplainedModal" [style.font-style]="'normal'">{{explanation | translate}}</span>
    <!--Historic update modal (no precision on new feature)-->
    <span *ngIf="!isNewFeatureExplainedModal" style="text-decoration: underline">{{explanationUnderline | translate}}</span>
  </div>
</div>

<div *ngIf="!updateMode" class="modal-footer">
  <ng-container *ngIf="!alertMode">
    <button type="button" class="cancel font-weight-bold"
            (click)="close()">{{'button.modal-cancel-no' | translate}}</button>
    <button type="button" class="btn confirm-delete d-flex" (click)="cancelConfirm()">
      <div class="icon icon--trash align-self-center"></div>
      <div class="text-uppercase font-weight-bold align-self-center">
        {{btnName | translate}}
      </div>
    </button>
  </ng-container>
  <ng-container *ngIf="alertMode">
    <button type="button" class="btn confirm-delete d-flex" (click)="close()">
      <div class="text-uppercase font-weight-bold align-self-center">
        {{btnName | translate}}
      </div>
    </button>
  </ng-container>
</div>

<!--Modal update mode /w and w/o snooze-->
<div *ngIf="updateMode" class="modal-footer update-modal">
  <ng-container>
    <button *ngIf="snooze && !isNewFeatureExplainedModal"
            id="{{snoozeButtonClickedId}}"
            class="snooze font-weight-bold"
            type="button"
            (click)="snoozeAndClose()">{{'application-update.modal-snooze' | translate}}</button>
    <button *ngIf="!isNewFeatureExplainedModal"
            type="button"
            class="btn confirm-delete d-flex"
            (click)="updateConfirm()">
      <div class="icon icon--refresh align-self-center"></div>
      <div class="text-uppercase font-weight-bold align-self-center">
        {{btnName | translate}}
      </div>
    </button>
    <button *ngIf="isNewFeatureExplainedModal"
            type="button"
            class="btn confirm-delete d-flex"
            (click)="onCloseNewFeatureExplainedModal()">
      <div class="text-uppercase font-weight-bold align-self-center">
        {{btnName | translate}}
      </div>
    </button>
  </ng-container>
</div>
