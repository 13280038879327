import { Item } from './generic/item';
import { Topic } from './topic';
import { Pagination } from './generic/pagination';

/**
 * Structure of an alert as retrieved from api.
 * snake_case attributes.
 */
export interface DbAlertUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  digest_is_active: boolean;
  created_at: string;
  updated_at: string;
}

/**
 * Class implementing DbAlertUser interface.
 * camelCase attributes. Constructor using interface.
 */
export class AlertUser {
  private _id: number;
  private _email: string;
  private _firstName: string;
  private _lastName: string;
  private _digestIsActive: boolean;
  private _createdAt: string;
  private _updatedAt: string;

  constructor(dbAlertUser: DbAlertUser) {
    this._id = dbAlertUser?.id;
    this._email = dbAlertUser?.email;
    this._firstName = dbAlertUser?.first_name;
    this._lastName = dbAlertUser?.last_name;
    this._digestIsActive = dbAlertUser?.digest_is_active;
    this._createdAt = dbAlertUser?.created_at;
    this._updatedAt = dbAlertUser?.updated_at;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get digestIsActive(): boolean {
    return this._digestIsActive;
  }

  set digestIsActive(value: boolean) {
    this._digestIsActive = value;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }

  get updatedAt(): string {
    return this._updatedAt;
  }

  set updatedAt(value: string) {
    this._updatedAt = value;
  }
}

/**
 * Structure of an Alert and it's linked users.
 * snake_case attributes.
 */
export interface DbAlertWithUsers {
  id: number;
  name: string;
  is_flagged: boolean;
  created_at: string;
  updated_at: string;
  users: AlertUser[];
}

/**
 * Class implementing DbAlertWithUsers interface.
 * camelCase attributes. Constructor using interface.
 */
export class AlertWithUsers extends Item {
  private _isFlagged: boolean;
  private _createdAt: string;
  private _updatedAt: string;
  private _users: AlertUser[];

  constructor(dbAlertWithUsers: DbAlertWithUsers) {
    super(dbAlertWithUsers.id, dbAlertWithUsers.name);
    this._isFlagged = dbAlertWithUsers.is_flagged;
    this._createdAt = dbAlertWithUsers.created_at;
    this._updatedAt = dbAlertWithUsers.updated_at;
    this._users = dbAlertWithUsers.users;
  }

  get isFlagged(): boolean {
    return this._isFlagged;
  }

  set isFlagged(value: boolean) {
    this._isFlagged = value;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }

  get updatedAt(): string {
    return this._updatedAt;
  }

  set updatedAt(value: string) {
    this._updatedAt = value;
  }

  get users(): AlertUser[] {
    return this._users;
  }

  set users(value: AlertUser[]) {
    this._users = value;
  }
}

/**
 * Manipulation interface to create an Alert that can be displayed with its activation state.
 * camelCase attributes.
 */
export interface DbDisplayedAlert extends AlertWithUsers {
  user: DbAlertUser;
}

/**
 * Displayed Alert: alert so it can be displayed with it's activation state.
 * camelCase attributes. Constructor using classes.
 */
export class DisplayedAlert extends Item {
  private _isFlagged: boolean;
  private _createdAt: string;
  private _updatedAt: string;
  private _users: AlertUser[];
  private _user: AlertUser;

  constructor(alertWithUsers: AlertWithUsers, user: AlertUser) {
    super(alertWithUsers.id, alertWithUsers.name);
    this._isFlagged = alertWithUsers.isFlagged;
    this._createdAt = alertWithUsers.createdAt;
    this._updatedAt = alertWithUsers.updatedAt;
    this._users = alertWithUsers.users;
    this._user = user;
  }

  get isFlagged(): boolean {
    return this._isFlagged;
  }

  set isFlagged(value: boolean) {
    this._isFlagged = value;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }

  get updatedAt(): string {
    return this._updatedAt;
  }

  set updatedAt(value: string) {
    this._updatedAt = value;
  }

  get users(): AlertUser[] {
    return this._users;
  }

  set users(value: AlertUser[]) {
    this._users = value;
  }

  get user(): AlertUser {
    return this._user;
  }

  set user(value: AlertUser) {
    this._user = value;
  }
}


/**
 * Structure of the an Alert extending Item and its corresponding topics.
 * snake_case attributes.
 */
export interface DbAlertRelatedToTopic extends Item {
  topics: Topic[];
}

/**
 * Class implementing DbAlertRelatedToTopic interface.
 * camelCase attributes. Constructor using attributes.
 */
export class AlertRelatedToTopic extends Item implements DbAlertRelatedToTopic {
  private _topics: Topic[];

  constructor(id: any, name: string, topics: Topic[]) {
    super(id, name);
    this._topics = topics;
  }

  get topics(): Topic[] {
    return this._topics;
  }

  set topics(value: Topic[]) {
    this._topics = value;
  }
}

/**
 * Structure of the response returned by method retrieving Alerts linked to a certain Topic.
 * snake_case attributes.
 */
export interface DbAlertRelatedToTopicsPostResponseBody {
  data: AlertRelatedToTopic[];
  pagination: Pagination;
}

/**
 * Class implementing DbAlertRelatedToTopicsPostResponseBody interface.
 * camelCase attributes. Constructor using interface.
 */
export class AlertRelatedToTopicsPostResponseBody {
  private _alerts: AlertRelatedToTopic[];
  private _pagination: Pagination;

  constructor(interfaceObject: DbAlertRelatedToTopicsPostResponseBody) {
    this._alerts = interfaceObject.data;
    this._pagination = interfaceObject.pagination;
  }

  get alerts(): AlertRelatedToTopic[] {
    return this._alerts;
  }

  set alerts(value: AlertRelatedToTopic[]) {
    this._alerts = value;
  }

  get pagination(): Pagination {
    return this._pagination;
  }

  set pagination(value: Pagination) {
    this._pagination = value;
  }
}
