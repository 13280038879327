import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AlertSettingService } from '../../shared/services/alert-setting.service';
import daysJson from './days.json';
import { StepModel } from '../../core/step/step.model';
import { StepsService } from '../../shared/services/steps.service';
import { DigestDayOfWeek } from '../../models/digest-settings';
import { ApiService } from '../../shared/services/api/api.service';
import { first, tap } from 'rxjs/operators';
import { AlertWithUsers, DbAlertWithUsers } from '../../models/alert';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-frequent-step-template',
  templateUrl: './frequent-step-template.component.html',
  styleUrls: ['./frequent-step-template.component.scss']
})
export class FrequentStepTemplateComponent implements OnInit, OnChanges {
  @Input() daysSetting: Array<DigestDayOfWeek>;
  @Input() alertSettingsRetrieved$: ReplaySubject<boolean>;
  @Input() alertId: string;
  @Input() name: string;
  @Input() step: StepModel;
  public days: Array<DigestDayOfWeek>;
  isNameUnique = true;
  userAlerts: AlertWithUsers[] = [];
  @Output() otherUserAlerts = new EventEmitter<DbAlertWithUsers[]>();

  constructor(
    private stepsService: StepsService,
    private alertSettingService: AlertSettingService,
    private apiService: ApiService
  ) {
    // initial set up of default pre-selection values (lu, me, ve).
    this.days = JSON.parse(JSON.stringify(daysJson.days));
    this.sendSelectDay();
    // set step completion to false on component creation.
    this.stepsService.updateStepCompletionState(3, false);
  }

  ngOnInit() {

    // Checking name uniqueness in case of duplication.
    const settingsPromise = this.alertSettingsRetrieved$
      .pipe(first())
      .toPromise();

    // Retrieving of user Alerts to manage name uniqueness
    const userId = localStorage.getItem('user_id');
    // Add fields  territory_uid and topics to help identify existence of lookalike alerts in tracking.
    const fields: Array <string> = ['id', 'name', 'territory_uid', 'topics'];
    const filters: object = { 'user_ids': [parseInt(userId, 10)], 'digest_type': 'topics_digest' };

    const userAlertsPromise = this.apiService.alert.getAlertsAsDbAlertWithUsers(fields, filters)
      .pipe(first())
      .pipe(tap((alerts) => {
        this.userAlerts = alerts.map(x => new AlertWithUsers(x));
        // Pass otherUserAlerts to the parent component.
        this.otherUserAlerts.emit(alerts);
      }))
      .toPromise();

    Promise.all([settingsPromise, userAlertsPromise])
      .then(() => {
        this.checkNameUniqueness();
        this.checkCompleted();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.daysSetting?.currentValue) {
      this.daysSetting = changes.daysSetting.currentValue;
      this.days = [...this.daysSetting];
      this.sendSelectDay();
    }

    if (changes.name?.currentValue) {
      this.name = changes.name.currentValue.trim();
      this.onNameChange();
    }

    if (changes.daysSetting?.currentValue || changes.name?.currentValue) {
      this.checkCompleted();
    }
  }

  onSelectDay(dayIndex: number): void {
    this.days[dayIndex].selected = !this.days[dayIndex].selected;
    this.checkCompleted();
    this.sendSelectDay();
  }

  sendSelectDay(): void {
    const daysSelected = this.getSelectedDay();
    this.alertSettingService.setFrequent(daysSelected);
  }

  getSelectedDay(): Array<string> {
    return this.days?.filter(x => x.selected).map(x => x.value);
  }

  onNameChange(): void {
    this.checkNameUniqueness();
    this.checkCompleted();
    this.alertSettingService.setName(this.name);
  }

  checkCompleted(): void {
    const isComplete = this.isNameUnique && (this.name?.length > 1) && this.getSelectedDay()?.length > 0;
    this.stepsService.updateStepCompletionState(3, isComplete);
  }

  checkNameUniqueness() {
    // Get other alerts, sifted by id.
    const otherAlerts = this.userAlerts.filter((alert) => Number(alert?.id) !== Number(this?.alertId));
    // Alert name is unique if is not given to another alert.
    this.isNameUnique = otherAlerts.filter((alert) =>
      alert?.name?.trim().toLowerCase() === this.name?.trim().toLowerCase()).length === 0;
  }
}
