import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../headers/headers.service';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiZendeskService {

  constructor(
    private http: HttpClient,
    private headersService: HeadersService
  ) {
  }

  /** Useful for testing purpose, mocking it because we trust the browser to handle the redirection.
   * It is hard to test the calling function otherwise. */
  _redirectToZendesk(redirectUrl): void {
    window.location.href = redirectUrl;
  }

  getJwtTokenForWebWidget(): Observable<string> {
    return this.http.get(`${environment.backV2EndUrl}faq/jwt/zendesk/web_widget`, this.headersService.httpHeaders)
      .pipe(map((response: { token: string }) => {
        return response.token;
      }));
  }

  getJwtTokenForZendeskAuthentication(returnToZendeskUrl?: string): Observable<void> {
    let query = `${environment.backV2EndUrl}faq/jwt/zendesk`;
    if (returnToZendeskUrl) {
      query += `?return_to=${returnToZendeskUrl}`;
    }
    return this.http.get(query, this.headersService.httpHeaders)
      .pipe(map((response: { redirect: string }) => {
        this._redirectToZendesk(response.redirect);
      }));
  }
}
