<div [ngClass]="{ 'flex-column': itemListLength === 0,
                  'flex-row justify-content-between align-items-center item-list-header': itemListLength !== 0 }"
     class="d-flex">
  <div class="d-flex align-items-center">
    <h3 class="table-title" id="table-title">
      {{translationFileSection + '.title' | translate}}
    </h3>
    <div class="count-rectangle" *ngIf="!hasFilter">
      <p class="color">
        {{items.length}}
      </p>
    </div>
    <div class="count-rectangle hasFilter pl-2 pr-2" *ngIf="hasFilter && itemListLength > 0">
      <p class="color pl-2 pr-2">
        {{items.length}}/{{itemListLength}}
      </p>
    </div>
  </div>
  <div *ngIf="itemListLength === 0" class="no-items-in-list">
    <div class="no-items-in-list-title">
      {{translationFileSection + '.placeholder-no-content-row-1' | translate}}
    </div>
    <div class="no-items-in-list-sub-title">
      {{translationFileSection + '.placeholder-no-content-row-2' | translate}}
    </div>
  </div>
  <button
    class="btn-create d-flex"
    [ngClass]="{ 'no-content-button': itemListLength === 0 }"
          (click)="openCreationModal()">
    <div class="align-self-center icon icon--plus-circle"></div>
    <div class="text-uppercase font-weight-bold align-self-center">{{translationFileSection + '.new-item-button' | translate}}</div>
  </button>
</div>
<ng-container *ngIf="items && items.length > 0">
  <div class="row header-table">
    <div class="col-xl-6 col-lg-5 col-md-4 col-sm-4 d-flex align-items-center" (click)="changeOrder(columns['col1'])">
      <p>
        {{translationFileSection + '.col-1' | translate}}
      </p>
      <ng-container *ngIf="currentOrder.orderBy === columns['col1']">
        <ng-container *ngIf="currentOrder.reverse">
          <img src="assets/images/icon-arrow-up-fill.svg" alt="arrow-up">
        </ng-container>
        <ng-container *ngIf="!currentOrder.reverse">
          <img src="assets/images/icon-arrow-down-fill.svg" alt="arrow-down">
        </ng-container>
      </ng-container>
      <ng-container *ngIf="currentOrder.orderBy !== columns['col1']">
        <img src="assets/images/icon-arrow-both-fill.svg" alt="arrow-both-up-and-down">
      </ng-container>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 d-flex align-items-center" (click)="changeOrder(columns['col2'])">
      <p>
        {{translationFileSection + '.col-2' | translate}}
      </p>
      <ng-container *ngIf="currentOrder.orderBy === columns['col2']">
        <ng-container *ngIf="currentOrder.reverse">
          <img src="assets/images/icon-arrow-up-fill.svg" alt="arrow-up">
        </ng-container>
        <ng-container *ngIf="!currentOrder.reverse">
          <img src="assets/images/icon-arrow-down-fill.svg" alt="arrow-down">
        </ng-container>
      </ng-container>
      <ng-container *ngIf="currentOrder.orderBy !== columns['col2']">
        <img src="assets/images/icon-arrow-both-fill.svg" alt="arrow-both-up-and-down">
      </ng-container>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 d-flex align-items-center" (click)="changeOrder(columns['col3'])">
      <p>
        {{translationFileSection + '.col-3' | translate}}
      </p>
      <ng-container *ngIf="currentOrder.orderBy === columns['col3']">
        <ng-container *ngIf="currentOrder.reverse">
          <img src="assets/images/icon-arrow-up-fill.svg" alt="arrow-up">
        </ng-container>
        <ng-container *ngIf="!currentOrder.reverse">
          <img src="assets/images/icon-arrow-down-fill.svg" alt="arrow-down">
        </ng-container>
      </ng-container>
      <ng-container *ngIf="currentOrder.orderBy !== columns['col3']">
        <img src="assets/images/icon-arrow-both-fill.svg" alt="arrow-both-up-and-down">
      </ng-container>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4">
    </div>
  </div>
  <div>
    <div class="card-board"
         *ngFor="let item of (currentOrder.orderBy === columns['col1'] ?
    (items | sortText: currentOrder.orderBy : currentOrder.reverse : forceUpdate) :
    (items | sortDate: currentOrder.orderBy : currentOrder.reverse : forceUpdate)) ; let i = index">
      <div class="item">
        <div class="row entity-item">
          <div class="col-xl-6 col-lg-5 col-md-4 col-sm-4 d-flex align-self-center">
            <img class="item-icon" [src]="'assets/images/' + iconName" alt="item-icon">
            <div class="font-weight-bold name"
                 [ngClass]="{'hovered-text': item.id === itemIdHovered}"
                 (mouseenter)="onHoverItem(item.id)" (mouseleave)="onHoverItem(0)"
                 (click)="onClickItem(item)" >{{item[columns['col1']]}}</div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 d-flex align-self-center">
            <div class="attribute">{{item[columns['col2']]}}</div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 d-flex align-self-center">
            <p class="attribute" *ngIf="item[columns['col3']]">
              {{item[columns['col3']] | date:'dd MMMM yyyy':'':'fr' }}
            </p>
          </div>
          <div [ngClass]="{'text-right d-flex flex-row' : true,
                          'col-xl-2 col-lg-3 col-md-4 col-sm-4' : updateOption,
                          'flex-grow-1' : !updateOption
                          }">
            <ng-container class="d-flex flex-row">
              <div *ngIf="!updateOption" class="separator d-flex align-self-center">
              </div>
              <div class="delete d-flex">
                <button class="d-flex align-self-center" (click)="openDeleteModal(item, i)"
                        placement="bottom"
                        ngbTooltip="{{translationFileSection + '.delete-item-button' | translate}}"
                        tooltipClass="custom-tooltip-trash" >
                  <img src="assets/images/icon-trash.svg" class="align-self-center icon-block--trash"
                       alt="icon-delete">
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="itemListLength === 0">
    </ng-container>

    <ng-container *ngIf="items?.length === 0 && itemListLength > 0">
      <div class="placeholder">
        <div class="table">
          <div>
            {{translationFileSection + '.placeholder-no-content-row-1' | translate}}
          </div>
          <div>
            {{translationFileSection + '.placeholder-no-content-row-2' | translate}}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
