import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ZendeskWebWidgetService } from '../../shared/services/zendesk/zendesk-web-widget.service';

@Component({
  selector: 'app-zendesk-faq',
  templateUrl: './zendesk-faq.component.html',
  styleUrls: ['./zendesk-faq.component.scss']
})
export class ZendeskFaqComponent implements OnInit {
  display = false;
  open = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private zendeskWebWidgetService: ZendeskWebWidgetService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.subscribeToWidgetSearchUpdate();
  }

  ngOnInit() {
    this.zendeskWebWidgetService.widgetShouldBeDisplayed.subscribe((display) => {
      this.display = display;
      this.changeDetectorRef.detectChanges();
    });
    this.zendeskWebWidgetService.panelOpeningState.subscribe((opened) => {
      this.open = opened;
      this.changeDetectorRef.detectChanges();
    });
  }

  /* Retrieve from top to bottom of routing tree the widgetSearch data. */
  subscribeToWidgetSearchUpdate() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(_ => {
        let tmpRoute = this.route.root;
        const data = {};
        while (tmpRoute) {
          for (const [key, value] of Object.entries(tmpRoute.snapshot.data)) {
            data[key] = value;
          }
          tmpRoute = tmpRoute.firstChild;
        }
        this.zendeskWebWidgetService.updateSearchSuggestion(data['widgetSearch']);
      });
  }

  openWidget() {
    this.zendeskWebWidgetService.openWebWidget();
  }

  closeWidget() {
    this.zendeskWebWidgetService.closeWebWidget();
  }


}
