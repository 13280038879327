import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-admin-doc-sidebar-button',
  templateUrl: './admin-doc-sidebar-button.component.html',
  styleUrls: ['./admin-doc-sidebar-button.component.scss']
})
export class AdminDocSidebarButtonComponent implements OnInit {
  @Input() sidebarVisible: boolean;
  @Input() toolbarHeight$: ReplaySubject<number>;
  @Output() displayLateralPanel = new EventEmitter<boolean>();


  constructor() { }

  ngOnInit(): void {
    const bodyStyles = document.body.style;
    const sidebarContainerWidth = document.getElementById('sidebarContainer').clientWidth;
    bodyStyles.setProperty('--sidebar-width', sidebarContainerWidth + 'px');

    this.toolbarHeight$.subscribe((toolbar) => {
      bodyStyles.setProperty('--toolbar-height', toolbar + 8 + 'px');
    });

  }

  onDisplayLateralPanel() {
    this.displayLateralPanel.emit(true);
  }
}
