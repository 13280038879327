import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../headers/headers.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiUserDigestsService {

  constructor(private http: HttpClient,
              private headersService: HeadersService) { }

  /**
   * PUT method in charge of switching the active state of an alert.
   * Param isActive correspond to the new, wanted state after calling the Endpoint.
   */
  switchAlertActiveState(alertId: string, isActive: boolean): Observable<any> {
    const body = {
      digest_id: alertId,
      is_active: isActive
    };
    return this.http
      .put(
        `${environment.backV2EndUrl}user_digests/is_active`,
        body,
        this.headersService.httpHeaders
      );
  }
}
