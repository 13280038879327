import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { TerritorySearch } from '../../core/territory-search/territory-search';
import { TerritoryHelper } from '../../shared/helpers/territory.helper';
import { TreeItem, TreeviewConfig, TreeviewItem } from '../../lib/ngx-treeview';
import { AlertSettingService } from '../../shared/services/alert-setting.service';
import { StepModel } from '../../core/step/step.model';
import { first, take } from 'rxjs/operators';
import { NotiferService } from 'src/app/shared/services/notifer.service';
import { StepsService } from '../../shared/services/steps.service';
import { DigestTerritory } from '../../models/digest-settings';
import { ApiService } from '../../shared/services/api/api.service';
import { TerritoryKind } from '../../core/territory-kind/territory-kind.enum';
import { TerritoryForAccountInfo } from '../../models/territory/territory-utils';
import { SearchHelper } from '../../shared/helpers/search.helper';
import { TerritoryManager } from '../../models/territory/territory-manager';

@Component({
  selector: 'app-perimeter-step-template',
  templateUrl: './perimeter-step-template.component.html',
  styleUrls: ['./perimeter-step-template.component.scss']
})
export class PerimeterStepTemplateComponent extends TerritorySearch implements OnInit, OnChanges {
  @ViewChild('treeview') treeview: ElementRef;
  @Input() territoriesSetting: Array<DigestTerritory>;
  @Input() step: StepModel;
  @Input() isComponentHidden: boolean;
  @Input() maxDepartmentNumber: number;
  @Output() totalDepartments = new EventEmitter<{ total: number, context: string }>();
  @Output() selectedDepartments = new EventEmitter<{ selected: number, context: string }>();
  territories: Array<object> = [];
  openTab: string;
  isAllTerritories: boolean;
  config = TreeviewConfig.create({
    hasFilter: true,
    decoupleChildFromParent: false,
    maxHeight: 270
  });
  filterText = '';
  nbMatchSearch = 0;

  clickOrPressDebounceDueTime = 0;

  constructor(
    private stepsService: StepsService,
    private notif: NotiferService,
    protected apiService: ApiService,
    private alertSettingService: AlertSettingService) {
      super(apiService);
      this.isAllTerritories = true;
      this.stepsService.updatePerimetersRetrievingState(false);
  }

  ngOnInit() {

    this.apiService.gwStatistics.AccountInfoReplaySubject
      .pipe(first())
      .subscribe((response) => {
        this.filteredList = response.territories.filter(elem => elem.kind === TerritoryKind.DEPARTMENT);
        this.totalDepartments.emit({total: this.filteredList.length, context: 'departments'});
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.territoriesSetting?.currentValue) {
      this.territoriesSetting = changes.territoriesSetting.currentValue;
      const territoryRequests = [];
      this.territoriesSetting.forEach(territory => {
        territoryRequests.push(this.addNewDepartment(territory.territory, territory.values));
      });
      Promise.all(territoryRequests).then(() => this.stepsService.updatePerimetersRetrievingState(true));
    }
  }

  onClickDepartment(territory: TerritoryForAccountInfo, disabled?: boolean) {
    if (!disabled) {
      const territoryUid = territory.uid;
      this.addNewDepartment(territoryUid);
      this.search('');
      this.territoryName = '';
      this.activeDepartmentIndex = 0; // select the first item in the list when a new input/letter is typed
      this.hideIfOpen();
    }
  }

  addNewDepartment(territoryUid: string, checkedUid = []): Promise<void> {
    return new Promise((resolve) => this.apiService.territory.retrieveTerritories(territoryUid, true)
      .pipe(take(1))
      .subscribe((territoryManager: TerritoryManager) => {
        this.alertSettingService.addTerritoryManager(territoryUid, territoryManager);
        const data = territoryManager.territory;
        this.resetTextSearch();
        const uid = data.uid;
        if (this.territories.some((x) => x['uid'] === uid)) {
          return;
        }
        const treeviews = this.getItemTreeview(data, uid, checkedUid);

        const checkedItems = treeviews.reduce((acc, val) => {
          return acc.concat(val.getSelection().checkedItems.map(k => k.value));
        }, []);
        const uncheckedItems = treeviews.reduce((acc, val) => {
          return acc.concat(val.getSelection().uncheckedItems.map(k => k.value));
        }, []);
        const nbItems = checkedItems.length + uncheckedItems.length;
        const territory = {
          name: `${data.code} - ${data.name}`,
          uid,
          treeviews,
          collapsed: false,
          checkedItems,
          nbItems,
          allChecked: uncheckedItems.length === 0,
          new: !checkedUid.length,
        };
        this.resetNewItemStats();
        this.territories.unshift(territory);
        this.sendTerritories();
        setTimeout(() => {
          this.resetNewItemStats();
        }, 5000);
        this.isAllTerritories = true;

        this.selectedDepartments.emit({selected: this.territories.length, context: 'departments'});
        resolve();
      }));
  }

  getItemTreeview(territories, uid, checkedUid): TreeviewItem[] {
    if (checkedUid.length) {
      return TerritoryHelper.transferToTreeview([territories], true, true, checkedUid);
    } else {
      if (!this.isAllTerritories) {
        this.openTab = uid;
        return TerritoryHelper.transferToTreeview([territories], true, true, false);
      } else {
        return TerritoryHelper.transferToTreeview([territories], true, true);
      }
    }
  }

  resetNewItemStats(): void {
    if (this.territories.length) {
      this.territories.forEach(x => x['new'] = false);
    }
  }

  onRemoveDepartment(territoryIndex): void {
    const [deleted] = this.territories.splice(territoryIndex, 1);
    this.alertSettingService.removeTerritoryManager(deleted['uid']);
    this.selectedDepartments.emit({selected: this.territories.length, context: 'departments'});
    this.sendTerritories();
  }

  openDepartmentTree(territoryUid) {
    this.openTab = territoryUid;
    this.resetTextSearch();
  }

  closeDepartmentTree() {
    this.openTab = null;
    this.resetTextSearch();
  }

  setAllTerritories(value) {
    this.isAllTerritories = value;
  }

  test(value) {
    this.notif.notifer(value);
  }

  onSelectedChange(value: Array<any>): void {
    const territoryIndex = this.territories.findIndex(item => item['uid'] === this.openTab);
    const territory = this.territories[territoryIndex];
    this.territories[territoryIndex]['checkedItems'] = value;
    this.territories[territoryIndex]['allChecked'] = value.length === territory['nbItems'];
    this.sendTerritories();
  }

  onSelectAllChange(territoryIndex) {
    this.notif.notifer(this.territories[territoryIndex]['treeviews'][0]);
    const territories = this.territories[territoryIndex];
    territories['treeviews'].forEach(child => child.setCheckedRecursive(territories['allChecked']));
    territories['checkedItems'] = territories['treeviews'].reduce((acc, val) => {
      return acc.concat(val.getSelection().checkedItems.map(k => k.value));
    }, []);
    this.sendTerritories();

  }

  collapse(territoryIndex) {
    this.territories[territoryIndex]['collapsed'] = !this.territories[territoryIndex]['collapsed'];
    this.territories[territoryIndex]['treeviews'].forEach(
      child => child.setCollapsedRecursive(this.territories[territoryIndex]['collapsed']));
  }

  sendTerritories() {
    const data = this.territories.map(x => ({
      territory: x['uid'],
      values: x['checkedItems']
    }));
    this.alertSettingService.setTerritories(data);
    this.ifDepartmentExists(data);
  }

  resetTextSearch(): void {
    this.filterText = '';
    this.nbMatchSearch = 0;
  }

  onFilterChange(_): void {
    setTimeout(() => {
      this.nbMatchSearch = this.treeview?.nativeElement?.querySelectorAll('b').length;
    }, 500);
  }

  onTargetItem(territoryIndex): void {
    this.territories[territoryIndex]['treeviews'].forEach(child => child.setCheckedRecursive(false));
  }

  getTargetLabel(item: TreeItem): string {
    const kind = item.infos['kind'];
    if (['FRCOMM', 'FREPCI'].includes(kind)) {
      return `wizard.${kind}`;
    } else {
      return '';
    }
  }

  checkItemSelected(territory): boolean {
    return this.territories.map(x => x['uid']).includes(territory.kind + territory.code);
  }

  ifDepartmentExists(data: any) {
    /*cette function permet de renvoyer un signal au component parent pour lui informer si
    il n'a pas aucune departement séléctionner*/
    if (this.checkIfAtLeastOneZoneChecked(data) === true) {
      if (this.territories.length === 0) {
        this.stepsService.updateStepCompletionState(0, false);
      } else {
        // Any additive change invalidates the overview step.
        this.stepsService.updateStepCompletionState(4, false);
        this.stepsService.updateStepCompletionState(0, true);
      }
    } else {
      this.stepsService.updateStepCompletionState(0, false);
    }
  }

  checkIfAtLeastOneZoneChecked(data: any): boolean {
    /* cette fonction permet de verifier si dans tout les department au moin une commune a ete selectionner */
    for (let i = 0; i < data.length; i++) {
      if (data[i]['values'].length === 0) {
        return false;
      }
    }
    return true;
  }

  clickOutsideDropdownMenu() {
    if (this.show) {
      this.show = false;
    }
  }

  onSelectOrEnterPressed(territory: any) {
    if (this.territories.length < this.maxDepartmentNumber && !this.isComponentHidden) {
      if (!this.show) {
        this.activeDepartmentIndex = this.getMinActiveIndex();
        this.show = true;
      } else {
        this.onClickDepartment(territory, this.checkItemSelected(territory));
        // waiting as much time as for the clickOrPressDebounce$ of the directive.
        // Avoiding ui bugs when using enter too rapidly.
        setTimeout(() => this.activeDepartmentIndex = this.getMinActiveIndex(), this.clickOrPressDebounceDueTime);
      }
    }
  }

  retrieveDueTime(dueTime: number) {
    this.clickOrPressDebounceDueTime = dueTime;
  }

  getMinActiveIndex(): number {
    let indexToReturn = 0;

    const selectedUids = this.territories.map((terr) => {
      return terr['uid'];
    });

    for (let i = 0; i < this.filteredList.length; i++) {
      if (selectedUids.includes(this.filteredList[i].kind + this.filteredList[i].code)) {
        indexToReturn++;
      } else {
        return indexToReturn;
      }
    }
    return indexToReturn;
  }

  updateActiveDepartmentIndex(isArrowDownPressed: boolean) {
    const index = this.activeDepartmentIndex;

    this.activeDepartmentIndex = isArrowDownPressed ? // cases DOWN or UP on vertical dropdown list.
      (index + 1 >= this.filteredList.length) ? 0 : (index + 1) : // DOWN > if at the end, go first elem, else increment.
      (index - 1 === -1) ? (this.filteredList.length - 1) : (index - 1); // UP > if at the begining, go last elem, else decrement.

    const selectedUids = this.territories.map((terr) => {
      return terr['uid'];
    });

    // Case department already selected.
    if (selectedUids.includes(this.filteredList[this.activeDepartmentIndex].kind + this.filteredList[this.activeDepartmentIndex].code)) {
      // Going one step further.
      this.updateActiveDepartmentIndex(isArrowDownPressed);
    }
  }

  // Overriding extended class method to update local activeIndex.
  search(value: string) {
    this.show = true;
    this.selectedUid = '';
    this.filteredList = this.territoriesList.filter((v: TerritoryForAccountInfo) => SearchHelper.searchRegExp(v.code + v.name, value));
    this.activeDepartmentIndex = this.getMinActiveIndex();
  }

  hideItem(item: TreeviewItem): boolean {
    if (item.children) {
      if (item.value) {
        return false;
      } else {
        const childInSearch = item.children.some(x => !!SearchHelper.searchRegExp(x.text, this.filterText));
        return !childInSearch;
      }
    } else {
      return !(!!SearchHelper.searchRegExp(item.text, this.filterText));
    }
  }
}
