import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-admin-doc-pdf-menus',
  templateUrl: './admin-doc-pdf-menus.component.html',
  styleUrls: ['./admin-doc-pdf-menus.component.scss']
})
export class AdminDocPdfMenusComponent implements OnInit {
  @Input() page: number;
  @Input() nbPages: number;
  @Output() pageEntered = new EventEmitter<number>();
  @Output() rotatePdf = new EventEmitter<string>();

  public zoomLevels = [
    'auto',
    'page-actual',
    'page-fit',
    'page-width',
    '25%',
    '50%',
    '75%',
    '100%',
    '150%',
    '200%',
    '250%',
    '400%',
  ];

  constructor() { }

  ngOnInit(): void {
  }

  onPageEntered(event) {
    this.pageEntered.emit(event);
  }

  onRotatePdf() {
    this.rotatePdf.emit('rotate-clockwise');
  }

}
