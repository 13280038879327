import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StepModel } from '../../core/step/step.model';
import { FunnelContextEnum, StepsService, StyleLists } from '../../shared/services/steps.service';
import { Subscription } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { UserTrackerService } from '../../shared/services/tracking/user-tracker.service';
import { AlertSettingService } from '../../shared/services/alert-setting.service';

export enum StepClickContextEnum {
  STEPPER_CLICK = 'stepper-click',
  PREVIOUS_OR_NEXT_CLICK = 'previous-or-next-click'
}

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit, OnDestroy {
  @Input() alertId: string;
  steps: StepModel[];
  currentStep: StepModel;
  subscription = new Subscription(); // subscription to deal with unsub.
  funnelIconUrl = '';
  FunnelContextEnum = FunnelContextEnum; // for html injection.
  styleLists: StyleLists;
  datumRetrieved: boolean;
  alertNameInputElement: HTMLElement;
  stepClickContext: StepClickContextEnum = StepClickContextEnum.PREVIOUS_OR_NEXT_CLICK;
  stepsClickedByStepper = new Array<number>(5).fill(0);
  successiveBrowsedSteps = [];
  sessionStartTimestamp = '';
  firstLoad = true;
  constructor(private stepsService: StepsService,
              private alertSettingService: AlertSettingService,
              private userTrackerService: UserTrackerService) {
    this.styleLists = new StyleLists();
    this.datumRetrieved = false;
    this.sessionStartTimestamp = (new Date()).toISOString();
  }

  ngOnInit() {
    this.subscription.add(
      this.stepsService.getSteps$()
        .subscribe((steps) => {
          this.steps = steps;
          this.updateFunnelContextIconUrl();
        }),
    );

    this.subscription.add(
      this.stepsService.getCurrentStep$()
        .subscribe((currentStep) => {
          this.currentStep = currentStep;
          this.successiveBrowsedSteps.push(currentStep.stepIndexLabel);
          setTimeout(() => this.setUpAlertNameInputField(this.currentStep.stepIndex), 100);

          this.trackSecondKPIOnDuplicationFeature();
          this.stepClickContext = StepClickContextEnum.PREVIOUS_OR_NEXT_CLICK;
        })
    );

    // On longest call.
    this.subscription.add(
      this.stepsService.areTopicsRetrieved$
        .subscribe((bool) => {
          if (!bool) {
            return;
          }
          // Allow display of step custom styles.
          this.datumRetrieved = true;
          // Subscription.
          this.stepsService.styleListsReplaySubject
            .pipe(debounceTime(300))
            .subscribe((styleLists) => {
              this.styleLists = styleLists;
            });
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.styleLists = new StyleLists();
    this.datumRetrieved = false;
  }

  setUpAlertNameInputField(stepIndex: number) {
    this.alertNameInputElement = document.getElementById('alert-name-input-field');
    if (stepIndex === 3) {
      if (this.stepsService.funnelContext === FunnelContextEnum.CREATION) {
        this.alertNameInputElement?.focus();
      } else if (this.stepsService.funnelContext === FunnelContextEnum.DUPLICATION) {
        (this.alertNameInputElement as HTMLInputElement)?.select();
      } // else nothing happens;
    }
  }

  get isEnabledStyle(): boolean[] {
    return this.styleLists.isEnabledStyle;
  }

  get isSuccessStyleList() {
    return this.styleLists.isSuccessStyle;
  }

  get isErroredStyleList() {
    return this.styleLists.isErroredStyle;
  }

  get funnelContext() {
    return this.stepsService.funnelContext;
  }

  updateFunnelContextIconUrl() {
    let url = '/assets/images/';
    switch (this.funnelContext) {
      case (FunnelContextEnum.MODIFICATION): {
        url += 'alert-edit-icon.svg';
        break;
      }
      case (FunnelContextEnum.DUPLICATION): {
        url += 'alert-duplication-icon.svg';
        break;
      }
      default: {
        url += 'alert-creation-icon.svg';
        break;
      }
    }
    this.funnelIconUrl = url;
  }

  onStepClick(step: StepModel) {
    // added info here to help know if we're using current step.
    this.stepClickContext = StepClickContextEnum.STEPPER_CLICK;
    this.stepsClickedByStepper[step.stepIndex] = 1;

    this.stepsService.setCurrentStep(step);
    // set back arePreviewsRetrieved to false
    if (step.stepIndex !== 4) {
      this.stepsService.arePreviewsRetrieved = false;
      this.stepsService.updateStepCompletionState(4, false);
    }
  }

  trackByStep(index: number, element: StepModel) {
    return `${index}-${element.stepName}`;
  }

  /**
   * Method to find ou is the user is using the newly implemented stepper.
   */
  trackSecondKPIOnDuplicationFeature() {
    if (this.firstLoad) {
      this.firstLoad = false;
      return;
    }

    const body = {
      session_start_timestamp: this.sessionStartTimestamp,
      event_timestamp: (new Date()).toISOString(),
      event_type: 'alert-funnel-step-changed',
      digest_id: Number(this.alertId),
      funnel_context: this.stepsService.funnelContext,
      ...this.userTrackerService.buildBasicUserInformations(),
      step_click_context: this.stepClickContext,
      step_clicked: this.currentStep,
      steps_clicked_by_stepper: this.stepsClickedByStepper,
      successive_browsed_steps: this.successiveBrowsedSteps,
    };

    this.userTrackerService.track(body)
      .pipe(first())
      .subscribe();
  }
}
