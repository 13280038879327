import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SearchService } from '../../shared/services/search.service';
import { Content } from '../../core/content/content';
import { EntityService } from '../../shared/services/entity.service';
import { ArticleImSearchService } from '../../shared/services/article-im-search.service';
import { Subscription } from 'rxjs';
import { PublicationSource } from '../search/publication-source';
import { first } from 'rxjs/operators';
import { UrlService } from '../../shared/services/url.service';
import { LoaderService } from '../../shared/services/loader.service';
import { ApiService } from '../../shared/services/api/api.service';

@Component({
  selector: 'app-admin-docs-page',
  templateUrl: './admin-docs-page.component.html',
  styleUrls: ['./admin-docs-page.component.scss']
})
export class AdminDocsPageComponent extends Content implements OnInit, OnDestroy {
  @ViewChild('adminDoc') adminDocElement: ElementRef;
  adminDocId = 'adminDoc';
  nbTotalAdminDoc: number;
  nbWebsite: number;
  nbTotalWebsite: number;
  docId: string;

  /** Subscriptions to services */
  getEntityIdSubscription: Subscription;
  entityServiceSubscription: Subscription;

  constructor(
    protected searchService: SearchService,
    protected entityService: EntityService,
    protected articleImSearchService: ArticleImSearchService,
    private apiService: ApiService,
    private urlService: UrlService,
    private loaderService: LoaderService
  ) {
    super(searchService, entityService, articleImSearchService);

  }

  ngOnInit() {

    this.apiService.gwStatistics.GoodwillStatisticsReplaySubject
      .pipe(first())
      .subscribe((stats) => {
        this.nbTotalAdminDoc = stats.allAdminDocsCount;
        this.nbTotalWebsite = stats.allWebsitesCount;
      });

    this.adminDocId = 'adminDoc' + this.searchService.SearchModeMap.get(PublicationSource.ADMIN_DOCS);

    this.getEntityIdSubscription =
      this.entityService.getEntityId().subscribe(docId => {
        this.docId = docId;
      });

    this.searchService.sentPageIndex(1);
    this.getSearch();

    // ADD event listener to store the current scrollTop value in the adminDoc component.
    setTimeout(() => {
      // this.adminDocElement.nativeElement.addEventListener(() => {
      (document.getElementById(this.adminDocId) as HTMLElement).addEventListener(
        'scroll',
        () => {
          this.scrollHandler();
        });
    }, 300);

    this.entityServiceSubscription = this.entityService.BookMarkPathsSubject
      .subscribe(({id, bookmarkPaths}) => {
        if (this.entities) {
          const updatedBookmarkPathsIndex = this.entities.findIndex(obj => obj['id'] === id);
          this.entities[updatedBookmarkPathsIndex]['bookmark_paths'] = bookmarkPaths;
        }
      });


  }

  scrollHandler() {
    const currentScroll = document.getElementById(this.adminDocId).scrollTop;
    // Set value of currentScroll in storing map
    this.searchService.SearchOffsetMap.get(PublicationSource.ADMIN_DOCS)
      .set(this.searchService.SearchModeMap.get(PublicationSource.ADMIN_DOCS), currentScroll);
  }


  ngOnDestroy() {
    /** Unsubscribe of the unherited searchSubscription from Content */
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    /** Unsubscribe of subscriptions */
    if (this.getEntityIdSubscription) {
      this.getEntityIdSubscription.unsubscribe();
    }

    if (this.entityServiceSubscription) {
      this.entityServiceSubscription.unsubscribe();
    }

    // Unsubscribe of the event listener
    if (document.getElementById('adminDoc0')) {
      (document.getElementById(this.adminDocId) as HTMLElement).removeEventListener('scroll', () => this.scrollHandler());
    }
    if (document.getElementById('adminDoc1')) {
      (document.getElementById(this.adminDocId) as HTMLElement).removeEventListener('scroll', () => this.scrollHandler());
    }

    this.searchService.currentPage = 1;

  }

  /** Overriding abstact method retrieveData declared in Content */
  retrieveData(search: object): void {
    if (this.urlService.evaluateIfComingFromOpenedDocument()) { // When coming from article | document, no data retieved.
      return;
    }
    this.entityService.sendNbResource(search['filters']['resources_count']);
    const body = this.buildBody(search);
    this.entityService.sendTotalCount(this.totalCount);

    this.request(this.apiService.adminDoc.retrieveAdminDocs(body, this.searchService.SearchModeMap.get(PublicationSource.ADMIN_DOCS))
      .pipe(first())
      .subscribe(
        data => {
          const scrollTop = this.searchService.SearchOffsetMap
            .get(PublicationSource.ADMIN_DOCS).get(this.searchService.SearchModeMap.get(PublicationSource.ADMIN_DOCS));
          this.adminDocElement.nativeElement.scrollTo({
            top: scrollTop
          });
          const bodyCount = this.buildCountBody(search);
          this.apiService.adminDoc.retrieveAdminDocsCount(bodyCount)
            .pipe(first())
            .subscribe(
              adminDocCountData => {
                this.setEntities(data, search['limit'], search['offset']);
                this.entityService.sendTotalCount(this.totalCount);
                this.entityService.sendAllCount(adminDocCountData['count']);
              });
        },
        _ => {
          this.totalCount = 0;
          this.entityService.sendTotalCount(this.totalCount);
          this.entityService.sendAllCount(0);
          this.loaderService.hideLoader(true);
        }));
  }
}
