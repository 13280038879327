export interface DbDigestStatus {
  id: number;
  name: string;
  status: string;
}

export class DigestStatus {
  id: number;
  name: string;
  status: string;

  constructor(
    id: number,
    name: string,
    status: string) {
    this.id = id;
    this.name = name;
    this.status = status;
  }

}
