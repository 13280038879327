import { Injectable } from '@angular/core';
import { OrderByBookmarkFolder } from '../../models/bookmark-folder';
import { ReplaySubject } from 'rxjs';
import { Folder } from '../../models/folder';

@Injectable({
  providedIn: 'root'
})
export class BookmarkFolderService {

  private _limit = 10000;
  private _pageIndex = 0;
  private _orderBy = OrderByBookmarkFolder.ENTITY_LABEL;
  private _reverse = false;
  private _folder = new ReplaySubject<Folder>(1);

  buildPostBody() {
    return {
      'entity_type': 'folder',
      'limit': this.limit,
      'page_index': this.pageIndex,
      'order_by': this.orderBy,
      'reverse': this.reverse
    };
  }

  get limit(): number {
    return this._limit;
  }

  set limit(value: number) {
    this._limit = value;
  }

  get pageIndex(): number {
    return this._pageIndex;
  }

  set pageIndex(value: number) {
    this._pageIndex = value;
  }

  get orderBy(): OrderByBookmarkFolder {
    return this._orderBy;
  }

  set orderBy(value: OrderByBookmarkFolder) {
    this._orderBy = value;
  }

  get reverse(): boolean {
    return this._reverse;
  }

  set reverse(value: boolean) {
    this._reverse = value;
  }

  set folder(value: Folder) {
    this._folder.next(value);
  }

  getFolderSubject(): ReplaySubject<Folder> {
    return this._folder;
  }

}
