import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { PublicationSource } from '../../components/search/publication-source';
import { SearchMode } from '../../components/search/search-mode';

const sortDefault = {
  field: 'publication_date',
  dir: 'desc'
};

@Injectable({ providedIn: 'root'})
export class SearchService {
  protected subject = new ReplaySubject<object>(0);
  protected limit = 25;
  protected offset = 0;
  protected text = '';
  private name = '';
  protected sortBy: object = sortDefault;
  protected filters: object;
  protected perimeter: Array<string>;

  protected searchModeMap = new Map<PublicationSource, SearchMode>();
  // Map caching an offset value for each source x searchMode configuration.
  protected searchOffsetMap = new Map<PublicationSource, Map<SearchMode, number>>();
  protected cachedTextMap = new Map<SearchMode, string>();
  protected cachedSelectedTopics = new Array<Object>();
  public searchModeMapChanged = new ReplaySubject<Map<PublicationSource, SearchMode>>();
  public cachedTextMapChanged = new Subject<Map<SearchMode, string>>();
  public cachedImpacterName = '';
  public cachedImpacterSearchText = '';  // Cached text for ArticleView


  public currentPage = 1;
  public nbPages = 1;

  constructor() {
    this.initSearchModeMap();
    this.initSearchOffsetMap();
  }

  /** Map storing the selected search mode every publication source */
  get SearchModeMap() {
    return this.searchModeMap;
  }

  /** Map storing every publication source x searchMode scrolls values */
  get SearchOffsetMap() {
    return this.searchOffsetMap;
  }

  /** Map storing cached text searched for specific search modes */
  get CachedTextMap() {
    return this.cachedTextMap;
  }

  /** Map storing cached text searched for specific search modes */
  set CachedTextMap(cachedTextMap) {
    this.cachedTextMap = cachedTextMap;
    this.cachedTextMapChanged.next(this.cachedTextMap);
  }

  /** Array storing cached selected topics */
  get CachedSelectedTopics() {
    return this.cachedSelectedTopics;
  }

  set CachedSelectedTopics(cachedSelectedTopics: Array<Object>) {
    this.cachedSelectedTopics = cachedSelectedTopics;
  }

  /** Name of impacter stored in service */
  get CachedImpacterName() {
    return this.cachedImpacterName;
  }

  set CachedImpacterName(cachedImpacterName: string) {
    this.cachedImpacterName = cachedImpacterName;
  }

  reset() {
    this.limit = 25;
    this.sortBy = sortDefault;
    this.offset = 0;
  }

  sentText(text: string) {
    this.text = text;
    this.offset = this.limit * (this.currentPage - 1);
    this.sendSearch();
  }

  resetText() {
    this.sortBy = sortDefault;
    this.offset = 0;
    this.text = '';
    this.sendSearch();
  }

  sentName(name: string) {
    this.name = name;
    this.offset = 0;
    this.sendSearch();
  }

  resetName() {
    this.name = '';
  }

  sentLimit(limit: number) {
    this.limit = limit;
    this.currentPage = 1;
    this.offset = 0;
    this.resetSearchOffsetMap();
    this.sendSearch();
  }

  sentSort(sortBy: object) {
    this.sortBy = sortBy;
    this.offset = 0;
    this.currentPage = 1;
    this.resetSearchOffsetMap();
    this.sendSearch();
  }

  sentPageIndex(pageIndex: number) {
    this.currentPage = pageIndex;
    this.offset = this.limit * (pageIndex - 1);
    this.resetSearchOffsetMap(); // set offset to 0.
    this.sendSearch();
  }

  sentFilter(filters: object) {
    this.filters = filters;
    this.offset = 0;
    this.resetSearchOffsetMap();
    this.currentPage = 1;
    this.sendSearch();
  }

  sendPerimeter(perimeter: Array<string>) {
    this.perimeter = perimeter;
  }

  sendSearch() {
    this.subject.next
    (
      {
        name: this.name,
        text: this.text,
        limit: this.limit,
        offset: this.offset,
        filters: this.filters,
        perimeter: this.perimeter,
        sortBy: this.sortBy
      }
    );
  }

  getSearch(): Observable<object> {
    return this.subject;
  }

  resetCachedTextMap() {
    this.cachedTextMap = new Map<SearchMode, string>();
    this.cachedTextMapChanged.next(this.cachedTextMap);
  }

  /** Init of every publication source searchMode to text */
  initSearchModeMap() {
    this.searchModeMap.set(PublicationSource.ARTICLES, SearchMode.TOPICS);
    this.searchModeMap.set(PublicationSource.ADMIN_DOCS, SearchMode.TOPICS);
    this.searchModeMap.set(PublicationSource.OTHERS, SearchMode.TEXT);
  }

  /** Init of every publication source x searchMode scrolls values to 0 */
  initSearchOffsetMap() {
    const initMap = new Map<SearchMode, number>();
    initMap.set(SearchMode.TOPICS, 0);
    initMap.set(SearchMode.TEXT, 0);
    this.searchOffsetMap.set(PublicationSource.ARTICLES, initMap);
    this.searchOffsetMap.set(PublicationSource.ADMIN_DOCS, initMap);
    this.searchOffsetMap.set(PublicationSource.OTHERS, initMap);
  }

  resetSearchModeMap() {
    this.initSearchModeMap();
  }

  resetSearchOffsetMap() {
    this.initSearchOffsetMap();
  }

  resetSearchServiceMaps() {
    this.resetCachedTextMap();
    this.resetSearchModeMap();
    this.resetSearchOffsetMap();
    this.CachedImpacterName = '';
    this.resetName();
  }
}
