interface SourceTerritories {
  dep: Array<string>;
  name: string;
}

export interface DbTerritoryCounters {
  admin_docs_count: number;
  articles_count: number;
  impacters_count: number;
  sources_count: number;
  uid: string;
  websites_count: number;
  sources_territories: Array<SourceTerritories>;
}

export class TerritoryCounters {
  private _adminDocsCount: number;
  private _articlesCount: number;
  private _impactersCount: number;
  private _sourcesCount: number;
  private _uid: string;
  private _websitesCount: number;
  private _sourcesTerritories: Array<SourceTerritories>;

  constructor(dbTerritoryCounters: DbTerritoryCounters) {
    this.adminDocsCount = dbTerritoryCounters.admin_docs_count;
    this.articlesCount = dbTerritoryCounters.articles_count;
    this.impactersCount = dbTerritoryCounters.impacters_count;
    this.sourcesCount = dbTerritoryCounters.sources_count;
    this.uid = dbTerritoryCounters.uid;
    this.websitesCount = dbTerritoryCounters.websites_count;
    this.sourcesTerritories = dbTerritoryCounters.sources_territories;
  }

  get adminDocsCount(): number {
    return this._adminDocsCount;
  }

  set adminDocsCount(value: number) {
    this._adminDocsCount = value;
  }

  get articlesCount(): number {
    return this._articlesCount;
  }

  set articlesCount(value: number) {
    this._articlesCount = value;
  }

  get impactersCount(): number {
    return this._impactersCount;
  }

  set impactersCount(value: number) {
    this._impactersCount = value;
  }

  get sourcesCount(): number {
    return this._sourcesCount;
  }

  set sourcesCount(value: number) {
    this._sourcesCount = value;
  }

  get uid(): string {
    return this._uid;
  }

  set uid(value: string) {
    this._uid = value;
  }

  get websitesCount(): number {
    return this._websitesCount;
  }

  set websitesCount(value: number) {
    this._websitesCount = value;
  }

  get sourcesTerritories(): Array<SourceTerritories> {
    return this._sourcesTerritories;
  }

  set sourcesTerritories(value: Array<SourceTerritories>) {
    this._sourcesTerritories = value;
  }
}
