import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SearchService } from '../../shared/services/search.service';
import { Content } from '../../core/content/content';
import { EntityService } from '../../shared/services/entity.service';
import { TerritoryKind } from '../../core/territory-kind/territory-kind.enum';
import { ArticleImSearchService } from '../../shared/services/article-im-search.service';
import { Subscription } from 'rxjs';
import { PublicationSource } from '../search/publication-source';
import { SearchMode } from '../search/search-mode';
import { first } from 'rxjs/operators';
import { UrlService } from '../../shared/services/url.service';
import { Article } from '../../models/article';
import { ApiService } from '../../shared/services/api/api.service';
import { LoaderService } from '../../shared/services/loader.service';
import { TerritoryCountersService } from '../../shared/services/territory-counters.service';

@Component({
  selector: 'app-articles-page',
  templateUrl: './articles-page.component.html',
  styleUrls: ['./articles-page.component.scss']
})
export class ArticlesPageComponent extends Content implements OnInit, OnDestroy {
  @Input() impacterId: string;
  articleId = 'article';
  @ViewChild('article') articleElement: ElementRef;
  nbTotalArticle: number;
  nbSource: number;
  nbTotalSource: number;
  docId: string;
  sources: Array<string>;
  firstInit = true;

  /** Subscriptions to services */
  getTerritoryCountersSubscription: Subscription;
  getEntityIdSubscription: Subscription;
  entityServiceSubscription: Subscription;

  constructor(
              private apiService: ApiService,
              protected searchService: SearchService,
              protected entityService: EntityService,
              protected articleImSearchService: ArticleImSearchService,
              private urlService: UrlService,
              private loaderService: LoaderService,
              private territoryCountersService: TerritoryCountersService
              ) {
    super(searchService, entityService, articleImSearchService);
  }

  ngOnInit() {

    this.apiService.gwStatistics.GoodwillStatisticsReplaySubject
      .pipe(first())
      .subscribe((statistics) => {
        this.nbTotalArticle = statistics.allArticlesCount;
        this.nbTotalSource = statistics.allSourcesCount;
      });

    this.getTerritoryCountersSubscription =
      this.territoryCountersService.getTerritoryCounters().subscribe((territoryCounters) => {
        this.sources = territoryCounters.sourcesTerritories.map(source => source.name);
      });

    this.getEntityIdSubscription =
      this.entityService.getEntityId().subscribe(docId => {
        this.docId = docId;
      });

    this.entityServiceSubscription = this.entityService.BookMarkPathsSubject
      .subscribe(({id, bookmarkPaths}) => {
        if (this.entities) {
          const updatedBookmarkPathsIndex = this.entities.findIndex(obj => obj['id'] === id);
          this.entities[updatedBookmarkPathsIndex]['bookmarkPaths'] = bookmarkPaths;
        }
    });

    this.articleId = 'articleId' + this.searchService.SearchModeMap.get(PublicationSource.ARTICLES);

    if (!this.impacterId) {
      this.searchService.sentPageIndex(1);
    }
    this.getSearch(!this.impacterId);

    // ADD event listener to store the current scrollTop value in the adminDoc component.
    setTimeout(() => {
      // this.adminDocElement.nativeElement.addEventListener(() => {
      (document.getElementById(this.articleId) as HTMLElement).addEventListener(
        'scroll',
        () => {
          this.scrollHandler();
        });
    }, 300);
  }

  scrollHandler() {
    const currentScroll = document.getElementById(this.articleId).scrollTop;
    // Set value of currentScroll in storing map
    this.searchService.SearchOffsetMap.get(PublicationSource.ARTICLES)
      .set(this.searchService.SearchModeMap.get(PublicationSource.ARTICLES), currentScroll);
  }

  ngOnDestroy() {
    // console.log('destroy de article-page');
    /** Unsubscribe of the unherited searchSubscription from Content */
    if (this.searchSubscription) { this.searchSubscription.unsubscribe(); }
    /** Unsubscribe of subscriptions */
    if (this.getEntityIdSubscription) { this.getEntityIdSubscription.unsubscribe(); }
    if (this.getTerritoryCountersSubscription) { this.getTerritoryCountersSubscription.unsubscribe(); }

    if (this.impacterId) {
      this.searchService.cachedImpacterSearchText = ''; // Setting to '' the cached search text
      this.articleImSearchService.reset();
    }

    if (this.entityServiceSubscription) { this.entityServiceSubscription.unsubscribe(); }
    // Unsubscribe of the event listener
    if (document.getElementById('articleId0')) {
      (document.getElementById(this.articleId) as HTMLElement).removeEventListener('scroll', () => this.scrollHandler());
    }
    if (document.getElementById('articleId1')) {
      (document.getElementById(this.articleId) as HTMLElement).removeEventListener('scroll', () => this.scrollHandler());
    }

      this.searchService.currentPage = 1;

  }

  retrieveData(search: object): void {
    if (this.urlService.evaluateIfComingFromOpenedDocument() && !this.impacterId) { // When coming from article|document, no data retrieved.
      return;
    }

    delete search['filters']['territories_kind'];
    search['filters']['source'] = this.sources;
    const body = this.buildBody(search);
    if (this.impacterId) {
      body['impacter_id'] = Number(this.impacterId);
    }
    body['filters']['territories'] = body['filters']['territories'].filter(t => t.includes(TerritoryKind.COMMUNE));

    let searchMode = SearchMode.TEXT;
    if (!this.impacterId) {
      searchMode = this.searchService.SearchModeMap.get(PublicationSource.ARTICLES);
    }

    this.request(this.apiService.article.retrieveArticles(body, searchMode)
      .pipe(first())
      .subscribe((data) => {
        const scrollTop = this.searchService.SearchOffsetMap
          .get(PublicationSource.ARTICLES).get(this.searchService.SearchModeMap.get(PublicationSource.ARTICLES));
          this.articleElement.nativeElement.scrollTo({
            top: this.impacterId ? 0 : scrollTop
          });
          this.getTerritoryCountersSubscription =
            this.territoryCountersService.getTerritoryCounters().subscribe((territoryCounters) => {
              this.entityService.sendSourcesList(territoryCounters.sourcesTerritories.map((source => source.name)));
              this.entityService.sendAllCount(territoryCounters.articlesCount);
              this.entityService.sendNbResource(territoryCounters.sourcesCount);
            });
          this.setEntities(data, search['limit'], search['offset']);
          if (this.impacterId) {
            this.entityService.sendArticleIMCurrentCount(this.totalCount);
            if (!search['text'] && this.firstInit) {
              this.entityService.sendArticleIMTotalCount(this.totalCount);
              this.firstInit = false;
            }
          } else {
            this.entityService.sendTotalCount(this.totalCount);
          }
        },
        _ => {
          this.totalCount = 0;
          this.entityService.sendTotalCount(this.totalCount);
          this.loaderService.hideLoader(true);
        }));
  }

  get articles(): Array<Article> {
    return this.entities as Array<Article>;
  }

}

