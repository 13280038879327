import { ElementRef, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { RegexpSearch } from '../helpers/regex';

const SEARCH_TIMEOUT = 700;

@Injectable({
  providedIn: 'root'
})
export class ControlFService {
  inputSearch = '';
  totalMatches: number;
  elements: HTMLCollectionOf<any>;
  subject = new ReplaySubject<object>(0);
  inSearch = false;
  searchNotFound = false;
  private searchTime: number;
  show = false;
  intervalId: number;

  constructor() {
  }

  find(value: string, element: ElementRef): void {
    this.inputSearch = value;
    setTimeout(() => {
      this.elements = element.nativeElement.getElementsByTagName('em');
      this.totalMatches = this.elements.length;
      if (this.inputSearch && this.elements[0]) {
        this.goToSelected(1);
      }
    });
  }

  textContainsInputSearch(value: string, articleText): void {
    if (RegexpSearch.Search(articleText, value)) {
      this.hideLoader();
    }
  }


  prev_find(event, element: ElementRef, articleText): void {
    if (event) {
      this.inSearch = true;
      this.sendLoaderAttributes();
      this.searchTime = (new Date()).getTime();
      this.displayLoader();
      this.find(event, element);
      this.textContainsInputSearch(event, articleText);
      this.sendLoaderAttributes();
    } else {
      this.inputSearch = event;
      this.inSearch = false;
      this.hideLoader();
      this.sendLoaderAttributes();
    }

  }

  sendLoaderAttributes(): void {
    this.subject.next(
      {
        'show': this.show,
        'inSearch': this.inSearch,
        'searchNotFound': this.searchNotFound

      });
  }

  getLoaderAttributes(): Observable<object> {
    return this.subject;
  }


  goToSelected(value: number): void {
    const index = value - 1;
    if (this.inputSearch && this.elements[index]) {
      for (let i = 0; i < this.totalMatches; i++) {
        this.elements.item(i).classList.remove('selected');
      }
      this.elements[index].scrollIntoView({block: 'center'});
      this.elements.item(index).classList.add('selected');
    }
  }

  displayLoader(): void {
    this.show = true;
    this.sendLoaderAttributes();
  }

  hideLoader(): void {
    this.show = false;
  }

  closeSearchLoader(): void {
    this.hideLoader();
    this.inSearch = false;
    this.sendLoaderAttributes();

  }

  setSearchTimeout() {
    this.intervalId = setInterval(() => {
      if (!this.inSearch) {
        return;
      }
      const now = (new Date()).getTime();
      if (now - this.searchTime > SEARCH_TIMEOUT) {
        this.searchNotFound = true;
        this.sendLoaderAttributes();
        setTimeout(() => {
          this.closeSearchLoader();
          this.searchNotFound = false;
          this.sendLoaderAttributes();
        }, 1000);
      }
    });
  }

  clearInterval() {
    clearInterval(this.intervalId);
  }

  resetInputSearch() {
    this.inputSearch = '';
    this.totalMatches = 0;
  }
}
