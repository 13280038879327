import { TerritoryForArticle } from './territory/territory-utils';

/**
 * Structure of an Alert-Document Preview.
 * snake_case attributes.
 */
export interface DbAlertDocumentPreview {
  id: string;
  title: string;
  publication_date: Date;
  excerpt: string;
  source?: string;
  territories?: Array<TerritoryForArticle>;
  html?: {
    html_key: string;
    nb_images: number;
  };
  website?: string;
  url?: string;
  displayed_publication_type?: string;
}

/**
 * Class describing an Alert-Document preview.
 * camelCase attributes. Constructor bases on interface.
 */
export class AlertDocumentPreview {

  id: string;
  title: string;
  publicationDate: Date;
  excerpt: string;
  source?: string;
  territories?: Array<TerritoryForArticle>;
  html?: {
    htmlKey: string;
    nbImages: number;
  };
  website?: string;
  url?: string;
  displayedPublicationType?: string;

  constructor(document: DbAlertDocumentPreview) {
    this.id = document.id;
    this.title = document.title;
    this.publicationDate = document.publication_date;
    this.excerpt = document.excerpt;
    this.source = document?.source;
    this.territories = document?.territories;
    this.html = document.html ? {
      htmlKey: document.html.html_key,
      nbImages: document.html.nb_images
    } : null;
    this.website = document?.website;
    this.url = document?.url;
    this.displayedPublicationType = document.displayed_publication_type;
  }
}

/**
 * Class describing the response body return by the endpoint retrieving Alert-Documents previews.
 */
export class AlertDocumentPreviewResponseBody {
  alertDocumentPreviews: AlertDocumentPreview[];
  alertPreviewPeriod: AlertDocumentPreviewPeriod;
  totalCount: number;

  constructor(alertDocumentPreviews: AlertDocumentPreview[],
              alertPreviewPeriod: AlertDocumentPreviewPeriod,
              totalCount: number) {
    this.alertDocumentPreviews = alertDocumentPreviews;
    this.alertPreviewPeriod = alertPreviewPeriod;
    this.totalCount = totalCount;
  }
}

/**
 * Structure of an Alert-Document preview period.
 * snake_case attributes.
 */
export interface DbAlertDocumentPreviewPeriod {
  alert_date: Date;
  from: Date;
  to: Date;
  nb_admin_doc: number;
  nb_article: number;
}

/**
 * Class describing Alert-Document preview period.
 * camelCase attributes. Constructor based on the interface.
 */
export class AlertDocumentPreviewPeriod {
  alertDate: Date;
  from: Date;
  to: Date;
  nbAdminDoc: number;
  nbArticle: number;

  constructor(dbAlertDocumentPreviewPeriod: DbAlertDocumentPreviewPeriod) {
    this.alertDate = dbAlertDocumentPreviewPeriod.alert_date;
    this.from = dbAlertDocumentPreviewPeriod.from;
    this.to = dbAlertDocumentPreviewPeriod.to;
    this.nbAdminDoc = dbAlertDocumentPreviewPeriod.nb_admin_doc;
    this.nbArticle = dbAlertDocumentPreviewPeriod.nb_article;
  }
}
