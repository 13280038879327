import { SubKind, TerritoryKind } from '../core/territory-kind/territory-kind.enum';

interface DigestTerritoryDb {
  territory: string;
  total_nb_locations: number;
  values: Array<string>;
}

export class DigestTerritory {
  private _territory: string;
  private _totalNbLocations: number;
  private _values: Array<string>;

  constructor(territoryDb: DigestTerritoryDb) {
    this._territory = territoryDb.territory;
    this._totalNbLocations = territoryDb.total_nb_locations;
    this._values = territoryDb.values;
  }

  toStringAsPythonObject() {
    return {
      territory: this.territory,
      total_nb_locations: this.totalNbLocations,
      values: this.values
    };
  }

  toStringForPostNewAlert() {
    return {
      territory: this.territory,
      values: this.values
    };
  }

  get territory(): string {
    return this._territory;
  }

  set territory(value: string) {
    this._territory = value;
  }

  get totalNbLocations(): number {
    return this._totalNbLocations;
  }

  set totalNbLocations(value: number) {
    this._totalNbLocations = value;
  }

  get values(): Array<string> {
    return this._values;
  }

  set values(value: Array<string>) {
    this._values = value;
  }
}

export interface DigestDayOfWeek {
  label: string;
  value: string;
  selected: boolean;
}

export interface DigestPublicationTerritoryKind {
  value: TerritoryKind | SubKind;
  checked: boolean;
}

export interface DbDigestSettings {
  day_of_week: string;
  id: string;
  name: string;
  publication_territories_kind: string;
  regional_daily_press: boolean;
  territories: Array<DigestTerritoryDb>;
  syndicates: Array<string>;
  topics_ids: Array<number>;
}

const days = [
  {label: 'L', value: 'mon'},
  {label: 'M', value: 'tue'},
  {label: 'M', value: 'wed'},
  {label: 'J', value: 'thu'},
  {label: 'V', value: 'fri'},
  {label: 'S', value: 'sat'},
  {label: 'D', value: 'sun'},
];

export class DigestSettings {
  private _id: number;
  private _dayOfWeek: Array<DigestDayOfWeek> = days.map(day => {
    return {
      label: day.label,
      value: day.value,
      selected: false
    };
  });
  private _name: string;
  private _publicationTerritoriesKinds: Array<DigestPublicationTerritoryKind>;
  private _regionalDailyPress: boolean;
  private _territories: Array<DigestTerritory>;
  private _syndicates: Array<string>;
  private _topicsIds: Array<number>;

  constructor(digestSettingsDb: DbDigestSettings) {
    this._id = Number(digestSettingsDb.id);
    this._name = digestSettingsDb.name;
    this._regionalDailyPress = digestSettingsDb.regional_daily_press;
    this._topicsIds = digestSettingsDb.topics_ids;
    this._territories = digestSettingsDb.territories.map(territoryDb => {
      return new DigestTerritory(territoryDb);
    });
    this._syndicates = digestSettingsDb.syndicates;
    for (const day of this._dayOfWeek) {
      if (digestSettingsDb.day_of_week.includes(day.value)) {
        day.selected = true;
      }
    }
    const kinds = digestSettingsDb?.publication_territories_kind?.split(',');
    this._publicationTerritoriesKinds = [
      {
        value: TerritoryKind.COMMUNE,
        checked: kinds.includes(TerritoryKind.COMMUNE)
      },
      {
        value: TerritoryKind.EPCI,
        checked: kinds.includes(TerritoryKind.EPCI)
      },
      {
        value: SubKind.SYNDICAT,
        checked: kinds.includes(SubKind.SYNDICAT)
      }
    ];
  }

  toStringAsPythonObject() {
    return {
      day_of_week: this.dayOfWeek,
      id: this.id,
      name: this.name,
      publication_territories_kind: this.publicationTerritoriesKinds,
      regional_daily_press: this.regionalDailyPress,
      territories: this.territories.map((x) => x.toStringAsPythonObject()),
      syndicates: this.syndicates,
      topics_ids: this.topicsIds,
    };
  }

  toStringForPostNewAlert() {
    return {
      day_of_week: this.dayOfWeek.map(x => {
        if (x.selected) {
          return x.value;
        }
      }).filter(elm => elm).join(','),
      name: this.name,
      publication_territories_kind: this.publicationTerritoriesKinds.map((x) => {
        if (x.checked) {
          return x.value;
        }
      }).filter(elm => elm).join(','),
      regional_daily_press: this.regionalDailyPress,
      territories: this.territories.map((x) => x.toStringForPostNewAlert()),
      syndicates: this.syndicates,
      topics_ids: this.topicsIds,
    };
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get dayOfWeek(): Array<DigestDayOfWeek> {
    return this._dayOfWeek;
  }

  set dayOfWeek(value: Array<DigestDayOfWeek>) {
    this._dayOfWeek = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get publicationTerritoriesKinds(): Array<DigestPublicationTerritoryKind> {
    return this._publicationTerritoriesKinds;
  }

  set publicationTerritoriesKinds(value: Array<DigestPublicationTerritoryKind>) {
    this._publicationTerritoriesKinds = value;
  }

  get regionalDailyPress(): boolean {
    return this._regionalDailyPress;
  }

  set regionalDailyPress(value: boolean) {
    this._regionalDailyPress = value;
  }

  get territories(): Array<DigestTerritory> {
    return this._territories;
  }

  set territories(value: Array<DigestTerritory>) {
    this._territories = value;
  }

  get syndicates(): Array<string> {
    return this._syndicates;
  }

  get topicsIds(): Array<number> {
    return this._topicsIds;
  }

  set topicsIds(value: Array<number>) {
    this._topicsIds = value;
  }
}
