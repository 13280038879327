import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { StoredCollectionNames } from '../../models/stored-collection-names';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ContextService {

  // Time to live, in milliseconds.
  TTL = 24 * 60 * 60 * 1000; // one day

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService
  ) { }

  async getSharedDocumentContext(shareId: string) {
    try {
      const document = await this.apiService.share.getSharedDocument(shareId).pipe(first()).toPromise();
      return document.snippet;
    } catch (e) {
      console.log(`error when retrieving context from share: ${e}`);
    }
  }

  async retrieveContextFromCollectiveOrder(documentId, collectiveOrderId) {
    try {
      const response = await this.apiService.alertsSending.retrieveContextFromCollectiveOrder(collectiveOrderId).pipe(first()).toPromise();
      for (const doc of response.docs) {
        if (doc.id === documentId) {
          return doc.excerpt;
        }
      }
    } catch (e) {
      console.log(`error when retrieving context from collective order: ${e}`);
    }
    return null;
  }

  async getContext(currentNavigationState, documentId, openSource, openSourceId: string, collectiveOrderId) {
    let snippet = null;

    let storedContexts = this.getStoredContexts();
    if (!storedContexts) {
      storedContexts = {storedItems: []};
    }
    const storedContext = storedContexts.find(item => item?.id === documentId);

    if (openSource === 'share') {
      snippet = await this.getSharedDocumentContext(openSourceId);
    } else if (openSource === 'digest') {
      snippet = await this.retrieveContextFromCollectiveOrder(documentId, collectiveOrderId);
    } else if (currentNavigationState?.context) {
      snippet = currentNavigationState.context;
    } else if (storedContext) { // default case
      snippet = storedContext.snippet;
    }
    return snippet;
  }

  /**
   * Method to store a context inside localStorage with a TTL (time to live) list of storedContexts.
   * @param documentId document id
   * @param snippet context
   */
  setSnippetWithExpiry(documentId: string, snippet: string): void {
    const itemToStore = {
      'id': documentId,
      'snippet': snippet,
      'expiry': Date.now() + this.TTL
    };
    this.localStorageService.storeItem(StoredCollectionNames.STORED_CONTEXTS, itemToStore);
  }

  getStoredContexts(): any {
    return JSON.parse(localStorage.getItem(StoredCollectionNames.STORED_CONTEXTS))?.storedItems;
  }

}

