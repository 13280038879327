<div [ngClass]="{'form-header-container': !error && !warning,
                'form-header-container-warning': warning,
                'form-header-container-error': error}">

  <div class="d-flex justify-content-between total-step-width header-content">

    <div class="wizard-text-header d-flex flex-column justify-content-between">

      <img *ngIf="warning" src="/assets/images/info-circle-fill.svg" class="warning" alt="info-circle-fill">
      <img *ngIf="error" src="/assets/images/alert-triangle-fill.svg" class="error" alt="alert-triangle-fill">

      <!--Normal Case-->
      <div *ngIf="!warning && !error"
           class="step-title">
        {{stepTitle | translate}}
      </div>

      <!--Error Case-->
      <div *ngIf="warning || error"
           class="step-title warning-or-error add-marge-adapter"
           ngbTooltip="{{stepSubTitle | translate}}"
           tooltipClass="tooltip-alert-error"
           placement="bottom">
        {{stepTitle | translate}}
      </div>

      <div class="step-sub-title d-flex"
           *ngIf="!warning && !error"
           [ngClass]="{'add-marge-adapter' : error || warning,
                   'empty': currentSelectionValue === 0 && !step?.isComplete && (step?.stepIndex !== 4),
                   'not-empty': currentSelectionValue > 0 || step?.isComplete }">

      <span *ngIf="currentSelectionValue === 0 && !step?.isComplete && (step?.stepIndex !== 4)">
        {{stepSubTitleEmptySelection | translate}}</span>
        <span *ngIf="currentSelectionValue > 0 || step?.isComplete">
          {{stepSubTitle | translate}}</span>

        <ng-container *ngIf="maxSelectionValue > selectionComplexityLimitationValue ; else invisibleDiv">
          <app-progress-bar
            [nameValue]="valueName"
            [currentValue]="currentSelectionValue"
            [maxValue]="selectionComplexityLimitationValue"
            [progressBarWidth]="progressBarWidth">
          </app-progress-bar>
        </ng-container>

        <ng-template #invisibleDiv>
          <div class="invisible">I'm invisible</div>
        </ng-template>

      </div>
    </div>


    <div class="wizard-buttons-header d-flex justify-content-between">

      <div class="d-flex">

        <button *ngIf="!isFirstStep()"
                (click)="onPrevStep()"
                class="btn align-self-center btn-prev d-flex">
            <div class="icon-btn icon-btn--arrow-left align-self-center"></div>
        </button>

        <div class="add-marge-adapter-next align-self-center"
             [ngbTooltip]="error ? tipAlert: ''"
             tooltipClass="{{alertId? 'tooltip-alert-error' : 'tooltip-alert-warning'}}"
             placement="bottom">

          <button (click)="onNextStep()"
                  [disabled]="awaitingSubmission || (error || !(currentStep | async)?.isComplete)"
                  class="btn align-self-center btn-next d-flex">

            <ng-container *ngIf="!isLastStep()">
              <div class="align-self-center">{{'button.next' | translate}}</div>
              <div class="align-self-center icon-wizard icon-wizard--arrow-right"></div>
            </ng-container>

            <ng-container *ngIf="isLastStep()">
              <div class="align-self-center">{{ validationButtonText | translate }}</div>
              <div class="align-self-center icon-wizard icon-wizard--checkmark"></div>
            </ng-container>

          </button>
        </div>
      </div>

      <ng-template #tipAlert>
        <div class="text-left">
          <div>{{'button.alert-anomalies' | translate}}</div>
        </div>
      </ng-template>

    </div>
  </div>

</div>
