<div [hidden]="docId && docId != impacterId" class="contain" #article id="{{articleId}}">
  <app-stats-page *ngIf="totalCount === null" pageName="article" [sourceCount]="nbTotalSource" [totalCount]="nbTotalArticle"></app-stats-page>
  <app-result-message *ngIf="totalCount === 0" [inImpacterView]="!!impacterId"></app-result-message>
  <app-loader [inImpacterView]="!!impacterId"></app-loader>
  <div [hidden]="!totalCount" class="list-content">
    <ng-container *ngFor="let article of articles">
      <app-article-card
        [bookmarkPaths]="article.bookmarkPaths"
        [docId]="article.id"
        [title]="article.title"
        [publicationDate]="article.publicationDate"
        [source]="article.source"
        [nbOccurrences]="article.occurrenceCount"
        [text]="article.excerpt[0]"
        [territories]="article.territories"
        [photoCount]="article.html?.nbImages"
        [impacterId]="impacterId"
      >
      </app-article-card>
    </ng-container>
    <app-pagination *ngIf="nbPages > 1" [nbPages]="nbPages" [stats]="dataUpdated" [inImpacterView]="!!impacterId"></app-pagination>
  </div>
</div>
<section>
  <router-outlet></router-outlet>
</section>
