<div class="container-fluid nopadding">
    <div class="position-absolute m-3" style="bottom: 0;right: 0;z-index: 999;">
        <img src="assets/images/logo.jpg" width="100px" alt="logo-explain">
    </div>
    <div class="row nopadding">
        <div class="col-xl-6 col-lg-4 col-md-3 left-side"></div>
        <div class="col-xl-6 col-lg-8 col-md-9 col-sm-12 col-12 right-side pt-5 d-flex justify-content-center">
            <div class="mt-5" style="width: 80%;">
                <div class="title">
                    {{'navigator.title' | translate}}
                </div>
                <div class="description mt-3 mb-5">
                    {{'navigator.description' | translate}}
                </div>
                <div class="d-flex w-100 justify-content-start">
                    <div class="row w-100 nopadding">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 nopadding">
                            <div class="Rectangle pt-3 mr-4 mt-2" (click)="openChrome()" (mouseenter)="hover('chrome')" (mouseleave) ="out('chrome')" >
                                <div class="w-100 d-flex justify-content-center">
                                    <div>
                                        <img src="assets/images/chrome.png" width="64px" alt="icon-chrome">
                                    </div>
                                </div>
                                <div class="w-100 d-flex justify-content-center mt-2 navigator">
                                    {{'navigator.chrome.title' | translate}}
                                </div>
                                <div class="w-100 d-flex justify-content-center  mt-1 navigator-description">
                                    {{'navigator.chrome.description' | translate}}
                                </div>
                                <div [ngClass]="{'d' : chromeDownload}" class="w-100 d-flex justify-content-center align-items-center  mt-3 download">
                                    <img src="assets/images/download.svg" width="20px" alt="icon-download">
                                    <div>{{'navigator.download' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 nopadding">
                            <div (click)="openFirefox()" class="Rectangle2 pt-3 mt-2"  (mouseenter)="hover('firefox')" (mouseleave) ="out('firefox')">
                                <div class="w-100 d-flex justify-content-center">
                                    <div>
                                        <img src="assets/images/firefox.png" width="64px" height="64px" alt="icon-firefox">
                                    </div>
                                </div>
                                <div class="w-100 d-flex justify-content-center mt-2 navigator">
                                    {{'navigator.firefox.title' | translate}}
                                </div>
                                <div class="w-100 d-flex justify-content-center  mt-1 navigator-description">
                                    {{'navigator.firefox.description' | translate}}
                                </div>
                                <div  [ngClass]="{'d' : firefoxDownload}" class="w-100 d-flex justify-content-center align-items-center  mt-3 download">
                                    <img src="assets/images/download.svg" width="20px" alt="icon-download">
                                    <div>{{'navigator.download' | translate}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
