import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Article } from '../../../models/article';

interface EntitiesContext {
  images: Object;
  texts: Object;
  count: number;
}

@Component({
  selector: 'app-entities-accordion',
  templateUrl: './entities-accordion.component.html',
  styleUrls: ['./entities-accordion.component.scss']
})
export class EntitiesAccordionComponent implements OnInit {
  @Input() article: Article;

  imCtx: EntitiesContext;
  teCtx: EntitiesContext;

  ngOnInit(): void {
    if (!this.article) { return; }
    this.imCtx = {
      images: {
        header: '../../../../assets/images/icon-people.svg',
        empty: '../../../../assets/images/icon-people-unknown.svg'
      },
      texts: {
        empty: 'article.no-impacters-identified',
        one:  'article.impacter-identified',
        many: 'article.impacters-identified'
      },
      count: this.article.impacters.length
    };
    this.teCtx = {
      images: {
        header: '../../../../assets/images/icon-pin.svg',
        empty: '../../../../assets/images/icon-pin-unknown.svg'
      },
      texts: {
        empty: 'article.no-towns-identified',
        one:  'article.town-identified',
        many: 'article.towns-identified'
      },
      count: this.article.territories.length
    };
  }
}
