import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-example-guide-template',
  templateUrl: './example-guide-template.component.html',
  styleUrls: ['./example-guide-template.component.scss']
})
export class ExampleGuideTemplateComponent implements OnInit {
  @Input() examples: Array<{ text: string, correct: boolean }>;

  constructor() {
  }

  ngOnInit(): void {
  }

}
