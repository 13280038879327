import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {

  transform(array: Array<object>, attribute: string, value: string) {
    return array.filter(i => i[attribute] === value);
  }
}
