import { TreeviewItem } from '../models/treeview-item';

export const TreeviewHelper = {
  concatSelection
};

function concatSelection(items: TreeviewItem[], checked: TreeviewItem[], unchecked: TreeviewItem[]): { [k: string]: TreeviewItem[] } {
  let checkedItems = [...checked];
  let uncheckedItems = [...unchecked];
  for (const item of items) {
    const selection = item.getSelection();
    checkedItems = checkedItems.concat(selection.checkedItems);
    uncheckedItems = uncheckedItems.concat(selection.uncheckedItems);
  }
  return {
    checked: checkedItems,
    unchecked: uncheckedItems
  };
}
