<div class="d-flex search-bar"
     [ngClass]="{
        compact: display === 'compact'
      }"
>
  <div class="w-100 position-relative">
    <div *ngIf="display === 'normal'" class="icon position-absolute icon-search--search"></div>
    <input type="text" id="search-test" [ngClass]="{'in-search': totalMatches}"
           [placeholder]="'search.search-placeholder-not-quote-mark' | translate"
           [(ngModel)]="searchText"
           (keyup.enter)="find()"
           autocomplete="off"
           [disabled]="!documentLoaded"
    />
    <div class="d-flex position-absolute find-btn" *ngIf="totalMatches && searchText">
      <span>{{currentMatchNumber}}/{{totalMatches}}</span>
      <mat-icon
        svgIcon="arrow-ios-up"
        class="btn-prev"
        (click)="findPrevious()"
      ></mat-icon>
      <mat-icon
        svgIcon="arrow-ios-down"
        class="btn-next"
        (click)="findNext()"
      ></mat-icon>
    </div>
      <mat-icon
        *ngIf="searchText"
        svgIcon="close-circle"
        (click)="searchText=''"
        class="close-icon-button"></mat-icon>
  </div>
  <button class="d-flex" (click)="find()"
          [ngClass]="{ 'compact-button': display === 'compact'}"
  >
    <ng-container *ngIf="display === 'compact'; else normal">
      <mat-icon svgIcon="search"></mat-icon>
    </ng-container>
  </button>
</div>

<ng-template #normal>
  <em class="icon-search icon-search--search align-self-center"></em>
  <span class="text-uppercase font-weight-bold align-self-center">{{'search.search-btn' | translate}}</span>
</ng-template>
