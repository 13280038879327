<div class="no-select overview-container total-step-width">

  <!--Separation-->
  <div *ngIf="!displayError500Panels && isScrollDown138px"
       class="result-separator-on-scroll-down">
  </div>

  <!--Scrolling management-->
  <ng-container *ngIf="!isScrollDown138px">

    <!--Overview abstract-->
    <div *ngIf="!displayError500Panels; else error500Panel"
         class="overview-abstract">

      <!--Loader taking all space-->
      <div *ngIf="!fetchingComplete"
           class="no-select overview-loader transparent-effect card-info d-flex align-items-center justify-content-center">
        <app-simple-loader></app-simple-loader>
      </div>

      <div class="alert-name d-flex justify-content-center align-items-center">
        <div class="text-center">
          {{ alertName }}
        </div>
      </div>

      <!--Main container-->
      <div class="previous-next-period-container">

        <div class="left-part d-flex">
          <button class="align-middle"
                  (click)="onNextPreview()">
            <div class="icon-btn icon-btn--arrow-left"></div>
          </button>
          <div class="d-table">
            <div class="d-table-cell title-date-numerical underline align-middle">
              {{alertDocumentPreviewPeriod?.from | date:'dd/MM/y':'':'fr'}}
            </div>
          </div>
        </div>

        <div class="center-part d-table">
          <div class="title-date text-center d-table-cell align-middle">
            {{alertDocumentPreviewPeriod?.alertDate | date:'EEEE dd MMMM y':'':'fr' | articleTitleCase}}
          </div>
        </div>

        <div class="right-part d-flex">
          <div class="d-table">
            <div class="d-table-cell title-date-numerical underline align-middle">
              {{alertDocumentPreviewPeriod?.to | date:'dd/MM/y':'':'fr'}}
            </div>
          </div>
          <button class="align-self-center"
                  (click)="onPrevPreview()"
                  [disabled]="!this.Params.alert_offset">
            <div class="icon-btn icon-btn--arrow-right"></div>
          </button>
        </div>

      </div>

      <!--Describe the currently displayed period-->
      <div class="period-description d-flex flex-column align-middle justify-content-center">

        <div class="ligne-1 d-flex justify-content-center">
          {{"wizard.for-the-period" | translate}}
          <div class="ligne-1-dates d-flex">
            {{alertDocumentPreviewPeriod?.from | date:'dd/MM/y':'':'fr'}}
            <div class="to">{{"wizard.to" | translate}}</div>
            {{alertDocumentPreviewPeriod?.to   | date:'dd/MM/y':'':'fr'}}
          </div>
        </div>
        <div class="ligne-2 text-center">
          {{"wizard.would-have-received" | translate}}
        </div>

      </div>

      <!--Contains icons w/ number of results-->
      <div class="found-documents-container d-flex align-middle justify-content-center">

        <div class="text-center">

          <div class="found-articles found-item d-flex align-middle">
            <div class="icon icon--article"></div>
            <div>
              {{alertDocumentPreviewPeriod?.nbArticle}}</div>
            <div>
              {{"wizard.article" | translate}}</div>
          </div>
          <div class="found-collectivity-publications found-item d-flex align-middle">
            <div class="icon icon--admin-doc"></div>
            <div>
              {{alertDocumentPreviewPeriod?.nbAdminDoc}}</div>
            <div>
              {{"wizard.publication" | translate}}</div>
          </div>

        </div>

      </div>


    </div>

  </ng-container>

  <!--Overview hidden abstract for scrolling management — may be used in the future...-->
  <div *ngIf="!displayError500Panels && isScrollDown138px"
       class="abstract-hidden-container">

    <div class="overview-abstract overview-hidden">

      <!--Loader taking all space-->
      <div *ngIf="!fetchingComplete"
           class="no-select overview-loader transparent-effect card-info d-flex align-items-center justify-content-center">
        <app-simple-loader></app-simple-loader>
      </div>

      <div class="alert-name d-flex justify-content-center align-items-center">
        <div class="text-center">
          {{ alertName }}
        </div>
      </div>

      <!--Main container-->
      <div class="previous-next-period-container">

        <div class="left-part d-flex">
          <button class="align-middle"
                  (click)="onNextPreview()">
            <div class="icon-btn icon-btn--arrow-left"></div>
          </button>
          <div class="d-table">
            <div class="d-table-cell title-date-numerical underline align-middle">
              {{alertDocumentPreviewPeriod?.from | date:'dd/MM/y':'':'fr'}}
            </div>
          </div>
        </div>

        <div class="center-part d-table">
          <div class="title-date text-center d-table-cell align-middle">
            {{alertDocumentPreviewPeriod?.alertDate | date:'EEEE dd MMMM y':'':'fr' | articleTitleCase}}
          </div>
        </div>

        <div class="right-part d-flex">
          <div class="d-table">
            <div class="d-table-cell title-date-numerical underline align-middle">
              {{alertDocumentPreviewPeriod?.to | date:'dd/MM/y':'':'fr'}}
            </div>
          </div>
          <button class="align-self-center"
                  (click)="onPrevPreview()"
                  [disabled]="!this.Params.alert_offset">
            <div class="icon-btn icon-btn--arrow-right"></div>
          </button>
        </div>

      </div>

    </div>
  </div>

  <!--Aperçu du contenu — titre-->
  <div *ngIf="!displayError500Panels" class="overview-title"
       [style.opacity]="isScrollDown138px ? 0 : 1">
    {{"wizard.overview-content" | translate | uppercase}}
  </div>

  <!--Aperçu du contenu — contenus-->
  <div *ngIf="!displayError500Panels" class="overview-previews-container no-select">

    <!--Loader taking top part space-->
    <div *ngIf="!fetchingComplete"
         [style.top.px]="getPartialUpLoaderToValueInPx()"
         class="no-select overview-loader--partial-up card-info d-flex align-items-center justify-content-center">
      <app-simple-loader></app-simple-loader>
    </div>

    <!--Transparent background taking all space-->
    <div *ngIf="!fetchingComplete"
         class="no-select overview-loader--full-down transparent-effect card-info d-flex align-items-center justify-content-center">
    </div>

    <div class="overview-previews bottom-side d-flex">

      <div class="overview-previews-list total-step-width">

        <ng-container>
          <ng-container *ngIf="totalCount > 0">
            <ng-container *ngFor="let preview of alertDocumentPreviews ; let i = index">
              <ng-container *ngIf="preview.source">
                <app-article-card
                  [docId]="preview.id"
                  [allowOnClick]="false"
                  [title]="preview.title"
                  [publicationDate]="preview.publicationDate"
                  [source]="preview.source"
                  [text]="preview.excerpt"
                  [isPreview]="true"
                  [territories]="preview.territories"
                  [photoCount]="preview.html?.nbImages"
                  [showOccurrences]="false"
                >
                </app-article-card>
              </ng-container>
              <ng-container *ngIf="preview?.website">
                <app-admin-doc-card
                  [showOccurrences]="false"
                  [allowOnClick]="false"
                  [docId]="preview.id"
                  [title]="preview.title"
                  [publicationDate]="preview.publicationDate"
                  [website]="preview.website"
                  [text]="preview.excerpt"
                  [isPreview]="true"
                  [url]="preview.url"
                  [displayedPublicationType]="preview.displayedPublicationType"
                >
                </app-admin-doc-card>
              </ng-container>
              <!--Line separator between contents-->
              <div *ngIf="i < alertDocumentPreviews.length"
                   class="line-separator-container">
                <div class="line-separator"></div>
              </div>
              <ng-container *ngIf="i < alertDocumentPreviews.length - 1">
              </ng-container>
              <ng-container *ngIf="alertDocumentPreviews.length === 1">
              </ng-container>
            </ng-container>
          </ng-container>

          <!--Case no result-->
          <ng-container *ngIf="totalCount === 0">
            <div class="no-result no-select">
              <div>{{"wizard.overview-no-result-title" | translate}}</div>
              <div>{{"wizard.overview-no-result-sub-title" | translate}}</div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-template #error500Panel class="d-flex align-middle justify-content-center h-100 total-step-width">
    <div class="error-500-panel h-100 w-100">
      <div class="error-message-container h-100 w-100">
        <div class="error-img w-100">
          <img src="../../../assets/images/eye-off-icon.svg" alt="eye-off-icon">
        </div>
        <div class="error-title" [innerHTML]="'wizard.overview-error-title' | translate">
        </div>
        <div class="error-message" [innerHTML]="'wizard.overview-error-message' | translate">
        </div>
      </div>
    </div>
  </ng-template>

</div>
