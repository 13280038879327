<a [routerLink]="allowOnClick ? docId : null"
   (contextmenu)="onClickCard($event)"
   (click)="onClickCard($event); trackClickedArticle()"
   [state]="{context: text}">
  <div id="card-{{docId}}" [ngClass]="{'preview': isPreview}" class="card-board">
    <div class="row">

      <div class="col-9 card-info d-flex">
        <div class="d-flex align-self-center">
          <div class="icon icon--calendar"></div>
          <div *ngIf="publicationDate" class="text-capitalize">{{publicationDate | date:'dd MMMM yyyy':'':'fr' }}</div>
          <div *ngIf="!publicationDate" class="text-capitalize">{{'content.date_not_detected' | translate}}</div>
        </div>
        <div class="d-flex align-self-center">
          <div class="icon icon--article"></div>
          <div>{{source}}</div>
        </div>
      </div>

      <div class="col-3 occurrence-and-folder d-flex justify-content-end position-static">
        <app-occurrence-and-folder-icon-badge
          class="d-flex"
          [folderId]="folderId"
          [nbOccurrences]="nbOccurrences"
          [showOccurrences]="showOccurrences"
          [bookmarkPaths]="bookmarkPaths"
          [displayBookmarkCreationDate]="displayBookmarkCreationDate">
        </app-occurrence-and-folder-icon-badge>
      </div>
    </div>

    <div class="card-title position-static text-truncate" [innerHTML]="title | articleTitleCase"></div>

    <div class="territory-section"
         [ngClass]="displayReadMore ? 'split' : ''"
         id="territory-section-{{docId}}">
      <div *ngIf="territories.length > 0"
           class="territories d-flex align-self-center">
        <mat-icon svgIcon="pin" class="icon-16 fill-primary-500 mr-1"></mat-icon>
        <div id="territory-list-{{docId}}" class="territory-list text-truncate-territories">
          <span *ngFor="let territory of territories; let i = index">
            <span class="territory-name font-weight-bold color-primary-500">{{territory['name']}}</span>
            <span *ngIf="i < territories.length - 1"
                  class="separator color-basic-600 font-weight-light"> | </span>
            <span *ngIf="i === territories.length - 1" class="fantom-space">...</span>
          </span>
        </div>
      </div>
      <div *ngIf="territories.length === 0" class="territories d-flex align-self-center">
        <mat-icon svgIcon="pin-basic-600" class="icon-16 fill-basic-600 mr-1"></mat-icon>
        <span class="font-italic territory-name font-weight-bold color-basic-600">{{'content.non-detected-territory' | translate}}</span>
      </div>
      <div *ngIf="displayReadMore" class="fading-color-box">

      </div>
      <div *ngIf="displayReadMore"
           id="read-more-{{docId}}"
           class="read-more d-flex"
           (click)="onReadMoreClick($event)"
           [ngbTooltip]="territoryTooltip"
           tooltipClass="custom-tooltip-article-card-territories"
           placement="bottom-right">
        <mat-icon svgIcon="plus-circle-icon-fill" class="icon-16 fill-basic-600 mr-1 align-self-center align-middle"></mat-icon>
        <div class="text color-basic-600 font-weight-bold align-self-center">{{"content.see-more"| translate}}</div>
      </div>
    </div>

    <div class="card-text" [innerHTML]="text"></div>
  </div>
</a>

<ng-template #territoryTooltip class="territory-tooltip">
      <span *ngFor="let territory of territories; let i = index">
            <span class="territory-name font-weight-bold color-white">{{territory['name']}}</span>
            <span *ngIf="i < territories.length - 1"
                  class="separator color-basic-600 font-weight-light"> | </span>
      </span>
</ng-template>
