import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  /**
   * Method to add an item to a collection inside localStorage
   * @param keyName - stored collection key name
   * @param itemToStore - item to store
   */
  storeItem(keyName: string, itemToStore: any) {
    let storedCollection = JSON.parse(localStorage.getItem(keyName));
    if (!storedCollection) {
      storedCollection = { storedItems : []};
    }
    const indexOfExisting = storedCollection.storedItems.findIndex(item => item.id === itemToStore.id);
    if (indexOfExisting > -1) {
      storedCollection.storedItems[indexOfExisting] = itemToStore;
    } else {
      storedCollection.storedItems.push(itemToStore);
    }
    localStorage.setItem(keyName, JSON.stringify(storedCollection));
  }

  /**
   * Method to purge the localStorage. Compares Date.now() with expiry.
   * @param storedCollectionKey key name of the storedCollection object
   */
  sanitizeLocalStorage(storedCollectionKey: string) {
    let storedCollection = JSON.parse(localStorage.getItem(storedCollectionKey));

    if (!storedCollection) {
      storedCollection = { storedItems: [] };
      localStorage.setItem(storedCollectionKey, JSON.stringify(storedCollection));
      return;
    }

    const sanitizedArray = storedCollection?.storedItems
      .filter(storedItem => Number(storedItem.expiry) > Date.now());

    localStorage.setItem(storedCollectionKey, JSON.stringify({ storedItems: sanitizedArray }));
  }

  /**
   * Method to set up a period sanitization of the localStorage
   * @param storedCollectionKey - stored collection key name
   * @param duration - ms
   */
  setUpPeriodicSanitizer(storedCollectionKey: string, duration: number) {
    const period = interval(duration);

    period
      .pipe(tap(() => {
        this.sanitizeLocalStorage(storedCollectionKey);
      })).subscribe();

  }

}
